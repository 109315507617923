import React, { useCallback, useReducer } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import { useStateChange, makeInitialState } from "flows/interest/reducer";
import InterestInvestor from "./components/Investor";
import InterestAccreditation from "./components/Accreditation";
import InterestInvestment from "./components/Investment";

/* eslint-disable max-len */
export const InterestNewInvestor = ({
  entities,
  onNext,
  step,
  deal,
  asset
}) => {
  // Set initial state
  const initial = makeInitialState({ entities });

  // Setup state management
  const [state, handleChange] = useStateChange(initial);
  const handleSubmit = () => onNext(state);

  return (
    <>
      {step === 1 && (
        <InterestInvestor
          entities={entities}
          onSubmit={handleSubmit}
          onChange={handleChange}
          {...{ state }}
        />
      )}
      {step === 2 && (
        <InterestAccreditation
          onSubmit={handleSubmit}
          onChange={handleChange}
          {...{ asset, state }}
        />
      )}
      {step === 3 && (
        <InterestInvestment
          onSubmit={handleSubmit}
          onChange={handleChange}
          {...{ asset, deal, state }}
        />
      )}
    </>
  );
};

InterestNewInvestor.propTypes = {
  onNext: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  entities: PropTypes.array.isRequired,
  deal: PropTypes.object.isRequired,
  asset: PropTypes.shape({
    investorCheck: PropTypes.string.isRequired
  }).isRequired
};

InterestNewInvestor.fragments = {
  entity: gql`
    fragment InterestNewInvestorEntity on Entity {
      ...InterestInvestorEntity
      ...InterestAccreditationEntity
    }
    ${InterestInvestor.fragments.entity}
    ${InterestAccreditation.fragments.entity}
  `,
  deal: gql`
    fragment InterestNewInvestorDeal on Deal {
      ...InterestInvestmentDeal
    }
    ${InterestInvestment.fragments.deal}
  `,
  asset: gql`
    fragment InterestNewInvestorAsset on Asset {
      ...InterestAccreditationAsset
    }
    ${InterestAccreditation.fragments.asset}
  `
};

export default InterestNewInvestor;

import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { graphql } from "utils/apollo";
import gql from "graphql-tag";

import Spinner from "components/Spinner";
import Page from "components/Page";

import TaxDocs from "./component";

import "./style.css";

const Header = () => <h2>Tax Documents</h2>;

export const TaxDocsContainer = ({ data: { loading, userinfo } }) => {
  if (loading) {
    return (
      <>
        <Header />
        <Spinner data-test="tax_spinner" />
      </>
    );
  }
  return (
    <>
      <Header />
      <TaxDocs userinfo={userinfo} data-test="tax_child" />
    </>
  );
};

TaxDocsContainer.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    userinfo: PropTypes.object
  }).isRequired
};

export const query = gql`
  query TaxDocs {
    userinfo {
      id
      tax_documents {
        documents {
          id
          self
          year
          type
        }
      }
    }
  }
`;

export default compose(graphql(query))(TaxDocsContainer);

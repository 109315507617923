import React, { useCallback, useReducer } from "react";

import { defineAction } from "redux-define";
import { createAction, handleActions } from "redux-actions";
import { get, find } from "lodash";

const namespace = "flows/interest";

const PERSIST = defineAction("PERSIST", namespace);
export const persist = createAction(PERSIST.ACTION);

// Selectors
export const selectInterest = store => store.flows.interest;
export const selectInvestor = store => get(selectInterest(store), "investor");
export const selectAfter = store => get(selectInterest(store), "after");
export const selectConfirmationToast = store =>
  get(selectInterest(store), "confirmationToast");

export const initialState = {
  investor: {
    investorType: "person",
    investorCompany_type: "LLC",
    investorLLC_type: "P",
    investorName: "",
    investorSignatory: "",
    investorAddressStreet: "",
    investorAddressCity: "",
    investorAddressState: "",
    investorAddressZip: "",
    investorTaxId_F1: "",
    investorTaxId_F2: "",
    investorTaxId_S1: "",
    investorTaxId_S2: "",
    investorTaxId_S3: "",
    investorEntity_self: "new",
    accredited: null,
    qualified: null,
    investment_amount: null,
    investment_risk: false,
    legally_bound: false
  }
};

export const makeInitialState = ({ allocation, backing, entities }) => {
  let {
    investor: {
      investment_amount,
      accredited,
      qualified,
      investorEntity_self,
      investorName,
      investorType
    }
  } = initialState;
  let entity;

  if (allocation) {
    // Change allocation
    investment_amount = allocation.investment.amount;
    entity = find(entities, ["self", allocation.entity.self]);
  } else {
    if (backing) {
      // Use backing
      investment_amount = backing.amount;
    }

    if (entities && entities.length) {
      // Previous investor
      entity = entities[0];
    }
  }

  if (entity) {
    investorEntity_self = entity.self;
    accredited = entity.accreditation && entity.accreditation.type;
    qualified = entity.qualifiedPurchaser && entity.qualifiedPurchaser.type;
    investorType = entity.type;
    investorName = entity.name;
  }

  return Object.assign({}, initialState.investor, {
    investorEntity_self,
    accredited,
    qualified,
    investorType,
    investorName,
    investment_amount
  });
};

export const reducer = handleActions(
  {
    [PERSIST.ACTION]: (state, action) => ({
      ...state,
      ...action.payload
    })
  },
  initialState
);

export const useStateChange = initial => {
  const [state, dispatch] = useReducer(reducer, initial);
  const handleChange = useCallback(
    ({ name, value }) => {
      dispatch(
        persist({
          [name]: value
        })
      );
    },
    [dispatch, persist]
  );
  return [state, handleChange];
};

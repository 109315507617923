import React from "react";

import Page from "components/Page";

import "../../../flows/invest/confirmed/style.css";

const InvestConfirmed = () => (
  <section className="pageContainer">
    <Page>
      <section styleName="agreementContainer">
        <img src="/images/tick.svg" alt="" />
        <h2>You&#39;ve signed the closing documents</h2>
        <section styleName="messages" data-test="confirmation">
          <p styleName="transfer">
            Please wire your contribution, using the instructions emailed to you
          </p>
        </section>
      </section>
      <p styleName="logout">
        Since you&#39;re done we&#39;ve automatically logged you out
      </p>
    </Page>
  </section>
);

export default InvestConfirmed;

import React from "react";
import PropTypes from "prop-types";

import "./style.css";

export const TaxDocs = ({
  userinfo: {
    tax_documents: { documents }
  }
}) => {
  if (documents.length === 0) {
    return (
      <div styleName="none" data-test="tax_docs_none">
        <section styleName="detail">
          <p>None issued yet</p>
        </section>
      </div>
    );
  }

  return (
    <>
      {documents.map(doc => (
        <a
          href={`/docs/tax_documents/${doc.id}`}
          target="_blank"
          key={doc.id}
          data-test="tax_docs_link"
        >
          <div styleName="container">
            <section styleName="detail">
              <header styleName="name">{doc.year}</header>
            </section>
            <section styleName="detail">
              <header styleName="link">Schedule K-1</header>
              <p>Form 1065</p>
            </section>
          </div>
        </a>
      ))}
    </>
  );
};

TaxDocs.propTypes = {
  userinfo: PropTypes.shape({
    tax_documents: PropTypes.shape({
      documents: PropTypes.array.isRequired
    }).isRequired
  }).isRequired
};

export default TaxDocs;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { login } from "flows/session/reducer";

import Spinner from "components/Spinner";
import { useAuth0 } from "@auth0/auth0-react";

const LoggedIn = () => {
  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    const setToken = async () => {
      const accessToken = await getAccessTokenSilently();
      const claims = await getIdTokenClaims();

      dispatch(
        login({
          accessToken,
          idTokenPayload: user,
          expiresAt: claims.exp * 1000,
        })
      );
    };

    if (isAuthenticated) {
      setToken();
    }
  }, [
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
    user,
    dispatch,
  ]);

  return (
    <div>
      <Spinner />
    </div>
  );
};

export default LoggedIn;

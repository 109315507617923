exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".web-styles-___shadow__bottom___UWpEM {\n  box-shadow: 0px 1px 1px 0px #dadade;\n}\n\n.web-styles-___shadow__bottom_important___2DbM6 {\n  box-shadow: 0px 1px 1px 0px #dadade !important;\n}\n\n.web-styles-___shadow__top___OmpW3 {\n  box-shadow: 0px -1px 1px 0px #dadade;\n}\n\n.web-styles-___shadow__top_important___2fodl {\n  box-shadow: 0px -1px 1px 0px #dadade !important;\n}\n", ""]);

// Exports
exports.locals = {
	"bottom": "web-styles-___shadow__bottom___UWpEM",
	"bottom_important": "web-styles-___shadow__bottom_important___2DbM6",
	"top": "web-styles-___shadow__top___OmpW3",
	"top_important": "web-styles-___shadow__top_important___2fodl"
};
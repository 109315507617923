exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/box.css"), "");
exports.i(require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css"), "");

// Module
exports.push([module.id, "/* Calculator styles */\n\n.web-flows-interest-share-components-InterestCalculator-___style__calc_container___1Ufvx {\n  padding: 1rem;\n}\n\n.web-flows-interest-share-components-InterestCalculator-___style__calc_summary___lM38J {\n  margin-top: 0;\n  font-weight: 14pt;\n}\n\n.web-flows-interest-share-components-InterestCalculator-___style__calc_summary_amount___11WGF {\n  font-weight: 600;\n}\n\n.web-flows-interest-share-components-InterestCalculator-___style__open_link___36t6T {\n  display: block;\n  margin-top: 0.33rem;\n}\n\n.web-flows-interest-share-components-InterestCalculator-___style__header___3zizV {\n  padding: 0;\n}\n", ""]);

// Exports
exports.locals = {
	"calc_container": "web-flows-interest-share-components-InterestCalculator-___style__calc_container___1Ufvx " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/box.css").locals["box"] + "",
	"calc_summary": "web-flows-interest-share-components-InterestCalculator-___style__calc_summary___lM38J",
	"calc_summary_amount": "web-flows-interest-share-components-InterestCalculator-___style__calc_summary_amount___11WGF " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css").locals["green"] + "",
	"open_link": "web-flows-interest-share-components-InterestCalculator-___style__open_link___36t6T",
	"header": "web-flows-interest-share-components-InterestCalculator-___style__header___3zizV"
};
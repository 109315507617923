import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { get, find, isInteger } from "lodash";
import { renderPoints, toAmount, toRoundedAmount } from "utils";
import { HiddenP, SliddenDiv, HiddenDiv, PoseGroup } from "utils/posed";

import "./style.css";

const Question = ({ q, a }) => {
  const [expanded, setExpanded] = useState(false);
  const onLinkClick = useCallback(
    e => {
      e.stopPropagation();
      setExpanded(!expanded);
    },
    [setExpanded, expanded]
  );

  const onTextClick = useCallback(e => {
    e.stopPropagation();
  }, []);

  return (
    <li styleName="question_item" onClick={onTextClick}>
      <PoseGroup flipMove={false}>
        <HiddenP
          key="q"
          styleName={`question_header ${
            expanded ? "expanded_question_header" : ""
          }`}
        >
          <a onClick={onLinkClick}>
            <span styleName="arrow">{expanded ? "▼ " : "►"}</span>
            {q}
          </a>
        </HiddenP>
        {!!expanded && (
          <SliddenDiv key="a">
            <section styleName="answer">{a}</section>
          </SliddenDiv>
        )}
      </PoseGroup>
    </li>
  );
};

const Metric = ({
  id,
  name,
  shortDescription,
  longDescription,
  projected,
  actual,
  actualLabel,
  questions,
  expanded,
  onToggle
}) => {
  return (
    <PoseGroup>
      <HiddenDiv key="content">
        <section onClick={onToggle}>
          <h4 styleName="label">{name}</h4>

          {!expanded && (
            <HiddenDiv styleName="metric_subnote" key="false">
              {shortDescription} <wbr />
              <a className="no_wrap no_mobile">Tell me more</a>
            </HiddenDiv>
          )}
          {!!expanded && (
            <HiddenDiv
              styleName="metric_subnote_expanded"
              onClick={onToggle}
              key="true"
            >
              {longDescription} <a>&nbsp;less</a>
            </HiddenDiv>
          )}
          <section
            styleName={`metrics ${expanded ? "expanded_metrics" : ""}`}
            key="metrics"
          >
            <section>
              <p styleName="projected" data-test={`tracker_${id}_projected`}>
                {projected}
              </p>
              <p styleName="subnote">Projected</p>
            </section>
            <section>
              <p styleName="actual" data-test={`tracker_${id}_actual`}>
                {actual}
              </p>
              <p styleName="subnote">{actualLabel}</p>
            </section>
          </section>
          {!!expanded && (
            <SliddenDiv key="questions">
              <h4 styleName="questions_header">Common Questions</h4>
              <ul styleName="questions_list">
                {questions.map(q => (
                  <Question {...q} key={q.q} />
                ))}
              </ul>
            </SliddenDiv>
          )}
          {!expanded && (
            <p>
              <a key="more" className="only_mobile">
                Tell me more
              </a>
            </p>
          )}
        </section>
      </HiddenDiv>
    </PoseGroup>
  );
};

const yieldMetric = ({ distributions }) => {
  let dataDesc = (
    <p>
      The actual yield is calculated from averaging the yields of the{" "}
      {distributions.length} distributions you've received:
    </p>
  );
  if (distributions.length === 1) {
    dataDesc = (
      <p>The actual yield is the yield of the distribution you've received:</p>
    );
  }

  const dataTable = (
    <table styleName="table">
      <tbody>
        <tr>
          <th>Distribution</th>
          <th>Amount</th>
          <th>Yield</th>
        </tr>
        {distributions.map(
          ({ id, yield: distro_yield, breakdown: { net }, description }) => (
            <tr key={id}>
              <td>{description}</td>
              <td>{toAmount(net)}</td>
              {!!(distro_yield && distro_yield.net) && (
                <td>{renderPoints(distro_yield.net)}</td>
              )}
            </tr>
          )
        )}
      </tbody>
    </table>
  );

  return {
    id: "yield",
    name: "Yield",
    shortDescription: "Annual return",
    longDescription:
      "Measures how much of your investment you receive back each year. Typically dividend stocks have a yield of around 4%.",
    actualLabel: "So far",
    questions: [
      {
        q: "The yield looks good, so this investment on track, right?",
        a: (
          <>
            <p>
              Maybe. Yield is calculated from the distributions you've received
              so far, theoretically based on the income generated by the
              underlying asset.
            </p>
            <p>
              However operators keep a reserve to even out fluctuations in
              income. Their business plan can also use the reserve strategically
              to meet specific goals or to mitigate known risks. Your
              distributions could be funded from reserves instead of net income
              because the asset is underperforming, because it's part of the
              business plan or both.
            </p>
            <p>
              Review the quarterly updates to check that the net operating
              income is on track with the business plan and that reserves aren't
              depleted.
            </p>
          </>
        )
      },
      {
        q: "What’s the data behind these numbers?",
        a: (
          <>
            <p>
              The projected yield is calculated from the financial model
              underwritten by the sponsor. The number presented is an average of
              the entire investment term, so if the asset yield is projected to
              increase in the future, the average yield achieved so far may be
              lower than the projected average and still be on track.
            </p>
            {dataDesc}
            {dataTable}
          </>
        )
      }
    ]
  };
};

const irrMetric = ({ allocation }) => {
  let dataTable;
  const breakdown = get(allocation, "irr.breakdown");
  const projected_breakdown = get(allocation, "projected.irr.breakdown");

  const total = breakdown.reduce((acc, currnt) => {
    if (isInteger(currnt.amount)) {
      return acc + currnt.amount;
    }
  }, 0);
  const total_projected = projected_breakdown.reduce((acc, currnt) => {
    if (isInteger(currnt.amount)) {
      return acc + currnt.amount;
    }
  }, 0);

  if (breakdown && projected_breakdown) {
    dataTable = (
      <table styleName="table">
        <tbody>
          <tr>
            <th>Year</th>
            <th>Projected</th>
            <th>Expected</th>
          </tr>
          {breakdown.map(({ year, amount }) => {
            const projected_year = find(projected_breakdown, { year });
            const projected_amount =
              (projected_year && toRoundedAmount(projected_year.amount)) ||
              "N/A";
            return (
              <tr key={year}>
                <td>Year {year}</td>
                <td>{projected_amount}</td>
                <td>{toRoundedAmount(amount)}</td>
              </tr>
            );
          })}
          <tr key="total">
            <td styleName="total">Total</td>
            <td styleName="total">{toRoundedAmount(total_projected)}</td>
            <td styleName="total">{toRoundedAmount(total)}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  return {
    id: "irr",
    name: "IRR",
    shortDescription: "Overall return",
    longDescription:
      "Measures your overall return on investment of an asset while taking into account the time value of money.",
    actualLabel: "Expected",
    questions: [
      {
        q: "The time value of what now?",
        a: (
          <>
            <p>
              If two investments return the same overall amount but one is a 5
              year deal and the other a 10 year deal, the one that returns
              earlier will have a higher IRR.{" "}
            </p>
            <p>
              That's because you can reinvest the returns from the 5 year deal
              so that by the time the 10 year deal liquidates, you'll have
              earned more from the 5 year deal than the 10 year deal.
            </p>
          </>
        )
      },
      {
        q:
          "The expected IRR looks about right, so this investment is on track?",
        a: (
          <>
            <p>
              Maybe. The majority of your returns will materialize when the
              asset is sold at the end of the investment term, so differences
              between projected and actual distributions will only result in
              small changes to the expected IRR.
            </p>
            <p>
              To fully evaluate an investment, you need to determine how well
              the operator is executing on their business plan and whether their
              projections about the market are proving true. This should be
              disclosed in quarterly updates.
            </p>
            <p>
              As a first step, look at the net operating income (NOI) to measure
              their success in plan execution.{" "}
            </p>
          </>
        )
      },
      {
        q: "What’s the data behind these numbers?",
        a: (
          <>
            <p>
              The projected IRR is calculated from the financial model produced
              by underwriter.
            </p>
            <p>
              The expected IRR is calculated from combining the distributions
              you've received with that projection.
            </p>
            {!!(allocation.irr && allocation.irr.breakdown) && dataTable}
          </>
        )
      }
    ]
  };
};

const PerformanceMetrics = ({ allocation, distributions }) => {
  const projected_yield = get(allocation, "projected.yield.net");
  const actual_yield = get(allocation, "yield.net");
  const projected_irr = get(allocation, "projected.irr.net");
  const actual_irr = get(allocation, "irr.net");

  const [yieldExpanded, setYieldExpanded] = useState(false);
  const [irrExpanded, setIrrExpanded] = useState(false);

  const toggleYieldExpand = useCallback(
    () => setYieldExpanded(!yieldExpanded),
    [yieldExpanded, setYieldExpanded]
  );
  const toggleIrrExpand = useCallback(() => setIrrExpanded(!irrExpanded), [
    irrExpanded,
    setIrrExpanded
  ]);

  if (projected_yield && actual_yield && projected_irr && actual_irr) {
    return (
      <section
        styleName={
          yieldExpanded || irrExpanded ? "expanded_container" : "container"
        }
      >
        <Metric
          projected={renderPoints(projected_yield)}
          actual={renderPoints(actual_yield)}
          expanded={yieldExpanded}
          onToggle={toggleYieldExpand}
          {...yieldMetric({ distributions })}
        />
        <Metric
          projected={renderPoints(projected_irr)}
          actual={renderPoints(actual_irr)}
          expanded={irrExpanded}
          onToggle={toggleIrrExpand}
          {...irrMetric({ allocation })}
        />
      </section>
    );
  }
  return null;
};

PerformanceMetrics.propTypes = {
  allocation: PropTypes.shape({
    yield: PropTypes.shape({
      net: PropTypes.number.isRequired,
      irr: PropTypes.string.isRequired
    }).isRequired,
    projected: PropTypes.shape({
      yield: PropTypes.shape({
        net: PropTypes.number.isRequired,
        irr: PropTypes.string.isRequired
      })
    }).isRequired
  }).isRequired
};

export default PerformanceMetrics;

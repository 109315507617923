import React from "react";
import { connect } from "react-redux";

import Spinner from "components/Spinner";
import { selectUserLoaded } from "flows/session/selectors";

const mapUserLoaded = store => ({
  userLoaded: selectUserLoaded(store)
});

const waitForUser = () => WrappedComponent => {
  const WaitForUser = props => {
    if (props.userLoaded) {
      return <WrappedComponent {...props} />;
    } else {
      return <Spinner />;
    }
  };

  return connect(mapUserLoaded)(WaitForUser);
};

export default waitForUser;

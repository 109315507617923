import React from "react";
import PropTypes from "prop-types";

import "./Page.css";

const Page = props => (
  <div styleName="page" {...props}>
    {props.children}
  </div>
);

Page.propTypes = {
  children: PropTypes.node.isRequired
};

export default Page;

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactModal from "react-modal";

import {
  submitAnnouncement as submit,
  cancelAnnouncement as cancel
} from "flows/deal/announce/index";
import {
  selectShowAnnouncement as selectShow,
  selectAnnouncementSending as selectSending
} from "flows/deal/announce/reducer";

import Spinner from "components/Spinner";
import SendAnnouncement from "./component";

export const SendAnnouncementContainer = ({
  show,
  submit,
  cancel,
  sending,
  asset,
  deal,
  allocation
}) => (
  <ReactModal
    isOpen={show}
    contentLabel="Send Announcement"
    shouldCloseOnOverlayClick
    shouldFocusAfterRender={false}
    onRequestClose={cancel}
    className="ReactModal"
    ariaHideApp={false}
    data-test="announcement_modal"
  >
    <SendAnnouncement
      submit={submit}
      cancel={cancel}
      sending={sending}
      deal={deal}
      asset={asset}
      allocation={allocation}
      data-test="announcement_child"
    />
  </ReactModal>
);

SendAnnouncementContainer.propTypes = {
  show: PropTypes.bool.isRequired,
  sending: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  asset: PropTypes.object.isRequired,
  allocation: PropTypes.object
};

export const mapStore = store => ({
  show: selectShow(store),
  sending: selectSending(store)
});

const exported = connect(
  mapStore,
  { cancel, submit }
)(SendAnnouncementContainer);
exported.fragments = SendAnnouncement.fragments;
export default exported;

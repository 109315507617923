exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!../styles/box.css"), "");

// Module
exports.push([module.id, ".web-components-___Page__page___14mKH {\n  padding: 2em;\n  background-color: #fff;\n}\n\n.web-components-___Page__page___14mKH h2 {\n  padding-top: 0;\n}\n\n@media (max-width: 599px) {\n  .web-components-___Page__page___14mKH {\n    border: none;\n    border-top: 1px solid #e5e3e3;\n    border-bottom: 1px solid #e5e3e3;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"page": "web-components-___Page__page___14mKH " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!../styles/box.css").locals["box"] + ""
};
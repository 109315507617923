import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { graphql } from "utils/apollo";
import moment from "moment";
import { get, every } from "lodash";
import gql from "graphql-tag";

import { selectUserId } from "flows/session/selectors";

import Spinner from "components/Spinner";
import Page from "components/Page";

import DistributionTotals from "./components/totals";
import DistributionsGraph from "./components/graph";
import PerformanceMetrics from "./components/metrics";

import "./style.css";

export const TrackerContainer = ({ data: { loading, deal }, deal_id }) => {
  if (loading) {
    return <Spinner data-test="tracker_spinner" />;
  }

  const distributions = get(deal, "allocation.distributions.distributions");

  const has_proper_attributes = distro =>
    distro &&
    distro.breakdown &&
    distro.breakdown.net &&
    distro.description &&
    distro.endDate &&
    distro.projected &&
    distro.projected.net;

  const shouldRender =
    distributions &&
    distributions.length &&
    every(distributions, has_proper_attributes);

  if (shouldRender) {
    return (
      <div data-test="tracker_container">
        <header styleName="header">
          <h2 styleName="h2">Performance Tracker</h2>
          <p className="section_descriptor">
            How is this investment doing vs its projections?
          </p>
        </header>
        <section>
          <section style={{ marginTop: "1rem" }}>
            <PerformanceMetrics
              allocation={deal.allocation}
              distributions={distributions}
            />
          </section>
          <section styleName="distributions_container">
            <h4 styleName="distributions_label">Distributions</h4>
            <DistributionTotals distributions={distributions} />
            <DistributionsGraph
              distributions={distributions}
              asset={deal.asset}
            />
          </section>
        </section>
      </div>
    );
  }
  return null;
};

TrackerContainer.propTypes = {
  dealId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    deal: PropTypes.shape({
      //TODO: fill er up
    })
  }).isRequired
};

const query = gql`
  query TrackerDistributions($dealId: ID!, $userId: ID!) {
    deal(id: $dealId) {
      id
      self
      asset {
        id
        self
        closingDate
      }
      allocation(user_id: $userId) {
        id
        self
        yield {
          net
        }
        irr {
          net
          breakdown {
            year
            amount
          }
        }
        projected {
          yield {
            net
          }
          irr {
            net
            breakdown {
              year
              amount
            }
          }
        }
        distributions {
          distributions {
            id
            distributedDate
            amount
            description
            breakdown {
              asset_earned
              perf_alloc_earned
              net
              gross
            }
            projected {
              net
              gross
            }
            yield {
              net
            }
            endDate
          }
        }
      }
    }
  }
`;

const mapPropsToOptions = ({ dealId, userId }) => ({
  variables: { dealId, userId }
});

export const mapStore = store => ({
  userId: selectUserId(store)
});

export default compose(
  connect(mapStore),
  graphql(query, {
    options: mapPropsToOptions
  })
)(TrackerContainer);

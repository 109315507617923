exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!./shadow.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!./type.css"), "");

// Module
exports.push([module.id, ".web-styles-___box__box___1H6FT {\n  box-shadow: var(--box-box-shadow);\n  background-color: #fff;\n}\n\n@media (min-width: 600px) {\n  .web-styles-___box__box___1H6FT {\n    border-radius: var(--box-border-radius);\n  }\n}\n\n.web-styles-___box__box_important___2f5t8 {\n  box-shadow: 0px 1px 2px 0px #dadade !important;\n  background-color: #fff !important;\n}\n\n.web-styles-___box__nobox___3Nlf4 {\n  background-color: transparent;\n  box-shadow: none;\n}\n\n.web-styles-___box__item___kmv0z {\n  margin: 1rem 0;\n}\n\n@media (max-width: 599px) {\n  .web-styles-___box__item___kmv0z {\n    margin: 0;\n    border-bottom: #e5e5e5 1px solid;\n  }\n\n  .web-styles-___box__item___kmv0z:first-child {\n    margin-top: 1rem;\n  }\n\n  .web-styles-___box__item___kmv0z:last-child {\n    margin-bottom: 1rem;\n    border: none;\n  }\n}\n\n.web-styles-___box__clickable___3S2cq {\n  transition: background-color 200ms ease;\n}\n\n.web-styles-___box__clickable___3S2cq:hover {\n  cursor: pointer;\n  background-color: #f7f7f7;\n}\n\n/* Title styles */\n.web-styles-___box__box_title___1iEGG {\n  font-size: 14pt;\n  color: #585858;\n  padding-bottom: 0.2em;\n  font-weight: 400;\n}\n\n.web-styles-___box__box_subtitle___2xyhX {\n  font-size: 12pt;\n  color: #95989a;\n  margin: 0;\n}\n\n.web-styles-___box__pic___2TAhN {\n  height: 2.5rem;\n  width: 2.5rem;\n  object-fit: cover;\n  border-radius: 50%;\n  background-color: #e5e3e3;\n}\n\n/* Title with icon styles */\n\n.web-styles-___box__title_with_icon_container___3U0nP {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.web-styles-___box__title_with_icon_title_container___2GQ6X {\n  padding-bottom: 0;\n  padding-left: 0.5rem;\n}\n", ""]);

// Exports
exports.locals = {
	"box": "web-styles-___box__box___1H6FT " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!./shadow.css").locals["bottom"] + "",
	"box_important": "web-styles-___box__box_important___2f5t8 " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!./shadow.css").locals["bottom_important"] + "",
	"nobox": "web-styles-___box__nobox___3Nlf4",
	"item": "web-styles-___box__item___kmv0z web-styles-___box__box___1H6FT " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!./shadow.css").locals["bottom"] + "",
	"clickable": "web-styles-___box__clickable___3S2cq",
	"box_title": "web-styles-___box__box_title___1iEGG",
	"box_subtitle": "web-styles-___box__box_subtitle___2xyhX",
	"pic": "web-styles-___box__pic___2TAhN",
	"title_with_icon_container": "web-styles-___box__title_with_icon_container___3U0nP",
	"title_with_icon_title_container": "web-styles-___box__title_with_icon_title_container___2GQ6X web-styles-___box__box_title___1iEGG " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!./type.css").locals["light"] + ""
};
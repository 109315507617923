import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { compose } from "redux";
import { connect } from "react-redux";
import { shortAmount, renderPoints, get_amount_from_alloc } from "utils";

import "../../style.css";

const FinalizingDealHeaderMetrics = ({
  allocation,
  asset: { raised, allocated, allocationCount }
}) => {
  let percentage = 0;
  if (raised > 0 && allocated > 0) {
    percentage = raised / allocated;
  }

  let invested;

  if (allocation) {
    invested = (
      <div>
        <dt styleName="invested" data-test="metrics_invested">
          {get_amount_from_alloc(allocation)}
        </dt>
        <dd styleName="label">investing</dd>
      </div>
    );
  }

  return (
    <section>
      <dl styleName="header_metrics_list">
        {invested}
        {!!raised && (
          <div>
            <dt styleName="days">{shortAmount(raised)}</dt>
            <dd styleName="label">from {allocationCount} investors</dd>
          </div>
        )}
        {!!percentage && (
          <div styleName="extra">
            <dt styleName="number">{renderPoints(percentage, 0)}</dt>
            <dd styleName="label">of allocation</dd>
          </div>
        )}
      </dl>
      <div styleName="line" />
    </section>
  );
};

FinalizingDealHeaderMetrics.propTypes = {
  asset: PropTypes.shape({
    allocationCount: PropTypes.number.isRequired
  }).isRequired,
  allocation: PropTypes.shape({
    investment: PropTypes.shape({
      amount: PropTypes.number,
      allocated: PropTypes.number
    }).isRequired
  })
};
export default FinalizingDealHeaderMetrics;

import moment from "moment";
import { uniq, range } from "lodash";

export default function getYearsSince(createdAt, currentYear_) {
  const currentYear = parseInt(currentYear_, 10);
  const createdYear = moment(createdAt).year();
  let years = [currentYear];
  years = years.concat(range(currentYear, createdYear));
  years.push(createdYear);
  return uniq(years);
}

import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import gql from "graphql-tag";
import { renderPoints, toAmount } from "utils";
import { useInputChange } from "utils/handleInputChange";
import CountUp from "react-countup";

import "./style.css";

import { BigBlueButton } from "components/Button";

const AddBacking = ({ deal, invite, allocation, asset, submit, skip }) => {
  const commitmentInput = useInputChange({
    initial: allocation.investment.amount / 100,
    validator: v => v >= 10000
  });

  const commitment = parseInt(commitmentInput.value, 10);
  const submitDisabled = commitmentInput.invalid;

  // const isInvestor = invite.type === "investor";
  const allocation_oversubscribed =
    allocation.investment.allocated < allocation.investment.amount;
  const handleSubmit = useCallback(() => {
    submit(commitment * 100);
  }, [commitment, submit]);

  return (
    <section styleName="container">
      <h2 styleName="thinking">Thinking about your next investment?</h2>
      <p styleName="already">
        If you know how much you’ll be investing next time, let your syndicate
        know and help it secure more opportunities and better terms.
      </p>
      <aside styleName="benefits">
        <div>
          <dt>Priority Access</dt>
          <dd>
            You&rsquo;ll be prioritized if the next opportunity gets
            oversubscribed
          </dd>
        </div>
        <div>
          <dt>Non-binding</dt>
          <dd>
            You can cancel your commitment once the next opportunity opens
          </dd>
        </div>
      </aside>
      {!!asset.over_subscription && allocation_oversubscribed && (
        <>
          {!!allocation.investment.backing && (
            <p styleName="cut_down">
              <span styleName="cut_down_important">
                {asset.name} was oversubscribed by{" "}
                <strong>{renderPoints(asset.over_subscription)}</strong>
              </span>{" "}
              <br /> Your allocation was protected because you pre-committed
              last time.
            </p>
          )}
          {!allocation.investment.backing && (
            <p styleName="cut_down">
              <span styleName="cut_down_important">
                {asset.name} was oversubscribed by{" "}
                <strong>{renderPoints(asset.over_subscription)}</strong>.
              </span>{" "}
              <br />
              If you had pre-committed your allocation would be{" "}
              <strong>{toAmount(allocation.investment.amount)}</strong> instead
              of <strong>{toAmount(allocation.investment.allocated)}</strong>.
            </p>
          )}
        </>
      )}

      <form styleName="formContainer" className="native_form">
        <div styleName="commitmentContainer">
          <label styleName="formlabel" htmlFor="investment">
            Pre-commitment
          </label>
          <input
            type="text"
            className="input_dollar"
            id="investment"
            data-test="backing_investment"
            autoComplete="off"
            {...commitmentInput.attrs}
          />
          {commitmentInput.invalid && (
            <p styleName="formerror">Minimum: $10,000</p>
          )}
        </div>
      </form>
      <footer styleName="buttons">
        <BigBlueButton
          disabled={submitDisabled}
          onClick={handleSubmit}
          data-test="backing_submit"
        >
          Pre-commit to invest
        </BigBlueButton>
        <a onClick={skip} data-test="backing_skip">
          Don&rsquo;t commit
        </a>
      </footer>
    </section>
  );
};

AddBacking.fragments = {
  asset: gql`
    fragment AddBackingAsset on Asset {
      name
      over_subscription
    }
  `,
  deal: gql`
    fragment AddBackingDeal on Deal {
      id
      name
    }
  `,
  invite: gql`
    fragment AddBackingInvite on Invite {
      id
      type
      syndicator {
        id
        firstName
        lastName
      }
    }
  `,
  allocation: gql`
    fragment AddBackingAllocation on Allocation {
      id
      investment {
        allocated
        amount
        backing
        discount
      }
    }
  `
};

AddBacking.propTypes = {
  deal: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  invite: PropTypes.shape({
    type: PropTypes.string.isRequired,
    syndicator: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  allocation: PropTypes.shape({
    investment: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      allocated: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  asset: PropTypes.shape({
    name: PropTypes.string.isRequired,
    over_subscription: PropTypes.number
  }).isRequired,
  submit: PropTypes.func.isRequired,
  skip: PropTypes.func.isRequired
};

export default AddBacking;

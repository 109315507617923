import React, { useState, useCallback } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { graphql } from "utils/apollo";

import { get } from "lodash";
import gql from "graphql-tag";

import getYearsSince from "utils/getYearsSince";
import handleInputChange from "utils/handleInputChange";
import Spinner from "components/Spinner";

import Update from "./components/Update";

import "./style.css";

export const UpdatesContainer = ({ data: { loading, deal }, deal_id }) => {
  if (loading) {
    return <Spinner data-test="updates_spinner" />;
  }

  const updates = get(deal, "asset.updates.asset_updates") || [];
  const [shown, setShown] = useState(updates.slice(0, 2));
  const onShowMore = useCallback(() => {
    setShown(updates);
  }, [setShown, updates]);

  if (updates && updates.length) {
    return (
      <div>
        <header styleName="header">
          <h2 styleName="h2">Updates</h2>
          <p className="section_descriptor">
            Progress reports from the asset operator
          </p>
        </header>
        <section data-test="updates_items">
          {shown.map(update => (
            <Update update={update} key={update.id} />
          ))}
        </section>
        {shown.length < updates.length && (
          <section styleName="show_more">
            <a onClick={onShowMore} data-test="updates_more">
              Show all updates
            </a>
          </section>
        )}
      </div>
    );
  }

  return null;
};

UpdatesContainer.propTypes = {
  dealId: PropTypes.string.isRequired,
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    deal: PropTypes.shape({
      asset: PropTypes.shape({
        updates: PropTypes.shape({
          asset_updates: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired
            })
          )
        })
      }).isRequired
    })
  }).isRequired
};

const query = gql`
  query AssetUpdates($dealId: ID!) {
    deal(id: $dealId) {
      id
      self
      asset {
        id
        self
        updates(deal_id: $dealId) {
          asset_updates {
            id
            self
            description
            content
            link {
              text
              url
            }
            createdAt
          }
        }
      }
    }
  }
`;

const mapPropsToOptions = ({ dealId }) => ({ variables: { dealId } });

export default compose(
  graphql(query, {
    options: mapPropsToOptions
  })
)(UpdatesContainer);

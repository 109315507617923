import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { graphql } from "utils/apollo";
import gql from "graphql-tag";
import waitForUser from "utils/waitForUser";

import { selectUserId } from "flows/session/selectors";

import Spinner from "components/Spinner";
import WiringInstructions from "components/WiringInstructions";
import { nextInvestFlow as closed } from "../redux";

export const InvestClosingContainer = ({
  closed,
  match: {
    params: { after }
  },
  data: { deal, loading }
}) => {
  if (loading) {
    return <Spinner />;
  }

  return (
    <WiringInstructions
      onFinish={after ? closed : null}
      deal={deal}
      asset={deal.asset}
      allocation={deal.allocation}
      data-test="invest_closing"
    />
  );
};

const query = gql`
  query InvestClosingInfo($dealId: ID!, $userId: ID!) {
    deal(id: $dealId) {
      id
      self
      ...WiringInstructionsDeal
      asset {
        id
        self
        ...WiringInstructionsAsset
      }
      allocation(user_id: $userId) {
        id
        self
        ...WiringInstructionsAllocation
      }
    }
  }
  ${WiringInstructions.fragments.deal}
  ${WiringInstructions.fragments.asset}
  ${WiringInstructions.fragments.allocation}
`;

InvestClosingContainer.propTypes = {
  closed: PropTypes.func.isRequired,
  data: PropTypes.shape({
    deal: PropTypes.shape({
      asset: PropTypes.object.isRequired,
      allocation: PropTypes.object.isRequired
    }),
    loading: PropTypes.bool.isRequired
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      dealId: PropTypes.string.isRequired,
      after: PropTypes.string
    }).isRequired
  }).isRequired
};

const mapPropsToOptions = ({
  match: {
    params: { dealId }
  },
  userId
}) => ({
  variables: {
    dealId,
    userId
  }
});

export const mapStore = store => ({
  userId: selectUserId(store)
});

export default compose(
  waitForUser(),
  connect(
    mapStore,
    { closed }
  ),
  graphql(query, {
    options: mapPropsToOptions
  })
)(InvestClosingContainer);

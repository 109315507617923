import { isInteger, fill } from "lodash";
import gql from "graphql-tag";
import Finance from "financejs";

const finance = new Finance();

const calc_irr = ({ investment, annual, total, term }) => {
  const years = term - 1;
  const distros = fill(Array(years), annual);
  const lastDistro = total - annual * years;
  const irrArgs = [investment * -1, ...distros, lastDistro];
  try {
    return finance.IRR.apply(finance, irrArgs) / 100;
  } catch {
    return 0;
  }
};

const calculator = ({
  deal,
  invite,
  asset,
  backing,
  user_self,
  investment,
  raise
}) => {
  if (!isInteger(investment)) {
    throw new Error(`utils.calc: investment not integer: ${investment}`);
  }

  if (!isInteger(raise)) {
    throw new Error(`utils.calc: raise not integer: ${raise}`);
  }

  const {
    metrics: {
      leadExit,
      subleadExit,
      syndicateExit,
      leadYield,
      subleadYield,
      syndicateYield
    }
  } = deal;

  const isLead = invite.syndicator.self === user_self;
  const hasBacking = !!backing;

  if (investment >= 0 && raise >= 0) {
    /*
      Total Return
    */
    const investorReturn = investment * subleadExit;
    const leadReturn = investment * leadExit;
    const inviteReturnDelta = raise * syndicateExit;

    const backingExit = (leadExit - subleadExit) / 2;

    let selfReturnWithoutBacking, backingReturnDelta;

    // Leads get no backing discount
    if (isLead) {
      backingReturnDelta = 0;
      selfReturnWithoutBacking = leadReturn;
    } else if (hasBacking) {
      // You only get backing refund up to amount backed
      const backingAmount = backing.amount / 100;
      if (backingAmount >= investment) {
        backingReturnDelta = investment * backingExit;
      } else {
        backingReturnDelta = backingAmount * backingExit;
      }

      selfReturnWithoutBacking = investorReturn;
    } else {
      // Investor with no backing
      backingReturnDelta = 0;
      selfReturnWithoutBacking = investorReturn;
    }

    const selfReturn = selfReturnWithoutBacking + backingReturnDelta;
    const inviteReturn = selfReturn + inviteReturnDelta;

    /*
      Annual Return
    */
    const investorAnnual = investment * subleadYield;
    const leadAnnual = investment * leadYield;
    const inviteAnnualDelta = raise * syndicateYield;
    const backingYield = (leadYield - subleadYield) / 2;

    let selfAnnualWithoutBacking, backingAnnualDelta;

    // Leads get no backing discount
    if (isLead) {
      backingAnnualDelta = 0;
      selfAnnualWithoutBacking = leadAnnual;
    } else if (hasBacking) {
      // You only get backing refund up to amount backed
      const backingAmount = backing.amount / 100;
      if (backingAmount >= investment) {
        backingAnnualDelta = investment * backingYield;
      } else {
        backingAnnualDelta = backingAmount * backingYield;
      }

      selfAnnualWithoutBacking = investorAnnual;
    } else {
      // Investor with no backing
      backingAnnualDelta = 0;
      selfAnnualWithoutBacking = investorAnnual;
    }

    const selfAnnual = selfAnnualWithoutBacking + backingAnnualDelta;
    const inviteAnnual = selfAnnual + inviteAnnualDelta;

    /*
      Yield
    */

    const selfYieldWithoutBacking = selfAnnualWithoutBacking / investment;
    const selfYield = selfAnnual / investment;
    const inviteYield = inviteAnnual / investment;

    /*
      IRR
    */
    const selfIRRWithoutBacking = calc_irr({
      annual: selfAnnualWithoutBacking,
      total: selfReturnWithoutBacking,
      term: asset.term,
      investment
    });

    const selfIRR = calc_irr({
      annual: selfAnnual,
      total: selfReturn,
      term: asset.term,
      investment
    });

    const inviteIRR = calc_irr({
      annual: inviteAnnual,
      total: inviteReturn,
      term: asset.term,
      investment
    });

    const result = {
      leadExit,
      subleadExit,
      syndicateExit,
      leadYield,
      subleadYield,
      syndicateYield,
      investment,
      raise,
      investorReturn,
      leadReturn,
      inviteReturnDelta,
      backingReturnDelta,
      selfReturn,
      selfReturnWithoutBacking,
      inviteReturn,
      investorAnnual,
      leadAnnual,
      inviteAnnualDelta,
      backingAnnualDelta,
      selfAnnual,
      selfAnnualWithoutBacking,
      inviteAnnual,
      selfYieldWithoutBacking,
      selfYield,
      inviteYield,
      selfIRRWithoutBacking,
      selfIRR,
      inviteIRR
    };

    return result;
  } else {
    return {
      leadExit,
      subleadExit,
      syndicateExit,
      leadYield,
      subleadYield,
      syndicateYield,
      investment,
      raise,
      investorReturn: 0,
      leadReturn: 0,
      inviteReturnDelta: 0,
      backingReturnDelta: 0,
      selfReturn: 0,
      selfReturnWithoutBacking: 0,
      inviteReturn: 0,
      investorAnnual: 0,
      leadAnnual: 0,
      inviteAnnualDelta: 0,
      backingAnnualDelta: 0,
      selfAnnual: 0,
      selfAnnualWithoutBacking: 0,
      inviteAnnual: 0,
      selfYieldWithoutBacking: 0,
      selfYield: 0,
      inviteYield: 0,
      selfIRRWithoutBacking: 0,
      selfIRR: 0,
      inviteIRR: 0
    };
  }
};

calculator.fragments = {
  deal: gql`
    fragment CalculatorUtilDeal on Deal {
      metrics {
        leadExit
        subleadExit
        syndicateExit
        leadYield
        subleadYield
        syndicateYield
      }
    }
  `,
  invite: gql`
    fragment CalculatorUtilInvite on Invite {
      syndicator {
        self
      }
    }
  `,
  backing: gql`
    fragment CalculatorUtilBacking on Backing {
      amount
    }
  `,
  asset: gql`
    fragment CalculatorUtilAsset on Asset {
      term
    }
  `
};

export default calculator;

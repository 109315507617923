import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { load } from "flows/docs/unsigned";

export class RetrieveDealUnsigned extends React.PureComponent {
  componentDidMount() {
    this.props.load({ id: this.props.match.params.dealId });
  }
  render() {
    return (
      <div>
        <img src="/images/ring.svg" alt="Loading" />
      </div>
    );
  }
}

RetrieveDealUnsigned.propTypes = {
  load: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  }).isRequired
};

export default connect(
  null,
  { load }
)(RetrieveDealUnsigned);

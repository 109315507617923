import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import { BigRedButton } from "components/Button";
import PictureHeader from "components/PictureHeader";
import EntityPicker from "components/EntityPicker";
import Accreditation from "components/Accreditation";
import QualifiedPurchaser from "components/QualifiedPurchaser";
import CommitToInvest from "components/CommitToInvest";
import { toAmount } from "utils";
import { useStateChange, makeInitialState } from "flows/interest/reducer";

import "./style.css";

export const InterestBacking = ({
  deal,
  entities,
  backing,
  allocation,
  onSubmit,
}) => {
  // Set initial state
  const initial = makeInitialState({ entities, backing, allocation });
  const [isCommittmentValid, setCommitmentValid] = useState(false);

  // Set title
  let title = `Commit to invest in ${deal.name}`;
  if (allocation) {
    title = `Change your commitment in ${deal.name}`;
  } else if (backing) {
    title = `Confirm your pre-commitment in ${deal.name}`;
  }

  const { asset } = deal;

  // Setup local state management
  const [state, handleChange] = useStateChange(initial);

  // Validation logic
  const isInvestorValid = EntityPicker.isValid(state);
  let isAccreditationValid = false;
  if (asset.investorCheck === "accredited_investor") {
    isAccreditationValid = Accreditation.isValid(state);
  } else if (asset.investorCheck === "qualified_purchaser") {
    isAccreditationValid = QualifiedPurchaser.isValid(state);
  }

  const canSubmit =
    isInvestorValid && isAccreditationValid && isCommittmentValid;

  // Callbacks
  const handleSubmit = useCallback(() => {
    onSubmit(state);
  }, [onSubmit, state]);

  return (
    <section
      className="pageContainer"
      style={{ marginTop: "1rem" }}
      data-test="interest_backing_header"
    >
      <PictureHeader image={deal.image} title={title} />
      <section styleName="mainContainer">
        <section>
          <section styleName="step">
            <p>1</p>
          </section>
          <section>
            <EntityPicker
              entities={entities}
              onChange={handleChange}
              value={state}
            />
          </section>
        </section>
        <section>
          <section styleName="step">
            <p>2</p>
          </section>
          <section>
            {asset.investorCheck === "qualified_purchaser" ? (
              <QualifiedPurchaser
                onChange={handleChange}
                value={state.qualified}
                type={state.investorType}
                name={state.investorName}
              />
            ) : (
              <Accreditation
                onChange={handleChange}
                value={state.accredited}
                type={state.investorType}
                name={state.investorName}
              />
            )}
          </section>
        </section>
        <section>
          <section styleName="step">
            <p>3</p>
          </section>
          <section>
            <CommitToInvest
              onChange={handleChange}
              value={state}
              deal={deal}
              setIsValid={setCommitmentValid}
            />
          </section>
        </section>
        <footer styleName="buttonContainer">
          <BigRedButton
            disabled={!canSubmit}
            onClick={handleSubmit}
            data-test="interest_backing_submit"
          >
            Invest {toAmount(state.investment_amount)}
          </BigRedButton>
        </footer>
      </section>
    </section>
  );
};

InterestBacking.propTypes = {
  deal: PropTypes.shape({
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  entities: PropTypes.array.isRequired,
  backing: PropTypes.shape({
    amount: PropTypes.number.isRequired,
  }),
  allocation: PropTypes.shape({
    investment: PropTypes.shape({
      amount: PropTypes.number.isRequired,
    }).isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
};

InterestBacking.fragments = {
  deal: gql`
    fragment InterestBackingDeal on Deal {
      name
      image
      asset {
        id
        investorCheck
      }
      ...CommitToInvestDeal
    }
    ${CommitToInvest.fragments.deal}
  `,
  backing: gql`
    fragment InterestBackingBacking on Backing {
      amount
    }
  `,
  allocation: gql`
    fragment InterestBackingAllocation on Allocation {
      investment {
        amount
      }
      entity {
        self
      }
    }
  `,
  entity: gql`
    fragment InterestBackingEntity on Entity {
      self
      name
      type
      accreditation {
        type
      }
      qualifiedPurchaser {
        type
      }
      ...EntityPickerEntity
    }
    ${EntityPicker.fragments.entity}
  `,
};

export default InterestBacking;

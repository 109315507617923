import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import Calculator from "components/Calculator";

import "./style.css";

export class DealCalc extends React.Component {
  constructor(props) {
    super(props);

    const { deal, allocation, invite } = props;

    this.state = {
      showCalc: !!!allocation,
      calcMode: "invest",
      investment: 0,
      raise: 0
    };

    if (allocation) {
      this.state.investment = allocation.investment.amount / 100;
    }

    this.onInvestmentChange = this.onInvestmentChange.bind(this);
  }
  onInvestmentChange({ investment }) {
    this.setState({ investment });
  }
  renderHeader() {
    const { allocation } = this.props;
    let text = "Calculate your returns";
    let subtext = "based on the metrics projected by the underwriter";
    return (
      <header styleName="header">
        <h2>{text}</h2>
        <p className="section_descriptor">{subtext}</p>
      </header>
    );
  }
  renderCalc() {
    const { deal, invite, allocation, backing, asset, user_self } = this.props;
    const { calcMode } = this.state;

    return (
      <section styleName="calc_container">
        <Calculator
          deal={deal}
          invite={invite}
          mode={calcMode}
          user_self={user_self}
          allocation={allocation}
          asset={asset}
          backing={backing}
          onInvestmentChange={this.onInvestmentChange}
        />
      </section>
    );
  }
  render() {
    const { showCalc } = this.state;
    if (showCalc) {
      return (
        <section>
          {this.renderHeader()}
          {this.renderCalc()}
        </section>
      );
    }
    return null;
  }
}

DealCalc.fragments = {
  deal: gql`
    fragment DealCalcDeal on Deal {
      ...CalculatorDeal
    }
    ${Calculator.fragments.deal}
  `,
  invite: gql`
    fragment DealCalcInvite on Invite {
      ...CalculatorInvite
    }
    ${Calculator.fragments.invite}
  `,
  backing: gql`
    fragment DealCalcBacking on Backing {
      ...CalculatorBacking
    }
    ${Calculator.fragments.backing}
  `,
  asset: gql`
    fragment DealCalcAsset on Asset {
      ...CalculatorAsset
    }
    ${Calculator.fragments.asset}
  `
};

DealCalc.propTypes = {
  deal: PropTypes.object.isRequired,
  asset: PropTypes.object.isRequired,
  invite: PropTypes.shape({
    type: PropTypes.string.isRequired,
    syndicator: PropTypes.shape({
      self: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  allocation: PropTypes.shape({
    investment: PropTypes.shape({
      amount: PropTypes.number.isRequired
    }).isRequired
  }),
  backing: PropTypes.object,
  user_self: PropTypes.string.isRequired
};

export default DealCalc;

import React from "react";
import PropTypes from "prop-types";

import { get_amount_from_alloc } from "utils";
import "./style.css";

const Commitment = ({ allocation }) => (
  <div styleName="committment_container">
    <div>
      <dd styleName="committment_label">
        {allocation.investment.allocated ? "Allocation" : "Commitment"}
      </dd>
      <dt styleName="committment_amount" data-test="invest_commitment">
        {get_amount_from_alloc(allocation)}
      </dt>
    </div>
  </div>
);

Commitment.propTypes = {
  allocation: PropTypes.shape({
    investment: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      allocated: PropTypes.number
    }).isRequired
  }).isRequired
};

export default Commitment;

import React from "react";
import PropTypes from "prop-types";

import { renderEntityType } from "utils";

import "../style.css";

const renderBankLink = (source, entity_self, link) => {
  const onClick = () => link({ entity: { self: entity_self } });
  if (source) {
    return (
      <p data-test="profile_account_name">
        {source.name}{" "}
        <a onClick={onClick} data-test="profile_link_change">
          Change
        </a>
      </p>
    );
  } else {
    return (
      <p data-test="profile_none">
        None on file{" "}
        <a onClick={onClick} data-test="profile_link">
          Link
        </a>
      </p>
    );
  }
};

export const Entity = ({ userinfo: { entities }, link }) => {
  if (entities && entities.length) {
    return (
      <section>
        {entities.map(
          ({ name, id, self, type, street, city, state, zip, source }) => (
            <div styleName="bank" key={id}>
              <section styleName="block">
                <header styleName="name">{name}</header>
                <div styleName="type">{renderEntityType(type)}</div>
              </section>
              <section styleName="block">
                <header>Address</header>
                <p>
                  {street}, {city}, {state}, {zip}
                </p>
              </section>
              <section styleName="block">
                <header>Bank Account</header>
                {renderBankLink(source, self, link)}
              </section>
            </div>
          )
        )}
      </section>
    );
  }
  return (
    <div styleName="bank" data-test="profile_none">
      You haven't invested yet
    </div>
  );
};

Entity.propTypes = {
  userinfo: PropTypes.shape({
    entities: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        self: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        street: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        zip: PropTypes.string.isRequired,
        source: PropTypes.shape({
          name: PropTypes.string.isRequired
        })
      })
    )
  }).isRequired,
  link: PropTypes.func.isRequired
};

export default Entity;

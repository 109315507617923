exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/background.css"), "");
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/type.css"), "");

// Module
exports.push([module.id, ".web-components-ShareDeal-___style__link_container___1lFI4 {\n  padding-top: 1rem;\n  display: flex;\n  justify-content: center;\n}\n\n.web-components-ShareDeal-___style__link_container___1lFI4 > a {\n  padding: 0 0.75rem;\n  text-align: center;\n}\n\n.web-components-ShareDeal-___style__link_container___1lFI4 > a:hover > .web-components-ShareDeal-___style__icon_container___3tulx {\n  background-color: #585858;\n}\n\n.web-components-ShareDeal-___style__link_container___1lFI4 > a:hover > .web-components-ShareDeal-___style__link_text___2xl70 {\n  color: #585858;\n}\n\n.web-components-ShareDeal-___style__icon_container___3tulx {\n  border-radius: 50%;\n  height: 35px;\n  width: 35px;\n  text-align: center;\n  margin: 0 auto 0.33rem;\n}\n\n.web-components-ShareDeal-___style__copy_icon___1Yrww {\n}\n\n.web-components-ShareDeal-___style__email_icon___3WeoL {\n}\n\n.web-components-ShareDeal-___style__whatsapp_icon___qditb {\n}\n\n.web-components-ShareDeal-___style__link_text___2xl70 {\n}\n", ""]);

// Exports
exports.locals = {
	"link_container": "web-components-ShareDeal-___style__link_container___1lFI4",
	"icon_container": "web-components-ShareDeal-___style__icon_container___3tulx",
	"link_text": "web-components-ShareDeal-___style__link_text___2xl70 " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/type.css").locals["light"] + "",
	"copy_icon": "web-components-ShareDeal-___style__copy_icon___1Yrww " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/background.css").locals["blue"] + " web-components-ShareDeal-___style__icon_container___3tulx",
	"email_icon": "web-components-ShareDeal-___style__email_icon___3WeoL " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/background.css").locals["yellow"] + " web-components-ShareDeal-___style__icon_container___3tulx",
	"whatsapp_icon": "web-components-ShareDeal-___style__whatsapp_icon___qditb " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/background.css").locals["green"] + " web-components-ShareDeal-___style__icon_container___3tulx"
};
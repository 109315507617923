import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const WiringDeadline = ({ asset }) => (
  <span data-test="wiring_deadline">
    <strong>5pm ET</strong> on{" "}
    <strong data-test="closing_date">
      {moment(asset.wiringDate).format("dddd[,] Do MMMM Y")}
    </strong>
  </span>
);
WiringDeadline.propTypes = {
  asset: PropTypes.shape({
    wiringDate: PropTypes.string.isRequired
  }).isRequired
};

export default WiringDeadline;

exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/box.css"), "");
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/type.css"), "");

// Module
exports.push([module.id, ".web-flows-account-components-TaxDocs-___style__loadContainer___UsDMU {\n  text-align: center;\n  margin: 1rem 0;\n  padding: 1rem;\n}\n\n.web-flows-account-components-TaxDocs-___style__none___NtJ99 {\n  margin: 1rem 0;\n  padding: 1em 1.5em;\n  text-align: center;\n}\n\n.web-flows-account-components-TaxDocs-___style__container___1I0bd {\n  margin: 1rem 0;\n  display: flex;\n  padding: 1em 1.5em;\n}\n\n.web-flows-account-components-TaxDocs-___style__detail___1CFb6 {\n  flex-basis: 33%;\n  color: #95989a;\n  border-left: #e5e5e5 1px solid;\n  padding: 0 0.67em;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n@media (max-width: 599px) {\n  .web-flows-account-components-TaxDocs-___style__container___1I0bd {\n    flex-direction: column;\n  }\n\n  .web-flows-account-components-TaxDocs-___style__detail___1CFb6 {\n    flex-basis: auto;\n    border: none;\n    padding: 0.5em 0;\n  }\n}\n\n.web-flows-account-components-TaxDocs-___style__detail___1CFb6:first-child {\n  border: none;\n  padding-left: 0;\n  flex-basis: 25%;\n}\n\n.web-flows-account-components-TaxDocs-___style__detail___1CFb6 > header {\n  padding-bottom: 0.2em;\n}\n\n.web-flows-account-components-TaxDocs-___style__detail___1CFb6 > p {\n  margin: 0;\n}\n\n.web-flows-account-components-TaxDocs-___style__name___2JQWl {\n}\n\n.web-flows-account-components-TaxDocs-___style__link___16_EL {\n}\n", ""]);

// Exports
exports.locals = {
	"loadContainer": "web-flows-account-components-TaxDocs-___style__loadContainer___UsDMU " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/box.css").locals["box"] + "",
	"none": "web-flows-account-components-TaxDocs-___style__none___NtJ99 " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/box.css").locals["box"] + " " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/type.css").locals["light"] + "",
	"container": "web-flows-account-components-TaxDocs-___style__container___1I0bd " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/box.css").locals["box"] + " " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/box.css").locals["clickable"] + "",
	"detail": "web-flows-account-components-TaxDocs-___style__detail___1CFb6",
	"name": "web-flows-account-components-TaxDocs-___style__name___2JQWl " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/box.css").locals["box_title"] + "",
	"link": "web-flows-account-components-TaxDocs-___style__link___16_EL " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/type.css").locals["link"] + ""
};
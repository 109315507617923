import React from "react";
import PropTypes from "prop-types";

import "./Footer.css";

const Footer = props => {
  const styleName = (props.border && "footer border") || "footer";

  return <footer styleName={styleName}>{props.children}</footer>;
};

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  border: PropTypes.bool
};

Footer.defaultProps = {
  border: false
};

export default Footer;

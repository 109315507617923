import { defineAction } from "redux-define";
import { createAction } from "redux-actions";
import browserHistory from "utils/history";
import { put, takeLatest, select, take, call } from "redux-saga/effects";

import { getUserInfoSaga } from "api/users";

const namespace = "flows/lead/deal";

// Actions
const LEAD_ACCEPT_DONE = defineAction("LEAD_ACCEPT_DONE", namespace);

export const leadAcceptDone = createAction(LEAD_ACCEPT_DONE.ACTION);

// Lead Deal Saga
export function* leadAcceptDoneSaga({ payload: { dealId } }) {
  // Load up user now that the user is definitely created
  yield call(getUserInfoSaga);

  // Take them to the next page
  yield call(browserHistory.push, `/deals/${dealId}`);
}

export function* sagas() {
  yield takeLatest(LEAD_ACCEPT_DONE.ACTION, leadAcceptDoneSaga);
}

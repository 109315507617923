import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { useDispatch } from "react-redux";

import { WhiteButton } from "components/Button";
import EditUserProfile from "components/EditUserProfile";
import ModalPage from "components/ModalPage";

import { profileEditSaved } from "./redux";

import "./style.css";

const getIDP = (authId, auth0) => {
  if (authId.startsWith("google")) {
    return `Google Account (${auth0.email})`;
  } else if (authId.startsWith("facebook")) {
    return `Facebook Account (${auth0.email})`;
  } else if (authId.startsWith("sms")) {
    return `Phone (${auth0.phone_number})`;
  }
};

export const Profile = ({
  auth0,
  userinfo: { firstName, lastName, email, authId }
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const closeEdit = useCallback(() => setShowEdit(false));
  const openEdit = useCallback(() => setShowEdit(true));

  const dispatch = useDispatch();

  const onSaved = useCallback(() => {
    dispatch(profileEditSaved());
    closeEdit();
  }, [closeEdit, dispatch]);

  let login = "";

  if (getIDP(authId, auth0)) {
    login = (
      <section styleName="detail">
        <header>Login</header>
        <p data-test="profile_login">{getIDP(authId, auth0)}</p>
      </section>
    );
  }

  return (
    <>
      <header styleName="header">
        <h2>Profile</h2>
        <section className="only_mobile">
          <a styleName="edit_mobile" onClick={openEdit}>
            Edit
          </a>
        </section>
        <section className="no_mobile">
          <WhiteButton onClick={openEdit}>Edit Profile</WhiteButton>
        </section>
      </header>
      <div styleName="profile">
        <section styleName="detail">
          <header styleName="name">
            {firstName} {lastName}
          </header>
        </section>
        <section styleName="detail">
          <header>Email</header>
          <p>{email}</p>
        </section>
        {login}
      </div>
      <ReactModal
        isOpen={showEdit}
        contentLabel="Edit Profile"
        shouldCloseOnOverlayClick
        onRequestClose={closeEdit}
        shouldFocusAfterRender={false}
        className="ReactModal"
        ariaHideApp={false}
        data-test="edit_profile_modal"
      >
        <ModalPage>
          <EditUserProfile
            header={<h2>Edit Profile</h2>}
            body={<p />}
            submitText="Save"
            onSaved={onSaved}
            allowCancel
            onCancel={closeEdit}
          />
        </ModalPage>
      </ReactModal>
    </>
  );
};

Profile.propTypes = {
  userinfo: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    authId: PropTypes.string.isRequired
  }).isRequired,
  auth0: PropTypes.shape({
    email: PropTypes.string,
    phone_number: PropTypes.string
  }).isRequired
};

export default Profile;

import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import RadioList from "components/RadioList";
import {
  useInputChange,
  renderStateList,
  isValidUSState,
  isValidUSZip,
} from "utils";
import EntityList from "./components/EntityList";
import { find } from "lodash";
import { initialState } from "flows/interest/reducer";

import "./style.css";

const InvestorType = ({ onChange, value, name }) => {
  const typeInput = useInputChange({
    initial: value,
    onChange,
  });

  return (
    <RadioList>
      <p>
        <input
          type="radio"
          id="investorType_person"
          name={name}
          value="person"
          checked={value === "person"}
          {...typeInput.radioAttrs}
        />
        <label htmlFor="investorType_person">A Natural Person</label>
      </p>
      <p>
        <input
          type="radio"
          id="investorType_trust"
          name={name}
          value="trust"
          checked={value === "trust"}
          {...typeInput.radioAttrs}
        />
        <label htmlFor="investorType_trust">A Trust</label>
      </p>
      <p>
        <input
          type="radio"
          id="investorType_company"
          name={name}
          value="company"
          checked={value === "company"}
          data-test="investor_company"
          {...typeInput.radioAttrs}
        />
        <label htmlFor="investorType_company">A Company</label>
      </p>
    </RadioList>
  );
};

InvestorType.isValid = ({ investorType }) => {
  return !!investorType;
};

const CompanyDetails = ({ onChange, value }) => {
  const typeInput = useInputChange({
    initial: value.investorCompany_type,
    onChange,
  });

  const llcTypeInput = useInputChange({
    initial: value.investorLLC_type,
    onChange,
  });

  return (
    <div>
      <form>
        <label styleName="formlabel" htmlFor="investorCompany_type">
          Company Type:
        </label>
        <select
          name="investorCompany_type"
          id="investorCompany_type"
          data-test="investorCompany_type"
          {...typeInput.attrs}
        >
          <option value="LLC">LLC</option>
          <option value="CCorp">C Corp</option>
          <option value="SCorp">S Corp</option>
          <option value="Partnership">Partnership</option>
        </select>
        {value.investorCompany_type === "LLC" && (
          <>
            <label styleName="formlabel" htmlFor="investorLLC_type">
              LLC Tax Classification:
            </label>
            <select
              name="investorLLC_type"
              id="investorLLC_type"
              data-test="investorLLC_type"
              {...llcTypeInput.attrs}
            >
              <option value="P">Partnership</option>
              <option value="C">C Corp</option>
              <option value="S">S Corp</option>
            </select>
          </>
        )}
      </form>
    </div>
  );
};

CompanyDetails.isValid = ({ investorCompany_type, investorLLC_type }) => {
  const needsLLCType = investorCompany_type === "LLC";
  const validLLCType = (needsLLCType && !!investorLLC_type) || !needsLLCType;

  return validLLCType && !!investorCompany_type;
};

const AddressForm = ({ onChange, value }) => {
  const streetInput = useInputChange({
    initial: value.investorAddressStreet,
    onChange,
    validator: (value) => value && value.length <= 50,
  });

  const cityInput = useInputChange({
    initial: value.investorAddressCity,
    onChange,
    validator: (value) => value && value.length <= 50,
  });

  const stateInput = useInputChange({
    initial: value.investorAddressState,
    onChange,
  });

  const zipInput = useInputChange({
    initial: value.investorAddressZip,
    onChange,
    validator: isValidUSZip,
  });

  return (
    <form styleName="address">
      <label styleName="formlabel" htmlFor="investorAddressStreet">
        Address of Investing Entity:
      </label>
      <input
        type="text"
        placeholder="Address e.g. 123 Cool St, Apt 9A"
        id="investorAddressStreet"
        name="investorAddressStreet"
        data-test="investorAddressStreet"
        autoComplete="street-address"
        {...streetInput.attrs}
      />
      <input
        type="text"
        placeholder="City ex.g. New York"
        id="investorAddressCity"
        name="investorAddressCity"
        data-test="investorAddressCity"
        autoComplete="address-level2"
        {...cityInput.attrs}
      />
      <select
        id="investorAddressState"
        name="investorAddressState"
        data-test="investorAddressState"
        autoComplete="address-level1"
        {...stateInput.attrs}
      >
        {renderStateList()}
      </select>
      <input
        type="text"
        placeholder="Zip e.g. 10001"
        id="investorAddressZip"
        name="investorAddressZip"
        data-test="investorAddressZip"
        autoComplete="postal-code"
        {...zipInput.attrs}
      />
    </form>
  );
};

AddressForm.isValid = ({
  investorAddressStreet,
  investorAddressCity,
  investorAddressState,
  investorAddressZip,
}) => {
  const addressFieldsSet =
    investorAddressStreet &&
    investorAddressCity &&
    investorAddressState &&
    investorAddressZip;

  const validZip = isValidUSZip(investorAddressZip);
  const validState = isValidUSState(investorAddressState);
  const validAddress = addressFieldsSet && validState && validZip;

  return validAddress;
};

const TaxInput = ({ onChange, value }) => {
  const f1Input = useInputChange({
    initial: value.investorTaxId_F1,
    onChange,
    validator: (value) => value && value.length === 2,
  });

  const f2Input = useInputChange({
    initial: value.investorTaxId_F2,
    onChange,
    validator: (value) => value && value.length === 7,
  });

  const s1Input = useInputChange({
    initial: value.investorTaxId_S1,
    onChange,
    validator: (value) => value && value.length === 3,
  });

  const s2Input = useInputChange({
    initial: value.investorTaxId_S2,
    onChange,
    validator: (value) => value && value.length === 2,
  });

  const s3Input = useInputChange({
    initial: value.investorTaxId_S3,
    onChange,
    validator: (value) => value && value.length === 4,
  });

  return (
    <>
      {(value.investorType === "company" || value.investorType === "trust") && (
        <form styleName="taxId">
          <label styleName="formlabel" htmlFor="FEIN">
            FEIN:
          </label>
          <input
            type="number"
            name="investorTaxId_F1"
            min="0"
            max="99"
            id="FEIN"
            placeholder="xx"
            data-test="investorTaxId_F1"
            {...f1Input.attrs}
          />
          <span>-</span>
          <input
            type="number"
            name="investorTaxId_F2"
            min="0"
            max="9999999"
            placeholder="xxxxxxx"
            data-test="investorTaxId_F2"
            {...f2Input.attrs}
          />
        </form>
      )}

      {value.investorType === "person" && (
        <form styleName="taxId">
          <label styleName="formlabel" htmlFor="SSN">
            SSN:
          </label>
          <input
            type="number"
            name="investorTaxId_S1"
            min="0"
            max="999"
            id="SSN"
            placeholder="xxx"
            data-test="investorTaxId_S1"
            {...s1Input.attrs}
          />
          <span>-</span>
          <input
            type="number"
            name="investorTaxId_S2"
            min="0"
            max="99"
            placeholder="xx"
            data-test="investorTaxId_S2"
            {...s2Input.attrs}
          />
          <span>-</span>
          <input
            type="number"
            name="investorTaxId_S3"
            min="0"
            max="9999"
            placeholder="xxxx"
            data-test="investorTaxId_S3"
            {...s3Input.attrs}
          />
        </form>
      )}
    </>
  );
};

TaxInput.isValid = ({
  investorType,
  investorTaxId_S1,
  investorTaxId_S2,
  investorTaxId_S3,
  investorTaxId_F1,
  investorTaxId_F2,
}) => {
  // Validate SSN
  const needsSSN = investorType === "person";
  const validSSN =
    investorTaxId_S1 &&
    investorTaxId_S2 &&
    investorTaxId_S3 &&
    investorTaxId_S1.length === 3 &&
    investorTaxId_S2.length === 2 &&
    investorTaxId_S3.length === 4;

  // Validate FEIN
  const needsFEIN = investorType === "trust" || investorType === "company";
  const validFEIN =
    investorTaxId_F1 &&
    investorTaxId_F2 &&
    investorTaxId_F1.length === 2 &&
    investorTaxId_F2.length === 7;

  // Validate Tax Id
  const validTaxId = (needsSSN && validSSN) || (needsFEIN && validFEIN);

  return validTaxId;
};

const NewEntity = ({ onChange, value }) => {
  const nameInput = useInputChange({
    initial: value.investorName,
    onChange,
    validator: (value) => value && value.length <= 50,
  });

  const signatoryInput = useInputChange({
    initial: value.investorSignatory,
    onChange,
    validator: (value) => value && value.length <= 50,
  });

  return (
    <div className="fs-block highlight-block" data-test="new_entity_form">
      <InvestorType
        onChange={onChange}
        value={value.investorType}
        name="investorType"
      />
      {value.investorType === "company" && (
        <CompanyDetails onChange={onChange} value={value} />
      )}
      <form>
        <label styleName="formlabel" htmlFor="investorName">
          Legal Name of Investing Entity:
        </label>
        <input
          type="text"
          id="investorName"
          name="investorName"
          data-test="investor_name"
          autoComplete="name"
          {...nameInput.attrs}
        />
      </form>
      {(value.investorType === "company" || value.investorType === "trust") && (
        <form>
          <label styleName="formlabel" htmlFor="investorSignatory">
            Natural Person Signing Legal Contracts:
          </label>
          <input
            type="text"
            id="investorSignatory"
            name="investorSignatory"
            data-test="investor_signatory"
            {...signatoryInput.attrs}
          />
        </form>
      )}
      <AddressForm onChange={onChange} value={value} />
      <TaxInput onChange={onChange} value={value} />
      <div styleName="note">
        Note: The information you provide here will be used to complete a{" "}
        <a
          href="https://www.irs.gov/pub/irs-pdf/fw9.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong>W-9</strong>
        </a>{" "}
        form. You will be asked to certify it as accurate, under penalty of
        perjury.
      </div>
    </div>
  );
};

NewEntity.isValid = (state) => {
  const { investorType, investorName, investorSignatory } = state;
  const needsSignatory = investorType === "trust" || investorType === "company";
  const validSignatory =
    (needsSignatory && investorSignatory && investorSignatory.length <= 50) ||
    !needsSignatory;
  const validName = investorName && investorName.length <= 50 && validSignatory;

  return (
    InvestorType.isValid(state) &&
    CompanyDetails.isValid(state) &&
    validName &&
    AddressForm.isValid(state) &&
    TaxInput.isValid(state)
  );
};

const EntityPicker = ({ entities, onChange, value }) => {
  // Set additional attributes when picking existing entity
  // so that they can be used later in the flow
  const handleEntityListChange = ({ name, value }) => {
    if (name === "investorEntity_self") {
      if (value === "new") {
        onChange({
          name: "investorName",
          value: initialState.investor.investorName,
        });
        onChange({
          name: "investorType",
          value: initialState.investor.investorType,
        });
        onChange({
          name: "accredited",
          value: initialState.investor.accredited,
        });
      } else {
        const entity = find(entities, ["self", value]);
        if (entity) {
          onChange({ name: "investorName", value: entity.name });
          onChange({ name: "investorType", value: entity.type });
          onChange({ name: "accredited", value: entity.accreditation.type });
        }
      }
    }
    onChange({ name, value });
  };

  const entityInput = useInputChange({
    initial: value.investorEntity_self,
    onChange: handleEntityListChange,
  });

  return (
    <section className="native_form">
      <p>I&rsquo;m investing as:</p>
      {entities.length > 0 && (
        <EntityList
          entities={entities}
          data-test="investor_entity_list"
          {...entityInput.attrs}
        />
      )}
      {value.investorEntity_self === "new" && (
        <NewEntity onChange={onChange} value={value} />
      )}
    </section>
  );
};

EntityPicker.isValid = (state) => {
  const { investorEntity_self } = state;
  const validEntity = investorEntity_self && investorEntity_self !== "new";
  // If entity is selected we're good to go
  if (validEntity) {
    return true;
  }

  return NewEntity.isValid(state);
};

EntityPicker.fragments = {
  entity: gql`
    fragment EntityPickerEntity on Entity {
      ...EntityListEntity
    }
    ${EntityList.fragments.entity}
  `,
};

export default EntityPicker;

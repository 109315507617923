import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { investorAcceptDone as done } from "./redux";
import { handleError as error } from "api/utils";

import AcceptInvite from "components/AcceptInvite";

const InterestDealContainer = ({
  match: {
    params: { secret, dealId }
  }
}) => {
  const dispatch = useDispatch();
  const onDone = () => {
    dispatch(done({ dealId }));
  };
  const onError = e => {
    dispatch(error(e));
  };

  return (
    <AcceptInvite type="investor" {...{ dealId, secret, onDone, onError }} />
  );
};

InterestDealContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      secret: PropTypes.string.isRequired,
      dealId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default InterestDealContainer;
export { graphql } from "components/AcceptInvite";

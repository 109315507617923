exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/box.css"), "");

// Module
exports.push([module.id, ".web-flows-account-___style__loadContainer___d7dyf {\n  text-align: center;\n  margin: 1rem 0;\n  padding: 1rem;\n}\n", ""]);

// Exports
exports.locals = {
	"loadContainer": "web-flows-account-___style__loadContainer___d7dyf " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/box.css").locals["box"] + ""
};
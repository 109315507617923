import React, { useCallback, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useMUInputChange, useInputChange, isValidEmail } from "utils";
import Button from "components/Button";
import Footer from "components/Footer";

import { FormikTextInput } from "components/TextInput";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
});

import "./EditUserForm.css";

const EditUserForm = ({ initialValues, onSubmit, submitText }) => {
  initialValues.email = initialValues.email || "";
  return (
    <Formik {...{ initialValues, onSubmit, validationSchema }}>
      {({ isValid }) => (
        <Form data-test="edit_user_form">
          <section styleName="form_elements">
            <div styleName="form_input">
              <Field
                component={FormikTextInput}
                name="firstName"
                type="text"
                label="First Name"
                autoComplete="given-name"
                data-test="edit_user_firstName"
              />
            </div>
            <div styleName="form_input">
              <Field
                component={FormikTextInput}
                name="lastName"
                type="text"
                label="Last Name"
                autoComplete="family-name"
                data-test="edit_user_lastName"
              />
            </div>
            <div styleName="form_input">
              <Field
                component={FormikTextInput}
                name="email"
                type="email"
                label="Email"
                autoComplete="email"
                data-test="edit_user_email"
              />
            </div>
          </section>
          <Footer>
            <Button
              type="submit"
              data-test="edit_user_submit"
              disabled={!isValid}
            >
              {submitText}
            </Button>
          </Footer>
        </Form>
      )}
    </Formik>
  );
};

EditUserForm.propTypes = {
  initialValues: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string.isRequired,
};

export default EditUserForm;

export default {
  cove: {
    api: process.env.COVE_API,
    ui: process.env.COVE_UI,
    brand: process.env.COVE_UI_BRAND,
  },
  auth0: {
    clientId: process.env.AUTH0_CLIENT_ID,
    domain: process.env.AUTH0_DOMAIN,
    audience: process.env.AUTH0_AUDIENCE,
  },
  sentry: {
    dsn: process.env.SENTRY_DSN,
  },
  fullstory: {
    org: process.env.FULLSTORY_ORG,
    donottrack: [
      // wyatt local
      "56129411-c259-4ba7-a66c-f6fc4fb1f8d5",
      // ops@cove.io
      "4b8847f5-1d19-4d9c-9583-4b305944e288",
      // Sarah Bai
      "7370b80e-2a8e-41f4-a29a-8b6c32a220ab",
      // Sarah Chase
      "85d62163-ab9e-4d4b-9dfe-5c348df3e0f4",
    ],
  },
  highlight: {
    orgId: process.env.HIGHLIGHT_ORG_ID,
    env: process.env.HIGHLIGHT_ENV,
  },
  plaid: {
    env: process.env.PLAID_ENV,
    client: process.env.PLAID_CLIENT_ID,
    apiKey: process.env.PLAID_PUBLIC_KEY,
    webhook: process.env.PLAID_WEBHOOK,
  },
  hellosign: {
    client: process.env.HELLOSIGN_CLIENT_ID,
    skipDomainVerification: process.env.HELLOSIGN_SKIP_DOMAIN_VERIFICATION
      ? true
      : false,
  },
  intercom: {
    app_id: process.env.INTERCOM_APP_ID,
  },
};

import { defineAction } from "redux-define";
import { createAction } from "redux-actions";
import browserHistory from "utils/history";
import { put, takeLatest, select, take, call } from "redux-saga/effects";
import gql from "graphql-tag";

import { getUserInfoSaga } from "api/users";

import { persist } from "../reducer";

const namespace = "flows/interest/deal";

// Actions
const INVITE_ACCEPT_DONE = defineAction("INVITE_ACCEPT_DONE", namespace);

export const inviteAcceptDone = createAction(INVITE_ACCEPT_DONE.ACTION);

export const Moperation = "AcceptInvestorInvite";
export const mutation = gql`
mutation ${Moperation}($secret: ID!, $dealId: ID!) {
  acceptInvite(secret: $secret, dealId: $dealId, type: "investor") {
    invite {
      id
      self
      deal {
        id
        self
      }
    }
    is_new_user
  }
}
`;
export const acceptProps = ({ mutate }) => ({ secret, dealId, type }) =>
  mutate({
    variables: {
      dealId,
      secret,
      type
    }
  });

// Interest Deal Saga
export function* inviteAcceptDoneSaga({ payload: { dealId } }) {
  // Load up user now that the user is definitely created
  yield call(getUserInfoSaga);

  // Take them to the next page
  yield call(browserHistory.push, `/deals/${dealId}`);
}

export function* sagas() {
  yield takeLatest(INVITE_ACCEPT_DONE.ACTION, inviteAcceptDoneSaga);
}

exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/type.css"), "");

// Module
exports.push([module.id, ".web-components-CouldEarnMore-___style__could_earn_text___2YwhI {\n  margin: 0;\n}\n\n.web-components-CouldEarnMore-___style__could_earn_amount___2Kx1Z {\n  font-weight: 600;\n}\n", ""]);

// Exports
exports.locals = {
	"could_earn_text": "web-components-CouldEarnMore-___style__could_earn_text___2YwhI",
	"could_earn_amount": "web-components-CouldEarnMore-___style__could_earn_amount___2Kx1Z " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/type.css").locals["green"] + ""
};
import { defineAction } from "redux-define";
import { createAction } from "redux-actions";
import { put, takeLatest } from "redux-saga/effects";
import { logout } from "flows/session/reducer";

const namespace = "flows/invest/confirmed";

// Actions
const LOAD_INVEST_CONFIRMED = defineAction("LOAD_INVEST_CONFIRMED", namespace);
export const loadInvestConfirmed = createAction(LOAD_INVEST_CONFIRMED.ACTION);

// Invest Confirm Saga
export function* investConfirmedSaga() {
  // Trigger logout
  yield put(logout());
}

export function* sagas() {
  yield takeLatest(LOAD_INVEST_CONFIRMED.ACTION, investConfirmedSaga);
}

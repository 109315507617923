import React from "react";
import PropTypes from "prop-types";
import { sum, values } from "lodash";

import Button from "components/Button";
import Footer from "components/Footer";
import Big from "big.js";

import { bigsum, numberWithCommas, toMoney } from "utils";

import "./style.css";

/* eslint-disable max-len */
export class AllocateSave extends React.PureComponent {
  constructor() {
    super();
    this.canSubmit = this.canSubmit.bind(this);
    this.addsUp = this.addsUp.bind(this);
    this.total = this.total.bind(this);
  }
  addsUp() {
    return this.total().eq(this.dealAllocation());
  }
  total() {
    const { allocations } = this.props;
    return bigsum(values(allocations));
  }
  dealAllocation() {
    const { deal } = this.props;
    return bigsum([deal.metrics.allocation, deal.metrics.flatFees]);
  }
  canSubmit() {
    return !this.props.saveLoading && this.addsUp();
  }
  renderDifference() {
    const total = this.total();
    const deal = this.dealAllocation();
    if (total.lt(deal)) {
      const diff = deal.minus(total);
      return `Under by ${toMoney(diff)}`;
    } else if (total.gt(deal)) {
      const diff = total.minus(deal);
      return `Over by ${toMoney(diff)}`;
    }
    return "";
  }
  render() {
    return (
      <Footer>
        <Button
          disabled={!this.canSubmit()}
          onClick={this.props.save}
          data-test="lead_allocate_save"
        >
          {this.props.saveLoading ? "Saving" : "Save"}
        </Button>
        <p styleName="total">
          Total: {toMoney(this.total())}
          <br />
          <span styleName="diff">{this.renderDifference()}</span>
        </p>
      </Footer>
    );
  }
}
/* eslint-enable max-len */

AllocateSave.propTypes = {
  save: PropTypes.func.isRequired,
  saveLoading: PropTypes.bool.isRequired,
  allocations: PropTypes.object.isRequired,
  deal: PropTypes.shape({
    metrics: PropTypes.shape({
      allocation: PropTypes.number.isRequired,
      flatFees: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};

export default AllocateSave;

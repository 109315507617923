import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { toDollars } from "utils";

import Page from "components/Page";
import Button from "components/Button";
import Footer from "components/Footer";
import WiringDeadline from "components/WiringDeadline";

import "./style.css";

/* eslint-disable max-len */
const WiringInstructions = ({ asset, deal, allocation, onFinish }) => {
  const account = asset.account || {};
  return (
    <section className="pageContainer">
      <Page>
        <h2 data-test="closing_header">{deal.name} Funding Details</h2>
        <p>
          Wire your contribution (
          <strong data-test="closing_amount">
            {toDollars(allocation.investment.allocated)}
          </strong>
          ) to the following account:
        </p>

        <section styleName="account">
          <p styleName="note">
            Note: Don&rsquo;t reuse a saved payee at your bank from your last
            investment
          </p>
          <strong>Recipient:</strong> {account.name}
          <br />
          <strong>Recipient Address:</strong> {account.address}
          <br />
          <strong>Account #:</strong> {account.number}
          <br />
          <strong>Routing #:</strong> {account.routing}
          <br />
          <strong>Bank:</strong> {account.bank_name}
          <br />
          <strong>Bank Address:</strong> {account.bank_address}
        </section>
        <p styleName="sure">
          Be sure to wire your contribution before{" "}
          <WiringDeadline asset={asset} />
        </p>
        {!!onFinish && (
          <Footer>
            <Button onClick={onFinish} data-test="closing_submit">
              Finish
            </Button>
          </Footer>
        )}
      </Page>
    </section>
  );
};
/* eslint-enable max-len */

WiringInstructions.fragments = {
  deal: gql`
    fragment WiringInstructionsDeal on Deal {
      name
    }
  `,
  asset: gql`
    fragment WiringInstructionsAsset on Asset {
      wiringDate
      account {
        name
        address
        number
        routing
        bank_name
        bank_address
      }
    }
  `,
  allocation: gql`
    fragment WiringInstructionsAllocation on Allocation {
      investment {
        allocated
      }
    }
  `,
};

WiringInstructions.propTypes = {
  onFinish: PropTypes.func,
  asset: PropTypes.shape({
    account: PropTypes.shape({
      name: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      routing: PropTypes.string.isRequired,
      bank_name: PropTypes.string.isRequired,
      bank_address: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  deal: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  allocation: PropTypes.shape({
    investment: PropTypes.shape({
      allocated: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

export default WiringInstructions;

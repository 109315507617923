import { defineAction } from "redux-define";
import { createAction } from "redux-actions";
import { call, put, select } from "redux-saga/effects";
import { H } from "highlight.run";
import Covert from "covert";
import config from "config";
import { selectUserLoaded } from "flows/session/selectors";
import { EVENTS, selectToken } from "./utils";

const api = config.cove.api;
const CoveApi = Covert({ api });

const namespace = "api/users";

// Actions
export const GET_USERINFO = defineAction("GET_USERINFO", EVENTS, namespace);
export const getUserInfo = createAction(GET_USERINFO.ACTION);
export const getUserInfoRequested = createAction(GET_USERINFO.REQUESTED);
export const getUserInfoSuccess = createAction(GET_USERINFO.SUCCESS);
export const getUserInfoError = createAction(GET_USERINFO.ERROR);

// API Calls
export const getUserInfoApi = (client, token) =>
  CoveApi.users.info(client, { token });

// Get User Info Saga
export function* getUserInfoSaga() {
  const loaded = yield select(selectUserLoaded);
  if (!loaded) {
    yield put(getUserInfoRequested());
    try {
      const token = yield select(selectToken);
      const response = yield call(getUserInfoApi, config.cove.brand.id, token);
      const data = response.userinfo;

      if (window.Raven) {
        yield call([window.Raven, window.Raven.setUserContext], {
          id: data.id,
        });
      }

      const fullName = `${data.firstName} ${data.lastName}`;

      if (config.highlight) {
        if (config.fullstory.donottrack.includes(data.id)) {
          window.localStorage.setItem("cove:fullstory:donottrack", "yes");
        } else {
          H.identify(data.email, { id: data.id, name: fullName });
        }
      }

      yield put(getUserInfoSuccess({ data }));
    } catch (error) {
      yield put(getUserInfoError({ error }));
      return error;
    }
  }
}

import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import gql from "graphql-tag";

import { selectAuth0 } from "flows/session/selectors";

import Profile from "./component";

import "./style.css";

export const ProfileContainer = ({ userinfo, auth0 }) => {
  return (
    <Profile userinfo={userinfo} auth0={auth0} data-test="profile_child" />
  );
};

ProfileContainer.propTypes = {
  userinfo: PropTypes.object.isRequired,
  auth0: PropTypes.object.isRequired
};

ProfileContainer.fragments = {
  userinfo: gql`
    fragment ProfileUserInfo on UserInfo {
      id
      firstName
      lastName
      email
      authId
    }
  `
};

export const mapStore = (store, ownProps) => ({
  auth0: selectAuth0(store)
});

export default compose(connect(mapStore))(ProfileContainer);

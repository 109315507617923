import React, { useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import Page from "components/Page";
import { BigRedButton } from "components/Button";
import { toMoney } from "utils";
import CommitToInvest from "components/CommitToInvest";

import "./style.css";

/* eslint-disable max-len */
export const InterestInvestment = ({ onSubmit, onChange, state, deal }) => {
  const amount = parseInt(state.investment_amount, 10);
  const [canSubmit, setCanSubmit] = useState(false);

  return (
    <Page style={{ paddingTop: "1rem" }}>
      <CommitToInvest
        onChange={onChange}
        value={state}
        deal={deal}
        setIsValid={setCanSubmit}
      />
      <footer styleName="footer">
        <BigRedButton
          onClick={onSubmit}
          disabled={!canSubmit}
          data-test="investment_submit"
        >
          Commit to invest {state.investment_amount && toMoney(amount)}
        </BigRedButton>
      </footer>
    </Page>
  );
};
/* eslint-enable max-len */

InterestInvestment.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  deal: PropTypes.object.isRequired,
};

InterestInvestment.fragments = {
  deal: gql`
    fragment InterestInvestmentDeal on Deal {
      ...CommitToInvestDeal
    }
    ${CommitToInvest.fragments.deal}
  `,
};
export default InterestInvestment;

import React from "react";
import PropTypes from "prop-types";

import Page from "components/Page";
// import RedirectToDeal from "components/RedirectToDeal";

import "./style.css";

/* eslint-disable max-len */
const LeadInvite = ({ login, deal }) => (
  <>
    <div styleName="page-bg" />
    <section className="pageContainer">
      <Page>
        <section styleName="inviteContainer">
          <img src={deal.sponsor.image} alt={deal.sponsor.name} />
          <h3>You've been invited to invest in {deal.name}</h3>
        </section>
        {/* <RedirectToDeal deal={deal}> */}
        <p styleName="login">
          To see this deal, you need to{" "}
          <a onClick={login} role="button" tabIndex="0" data-test="login">
            login
          </a>
        </p>
        {/* </RedirectToDeal> */}
      </Page>
    </section>
  </>
);
/* eslint-enable max-len */

LeadInvite.propTypes = {
  login: PropTypes.func.isRequired,
  deal: PropTypes.shape({
    name: PropTypes.string.isRequired,
    sponsor: PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default LeadInvite;

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { compose } from "redux";
import { connect } from "react-redux";
import gql from "graphql-tag";
import config from "config";
import Markdown from "react-markdown";

import AllocatingDealHeaderMetrics from "./components/Allocating";
import FinalizingDealHeaderMetrics from "./components/Finalizing";
import ClosedDealHeaderMetrics from "./components/Closed";

import { mergeProps } from "utils";

import "./style.css";

const InvitedBy = ({ invite, deal: { status, sponsor } }) => {
  if (config.cove.brand.hideInvitedBy) {
    return null;
  }
  let name = `${invite.syndicator.firstName} ${invite.syndicator.lastName}`;
  let image = invite.syndicator.image;

  if (invite.type === "lead") {
    name = sponsor.name;
    image = sponsor.image;
  }

  return (
    <section styleName="invited_by">
      <img src={image} alt="" data-test="invited_by_image" />
      <p data-test="invited_by_message">
        <span styleName="syndicator">{name}</span> invited you
      </p>
    </section>
  );
};

const Metrics = ({ deal, asset, allocation, allocations, now }) => {
  if (deal.status === "allocating") {
    return <AllocatingDealHeaderMetrics deal={deal} asset={asset} now={now} />;
  } else if (deal.status === "finalizing" || deal.status === "finalized") {
    return (
      <FinalizingDealHeaderMetrics
        deal={deal}
        asset={asset}
        allocation={allocation}
      />
    );
  } else if (deal.status === "closed") {
    return (
      <ClosedDealHeaderMetrics
        deal={deal}
        asset={asset}
        allocation={allocation}
        allocations={allocations}
        now={now}
      />
    );
  }
};

const Description = ({ description }) => {
  try {
    const decoded = atob(description);
    if (decoded) {
      return <p dangerouslySetInnerHTML={{ __html: decoded }} />;
    }
  } catch (err) {
    return <Markdown source={description} />;
  }
};

export const DealHeader = ({
  deal,
  asset,
  invite,
  allocation,
  allocations,
  now,
}) => (
  <header styleName="header">
    <img styleName="deal_image" src={deal.image} alt="" />
    <section styleName="details">
      <section styleName="top">
        <h1 data-test="deal_header">{deal.name}</h1>
        {InvitedBy({ deal, invite })}
      </section>
      <Description description={deal.description} />
      {Metrics({ deal, asset, allocation, allocations, now })}
    </section>
  </header>
);

DealHeader.propTypes = {
  deal: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    sponsor: PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  now: PropTypes.func.isRequired,
  asset: PropTypes.shape({
    closingDate: PropTypes.string.isRequired,
  }).isRequired,
  invite: PropTypes.shape({
    syndicator: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }).isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

const exported = compose(
  connect(
    null,
    null,
    mergeProps({
      now: () => moment(),
    })
  )
)(DealHeader);

exported.fragments = {
  asset: gql`
    fragment DealHeaderAsset on Asset {
      ...AllocatingDealHeaderMetricsAsset
    }
    ${AllocatingDealHeaderMetrics.fragments.asset}
  `,
};

export default exported;

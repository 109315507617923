import { defineAction } from "redux-define";
import { createAction } from "redux-actions";
import { put, take, race, takeLatest } from "redux-saga/effects";
import gql from "graphql-tag";

import {
  showDealFinalizing,
  hideDealFinalizing,
  showDealFinalized,
  hideDealFinalized,
  showDealClosed,
  hideDealClosed
} from "./reducer";

const namespace = "flows/deal/status";

// Actions
const DEAL_TRANSITION = defineAction("DEAL_TRANSITION");
export const triggerNextDealTransition = createAction(DEAL_TRANSITION.ACTION);

const DEAL_FINALIZING = defineAction(
  "DEAL_FINALIZING",
  ["SUBMIT", "CANCEL", "DONE"],
  namespace
);
export const submitFinalizing = createAction(DEAL_FINALIZING.SUBMIT);
export const cancelFinalizing = createAction(DEAL_FINALIZING.CANCEL);
export const doneFinalizing = createAction(DEAL_FINALIZING.DONE);

const DEAL_FINALIZED = defineAction(
  "DEAL_FINALIZED",
  ["SUBMIT", "CANCEL", "DONE"],
  namespace
);
export const submitFinalized = createAction(DEAL_FINALIZED.SUBMIT);
export const cancelFinalized = createAction(DEAL_FINALIZED.CANCEL);
export const doneFinalized = createAction(DEAL_FINALIZED.DONE);

const DEAL_CLOSED = defineAction(
  "DEAL_CLOSED",
  ["SUBMIT", "CANCEL", "DONE"],
  namespace
);
export const submitClosed = createAction(DEAL_CLOSED.SUBMIT);
export const cancelClosed = createAction(DEAL_CLOSED.CANCEL);
export const doneClosed = createAction(DEAL_CLOSED.DONE);

// Mutations
export const finalizingMutation = gql`
  mutation DealTransitionToFinalizing($dealId: ID!) {
    deal: transitionToFinalizing(id: $dealId) {
      id
      status
    }
  }
`;
export const finalizingProp = ({ mutate, ownProps }) => () =>
  mutate({
    variables: {
      dealId: ownProps.deal.id
    }
  });

export const finalizedMutation = gql`
  mutation DealTransitionToFinalized($dealId: ID!) {
    deal: transitionToFinalized(id: $dealId) {
      id
      status
    }
  }
`;
export const finalizedProp = ({ mutate, ownProps }) => () =>
  mutate({
    variables: {
      dealId: ownProps.deal.id
    }
  });

export const closedQuery = gql`
  query DealTransitionToClosed($dealId: ID!, $userId: ID!) {
    deal(id: $dealId) {
      id
      allocations(user_id: $userId) {
        id
        self
        documents {
          signed
        }
      }
    }
  }
`;
export const closedMutation = gql`
  mutation DealTransitionToClosed($dealId: ID!) {
    deal: transitionToClosed(id: $dealId) {
      id
      status
    }
  }
`;
export const closedProp = ({ mutate, ownProps }) => () =>
  mutate({
    variables: {
      dealId: ownProps.deal.id
    }
  });

// Transition deal to different states saga
export function* dealTransitionSaga({ payload }) {
  const { deal } = payload;

  let show;
  let hide;
  let ACTION;

  if (deal.status === "allocating") {
    show = showDealFinalizing;
    hide = hideDealFinalizing;
    ACTION = DEAL_FINALIZING;
  } else if (deal.status === "finalizing") {
    show = showDealFinalized;
    hide = hideDealFinalized;
    ACTION = DEAL_FINALIZED;
  } else if (deal.status === "finalized") {
    show = showDealClosed;
    hide = hideDealClosed;
    ACTION = DEAL_CLOSED;
  } else {
    throw new Error("Deal transition unsupported");
  }

  // show announcement popup
  yield put(show());

  // Wait for user interaction
  const { submit } = yield race({
    submit: take(ACTION.SUBMIT),
    cancel: take(ACTION.CANCEL)
  });

  // Wait for server result
  if (submit) {
    yield take(ACTION.DONE);
  }

  // hide announcement modal
  yield put(hide());
}

export function* sagas() {
  yield takeLatest(DEAL_TRANSITION.ACTION, dealTransitionSaga);
}

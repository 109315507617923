import { defineAction } from "redux-define";
import { createAction } from "redux-actions";
import { call, take, takeLatest, put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import gql from "graphql-tag";
import { get, map } from "lodash";

// Actions
const namespace = "flows/account/profile";

export const EDIT_SAVED = defineAction("EDIT_SAVED", namespace);
export const profileEditSaved = createAction(EDIT_SAVED.ACTION);

export function* profileEditSavedSaga() {
  // Show toast
  const message = `Profile updated`;
  yield call([toast, toast.success], message, {
    position: toast.POSITION.TOP_CENTER
  });
}

export function* sagas() {
  yield takeLatest(EDIT_SAVED.ACTION, profileEditSavedSaga);
}

import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import { sagas as apiSagas } from "../api";
import { sagas as flowSagas } from "../flows";
import createReducers from "../reducers";
import env from "./env";

const sagaMiddleware = createSagaMiddleware();

export default function configureStore() {
  const reducers = createReducers(env);
  const store = createStore(reducers, applyMiddleware(sagaMiddleware));

  const apiTask = sagaMiddleware.run(apiSagas);
  apiTask.done.catch(err => {
    window.Raven.captureException(err);
  });

  const flowTask = sagaMiddleware.run(flowSagas);
  flowTask.done.catch(err => {
    window.Raven.captureException(err);
  });

  return store;
}

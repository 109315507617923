import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { submitInterestInvite as submit } from "flows/interest/invite/index";
import { handleError as error } from "api/utils";
import { mergeProps } from "utils";

import Spinner from "components/Spinner";

import InterestInvite from "./component";

import config from "config";
import Covert from "covert";
const CoveApi = Covert({ api: config.cove.api });

export const fetch = id => CoveApi.invites.getBySecret(id, { isGuest: true });

export class InterestInviteContainer extends React.Component {
  constructor() {
    super();
    this.login = this.login.bind(this);
    this.state = {
      data: {
        loading: true,
        invite: null
      }
    };
  }
  componentDidMount() {
    const {
      fetch,
      match: {
        params: { secret }
      }
    } = this.props;
    fetch(secret)
      .then(invite => {
        this.setState({
          data: {
            loading: false,
            invite
          }
        });
      })
      .catch(error => {
        this.props.error({ error });
      });
  }
  login() {
    const { invite } = this.state.data;

    this.props.submit({ secret: this.props.match.params.secret, invite });
  }
  render() {
    const { invite, loading } = this.state.data;

    if (loading) {
      return <Spinner />;
    }

    return <InterestInvite login={this.login} invite={invite} />;
  }
}

InterestInviteContainer.propTypes = {
  submit: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
  fetch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      secret: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default compose(
  connect(
    null,
    { submit, error },
    mergeProps({ fetch })
  )
)(InterestInviteContainer);

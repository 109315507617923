exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".web-components-EditUserProfile-components-EditUserForm-___EditUserForm__form_elements___28xD6 {\n}\n\n.web-components-EditUserProfile-components-EditUserForm-___EditUserForm__form_input___2ZF3H {\n  margin: 1rem 0;\n}\n@media (min-width: 600px) and (max-width: 959px) {\n  .web-components-EditUserProfile-components-EditUserForm-___EditUserForm__forminput___2DT59 {\n    width: 60%;\n  }\n}\n\n@media (max-width: 599px) {\n  .web-components-EditUserProfile-components-EditUserForm-___EditUserForm__forminput___2DT59 {\n    width: 100%;\n  }\n}\n\n.web-components-EditUserProfile-components-EditUserForm-___EditUserForm__error___1tUmN {\n  color: #2d62b7;\n  font-weight: 600;\n}\n\n.web-components-EditUserProfile-components-EditUserForm-___EditUserForm__formlabel___2fvbq {\n  display: block;\n  padding: 0.4em 0;\n}\n", ""]);

// Exports
exports.locals = {
	"form_elements": "web-components-EditUserProfile-components-EditUserForm-___EditUserForm__form_elements___28xD6",
	"form_input": "web-components-EditUserProfile-components-EditUserForm-___EditUserForm__form_input___2ZF3H",
	"forminput": "web-components-EditUserProfile-components-EditUserForm-___EditUserForm__forminput___2DT59",
	"error": "web-components-EditUserProfile-components-EditUserForm-___EditUserForm__error___1tUmN",
	"formlabel": "web-components-EditUserProfile-components-EditUserForm-___EditUserForm__formlabel___2fvbq"
};
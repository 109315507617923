import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";

import { calculator } from "utils";

import "./style.css";

const TotalReturn = ({
  deal,
  invite,
  backing,
  asset,
  user_self,
  investment,
  raise,
  mode,
  backing_applies
}) => {
  const { selfReturn, selfReturnWithoutBacking, inviteReturn } = calculator({
    deal,
    invite,
    backing,
    asset,
    user_self,
    investment,
    raise
  });

  if (investment >= 0 && raise >= 0) {
    if (mode === "invest") {
      if (backing && backing_applies) {
        return (
          <>
            <span styleName="before">
              <CountUp
                end={Math.round(selfReturnWithoutBacking)}
                separator=","
                duration={0.5}
                delay={0}
                prefix="$"
                data-test="calc_total_invest"
              />
            </span>
            <span styleName="arrow">&nbsp;→&nbsp;</span>
            <span styleName="after">
              <CountUp
                end={Math.round(selfReturn)}
                separator=","
                duration={0.5}
                delay={0}
                prefix="$"
                data-test="calc_total_backing"
              />
            </span>
          </>
        );
      } else {
        return (
          <CountUp
            end={Math.round(selfReturn)}
            separator=","
            duration={0.5}
            delay={0}
            prefix="$"
            data-test="calc_total"
          />
        );
      }
    } else {
      return (
        <>
          <span styleName="before">
            <CountUp
              end={Math.round(selfReturnWithoutBacking)}
              separator=","
              duration={0.5}
              delay={0}
              prefix="$"
              data-test="calc_total_invest"
            />
          </span>
          <span styleName="arrow">&nbsp;→&nbsp;</span>
          <span styleName="after">
            <CountUp
              end={Math.round(inviteReturn)}
              separator=","
              duration={0.5}
              delay={0}
              prefix="$"
              data-test="calc_total_raise"
            />
          </span>
        </>
      );
    }
  }

  return "$0";
};

TotalReturn.propTypes = {
  deal: PropTypes.object.isRequired,
  invite: PropTypes.object.isRequired,
  user_self: PropTypes.string.isRequired,
  investment: PropTypes.number.isRequired,
  raise: PropTypes.number.isRequired,
  backing_applies: PropTypes.bool.isRequired
};

export default TotalReturn;

import createTypes from "redux-create-action-types";

import { toast } from "react-toastify";
import { defineAction } from "redux-define";
import { createAction } from "redux-actions";
import { takeLatest, call } from "redux-saga/effects";
import { get } from "lodash";

const REQUESTED = "REQUESTED";
const SUCCESS = "SUCCESS";
const ERROR = "ERROR";

export const EVENTS = [REQUESTED, SUCCESS, ERROR];

export const STATUS = createTypes("UPDATING", "UPDATED", "ERROR");

export const selectToken = store => get(store, "session.user.accessToken");

// Error handling
const ERRORED = defineAction("ERRORED", ["HANDLE"], "sentry");
export const handleError = createAction(ERRORED.HANDLE);

export function* handleErrorSaga({ payload }) {
  const { error, message } = payload;

  const toast_message =
    (error && ((error.body && error.body.message) || error.message || error)) ||
    message;
  yield call([toast, toast.error], toast_message, {
    position: toast.POSITION.TOP_CENTER
  });

  if (window.Raven) {
    yield call([window.Raven, window.Raven.captureException], error);
  }
}

export function* sagas() {
  yield takeLatest(ERRORED.HANDLE, handleErrorSaga);
}

const merge = require("lodash.merge");

const { get, post, gql } = require("./agent");

module.exports = (global_options) => {
  return {
    get: (id, local_options) => {
      const options = merge({}, global_options, local_options);
      return get(`/invites/${id}`, options);
    },
    getBySecret: (secret, local_options) => {
      const options = merge({}, global_options, local_options);
      return get(`/invites/secret/${secret}`, options);
    },
    accept: (secret, dealId, type, local_options) => {
      const options = merge({}, global_options, local_options);
      const mutation = `
         mutation M {
           acceptInvite(secret: "${secret}", dealId: "${dealId}",  type: "${type}") {
             invite {
               id
               self
               investor_invite_secret
               user {
                 id
                 self
               }
             }
             is_new_user
           }
         }
       `;
      return gql(mutation, null, options);
    },
  };
};

import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { graphql } from "utils/apollo";
import { get } from "lodash";

import { query } from "../../graphql";

import Spinner from "components/Spinner";
import Distribution from "../Distribution";

import "./style.css";

export const DistributionsList = ({ data: { deal, loading }, year }) => {
  if (loading) {
    return <Spinner data-test="distributions_list_spinner" />;
  }

  const distributions = get(deal, "allocation.distributions.distributions");

  if (distributions && distributions.length) {
    return (
      <section data-test="distributions_list">
        {distributions.map(distribution => (
          <Distribution key={distribution.id} distribution={distribution} />
        ))}
      </section>
    );
  } else {
    return (
      <p styleName="none" data-test="distributions_list_none">
        No distributions were made in {year}
      </p>
    );
  }
};

DistributionsList.propTypes = {
  dealId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    deal: PropTypes.shape({
      allocation: PropTypes.shape({
        distributions: PropTypes.shape({
          distributions: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired
            })
          )
        })
      }).isRequired
    })
  }).isRequired,
  year: PropTypes.string.isRequired
};

const mapPropsToOptions = ({ dealId, userId, year }) => ({
  variables: { dealId, userId, year: parseInt(year, 10) }
});

export default compose(
  graphql(query, {
    options: mapPropsToOptions
  })
)(DistributionsList);

import { defineAction } from "redux-define";
import { createAction } from "redux-actions";
import { call, take, takeLatest, put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import gql from "graphql-tag";
import { get, map } from "lodash";

// Actions
const namespace = "flows/deal/allocate";

export const DEAL_ALLOCATE_LOAD = defineAction(
  "DEAL_ALLOCATE_LOAD",
  ["PERSIST"],
  namespace
);
export const dealAllocateLoad = createAction(DEAL_ALLOCATE_LOAD.ACTION);
export const dealAllocateLoadPersist = createAction(DEAL_ALLOCATE_LOAD.PERSIST);

export const DEAL_ALLOCATE_CHANGE = defineAction(
  "DEAL_ALLOCATE_CHANGE",
  ["PERSIST"],
  namespace
);
export const dealAllocateChange = createAction(DEAL_ALLOCATE_CHANGE.ACTION);
export const dealAllocatePersist = createAction(DEAL_ALLOCATE_CHANGE.PERSIST);

export const DEAL_ALLOCATE_SAVED = defineAction(
  "DEAL_ALLOCATE_SAVED",
  namespace
);
export const dealAllocateSaved = createAction(DEAL_ALLOCATE_SAVED.ACTION);

// Query
export const Qoperation = "DealLeadInvestorsAllocate";
export const query = gql`
  query ${Qoperation}($dealId: ID!, $userId: ID!) {
    deal(id: $dealId) {
      id
      metrics {
        allocation
        flatFees
      }
      allocations(user_id: $userId) {
        id
        self
        investment {
          amount
          allocated
        }
        owner {
          firstName
          lastName
          image
          self
        }
      }
    }
  }
`;

export const Moperation = "AllocateDeal";
export const mutation = gql`
  mutation ${Moperation}($dealId: ID!, $allocations: [AllocationAmount]) {
    allocations: allocateDeal(id: $dealId, allocations: $allocations) {
      id
      investment {
        allocated
      }
    }
  }
`;
const transform = (allocated, id) => ({
  id,
  allocated
});
export const saveProps = ({ mutate, ownProps }) => () =>
  mutate({
    variables: {
      dealId: ownProps.deal.id,
      allocations: map(ownProps.allocations, transform)
    }
  });

export function* allocationChange({ payload }) {
  // Save changes to reducer
  yield put(dealAllocatePersist(payload));
}

export function* loadDealAllocateSaga({ payload: { allocations } }) {
  // Save initial data into reducer
  yield put(dealAllocateLoadPersist({ allocations }));

  // Handle allocation changes
  yield takeLatest(DEAL_ALLOCATE_CHANGE.ACTION, allocationChange);
}

export function* dealAllocatedSaga() {
  // Show toast
  const message = `Saved allocations`;
  yield call([toast, toast.success], message, {
    position: toast.POSITION.TOP_CENTER
  });
}

export function* sagas() {
  yield takeLatest(DEAL_ALLOCATE_LOAD.ACTION, loadDealAllocateSaga);
  yield takeLatest(DEAL_ALLOCATE_SAVED.ACTION, dealAllocatedSaga);
}

import React from "react";
import PropTypes from "prop-types";

import "./style.css";

const Docs = ({ allocation }) => (
  <section styleName="docs">
    <dt>
      <a
        href={`/docs/deal/unsigned/${allocation.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Operating Agreement and Subscription Agreement
      </a>
    </dt>
    <dd>
      Lays out the terms of the investment vehicle and your interest in it
    </dd>
    <dt>
      <a
        href={`/docs/w9/${allocation.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        IRS Form W-9 (Request for Taxpayer Identification Number and
        Certification)
      </a>
    </dt>
    <dd>Identifies you for tax reporting purposes</dd>
  </section>
);
Docs.propTypes = {
  allocation: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
};

export default Docs;

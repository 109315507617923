import React from "react";
import PropTypes from "prop-types";

import { useInputChange } from "utils";
import RadioList from "components/RadioList";

const IncomeLanguage = ({ type }) => {
  if (type !== "person" && type !== "Individual") {
    return "All equity owners in this entity are themselves accredited investors";
  }
  return "An annual income of over $200k (or $300k jointly with spouse) for the past two years, and expect the same this year";
};

const NetWorthLanguage = ({ type }) => {
  if (type !== "person" && type !== "Individual") {
    return "This entity has more than $5m in investments";
  }
  return "A net worth of over $1m, excluding my primary residence";
};

const WhoLanguage = ({ type, name }) => {
  if (type !== "person" && type !== "Individual") {
    return `${name || "Entity"} is an accredited investor because:`;
  }
  return <>I&rsquo;m an accredited investor because I have:</>;
};

const Accreditation = ({ onChange, value, type, name }) => {
  const accreditedInput = useInputChange({
    initial: value,
    onChange,
  });

  return (
    <>
      <p>
        <WhoLanguage type={type} name={name} />
      </p>
      <RadioList data-test="accreditation_form" className="native_form">
        <p>
          <input
            type="radio"
            id="accredited_net_worth"
            name="accredited"
            value="net_worth"
            data-test="accreditation_option"
            checked={value === "net_worth"}
            {...accreditedInput.radioAttrs}
          />
          <label
            htmlFor="accredited_net_worth"
            data-test="accreditation_net_worth_label"
          >
            <NetWorthLanguage type={type} />
          </label>
        </p>
        <p>
          <input
            type="radio"
            id="accredited_income"
            name="accredited"
            value="income"
            checked={value === "income"}
            {...accreditedInput.radioAttrs}
          />
          <label
            htmlFor="accredited_income"
            data-test="accreditation_income_label"
          >
            <IncomeLanguage type={type} />
          </label>
        </p>
      </RadioList>
    </>
  );
};

Accreditation.isValid = ({ accredited }) => {
  return !!accredited;
};

Accreditation.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Accreditation;

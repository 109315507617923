import gql from "graphql-tag";

export const sign_mutation = gql`
  mutation SignDocs($allocationId: ID!) {
    allocation: sign_docs(id: $allocationId) {
      id
      documents {
        signed
      }
    }
  }
`;
export const sign_props = ({ mutate, ownProps }) => () =>
  mutate({
    variables: {
      allocationId: ownProps.allocation.id
    }
  });

export const get_sign_operation = "GetSignURL";
export const get_sign_url_mutation = gql`
  mutation ${get_sign_operation}($allocationId: ID!) {
    allocation: get_sign_url(id: $allocationId) {
      sign_url
    }
  }
`;
export const get_sign_props = ({ mutate, ownProps }) => () =>
  mutate({
    variables: {
      allocationId: ownProps.allocation.id
    }
  });

import { defineAction } from "redux-define";
import { createAction } from "redux-actions";
import browserHistory from "utils/history";
import { takeLatest, take, call } from "redux-saga/effects";
import { toast } from "react-toastify";
const namespace = "flows/invest";

// Actions
const INVEST_FLOW = defineAction(
  "INVEST_FLOW",
  ["START", "NEXT", "SKIP"],
  namespace
);
export const startInvestFlow = createAction(INVEST_FLOW.START);
export const nextInvestFlow = createAction(INVEST_FLOW.NEXT);

// Saga
export function* investFlowSaga({
  payload: { deal, after, confirmationToast },
}) {
  // Wait for invest flow
  yield take(INVEST_FLOW.NEXT);

  // Skip pre-commmit step
  // // Send them to backing page
  // yield call(browserHistory.push, `/invest/backing/deals/${deal.id}`);
  // // Wait for backing to be done
  // yield take(INVEST_FLOW.NEXT);

  // Send them back to where they came from (they tuk r jerbs)
  yield call(browserHistory.push, atob(after));

  // Show toast
  if (confirmationToast === "yes") {
    const message = `Closing docs for ${deal.name} signed`;
    yield call([toast, toast.success], message, {
      position: toast.POSITION.TOP_CENTER,
    });
  }
}

export function* sagas() {
  yield takeLatest(INVEST_FLOW.START, investFlowSaga);
}

import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";

import { calculator } from "utils";

import "./style.css";

const Amount = ({ amount, name }) => (
  <span styleName={name}>
    <CountUp
      end={Math.round(amount)}
      separator=","
      duration={0.5}
      delay={0}
      prefix="$"
      data-test={name}
    />
  </span>
);

const Breakdown = ({
  deal,
  invite,
  backing,
  asset,
  user_self,
  investment,
  raise,
  mode
}) => {
  const backing_applies = invite.syndicator.self !== user_self;

  let {
    selfReturnWithoutBacking,
    backingReturnDelta,
    inviteReturnDelta
  } = calculator({
    deal,
    invite,
    backing,
    asset,
    user_self,
    investment,
    raise
  });

  if (investment >= 0 && raise >= 0) {
    return (
      <section styleName="container">
        <p styleName="item">
          Profits from your investment:{" "}
          <Amount amount={selfReturnWithoutBacking} name="breakdown_return" />
        </p>
        {backing && backing_applies && (
          <p styleName="item">
            Fees refunded from pre-committing:{" "}
            <Amount amount={backingReturnDelta} name="breakdown_backing" />
          </p>
        )}
        {mode === "raise" && (
          <p styleName="item">
            Fees earned from inviting:{" "}
            <Amount amount={inviteReturnDelta} name="breakdown_invite" />
          </p>
        )}
      </section>
    );
  }
};

Breakdown.propTypes = {
  deal: PropTypes.object.isRequired,
  invite: PropTypes.object.isRequired,
  user_self: PropTypes.string.isRequired,
  investment: PropTypes.number.isRequired,
  raise: PropTypes.number.isRequired
};

export default Breakdown;

import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { leadAcceptDone as done } from "./redux";
import { handleError as error } from "api/utils";

import AcceptInvite from "components/AcceptInvite";

const LeadDealContainer = ({
  match: {
    params: { secret, dealId }
  }
}) => {
  const dispatch = useDispatch();
  const onDone = () => {
    dispatch(done({ dealId }));
  };
  const onError = e => {
    dispatch(error(e));
  };

  return <AcceptInvite type="lead" {...{ dealId, secret, onDone, onError }} />;
};

LeadDealContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      secret: PropTypes.string.isRequired,
      dealId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default LeadDealContainer;
export { graphql } from "components/AcceptInvite";

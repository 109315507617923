exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/box.css"), "");
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/type.css"), "");

// Module
exports.push([module.id, ".web-flows-home-___style__header___3puof {\n  margin: 0;\n}\n\n.web-flows-home-___style__none___18xk5 {\n  text-align: center;\n  margin: 1rem 0;\n  padding: 1rem;\n}\n\n.web-flows-home-___style__investment_add___1Iry4 {\n  width: 100%;\n  text-align: center;\n}\n\n@media (max-width: 599px) {\n  .web-flows-home-___style__investment_add___1Iry4 {\n    margin: 1rem 0;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"header": "web-flows-home-___style__header___3puof",
	"none": "web-flows-home-___style__none___18xk5 " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/box.css").locals["box"] + " " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/type.css").locals["light"] + "",
	"investment_add": "web-flows-home-___style__investment_add___1Iry4"
};
exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".web-components-EditUserProfile-___EditUserProfile__header___15DK8 {\n  display: flex;\n  justify-content: space-between;\n  border-bottom: 1px solid #e5e3e3;\n  padding-bottom: 0.5rem;\n}\n\n.web-components-EditUserProfile-___EditUserProfile__close___23LYP {\n  padding: 0 0.7em 0.7em 0;\n}\n\n@media (min-width: 599px) {\n  .web-components-EditUserProfile-___EditUserProfile__content___oIhDF {\n    width: 600px;\n  }\n}\n\n@media (max-width: 599px) {\n  .web-components-EditUserProfile-___EditUserProfile__toplevel___T8EBq {\n    padding: 0.5rem 0em 0.5rem;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"header": "web-components-EditUserProfile-___EditUserProfile__header___15DK8",
	"close": "web-components-EditUserProfile-___EditUserProfile__close___23LYP",
	"content": "web-components-EditUserProfile-___EditUserProfile__content___oIhDF",
	"toplevel": "web-components-EditUserProfile-___EditUserProfile__toplevel___T8EBq"
};
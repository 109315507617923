exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../../styles/box.css"), "");

// Module
exports.push([module.id, ".web-flows-deal-investors-closed-components-Investor-___style__row___2z7XF {\n  display: flex;\n  flex-flow: row nowrap;\n  padding: 1rem;\n  color: #95989a;\n}\n\n.web-flows-deal-investors-closed-components-Investor-___style__item___29_JG {\n  flex-grow: 1;\n  flex-basis: 0;\n}\n\n.web-flows-deal-investors-closed-components-Investor-___style__name___36JUP {\n  display: flex;\n  flex-flow: row nowrap;\n  flex-grow: 2;\n}\n\n.web-flows-deal-investors-closed-components-Investor-___style__name___36JUP img {\n  height: 2.5em;\n  width: 2.5em;\n  border-radius: 50%;\n  background-color: #e5e3e3;\n}\n\n.web-flows-deal-investors-closed-components-Investor-___style__name___36JUP > span {\n  align-self: center;\n  margin: 0 0.5em;\n}\n\n.web-flows-deal-investors-closed-components-Investor-___style__status___1fBNl {\n  align-self: center;\n  flex-grow: 2;\n}\n\n.web-flows-deal-investors-closed-components-Investor-___style__status___1fBNl > span {\n  display: block;\n  margin: 0.2em;\n}\n\n.web-flows-deal-investors-closed-components-Investor-___style__inline_status___q9X_N {\n  display: none;\n  font-size: 0.9em;\n  margin-top: 0.3em;\n  color: #95989a;\n}\n\n@media (max-width: 950px) {\n  .web-flows-deal-investors-closed-components-Investor-___style__status___1fBNl {\n    display: none;\n  }\n\n  .web-flows-deal-investors-closed-components-Investor-___style__inline_status___q9X_N {\n    display: block;\n  }\n\n  .web-flows-deal-investors-closed-components-Investor-___style__name___36JUP > span {\n    color: #585858;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"row": "web-flows-deal-investors-closed-components-Investor-___style__row___2z7XF " + require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../../styles/box.css").locals["item"] + "",
	"item": "web-flows-deal-investors-closed-components-Investor-___style__item___29_JG",
	"name": "web-flows-deal-investors-closed-components-Investor-___style__name___36JUP",
	"status": "web-flows-deal-investors-closed-components-Investor-___style__status___1fBNl",
	"inline_status": "web-flows-deal-investors-closed-components-Investor-___style__inline_status___q9X_N"
};
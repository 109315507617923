import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { identity } from "lodash";

import ReactModal from "react-modal";

import { ONBOARD_MANUAL, manualVars } from "./graphql";
import ManualOnboard from "./components/ManualOnboard";
import { handleError as error } from "api/utils";
import { onboardedBankAccount as onboarded } from "./redux";

const LinkAccount = ({ entity, onLinking, onLinked, onCancel, user_self }) => {
  const [manualOpen, setManualOpen] = useState(true);

  const dispatch = useDispatch();
  const onError = useCallback(
    (err) => {
      dispatch(error(err));
      onCancel();
    },
    [dispatch]
  );
  const onSuccess = useCallback(
    (result) => {
      dispatch(onboarded(result));
      onLinked();
    },
    [dispatch]
  );

  const [saveManual] = useMutation(ONBOARD_MANUAL);
  const onboardManual = (metadata) => {
    onLinking && onLinking();
    saveManual(manualVars(metadata, user_self, entity.self))
      .then((result) => onSuccess({ source: result.data.sources }))
      .catch(onError);
  };

  const onManualCancel = () => {
    setManualOpen(false);
    onCancel();
  };

  return (
    <>
      <ReactModal
        isOpen={manualOpen}
        contentLabel="Had trouble?"
        shouldCloseOnOverlayClick
        onRequestClose={onManualCancel}
        shouldFocusAfterRender={false}
        className="ReactModal"
        ariaHideApp={false}
      >
        <ManualOnboard cancel={onManualCancel} submit={onboardManual} />
      </ReactModal>
    </>
  );
};

LinkAccount.propTypes = {
  entity: PropTypes.shape({
    self: PropTypes.string.isRequired,
  }).isRequired,
  onLinking: PropTypes.func,
  onLinked: PropTypes.func,
  onCancel: PropTypes.func,
};

LinkAccount.defaultProps = {
  onLinking: identity,
  onLinked: identity,
  onCancel: identity,
};

LinkAccount.fragments = {
  entity: gql`
    fragment LinkAccountEntity on Entity {
      self
    }
  `,
};

export default LinkAccount;

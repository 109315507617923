import React from "react";
import assert from "assert";

// Adapted from https://github.com/apollographql/react-apollo/issues/421#issuecomment-333109189
const mutateProps = ({ name = "mutate" } = {}) => WrappedComponent =>
  class MutateProps extends React.Component {
    constructor(props) {
      super(props);

      this.mutationProperty = name;
      this.loadingProperty = `${name}Loading`;
      this.errorProperty = `${name}Error`;
      this.resultProperty = `${name}Result`;

      assert(
        typeof this.props[this.mutationProperty] === "function",
        `this.${name} is not a function`
      );

      this.handleMutation = this.handleMutation.bind(this);
      this.state = {
        loading: false,
        error: null,
        result: null
      };
    }

    handleMutation() {
      this.setState({
        loading: true,
        error: null,
        result: null
      });
      const p = this.props[this.mutationProperty].apply(this, arguments);
      if (!p.then) {
        throw new Error("You didn't return a promise from the mutation");
      }
      return p
        .then(result => {
          this.setState({
            loading: false,
            error: null,
            result
          });
          return result;
        })
        .catch(error => {
          this.setState({
            loading: false,
            error,
            result: null
          });
          throw error;
        });
    }

    render() {
      const props = {
        ...this.props,
        [name]: this.handleMutation,
        [this.loadingProperty]: this.state.loading,
        [this.errorProperty]: this.state.error,
        [this.resultProperty]: this.state.result
      };
      return <WrappedComponent {...props} />;
    }
  };

export default mutateProps;

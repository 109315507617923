import posed, { PoseGroup } from "react-pose";

const fadeProps = {
  enter: { opacity: 1, transition: { duration: 200 } },
  exit: { opacity: 0, transition: { duration: 200 } }
};

const slideProps = {
  enter: { height: "auto", transition: { ease: "easeOut", duration: 200 } },
  exit: { height: 0, transition: { ease: "easeIn", duration: 0 } }
};

const sliddenProps = {
  enter: {
    height: "auto",
    opacity: 1,
    flip: true,
    overflow: "auto",
    transition: { ease: "easeOut", duration: 200 }
  },
  exit: {
    height: 0,
    opacity: 0,
    flip: false,
    overflow: "hidden",
    transition: { ease: "easeIn", duration: 200 }
  }
};

export const HiddenP = posed.p(fadeProps);
export const HiddenDiv = posed.div(fadeProps);
export const HiddenA = posed.a(fadeProps);

export const SlideP = posed.p(slideProps);
export const SlideDiv = posed.div(slideProps);
export const SlideA = posed.a(slideProps);

export const SliddenP = posed.p(sliddenProps);
export const SliddenDiv = posed.div(sliddenProps);
export const SliddenA = posed.a(sliddenProps);

export { PoseGroup } from "react-pose";

exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".web-flows-invest-agreement-components-Terms-___style__agree___5WNDs {\n  color: #95989a;\n  transition: color 0.2s;\n  font-size: 15pt;\n  margin: 1.33em 0 0;\n}\n\n.web-flows-invest-agreement-components-Terms-___style__agree___5WNDs > input {\n  vertical-align: middle;\n  margin-top: -0.3em;\n  margin-right: 0.33em;\n}\n\n.web-flows-invest-agreement-components-Terms-___style__terms___3XJWu {\n  padding-right: 2em;\n  color: #95989a;\n  transition: color 200ms;\n  margin: 0.67em 0 1em;\n}\n\n.web-flows-invest-agreement-components-Terms-___style__terms___3XJWu li {\n  padding-bottom: 200ms;\n}\n\n.web-flows-invest-agreement-components-Terms-___style__note___ydwkl {\n  color: #95989a;\n  margin: 1.33em 0 1em;\n}\n\n.web-flows-invest-agreement-components-Terms-___style__footer___3LSsC {\n  display: flex;\n  justify-content: center;\n  margin-top: 2em;\n}\n", ""]);

// Exports
exports.locals = {
	"agree": "web-flows-invest-agreement-components-Terms-___style__agree___5WNDs",
	"terms": "web-flows-invest-agreement-components-Terms-___style__terms___3XJWu",
	"note": "web-flows-invest-agreement-components-Terms-___style__note___ydwkl",
	"footer": "web-flows-invest-agreement-components-Terms-___style__footer___3LSsC"
};
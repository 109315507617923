import { defineAction } from "redux-define";
import { createAction, handleActions } from "redux-actions";
import { call, put, takeEvery, select } from "redux-saga/effects";
import Covert from "covert";
import config from "config";
import { selectUserSelf } from "flows/session/selectors";

import { EVENTS, STATUS, selectToken } from "./utils";

const api = config.cove.api;
const CoveApi = Covert({ api });

const namespace = "api/invites";

// Actions
const GET_INVITE = defineAction("GET_INVITE", EVENTS, namespace);
export const getInvite = createAction(GET_INVITE.ACTION);
export const getInviteRequested = createAction(GET_INVITE.REQUESTED);
export const getInviteSuccess = createAction(GET_INVITE.SUCCESS);
export const getInviteError = createAction(GET_INVITE.ERROR);

export const CREATE_INVITE = defineAction("CREATE_INVITE", EVENTS, namespace);
export const createInvite = createAction(CREATE_INVITE.ACTION);
export const createInviteRequested = createAction(CREATE_INVITE.REQUESTED);
export const createInviteSuccess = createAction(CREATE_INVITE.SUCCESS);
export const createInviteError = createAction(CREATE_INVITE.ERROR);

// Reducer
const initialState = {};
export const reducer = handleActions(
  {
    [GET_INVITE.REQUESTED]: (state, action) => ({
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        loaded: false
      }
    }),
    [GET_INVITE.SUCCESS]: (state, action) => ({
      ...state,
      [action.payload.id]: {
        ...state[action.payload.id],
        loaded: true,
        data: action.payload.data
      }
    })
    // TODO: Handle error
  },
  initialState
);

// Selector
export const selectInvite = (store, id) => store.data.invites[id];

// API Calls
export const fetchInviteApi = id => CoveApi.invites.get(id, { isGuest: true });
export const createInviteApi = (data, token) =>
  CoveApi.invites.create(data, { token });

// Get Invite Saga
export function* getInviteSaga({ payload }) {
  const id = payload.id;
  yield put(getInviteRequested({ id }));

  try {
    const data = yield call(fetchInviteApi, id);
    yield put(getInviteSuccess({ id, data }));
  } catch (error) {
    yield put(getInviteError({ id, error }));
  }
}

// Create Announcement Saga
export function* createInviteSaga({ payload }) {
  const { deal, message, email } = payload;
  const userSelf = yield select(selectUserSelf);
  const token = yield select(selectToken);

  // Spinner on
  yield put(createInviteRequested());

  try {
    yield call(
      createInviteApi,
      {
        message,
        email,
        deal: {
          self: deal.self
        },
        type: "investor",
        syndicator: {
          self: userSelf
        }
      },
      token
    );

    // Spinner off
    yield put(createInviteSuccess());
  } catch (error) {
    yield put(createInviteError({ error }));
  }
}

export function* sagas() {
  yield takeEvery(GET_INVITE.ACTION, getInviteSaga);
}

import React from "react";
import PropTypes from "prop-types";

import money from "money-math";
import millify from "millify";
import { isNull, isUndefined } from "lodash";

import "./style.css";

/* eslint-disable max-len */
export class InvestorAllocate extends React.Component {
  constructor(props) {
    super(props);

    // defaults
    const { investment } = this.props.allocation;
    let allocated = investment.allocated;
    if (isUndefined(allocated) || isNull(allocated)) {
      allocated = investment.amount;
    }

    this.state = {
      allocated: money.floatToAmount(allocated / 100)
    };

    // handlers
    this.onChange = this.onChange.bind(this);
  }
  onChange(event) {
    const { allocation } = this.props;
    const allocated = event.target.value;

    this.setState({
      allocated
    });

    this.props.change({
      allocation,
      allocated: allocated || 0
    });
  }
  renderName() {
    const {
      user,
      allocation: { owner }
    } = this.props;
    if (user === owner.self) {
      return "You";
    } else {
      return `${owner.firstName} ${owner.lastName}`;
    }
  }
  render() {
    const { allocation } = this.props;
    const { owner } = allocation;
    const initial = `$${money.format(
      "USD",
      money.floatToAmount(allocation.investment.amount / 100)
    )}`;
    // const allocated = `$${millify(parseInt(this.state.allocated, 10), 2)}`
    return (
      <div styleName="row" className="native_form">
        <div styleName="inline">
          <div styleName="name">
            <img src={owner.image} alt={this.renderName()} />
            <span data-test="allocate_investor_name">{this.renderName()}</span>
          </div>
          <div styleName="allocation">
            <span>
              <span styleName="allocation_header">Allocation: </span>
              <input
                className="input_dollar"
                type="number"
                step="5000"
                value={this.state.allocated}
                name="allocated"
                onChange={this.onChange}
                data-test="inline_investment_allocated"
              />
            </span>
            <div styleName="initial">Committed {initial}</div>
          </div>
        </div>
        <div styleName="name item">
          <img src={owner.image} alt="" />
          <span>{this.renderName()}</span>
        </div>
        <div styleName="item status">
          <div>
            <div styleName="allocation_header">Allocation</div>
            <input
              className="input_dollar"
              type="number"
              step="5000"
              value={this.state.allocated}
              name="allocated"
              onChange={this.onChange}
              data-test="investment_allocated"
            />
          </div>
        </div>
        <div styleName="item initial" data-test="allocate_investor_committed">
          Committed {initial}
        </div>
      </div>
    );
  }
}
/* eslint-enable max-len */

InvestorAllocate.propTypes = {
  allocation: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired
};

export default InvestorAllocate;

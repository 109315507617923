const co = require("co");
let assert = require("assert");
let fetch;
let Headers;
const { GraphQLClient } = require("graphql-request");

if (typeof window === "undefined") {
  fetch = require("cross-fetch");
  Headers = fetch.Headers;
} else {
  fetch = window.fetch;
  Headers = window.Headers;
  assert = console.assert;
}

const handleResponse = (response, options) => {
  return co(function* () {
    if (options.raw) {
      if (!response.ok) {
        const err = new Error(response);
        err.response = response;
        throw err;
      }
      return response;
    } else {
      if (!response.ok) {
        const body = yield response.json();
        const message = JSON.stringify(body);
        const err = new Error(`RestError: ${message}`);
        err.body = body;
        throw err;
      }
      return yield response.json();
    }
  });
};

module.exports = {
  get: (url, options) => {
    assert(options, "options not passed to post");
    assert(options.api, "options.api not passed to post");
    assert(
      options.token || options.isGuest,
      "options.token not passed in for auth'd call"
    );

    return co(function* () {
      let headers = new Headers();
      if (options.token) {
        headers = new Headers({
          Authorization: `Bearer ${options.token}`,
        });
      }

      const response = yield fetch(`${options.api}${url}`, {
        headers,
      });

      return handleResponse(response, options);
    });
  },
  post: (url, data, options) => {
    assert(options, "options not passed to post");
    assert(options.api, "options.api not passed to post");
    assert(options.token, "options.token not passed to post");

    return co(function* () {
      const response = yield fetch(`${options.api}${url}`, {
        headers: new Headers({
          Authorization: `Bearer ${options.token}`,
        }),
        method: "POST",
        body: JSON.stringify(data),
      });

      return handleResponse(response, options);
    });
  },
  patch: (url, data, options) => {
    assert(options, "options not passed to post");
    assert(options.token, "options.token not passed to post");

    return co(function* () {
      throw new Error("Not implemented");
    });
  },
  del: (url, data, options) => {
    assert(options, "options not passed to post");
    assert(options.token, "options.token not passed to post");

    return co(function* () {
      throw new Error("Not implemented");
    });
  },
  gql: (query, variables, options) => {
    assert(options, "options not passed to post");
    assert(options.token, "options.token not passed to post");

    return co(function* () {
      const client = new GraphQLClient(`${options.api}/graphql`, {
        headers: {
          Authorization: `Bearer ${options.token}`,
        },
      });

      return yield client.request(query, variables);
    });
  },
};

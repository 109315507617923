import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import gql from "graphql-tag";
import { graphql } from "utils/apollo";
import waitForUser from "utils/waitForUser";

import { selectUserId } from "flows/session/selectors";
import Spinner from "components/Spinner";

import { nextInterestFlow as next } from "../redux";
import InterestShare from "./component";

export const InterestShareContainer = ({ data: { deal, loading }, next }) => {
  if (loading) {
    return <Spinner data-test="interest_share_spinner" />;
  }
  return (
    <InterestShare
      deal={deal}
      invite={deal.invite}
      allocation={deal.allocation}
      asset={deal.asset}
      backing={deal.backing}
      onSubmit={next}
      onSkip={next}
      data-test="interest_share_child"
    />
  );
};

InterestShareContainer.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    deal: PropTypes.object
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      dealId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  userId: PropTypes.string.isRequired
};

export const query = gql`
  query InterestShare($dealId: ID!, $userId: ID!) {
    deal(id: $dealId) {
      id
      invite(user_id: $userId) {
        id
        ...InterestShareInvite
      }
      allocation(user_id: $userId) {
        id
        ...InterestShareAllocation
      }
      asset {
        id
        ...InterestShareAsset
      }
      backing(user_id: $userId) {
        id
        ...InterestShareBacking
      }
      ...InterestShareDeal
    }
  }
  ${InterestShare.fragments.deal}
  ${InterestShare.fragments.invite}
  ${InterestShare.fragments.allocation}
  ${InterestShare.fragments.asset}
  ${InterestShare.fragments.backing}
`;

const mapPropsToOptions = ({
  match: {
    params: { dealId }
  },
  userId
}) => ({
  variables: { dealId, userId },
  fetchPolicy: "network-only"
});

export const mapStore = store => ({
  userId: selectUserId(store)
});

export default compose(
  waitForUser(),
  connect(
    mapStore,
    { next }
  ),
  graphql(query, {
    options: mapPropsToOptions
  })
)(InterestShareContainer);

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { graphql } from "utils/apollo";
import gql from "graphql-tag";

import { handleError as error } from "api/utils";

import { startInterestFlow as start, nextInterestFlow as next } from "../redux";

import { saveMutation, saveProps, savePayload } from "../graphql";

import Spinner from "components/Spinner";
import { selectUserSelf } from "flows/session/selectors";

import InterestNewInvestor from "./component";
import InterestHeader from "./components/header";

export const InterestNewInvestorContainer = ({
  data: { loading, userinfo, deal },
  next,
  error,
  start,
  match: {
    params: { after, dealId }
  },
  saveLoading,
  save,
  user_self
}) => {
  // Start the interest flow
  useEffect(() => {
    start({
      after,
      dealId
    });
  }, []);

  const [step, setStep] = useState(1);

  // Callbacks
  const handleNext = state => {
    if (step < 3) {
      setStep(step + 1);
    } else {
      const payload = savePayload({
        state,
        user_self,
        deal,
        asset: deal.asset
      });
      save(payload)
        .then(() => next({ amount: payload.allocation.investment.amount }))
        .catch(error);
    }
  };

  // Render
  if (loading || saveLoading) {
    return <Spinner data-test="interest_new_spinner" />;
  }

  return (
    <section className="pageContainer">
      <InterestHeader deal={deal} step={step} />
      <InterestNewInvestor
        onNext={handleNext}
        entities={userinfo.entities}
        step={step}
        deal={deal}
        asset={deal.asset}
      />
    </section>
  );
};

InterestNewInvestorContainer.propTypes = {
  start: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      dealId: PropTypes.string.isRequired,
      after: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    userinfo: PropTypes.shape({
      entities: PropTypes.array
    })
  }).isRequired,
  save: PropTypes.func.isRequired,
  saveLoading: PropTypes.bool.isRequired
};

export const query = gql`
  query InterestNewInvestor($dealId: ID!) {
    deal(id: $dealId) {
      id
      self
      ...InterestNewInvestorDeal
      ...InterestHeaderDeal
      asset {
        id
        ...InterestNewInvestorAsset
      }
    }
    userinfo {
      id
      entities {
        id
        ...InterestNewInvestorEntity
      }
    }
  }
  ${InterestNewInvestor.fragments.entity}
  ${InterestNewInvestor.fragments.deal}
  ${InterestNewInvestor.fragments.asset}
  ${InterestHeader.fragments.deal}
`;

const mapPropsToOptions = ({
  match: {
    params: { dealId }
  }
}) => ({
  variables: { dealId }
});

export const mapStore = store => ({
  user_self: selectUserSelf(store)
});

export default compose(
  saveMutation,
  saveProps,
  graphql(query, {
    options: mapPropsToOptions
  }),
  connect(
    mapStore,
    { start, next, error }
  )
)(InterestNewInvestorContainer);

import React from "react";
import PropTypes from "prop-types";

import { GreyButton } from "components/Button";
import Page from "components/Page";

import { renderIRR, renderYield, renderExit } from "utils";

import "./style.css";

const renderTargetIRR = ({ deal, invite, user_self }) => {
  let targetIRR;
  if (deal.metrics.subleadIRR && deal.metrics.leadIRR) {
    return (
      <div>
        <dt styleName="number" data-test="metrics_irr">
          {renderIRR({ deal, invite, user_self })}
        </dt>
        <dd styleName="label">IRR</dd>
      </div>
    );
  }
};

const renderCoC = ({ deal, invite, user_self }) => {
  let targetCoC;
  if (deal.metrics.subleadYield && deal.metrics.leadYield) {
    return (
      <div>
        <dt styleName="number" data-test="metrics_yield">
          {renderYield({ deal, invite, user_self })}
        </dt>
        <dd styleName="label">Yield</dd>
      </div>
    );
  }
};

const renderExitMultiple = ({ deal, invite, user_self }) => {
  let exit;
  if (deal.metrics.subleadExit && deal.metrics.leadExit) {
    return (
      <div>
        <dt styleName="number" data-test="metrics_exit">
          {renderExit({ deal, invite, user_self })}
        </dt>
        <dd styleName="label">Exit Multiple</dd>
      </div>
    );
  }
};

const DealMetrics = ({ deal, invite, asset, user_self }) => {
  if (
    deal.metrics.leadIRR ||
    deal.metrics.leadYield ||
    deal.metrics.leadExit ||
    asset.term
  ) {
    return (
      <section styleName="container">
        <h2>Investment Metrics</h2>
        <p className="section_descriptor">
          give you a quick way to understand this opportunity
        </p>
        <dl styleName="list">
          {renderTargetIRR({ deal, invite, user_self })}
          {renderCoC({ deal, invite, user_self })}
          {renderExitMultiple({ deal, invite, user_self })}
          {!!asset.term && (
            <div>
              <dt styleName="number" data-test="metrics_term">
                {asset.term} years
              </dt>
              <dd styleName="label">Term</dd>
            </div>
          )}
        </dl>
        {!!deal.metrics.leadIRR && (
          <p styleName="projections">
            These projections are provided by the underwriter, based on their
            due diligence and financial modelling. Projections are net of fees.
          </p>
        )}
      </section>
    );
  }
  return null;
};

DealMetrics.propTypes = {
  deal: PropTypes.shape({
    metrics: PropTypes.shape({
      leadIRR: PropTypes.number,
      subleadIRR: PropTypes.number,
      leadYield: PropTypes.number,
      subleadYield: PropTypes.number,
      leadExit: PropTypes.number,
      subleadExit: PropTypes.number,
    }).isRequired,
  }).isRequired,
  invite: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  asset: PropTypes.shape({
    term: PropTypes.number,
  }).isRequired,
  user_self: PropTypes.string.isRequired,
};

export default DealMetrics;

import gql from "graphql-tag";

export const EDIT_PROFILE_QUERY = gql`
  query EditProfileQuery {
    userinfo {
      id
      self
      firstName
      lastName
      email
    }
  }
`;

export const EDIT_PROFILE_MUTATION = gql`
  mutation EditProfile($id: ID!, $user: UserProfileUpdatePayload) {
    update_user_profile(id: $id, user: $user) {
      user {
        id
        self
        firstName
        lastName
        email
      }
    }
  }
`;

export const mutationProps = ({ user, id }) => ({
  variables: {
    user,
    id
  },
  update: (
    store,
    {
      data: {
        update_user_profile: { user }
      }
    }
  ) => {
    // Read the data from our cache for this query.
    user.__typename = "UserInfo";

    // Write our data back to the cache.
    store.writeQuery({
      query: EDIT_PROFILE_QUERY,
      data: {
        userinfo: user
      }
    });
  }
});

import React from "react";
import PropTypes from "prop-types";

import { get_amount_from_alloc } from "utils";

import "./style.css";

const InvestorClosed = ({ allocation }) => {
  const { owner } = allocation;
  const name = `${owner.firstName} ${owner.lastName}`;

  let text = "Investing";
  if (allocation.investment && allocation.investment.amount) {
    const amount = get_amount_from_alloc(allocation);
    text = `Investing ${amount}`;
  }

  return (
    <div styleName="row">
      <div styleName="name item">
        <img src={owner.image} alt="" />
        <span>
          {name}
          <br />
          <span styleName="inline_status">{text}</span>
        </span>
      </div>
      <div styleName="item status" data-test="investor_closed_amount">
        {text}
      </div>
    </div>
  );
};
/* eslint-enable max-len */

InvestorClosed.propTypes = {
  allocation: PropTypes.shape({
    owner: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired
    }).isRequired,
    investment: PropTypes.shape({
      allocated: PropTypes.number
    }).isRequired
  }).isRequired
};

export default InvestorClosed;

exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/box.css"), "");
exports.i(require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css"), "");

// Module
exports.push([module.id, "@media (max-width: 599px) {\n  .web-flows-deal-lead-components-DealCalc-___style__header___oFRPB {\n    margin: 0 1rem;\n  }\n}\n\n.web-flows-deal-lead-components-DealCalc-___style__share_container___3mAUF {\n  padding: 1rem 0;\n}\n\n/* Could earn */\n.web-flows-deal-lead-components-DealCalc-___style__could_earn_container___3Xo71 {\n  padding: 1rem;\n  margin-top: 1rem;\n}\n\n@media (min-width: 600px) {\n  .web-flows-deal-lead-components-DealCalc-___style__could_earn_container___3Xo71 {\n    background-color: #f2f2f2;\n    box-shadow: none;\n  }\n}\n\n@media (max-width: 599px) {\n  .web-flows-deal-lead-components-DealCalc-___style__could_earn_container___3Xo71 {\n    background-color: white;\n  }\n}\n\n.web-flows-deal-lead-components-DealCalc-___style__could_earn_learn___2u5wb {\n  display: block;\n  font-size: 13pt;\n  padding-top: 0.33rem;\n}\n\n.web-flows-deal-lead-components-DealCalc-___style__could_earn_more___19PQJ {\n  margin: 0;\n}\n\n@media (max-width: 599px) {\n  .web-flows-deal-lead-components-DealCalc-___style__calc_container___2Y0BD {\n    margin: 0rem 1rem 0 1rem;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"header": "web-flows-deal-lead-components-DealCalc-___style__header___oFRPB",
	"share_container": "web-flows-deal-lead-components-DealCalc-___style__share_container___3mAUF",
	"could_earn_container": "web-flows-deal-lead-components-DealCalc-___style__could_earn_container___3Xo71 " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/box.css").locals["box"] + "",
	"could_earn_learn": "web-flows-deal-lead-components-DealCalc-___style__could_earn_learn___2u5wb",
	"could_earn_more": "web-flows-deal-lead-components-DealCalc-___style__could_earn_more___19PQJ " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css").locals["blue"] + "",
	"calc_container": "web-flows-deal-lead-components-DealCalc-___style__calc_container___2Y0BD"
};
const assert = require("assert");

const merge = require("lodash.merge");

const { get, post, patch, del, gql } = require("./agent");

module.exports = (global_options) => {
  return {
    create: ({ deal }, local_options) => {
      const options = merge({}, global_options, local_options);
      const query = `
        mutation M ($deal: DealCreate!) {
          createDeal(deal: $deal) {
            deal {
              id
              self
              name
              metrics {
                allocation
                flatFees
                leadExit
                subleadExit
                syndicateExit
                leadYield
                subleadYield
                syndicateYield
                leadIRR
                subleadIRR
                carry
                carry_hurdle
                carry_hurdle_type
                performanceAllocation
              }
              platform_fees {
                type
                amount 
                paid_by
              }
              syndicate_fees {
                type
                amount 
              }
              description
              memo {
                name
                link
              }
              model {
                name
                link
              }
              image
              status
              shares
              sponsor {
                name
                image
              }
              series {
                name
              }
              documents {
                closing
              }
              inviteCount
              allocationCount
              lead_invite_secret
              createdAt
              wiring_reminder_sent
            }
          }
        }
      `;
      return gql(query, { deal }, options);
    },
    get: (id, local_options) => {
      const options = merge({}, global_options, local_options);
      const query = `query GetDeal {
        deal(id: ${id}) {
          id
          self
          name
          metrics {
            allocation
            flatFees
            leadExit
            subleadExit
            syndicateExit
            leadYield
            subleadYield
            syndicateYield
            leadIRR
            subleadIRR
            carry
            carry_hurdle
            carry_hurdle_type
            performanceAllocation
          }
          platform_fees {
            type
            amount 
            paid_by
          }
          syndicate_fees {
            type
            amount 
          }
          description
          memo {
            name
            link
          }
          model {
            name
            link
          }
          image
          status
          shares
          sponsor {
            name
            image
          }
          series {
            name
          }
          documents {
            closing
          }
          inviteCount
          allocationCount
          lead_invite_secret
          createdAt
          wiring_reminder_sent
        }
      }`;

      return gql(query, null, options);
    },
    getBySecret: (secret, local_options) => {
      const options = merge({}, global_options, local_options);
      return get(`/deals/secret/${secret}`, options);
    },
    invites: (id, local_options) => {
      const options = merge({}, global_options, local_options);
      const query = `query GetDealInvites {
        deal(id: "${id}") {
          id
          name
          self
          invites {
            id
            self
            type
            used
            user {
              firstName
              lastName
              image
              self
            }
            allocation {
              investment {
                amount
              }
              owner {
                self
              }
            }
          }
        }
      }`;
      return gql(query, null, options);
    },
    finalize: (id, local_options) => {
      const options = merge({}, global_options, local_options);
      const query = `
        mutation M {
          deal: transitionToFinalizing(id:  "${id}") {
            id
            status
          }
        }
      `;
      return gql(query, null, options);
    },
    finalized: (id, local_options) => {
      const options = merge({}, global_options, local_options);
      const query = `
        mutation M {
          deal: transitionToFinalized(id:  "${id}") {
            id
            status
          }
        }
      `;
      return gql(query, null, options);
    },
    closed: (id, local_options) => {
      const options = merge({}, global_options, local_options);
      const query = `
        mutation M {
          deal: transitionToClosed(id:  "${id}") {
            id
            status
          }
        }
      `;
      return gql(query, null, options);
    },
    lead_allocate: (id, allocations, local_options) => {
      const options = merge({}, global_options, local_options);
      const query = `
        mutation M ($allocations: [AllocationAmount]) {
          allocations: allocateDeal(id: "${id}", allocations: $allocations) {
            id
            investment {
              allocated
            }
          }
        }
      `;
      return gql(query, { allocations }, options);
    },
    unsigned: (id, local_options) => {
      const options = merge({}, global_options, local_options);
      return get(`/deals/${id}/documents_closing`, options);
    },
  };
};

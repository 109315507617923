import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useInputChange, toAmount } from "utils";
import gql from "graphql-tag";

import Page from "components/Page";
import Button, { BigRedButton } from "components/Button";

import Docs from "../Docs";
import Terms from "../Terms";
import "./style.css";

const Agreement = ({ next, allocation, deal }) => {
  return (
    <Page>
      <h2 className="only_mobile">Sign Contracts</h2>
      <p styleName="subhead">These documents need to be signed:</p>
      <Docs allocation={allocation} />
      <Terms onSign={next} allocation={allocation} deal={deal} />
    </Page>
  );
};

Agreement.propTypes = {
  next: PropTypes.func.isRequired,
  allocation: PropTypes.object.isRequired,
  deal: PropTypes.object.isRequired
};

Agreement.fragments = {
  deal: gql`
    fragment AgreementDeal on Deal {
      ...TermsDeal
    }
    ${Terms.fragments.deal}
  `,
  allocation: gql`
    fragment AgreementAllocation on Allocation {
      ...TermsAllocation
    }
    ${Terms.fragments.allocation}
  `
};

export default Agreement;

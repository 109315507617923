/* eslint-disable no-control-regex */

import { defineAction } from "redux-define";
import { createAction } from "redux-actions";
import { put, take, race, call, takeLatest } from "redux-saga/effects";

import { createAnnouncementSaga } from "api/announcements";
import { showAnnouncement, hideAnnouncement } from "./reducer";

const namespace = "flows/deal/announce";

// Actions
const SEND_ANNOUNCEMENT = defineAction(
  "SEND_ANNOUNCEMENT",
  ["SUBMIT", "CANCEL"],
  namespace
);
export const sendAnnouncement = createAction(SEND_ANNOUNCEMENT.ACTION);
export const submitAnnouncement = createAction(SEND_ANNOUNCEMENT.SUBMIT);
export const cancelAnnouncement = createAction(SEND_ANNOUNCEMENT.CANCEL);

// Deal Lead Saga
export function* sendAnnouncementSaga({ payload }) {
  // show announcement popup
  yield put(showAnnouncement());

  // Wait
  const { submit } = yield race({
    submit: take(SEND_ANNOUNCEMENT.SUBMIT),
    cancel: take(SEND_ANNOUNCEMENT.CANCEL)
  });

  if (submit) {
    // Do the API call
    const message = submit.payload.message.replace(
      new RegExp("\r?\n", "g"),
      "<br />"
    );
    yield call(createAnnouncementSaga, {
      payload: {
        deal: payload.deal,
        message,
        subject: submit.payload.subject
      }
    });
  }

  // hide announcement modal
  yield put(hideAnnouncement());
}

export function* sagas() {
  yield takeLatest(SEND_ANNOUNCEMENT.ACTION, sendAnnouncementSaga);
}

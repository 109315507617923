import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";

import { calculator, renderPointsNoPercent } from "utils";

import "./style.css";

const IRR = ({
  deal,
  invite,
  backing,
  asset,
  user_self,
  investment,
  raise,
  mode
}) => {
  const backing_applies = invite.syndicator.self !== user_self;

  const { selfIRR, selfIRRWithoutBacking, inviteIRR } = calculator({
    deal,
    invite,
    backing,
    asset,
    user_self,
    investment,
    raise
  });

  if (investment >= 0 && raise >= 0) {
    return (
      <>
        <span styleName="before">
          <CountUp
            end={renderPointsNoPercent(selfIRRWithoutBacking)}
            separator=","
            duration={0.5}
            delay={0}
            decimals={1}
            suffix="%"
            data-test="calc_irr_invest"
          />
        </span>
        <span styleName="arrow">&nbsp;→&nbsp;</span>
        <span styleName="after">
          <CountUp
            end={renderPointsNoPercent(inviteIRR)}
            separator=","
            duration={0.5}
            delay={0}
            decimals={1}
            suffix="%"
            data-test="calc_irr"
          />
        </span>
      </>
    );
  }

  return "$0";
};

IRR.propTypes = {
  deal: PropTypes.object.isRequired,
  invite: PropTypes.object.isRequired,
  user_self: PropTypes.string.isRequired,
  investment: PropTypes.number.isRequired,
  raise: PropTypes.number.isRequired
};

export default IRR;

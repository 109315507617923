import React from "react";
import PropTypes from "prop-types";

import "./ModalPage.css";

const ModalPage = props => (
  <div styleName="modal_page" {...props}>
    {props.children}
  </div>
);

ModalPage.propTypes = {
  children: PropTypes.node.isRequired
};

export default ModalPage;

import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import Investment from "./components/Investment";
import Opportunity from "./components/Opportunity";

import "./style.css";

const Home = ({ opportunities, investments, investment_add }) => (
  <section className="fullPageContainer has_top_page_border">
    <h2 styleName="header">Opportunities</h2>
    <section data-test="opportunities_list">
      {!!opportunities.length &&
        opportunities.map(invite => (
          <Opportunity
            invite={invite}
            deal={invite.deal}
            key={invite.id}
            backing={invite.deal.backing}
            data-test={invite.id}
          />
        ))}
      {!opportunities.length && (
        <div styleName="none">No opportunities to consider right now.</div>
      )}
    </section>
    <h2 styleName="header">Investments</h2>
    <section data-test="investments_list">
      {!!investments.length &&
        investments.map(invite => (
          <Investment
            invite={invite}
            deal={invite.deal}
            key={invite.id}
            data-test={invite.id}
          />
        ))}
      {!investments.length && (
        <div styleName="none">No investments made yet.</div>
      )}
      {/* 
      <section styleName="investment_add" data-test="investment_add" onClick={investment_add}>
        <a>Add investment to portfolio</a>
      </section>
      */}
    </section>
  </section>
);

Home.propTypes = {
  opportunities: PropTypes.array.isRequired,
  investments: PropTypes.array.isRequired,
  investment_add: PropTypes.func.isRequired
};

Home.fragments = {
  investment: gql`
    fragment HomeInvestment on Invite {
      ...InvestmentInvite
    }
    ${Investment.fragments.invite}
  `,
  opportunity: gql`
    fragment HomeOpportunity on Invite {
      ...OpportunityInvite
    }
    ${Opportunity.fragments.invite}
  `,
  opportunity_backing: gql`
    fragment HomeOpportunityBacking on Backing {
      ...OpportunityBacking
    }
    ${Opportunity.fragments.backing}
  `
};

export default Home;

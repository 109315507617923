exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!../styles/box.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!../styles/border.css"), "");

// Module
exports.push([module.id, ".web-containers-___Login__fullscreen___rE2o2 {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  width: 100%;\n  height: 100vh;\n  background: var(--login-bg);\n  background-size: cover;\n}\n\n.web-containers-___Login__container___sBa_1 {\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  width: 100%;\n}\n\n.web-containers-___Login__login-box___2-4dO {\n  width: 100%;\n  max-width: 300px;\n  border-radius: 4px;\n}\n\n.web-containers-___Login__logo___uqZ1w {\n  padding: 1rem 1rem 0.5rem;\n  text-align: center;\n}\n\n.web-containers-___Login__logo___uqZ1w img {\n  height: 58px;\n}\n\n.web-containers-___Login__buttons___1K3ma {\n  padding: 20px 15px 10px 15px;\n}\n", ""]);

// Exports
exports.locals = {
	"fullscreen": "web-containers-___Login__fullscreen___rE2o2",
	"container": "web-containers-___Login__container___sBa_1",
	"login-box": "web-containers-___Login__login-box___2-4dO " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!../styles/box.css").locals["box"] + "",
	"logo": "web-containers-___Login__logo___uqZ1w " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!../styles/border.css").locals["bottom"] + "",
	"buttons": "web-containers-___Login__buttons___1K3ma"
};
import { fork } from "redux-saga/effects";

import { sagas as interest } from "./interest/redux";
import { sagas as invest } from "./invest/redux";
import { sagas as account } from "./account/redux";
import { sagas as lead } from "./lead/redux";

export function* sagas() {
  yield fork(interest);
  yield fork(invest);
  yield fork(account);
  yield fork(lead);
}

import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import config from "config";
import { get_amount_from_alloc } from "utils";

import "./style.css";

export default class Investment extends React.PureComponent {
  constructor() {
    super();

    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    const { deal, manage } = this.props;
    return manage({ deal });
  }
  renderStatus() {
    const { invite, deal, manage, interest, invest, closing } = this.props;
    let result;
    let sub;
    if (invite.type === "lead") {
      sub = (
        <p
          styleName="detail_body"
          onClick={this.manage}
          data-test="home_manage"
        >
          <span styleName="blue" data-test="investment_investors">
            {deal.allocationCount}
          </span>{" "}
          Investors
        </p>
      );

      if (invite.allocation) {
        const amount = get_amount_from_alloc(invite.allocation);
        result = (
          <p styleName="detail_body">
            Your investment:{" "}
            <span styleName="green" data-test="investment_amount">
              {amount}
            </span>
          </p>
        );
      }
      return (
        <section styleName="status">
          {result}
          {sub}
        </section>
      );
    } else if (invite.type === "investor") {
      const amount = get_amount_from_alloc(invite.allocation);
      return (
        <section styleName="status" data-test="home_open">
          <header styleName="detail_header">Investment</header>
          <p styleName="detail_body green" data-test="investment_amount">
            {amount}
          </p>
        </section>
      );
    }
  }
  renderInlineStatus() {
    const { invite, deal, manage, interest, invest, closing } = this.props;

    if (invite.allocation) {
      const amount = get_amount_from_alloc(invite.allocation);
      return (
        <>
          <span data-test="investment_amount_inline">{amount}</span>
        </>
      );
    } else {
      return (
        <>
          <span styleName="blue" data-test="investment_investors_inline">
            {deal.allocationCount}
          </span>{" "}
          Investors <a data-test="home_manage">Manage</a>
        </>
      );
    }
  }
  render() {
    const { deal } = this.props;
    return (
      <div
        styleName="investment"
        onClick={this.handleClick}
        data-test="investment_item"
      >
        <section styleName="header">
          <img src={deal.image} alt="" styleName="pic" />
          <header styleName="titleContainer">
            <div styleName="title">{deal.name}</div>
            <div styleName="inline_status">{this.renderInlineStatus()}</div>
          </header>
        </section>
        {!config.cove.brand.hideHomeSponsors && (
          <section styleName="detail multiple_details extra">
            <section styleName="kinda_extra">
              <header styleName="detail_header">Sponsor</header>
              <p styleName="detail_body">{deal.sponsor.name}</p>
            </section>
            <section>
              <header styleName="detail_header">Syndicate</header>
              <p styleName="detail_body">{deal.syndicate.name}</p>
            </section>
          </section>
        )}
        {this.renderStatus()}
      </div>
    );
  }
}

Investment.propTypes = {
  invite: PropTypes.shape({}).isRequired,
  deal: PropTypes.shape({
    self: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

Investment.fragments = {
  invite: gql`
    fragment InvestmentInvite on Invite {
      type
      deal {
        id
        self
        name
        status
        image
        inviteCount
        allocationCount
        sponsor {
          name
        }
        syndicate {
          id
          self
          name
        }
        asset {
          id
          self
          name
          inviteCount
          allocationCount
        }
      }
      allocation {
        id
        self
        investment {
          amount
          allocated
        }
        documents {
          signed
        }
      }
    }
  `,
};

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useInputChange, toAmount } from "utils";
import gql from "graphql-tag";
import { BigRedButton } from "components/Button";
import config from "config";

import "./style.css";

const Terms = ({ onSign, allocation, deal }) => {
  const agreementInput = useInputChange({
    initial: false,
  });
  const w9Input = useInputChange({
    initial: false,
  });

  const canSign = !!agreementInput.value && !!w9Input.value;

  const onSubmit = useCallback(() => {
    if (canSign) {
      onSign();
    }
  }, [canSign, onSign]);

  const agreement_style = (agreementInput.value && { color: "#585858" }) || {};
  const w9_style = (w9Input.value && { color: "#585858" }) || {};

  return (
    <section className="native_form">
      <p styleName="agree" style={agreement_style}>
        <input
          type="checkbox"
          id="invest_agreement_checked"
          name="agreement_checked"
          data-test="invest_agreement_checked"
          {...agreementInput.checkedAttrs}
        />
        <label htmlFor="invest_agreement_checked">
          I agree to the terms in the{" "}
          <a
            href={`/docs/deal/unsigned/${allocation.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            agreements
          </a>{" "}
          above including:
        </label>
      </p>
      <ul
        styleName="terms"
        style={agreement_style}
        onClick={() => {
          agreementInput.setValue(!agreementInput.value);
        }}
      >
        <li>
          I&#39;m contributing{" "}
          <strong>{toAmount(allocation.investment.allocated)}</strong> to a
          fund, <strong>{deal.series.name}</strong>, that will invest in{" "}
          {deal.asset.name}
        </li>
        <li>
          I&#39;m <strong>not investing directly</strong> into the asset and
          will not have direct investor rights
        </li>
        <li>
          I must do my <strong>own due diligence</strong> and have read the
          closing documents
        </li>
        <li>
          Investing with {deal.syndicate.name}, {deal.sponsor.name} or{" "}
          {config.cove.brand.title}{" "}
          <strong>doesn&#39;t guarantee any diligence</strong> on this
          investment
        </li>
      </ul>
      <p styleName="agree" style={w9_style}>
        <input
          type="checkbox"
          id="invest_w9_checked"
          name="w9_checked"
          data-test="invest_w9_checked"
          {...w9Input.checkedAttrs}
        />
        <label htmlFor="invest_w9_checked">
          I understand I am certifying the{" "}
          <a
            href={`/docs/w9/${allocation.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            IRS Form W-9
          </a>{" "}
          above:
        </label>
      </p>
      <ul
        styleName="terms"
        style={w9_style}
        onClick={() => {
          w9Input.setValue(!w9Input.value);
        }}
      >
        <li>
          I understand the information provided by me in the Form W-9 will be
          used by {config.cove.brand.title} for tax reporting purposes
        </li>
        <li>
          I have checked the validity of my IRS Form W-9 generated by{" "}
          {config.cove.brand.title} and certify it is correct, under penalties
          of perjury
        </li>
      </ul>
      <p styleName="note">
        You&#39;ll receive counter-signed copies of the closing documents when
        the deal closes.
      </p>
      <footer styleName="footer">
        <BigRedButton onClick={onSubmit} data-test="agree" disabled={!canSign}>
          Sign Contracts
        </BigRedButton>
      </footer>
    </section>
  );
};

Terms.propTypes = {
  onSign: PropTypes.func.isRequired,
  allocation: PropTypes.shape({
    id: PropTypes.string.isRequired,
    self: PropTypes.string.isRequired,
    investment: PropTypes.shape({
      allocated: PropTypes.number.isRequired,
    }).isRequired,
    documents: PropTypes.shape({
      signed: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  deal: PropTypes.shape({
    self: PropTypes.string.isRequired,
    series: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    asset: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    syndicate: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    sponsor: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

Terms.fragments = {
  deal: gql`
    fragment TermsDeal on Deal {
      self
      name
      series {
        name
      }
      asset {
        id
        name
      }
      sponsor {
        name
      }
      syndicate {
        id
        name
      }
    }
  `,
  allocation: gql`
    fragment TermsAllocation on Allocation {
      id
      self
      investment {
        funded
        allocated
      }
      documents {
        signed
      }
    }
  `,
};

export default Terms;

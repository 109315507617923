import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { history as historyShape } from "react-router-prop-types";

const OnRouteChange = ({ history }) => {
  useEffect(() => {
    // console.log('> Router', history.action, history.location)
    window.scrollTo(0, 0);
  }, [history.location.key]);

  return null;
};

OnRouteChange.propTypes = { history: historyShape };

export default withRouter(OnRouteChange);

exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/type.css"), "");
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/border.css"), "");

// Module
exports.push([module.id, ".web-components-PictureHeader-___style__container___3yhyr {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 1rem;\n}\n\n@media (max-width: 599px) {\n  .web-components-PictureHeader-___style__container___3yhyr {\n    padding: 0 1rem;\n    flex-direction: column;\n    text-align: center;\n  }\n  .web-components-PictureHeader-___style__pic___2gb30 {\n    margin-bottom: 1rem;\n  }\n}\n\n.web-components-PictureHeader-___style__pic___2gb30 {\n  width: 50px;\n  height: 50px;\n}\n\n.web-components-PictureHeader-___style__pic___2gb30 img {\n  width: 50px;\n  height: 50px;\n  border-radius: 100%;\n  background-position: center;\n  background-size: cover;\n  object-fit: cover;\n}\n\n.web-components-PictureHeader-___style__title___-oYiB {\n  font-size: 16pt;\n  padding: 0 1rem;\n}\n\n.web-components-PictureHeader-___style__border___2WI-v {\n}\n", ""]);

// Exports
exports.locals = {
	"container": "web-components-PictureHeader-___style__container___3yhyr",
	"pic": "web-components-PictureHeader-___style__pic___2gb30",
	"title": "web-components-PictureHeader-___style__title___-oYiB " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/type.css").locals["dark"] + "",
	"border": "web-components-PictureHeader-___style__border___2WI-v " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/border.css").locals["around"] + ""
};
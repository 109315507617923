exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/border.css"), "");
exports.i(require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css"), "");

// Module
exports.push([module.id, ".web-flows-deal-tracker-components-graph-___style__distributions_tracker___tAtbq {\n  padding-bottom: 1.66rem;\n}\n\n@media (min-width: 800px) {\n  .web-flows-deal-tracker-components-graph-___style__container___15b6G {\n    margin-right: 1rem;\n  }\n}\n\n.web-flows-deal-tracker-components-graph-___style__distributions_tracker___tAtbq .rv-discrete-color-legend {\n  text-align: center;\n  font-size: 12pt;\n}\n\n.web-flows-deal-tracker-components-graph-___style__distributions_tracker___tAtbq .rv-discrete-color-legend-item {\n  padding-top: 0 !important;\n}\n\n.web-flows-deal-tracker-components-graph-___style__distributions_tracker___tAtbq .rv-discrete-color-legend-item__title {\n  display: inline-block !important;\n  margin-left: 0.5rem !important;\n}\n\n.web-flows-deal-tracker-components-graph-___style__hint___1015_ {\n  background-color: rgba(255, 255, 255, 0.9);\n  padding: 0.5rem;\n  border-radius: 5px;\n  font-size: 10pt;\n}\n\n.web-flows-deal-tracker-components-graph-___style__hint_actual___29W9J {\n  font-weight: 500;\n}\n\n.web-flows-deal-tracker-components-graph-___style__hint_projected___F9AvZ {\n  font-weight: 500;\n}\n\n.web-flows-deal-tracker-components-graph-___style__hint_title___2sCue {\n  font-weight: 600;\n  line-height: 1.3em;\n}\n", ""]);

// Exports
exports.locals = {
	"distributions_tracker": "web-flows-deal-tracker-components-graph-___style__distributions_tracker___tAtbq",
	"container": "web-flows-deal-tracker-components-graph-___style__container___15b6G",
	"hint": "web-flows-deal-tracker-components-graph-___style__hint___1015_ " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/border.css").locals["around"] + " " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css").locals["dark"] + "",
	"hint_actual": "web-flows-deal-tracker-components-graph-___style__hint_actual___29W9J " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css").locals["green"] + "",
	"hint_projected": "web-flows-deal-tracker-components-graph-___style__hint_projected___F9AvZ " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css").locals["blue"] + "",
	"hint_title": "web-flows-deal-tracker-components-graph-___style__hint_title___2sCue"
};
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toAmount } from "utils";
import moment from "moment";
import {
  routeToDealInterestFromDeal as invest,
  routeToInterestBackingFromDeal as confirm,
} from "flows/routing";

import Button from "components/Button";

import Commitment from "../Commitment";

import "./style.css";

const PreCommitment = ({ backing }) => (
  <div styleName="backing_container">
    <dd styleName="backing_label">Pre-commitment</dd>
    <dt styleName="backing_amount" data-test="invest_precommitment">
      {toAmount(backing.amount)}
    </dt>
  </div>
);
PreCommitment.propTypes = {
  backing: PropTypes.shape({
    amount: PropTypes.number.isRequired,
  }).isRequired,
};

const Timeline = ({ asset }) => {
  const closingDate = moment(asset.closingDate);
  let close_in = closingDate.fromNow();
  if (closingDate.isBefore(new Date())) {
    close_in = "in 1 day";
  }

  return (
    <div styleName="timeline_container">
      <div styleName="timeline_label">Timeline</div>
      <div>
        Close <span styleName="duration">{close_in}</span>
      </div>
    </div>
  );
};
Timeline.propTypes = {
  asset: PropTypes.shape({
    closingDate: PropTypes.string.isRequired,
  }),
};

const InvestButton = ({ allocation, backing, onClick }) => {
  let label = "Invest";
  if (allocation) {
    label = (
      <span>
        Change<span styleName="little_extra"> commitment</span>
      </span>
    );
  } else if (backing) {
    label = (
      <span>
        <span styleName="too_much">Invest</span>
        <span styleName="little_extra">Confirm pre-commitment</span>
      </span>
    );
  }

  return (
    <Button onClick={onClick} data-test="deal_invest">
      {label}
    </Button>
  );
};
InvestButton.propTypes = {
  allocation: PropTypes.object,
  backing: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

const AssetOpen = ({
  asset,
  allocation,
  backing,
  deal,
  invite,
  invest,
  confirm,
}) => {
  const onClick = useCallback(() => {
    if (allocation || backing) {
      confirm({
        deal,
      });
    } else {
      invest({
        deal,
        invite,
      });
    }
  }, [allocation, backing, deal, invite, invest, confirm]);

  return (
    <section styleName="container">
      {allocation && <Commitment allocation={allocation} />}
      {!allocation && backing && <PreCommitment backing={backing} />}
      <Timeline asset={asset} />
      <section>
        <InvestButton
          allocation={allocation}
          backing={backing}
          onClick={onClick}
        />
      </section>
    </section>
  );
};

AssetOpen.propTypes = {
  asset: PropTypes.object.isRequired,
  allocation: PropTypes.object,
  backing: PropTypes.object,
  deal: PropTypes.object,
  invite: PropTypes.object,
  invest: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};

export default connect(
  null,
  { invest, confirm }
)(AssetOpen);

const assert = require("assert");

const merge = require("lodash.merge");

const { get, post, patch, del, gql } = require("./agent");

module.exports = (global_options) => {
  return {
    create: ({ asset }, local_options) => {
      const options = merge({}, global_options, local_options);
      const query = `
        mutation M ($asset: AssetCreate!) {
          asset: createAsset(asset: $asset) {
            id
            self
          }
        }
      `;
      return gql(query, { asset }, options);
    },
    add_update: ({ asset_update }, local_options) => {
      const options = merge({}, global_options, local_options);
      const query = `
        mutation M ($asset_update: AssetUpdateCreate!) {
          add_asset_update(asset_update: $asset_update, notify_investors: false) {
            asset_update {
              id
              self
              description
              content
              link {
                url
                text
              }
              createdAt
            }
          }
        }
      `;
      return gql(query, { asset_update }, options);
    },
    open: (id, local_options) => {
      const options = merge({}, global_options, local_options);
      const query = `
        mutation M ($id: ID!) {
          open_asset_for_commitments(id: $id) {
            asset {
              id
              self
              openToCommitments
            }
            asset_interests {
              id
              self
              used
            }
            asset_interests_count
          }
        }
      `;
      return gql(query, { id }, options);
    },
  };
};

import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import gql from "graphql-tag";
import { graphql } from "utils/apollo";

import waitForUser from "utils/waitForUser";

import { selectUserId, selectUserSelf } from "flows/session/selectors";
import Spinner from "components/Spinner";

import { handleError as error } from "api/utils";

import { nextInterestFlow as next, startInterestFlow as start } from "../redux";
import { saveMutation, saveProps, savePayload } from "../graphql";
import InterestBacking from "./component";

export const InterestBackingContainer = ({
  data: { deal, userinfo, loading },
  match: {
    params: { dealId, after },
  },
  save,
  next,
  start,
  error,
  user_self,
  saveLoading,
}) => {
  // Start the interest backing flow
  useEffect(() => {
    start({
      after,
      dealId,
    });
  }, []);

  // Callbacks
  const handleSubmit = (state) => {
    const payload = savePayload({ state, user_self, deal, asset: deal.asset });
    const onSave = () => next({ amount: state.investment_amount });
    save(payload)
      .then(onSave)
      .catch(error);
  };

  // Render
  if (loading || saveLoading) {
    return <Spinner data-test="interest_share_spinner" />;
  }
  return (
    <InterestBacking
      deal={deal}
      backing={deal.backing}
      allocation={deal.allocation}
      onSubmit={handleSubmit}
      entities={userinfo.entities}
      user_self={user_self}
      data-test="interest_share_child"
    />
  );
};

InterestBackingContainer.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    deal: PropTypes.shape({
      asset: PropTypes.shape({
        investorCheck: PropTypes.string.isRequired,
      }).isRequired,
    }),
    userinfo: PropTypes.object,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      dealId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  userId: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
  saveLoading: PropTypes.bool.isRequired,
  start: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
};

export const query = gql`
  query InterestBacking($dealId: ID!, $userId: ID!) {
    deal(id: $dealId) {
      id
      self
      backing(user_id: $userId) {
        id
        self
        ...InterestBackingBacking
      }
      allocation(user_id: $userId) {
        id
        self
        ...InterestBackingAllocation
      }
      ...InterestBackingDeal
    }
    userinfo {
      id
      entities {
        id
        self
        ...InterestBackingEntity
      }
    }
  }
  ${InterestBacking.fragments.deal}
  ${InterestBacking.fragments.backing}
  ${InterestBacking.fragments.allocation}
  ${InterestBacking.fragments.entity}
`;

const mapPropsToOptions = ({
  match: {
    params: { dealId },
  },
  userId,
}) => ({
  variables: { dealId, userId },
});

export const mapStore = (store) => ({
  userId: selectUserId(store),
  user_self: selectUserSelf(store),
});

export default compose(
  waitForUser(),
  saveMutation,
  saveProps,
  connect(
    mapStore,
    { next, start, error }
  ),
  graphql(query, {
    options: mapPropsToOptions,
  })
)(InterestBackingContainer);

const assert = require("assert");

const merge = require("lodash.merge");

const { get, post, patch, del } = require("./agent");

module.exports = (global_options) => {
  return {
    create: (data, local_options) => {
      const options = merge({}, global_options, local_options);
      return post(`/announcements`, data, options);
    },
  };
};

import { combineReducers } from "redux";

import env from "./env";

import { reducer as api } from "../api";
import { reducer as flows } from "../flows";
import { reducer as session } from "../flows/session/reducer";

export default envData =>
  combineReducers({
    session,
    data: api,
    flows,
    env: env(envData)
  });

import React, { useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import CouldEarnMore from "components/CouldEarnMore";
import Calculator from "components/Calculator";

import "./style.css";

const InterestCalculator = ({ deal, invite, allocation, backing, asset }) => {
  const investment = parseInt(allocation.investment.amount / 100, 10);
  const raise = investment * 2;

  const [open, setOpen] = useState(false);

  return (
    <section styleName="calc_container">
      {!open && (
        <>
          <CouldEarnMore
            deal={deal}
            invite={invite}
            backing={backing}
            asset={asset}
            investment={investment}
            raise={raise}
          />
          <a
            onClick={() => {
              setOpen(true);
            }}
            styleName="open_link"
            data-test="interest_calculator_open"
          >
            Calculate your returns
          </a>
        </>
      )}
      {open && (
        <>
          <h2 styleName="header">Increase your returns</h2>
          <p className="section_descriptor">
            by inviting others to invest with you
          </p>
          <Calculator
            deal={deal}
            invite={invite}
            mode="raise"
            allocation={allocation}
            backing={backing}
            asset={asset}
          />
        </>
      )}
    </section>
  );
};

InterestCalculator.propTypes = {
  deal: PropTypes.shape().isRequired,
  allocation: PropTypes.shape({
    investment: PropTypes.shape({
      amount: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  invite: PropTypes.shape({
    syndicator: PropTypes.shape({
      self: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  asset: PropTypes.shape({
    term: PropTypes.number.isRequired
  }).isRequired,
  backing: PropTypes.object
};

InterestCalculator.fragments = {
  deal: gql`
    fragment InterestCalculatorDeal on Deal {
      ...CalculatorDeal
    }
    ${Calculator.fragments.deal}
  `,
  invite: gql`
    fragment InterestCalculatorInvite on Invite {
      syndicator {
        id
        self
      }
      ...CalculatorInvite
    }
    ${Calculator.fragments.invite}
  `,
  allocation: gql`
    fragment InterestCalculatorAllocation on Allocation {
      investment {
        amount
      }
    }
  `,
  backing: gql`
    fragment InterestCalculatorBacking on Backing {
      ...CalculatorBacking
    }
    ${Calculator.fragments.backing}
  `,
  asset: gql`
    fragment InterestCalculatorAsset on Asset {
      ...CalculatorAsset
    }
    ${Calculator.fragments.asset}
  `
};

export default InterestCalculator;

import React, { useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import {
  FlexibleWidthXYPlot,
  LineMarkSeries,
  LineSeries,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
  DiscreteColorLegend,
  Hint
} from "react-vis";
import moment from "moment";

import { toAmount, toRoundedAmount } from "utils";

import "./style.css";

const plot_margin = { left: 30, right: 30, top: 10, bottom: 40 };

const get_quarter = month => {
  if (month < 4) {
    return "Q1";
  } else if (month < 7) {
    return "Q2";
  } else if (month < 10) {
    return "Q3";
  } else {
    return "Q4";
  }
};

const projected_styles = {
  color: "#2D62B7",
  strokeDasharray: [3, 4]
};

const actual_styles = {
  color: "#11BA79"
};

const series = [
  {
    title: `Projected`,
    ...projected_styles
  },
  {
    title: "Earned",
    ...actual_styles
  }
];

const tickFormat = (v, i, s, t) => {
  const date = moment(v);
  const month = v.getMonth();
  if (i === 0) {
    return `${get_quarter(month)} ${date.year()}`;
  } else if (i === 1) {
    return `${get_quarter(month)} ${date.year()}`;
  }
};

const xAxisStyle = {
  text: {
    fontSize: "10pt"
  }
};

const calc_data = ({ distributions, asset }) => {
  const actual = [];
  const projected = [{ x: moment(asset.closingDate).toDate(), y: 0 }];
  let total_actual = 0;
  let total_projected = 0;

  distributions.forEach(distro => {
    total_actual += distro.breakdown.net;
    total_projected += distro.projected.net;
    const x = moment(distro.endDate).toDate();
    actual.push({
      x,
      y: total_actual,
      title: distro.description,
      projected: total_projected
    });

    projected.push({
      x,
      y: total_projected
    });
  });

  const bigger = Math.max(total_actual, total_projected);
  const top = Math.ceil(bigger * 1.05);
  const range = [0, top];

  return {
    actual,
    projected,
    total_actual,
    total_projected,
    range
  };
};

const DistributionsGraph = ({ distributions, asset }) => {
  const { actual, projected, total_actual, total_projected, range } = useMemo(
    () => calc_data({ distributions, asset }),
    [distributions, asset]
  );

  const [current, setCurrent] = useState(null);

  const onMouseOver = useCallback(value => setCurrent(value), [setCurrent]);
  const onMouseOut = useCallback(() => setCurrent(false), [setCurrent]);

  const tickValues = [actual[0].x, actual[actual.length - 1].x];

  return (
    <section styleName="container">
      <FlexibleWidthXYPlot
        height={300}
        xType="time"
        yDomain={range}
        styleName="distributions_tracker"
        margin={plot_margin}
      >
        <XAxis
          tickFormat={tickFormat}
          style={xAxisStyle}
          tickValues={tickValues}
        />
        <LineSeries data={projected} {...projected_styles} strokeWidth={1} />
        <LineMarkSeries
          data={actual}
          {...actual_styles}
          strokeWidth={1}
          onNearestX={onMouseOver}
          onValueMouseOut={onMouseOut}
        />
        <DiscreteColorLegend items={series} orientation="horizontal" />
        {current ? (
          <Hint
            value={current}
            align={{ vertical: "auto", horizontal: "auto" }}
          >
            <div styleName="hint">
              <span styleName="hint_title">{current.title}</span>
              <br />
              Projected:{" "}
              <span styleName="hint_projected">
                {toRoundedAmount(current.projected)}
              </span>
              <br />
              Earned: <span styleName="hint_actual">{toAmount(current.y)}</span>
            </div>
          </Hint>
        ) : null}
      </FlexibleWidthXYPlot>
    </section>
  );
};

DistributionsGraph.propTypes = {
  distributions: PropTypes.arrayOf(
    PropTypes.shape({
      breakdown: PropTypes.shape({
        net: PropTypes.number.isRequired
      }).isRequired,
      description: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      projected: PropTypes.shape({
        net: PropTypes.number.isRequired
      }).isRequired
    })
  ).isRequired,
  asset: PropTypes.shape({
    closingDate: PropTypes.string.isRequired
  }).isRequired
};

export default DistributionsGraph;

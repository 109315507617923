import React, { useCallback, useReducer } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import Page from "components/Page";
import Button from "components/Button";
import Footer from "components/Footer";

import handleInputChange from "utils/handleInputChange";
import { useStateChange, makeInitialState } from "flows/interest/reducer";
import EntityPicker from "components/EntityPicker";

import "./style.css";

/* eslint-disable max-len */
export const InterestInvestor = ({ entities, onSubmit, onChange, state }) => {
  const canSubmit = EntityPicker.isValid(state);

  return (
    <Page style={{ paddingTop: "1rem" }}>
      <EntityPicker entities={entities} onChange={onChange} value={state} />
      <Footer>
        <Button
          onClick={onSubmit}
          disabled={!canSubmit}
          data-test="investor_submit"
        >
          Continue
        </Button>
      </Footer>
    </Page>
  );
};

InterestInvestor.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  entities: PropTypes.array.isRequired
};

InterestInvestor.fragments = {
  entity: gql`
    fragment InterestInvestorEntity on Entity {
      self
      ...EntityPickerEntity
    }
    ${EntityPicker.fragments.entity}
  `
};

export default InterestInvestor;

import { fork } from "redux-saga/effects";
import { combineReducers } from "redux";

import { sagas as inviteS, reducer as inviteR } from "./invite";
import { sagas as utilS } from "./utils";

export function* sagas() {
  yield fork(inviteS);
  yield fork(utilS);
}

export const reducer = combineReducers({
  invites: inviteR
});

import React from "react";
import TextField from "@material-ui/core/TextField";
import { TextField as FormikTextField } from "formik-material-ui";

export const FormikTextInput = props => {
  return (
    <FormikTextField
      margin="dense"
      variant="outlined"
      inputProps={{ "data-test": props["data-test"] }}
      fullWidth
      {...props}
    />
  );
};

const TextInput = props => {
  return (
    <TextField
      margin="dense"
      variant="outlined"
      inputProps={{ "data-test": props["data-test"] }}
      fullWidth
      {...props}
    />
  );
};

export default TextInput;

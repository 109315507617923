exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../styles/box.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../styles/background.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../styles/type.css"), "");

// Module
exports.push([module.id, ".web-flows-interest-backing-___style__mainContainer___3Tq8n {\n  padding: 2rem;\n}\n\n.web-flows-interest-backing-___style__mainContainer___3Tq8n > section {\n  margin: 2rem 0;\n  display: flex;\n  flex-direction: row;\n}\n\n.web-flows-interest-backing-___style__mainContainer___3Tq8n > section:first-child {\n  margin-top: 0rem;\n}\n\n.web-flows-interest-backing-___style__mainContainer___3Tq8n > section:last-of-type {\n  margin-bottom: 2rem;\n}\n\n.web-flows-interest-backing-___style__step___A1c5A {\n  min-width: 1.8rem;\n  height: 1.8rem;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: calc(0.7rem);\n  margin-right: 0.5rem;\n  font-size: 10pt;\n  line-height: 12pt;\n}\n\n.web-flows-interest-backing-___style__step___A1c5A > p {\n  margin: 0;\n}\n\n.web-flows-interest-backing-___style__buttonContainer___QZfF8 {\n  text-align: center;\n}\n", ""]);

// Exports
exports.locals = {
	"mainContainer": "web-flows-interest-backing-___style__mainContainer___3Tq8n " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../styles/box.css").locals["box"] + "",
	"step": "web-flows-interest-backing-___style__step___A1c5A " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../styles/background.css").locals["blue"] + " " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../styles/type.css").locals["white"] + "",
	"buttonContainer": "web-flows-interest-backing-___style__buttonContainer___QZfF8"
};
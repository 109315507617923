import gql from "graphql-tag";
import { pick } from "lodash";

export const query = gql`
  query ProfileBankAccounts {
    userinfo {
      id
      self
      entities {
        id
        self
        name
        street
        city
        state
        zip
        type
        source {
          id
          name
        }
      }
    }
  }
`;

import { defineAction } from "redux-define";
import { createAction } from "redux-actions";
import browserHistory from "utils/history";
import { takeLatest, take, fork, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { persist } from "flows/interest/reducer";
import { toAmount } from "utils";

import { sagas as interestDealSagas } from "./deal/redux";

// Actions
const namespace = "flows/interest";
const INTEREST_FLOW = defineAction(
  "INTEREST_FLOW",
  ["START", "NEXT"],
  namespace
);
export const startInterestFlow = createAction(INTEREST_FLOW.START);
export const nextInterestFlow = createAction(INTEREST_FLOW.NEXT);

// Saga
export function* interestFlowSaga({ payload: { after, dealId } }) {
  // Either coming from investment page or directly from backing page
  const result = yield take(INTEREST_FLOW.NEXT);
  const amount = result.payload.amount;

  // Share page
  yield call(browserHistory.push, `/interest/share/deals/${dealId}`);
  yield take(INTEREST_FLOW.NEXT);

  const message = `You committed to invest ${toAmount(amount)}`;
  yield call([toast, toast.success], message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 10000
  });

  // Send them back to where they came from (they tuk r jerbs)
  yield call(browserHistory.push, atob(after));
}

export function* sagas() {
  yield takeLatest(INTEREST_FLOW.START, interestFlowSaga);
  yield fork(interestDealSagas);
}

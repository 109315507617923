import { call, put, select } from "redux-saga/effects";
import Covert from "covert";
import config from "config";
import { EVENTS, STATUS, selectToken } from "./utils";

const api = config.cove.api;
const CoveApi = Covert({ api });

// API Calls
const getW9Api = (id, token) => CoveApi.allocations.w9(id, { token });
const getSignedApi = (id, token) => CoveApi.allocations.signed(id, { token });

// Get W9 URL Saga
export function* getW9URLSaga({ payload }) {
  const { id } = payload;
  const token = yield select(selectToken);
  try {
    const { url } = yield call(getW9Api, id, token);
    return url;
  } catch (error) {
    throw error;
  }
}

// Get Signed URL Saga
export function* getSignedURLSaga({ payload }) {
  const { id } = payload;
  const token = yield select(selectToken);
  try {
    const { url } = yield call(getSignedApi, id, token);
    return url;
  } catch (error) {
    throw error;
  }
}

import { defineAction } from "redux-define";
import { createAction, handleActions } from "redux-actions";
import { get } from "lodash";

import { CREATE_ANNOUNCEMENT } from "api/announcements";

import { selectAnnounce } from "../selectors";

const namespace = "flows/deal/announce";

// Actions
const ANNOUNCEMENT = defineAction("ANNOUNCEMENT", ["SHOW", "HIDE"], namespace);
export const showAnnouncement = createAction(ANNOUNCEMENT.SHOW);
export const hideAnnouncement = createAction(ANNOUNCEMENT.HIDE);

// Selectors
export const selectShowAnnouncement = store =>
  get(selectAnnounce(store), "show");
export const selectAnnouncementSending = store =>
  get(selectAnnounce(store), "sending");

const initialState = {
  show: false,
  sending: false
};

export const reducer = handleActions(
  {
    [ANNOUNCEMENT.SHOW]: state => ({
      ...state,
      show: true
    }),
    [ANNOUNCEMENT.HIDE]: state => ({
      ...state,
      show: false
    }),
    [CREATE_ANNOUNCEMENT.REQUESTED]: state => ({
      ...state,
      sending: true
    }),
    [CREATE_ANNOUNCEMENT.SUCCESS]: state => ({
      ...state,
      sending: false
    })
  },
  initialState
);

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { routeToDealManage as manage } from "flows/routing";

import Investment from "./component";

export class InvestmentContainer extends React.PureComponent {
  render() {
    return <Investment {...this.props} data-test="investment_child" />;
  }
}

InvestmentContainer.propTypes = {
  invite: PropTypes.object.isRequired,
  deal: PropTypes.object.isRequired,
  manage: PropTypes.func.isRequired
};

const exported = connect(
  null,
  { manage }
)(InvestmentContainer);
exported.fragments = Investment.fragments;

export default exported;

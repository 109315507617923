exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/type.css"), "");
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../style.css"), "");

// Module
exports.push([module.id, ".web-components-Calculator-components-AnnualReturn-___style__before___1EwMW {\n}\n\n.web-components-Calculator-components-AnnualReturn-___style__arrow___2OCXa {\n}\n\n.web-components-Calculator-components-AnnualReturn-___style__after___1yYCB {\n}\n", ""]);

// Exports
exports.locals = {
	"before": "web-components-Calculator-components-AnnualReturn-___style__before___1EwMW " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/type.css").locals["light"] + "",
	"arrow": "web-components-Calculator-components-AnnualReturn-___style__arrow___2OCXa " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../style.css").locals["amount_arrow"] + "",
	"after": "web-components-Calculator-components-AnnualReturn-___style__after___1yYCB " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/type.css").locals["blue"] + ""
};
import browserHistory from "utils/history";
import { takeLatest, take, call, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getUserInfoSaga } from "api/users";
import { LOGIN, LOGOUT } from "./reducer";
import formatISO from "date-fns/formatISO";

// LoggedIn Saga
export function* sessionSaga({ payload }) {
  // Save session
  const expiresAt = JSON.stringify(formatISO(new Date(payload.expiresAt)));

  yield call(
    [window.localStorage, window.localStorage.setItem],
    "authResult",
    JSON.stringify(payload)
  );
  yield call(
    [window.localStorage, window.localStorage.setItem],
    "expiresAt",
    expiresAt
  );

  // Check if this is a guest login (i.e. an invite flow)
  const guest = yield call(
    [window.sessionStorage, window.sessionStorage.getItem],
    "goAfterLoginAsGuest"
  );
  yield call(
    [window.sessionStorage, window.sessionStorage.removeItem],
    "goAfterLoginAsGuest"
  );

  /*
    Get logged in user from API and persist them
    If it's a guest login, the invite saga should do this instead,
    after it's accepted the invite
  */
  let resp;
  if (!guest) {
    resp = yield call(getUserInfoSaga);
  }

  if (resp && resp instanceof Error) {
    if (resp.body && resp.body.code === "request_user_not_found") {
      // Non cove user
      yield call(
        [toast, toast.error],
        `Error logging in, try a different account`,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 10000,
        }
      );
    } else {
      // Some other error
      const message = (resp.body && resp.body.message) || "Error logging in";
      yield call([toast, toast.error], message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 10000,
      });
    }

    // Cleanup session
    yield call(
      [window.localStorage, window.localStorage.removeItem],
      "authResult"
    );
    yield call(
      [window.localStorage, window.localStorage.removeItem],
      "expiresAt"
    );

    let timeout = 0;
    // If there was a error message to show, wait a few secs before redirecting
    // otherwise assume expired session and redirect immediately
    if (resp.body && resp.body.message) {
      timeout = 1000;
    }

    // Send user to login
    yield call(
      setTimeout,
      () => {
        browserHistory.push("/login");
        window.location.reload();
      },
      timeout
    );
  } else {
    // Figure out where we should go now
    const goAfterLogin = yield call(
      [window.sessionStorage, window.sessionStorage.getItem],
      "goAfterLogin"
    );

    const currentRoute = browserHistory.location.pathname;
    // If needed route somewhere else
    if (goAfterLogin) {
      // Login was triggered by trying to access something when not auth'd, take them there
      yield call(
        [window.sessionStorage, window.sessionStorage.removeItem],
        "goAfterLogin"
      );
      yield call([browserHistory, browserHistory.push], goAfterLogin);
    } else if (currentRoute === "/loggedin" || currentRoute === "/login") {
      // Just logged in but don't know where to go now, take them home
      yield call([browserHistory, browserHistory.push], "/");
    } // else accessing a page while logged in so don't route anywhere
  }

  // Wait for logout
  yield take(LOGOUT.ACTION);

  // Cleanup session
  yield call(
    [window.localStorage, window.localStorage.removeItem],
    "authResult"
  );
  yield call(
    [window.localStorage, window.localStorage.removeItem],
    "expiresAt"
  );

  // Cleanup sentry, fullstory
  if (window.Raven) {
    yield call([window.Raven, window.Raven.setUserContext]);
  }
}

export function* sagas() {
  yield takeLatest(LOGIN.ACTION, sessionSaga);
}

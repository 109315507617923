import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  routeToDealManage as manage,
  routeToDealInterestFromHome as interest,
  routeToDealInvestFromHome as invest,
  routeToDealClosing as closing
} from "flows/routing";

import Opportunity from "./component";

export class OpportunityContainer extends React.PureComponent {
  render() {
    return <Opportunity {...this.props} data-test="opportunity_child" />;
  }
}

OpportunityContainer.propTypes = {
  invite: PropTypes.object.isRequired,
  deal: PropTypes.object.isRequired,
  manage: PropTypes.func.isRequired,
  interest: PropTypes.func.isRequired,
  invest: PropTypes.func.isRequired,
  closing: PropTypes.func.isRequired
};

const exported = connect(
  null,
  { manage, interest, invest, closing }
)(OpportunityContainer);
exported.fragments = Opportunity.fragments;
export default exported;

const merge = require("lodash.merge");
const { get, post, gql } = require("./agent");

module.exports = (global_options) => {
  return {
    create: (data, local_options) => {
      const options = merge({}, global_options, local_options);
      return post("/users", data, options);
    },
    info: (client, local_options) => {
      const options = merge({}, global_options, local_options);
      const query = `
        query CovertUserinfo ($client: String!) {
          userinfo(client: $client) {
            id
            self
            firstName
            lastName
            email
            intercom_hash
            is_ops
          }
        }
      `;
      return gql(query, { client }, options);
    },
    add_tax_doc: ({ document }, local_options) => {
      const options = merge({}, global_options, local_options);
      const mutation = `
        mutation CovertAddTaxDoc ($document: TaxDocumentsCreate!) {
          create_tax_documents(document: $document) {
            document {
              id
              self
              year
              type
            }
          }
        }
      `;
      return gql(mutation, { document }, options);
    },
  };
};

import { defineAction } from "redux-define";
import { createAction } from "redux-actions";
import browserHistory from "utils/history";
import { put, takeLatest, select, take, call } from "redux-saga/effects";
import gql from "graphql-tag";

import { selectEnv } from "reducers/env";
import { getInvite, selectInvite } from "api/invite";

import { goAfterLogin } from "utils/AuthService";

const namespace = "flows/interest/invite";

// Actions
const SUBMIT_INTEREST_INVITE = defineAction(
  "SUBMIT_INTEREST_INVITE",
  namespace
);

export const submitInterestInvite = createAction(SUBMIT_INTEREST_INVITE.ACTION);

// Interest Invite Saga
export function* interestInviteSaga({ payload }) {
  const { invite, secret } = payload;

  // Take them to the login page, with a post-login redirect
  const after = btoa("/interest/confirm");
  // Carry on after login, passing guest = true
  yield call(
    goAfterLogin,
    `/interest${invite.deal.self}/invites/${secret}`,
    true
  );
  yield call(browserHistory.push, "/login");
}

export function* sagas() {
  yield takeLatest(SUBMIT_INTEREST_INVITE.ACTION, interestInviteSaga);
}

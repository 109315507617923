import React from "react";
import PropTypes from "prop-types";
import config from "config";

import { reduce, get, find } from "lodash";
import Big from "big.js";
import money from "money-math";

function shortenLargeNumber(num, digits) {
  var units = ["k", "m"],
    decimal;

  for (var i = units.length - 1; i >= 0; i--) {
    decimal = Math.pow(1000, i + 1);

    if (num <= -decimal || num >= decimal) {
      return +(num / decimal).toFixed(digits) + units[i];
    }
  }

  return num;
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function bigsum(numbers) {
  return reduce(
    numbers,
    (acc, number) => acc.plus(new Big(number)),
    new Big(0)
  );
}

export function toMoney(amount) {
  return (
    "$" +
    numberWithCommas((parseInt(amount, 10) / 100).toFixed(2)).replace(".00", "")
  );
}

export function toDollars(amount) {
  return "$" + numberWithCommas(parseInt(amount, 10) / 100);
}

export function toAmount(amount) {
  return (
    "$" +
    money.format("USD", money.floatToAmount(amount / 100)).replace(".00", "")
  );
}

export function toRoundedAmount(amount) {
  return (
    "$" +
    money
      .format("USD", money.floatToAmount(Math.round(amount / 100)))
      .replace(".00", "")
  );
}

export function shortAmount(amount) {
  return "$" + shortenLargeNumber(Math.round(amount / 100), 2);
}

const entityTypeMap = {
  Individual: "Natural Person",
  Trust: "Trust",
  LLC: "LLC",
  CCorp: "C Corp",
  SCorp: "S Corp",
  Partnership: "Partnership"
};

export function renderEntityType(type) {
  return entityTypeMap[type];
}

export const get_amount_from_alloc = ({ investment }) => {
  let amount = investment.allocated || investment.amount;
  return toAmount(amount);
};

export function renderIRR({ deal, invite, user_self }) {
  if (!user_self) {
    throw new Error("renderIRR must be passed a user_self");
  }

  const invite_user = get(invite, "syndicator.self");
  if (!invite_user) {
    throw new Error("renderIRR must be passed a invite.syndicator.self");
  }

  let targetIRR;
  if (invite_user === user_self) {
    targetIRR = deal.metrics.leadIRR;
  } else {
    targetIRR = deal.metrics.subleadIRR;
  }

  return renderPoints(targetIRR);
}

export function renderYield({ deal, invite, user_self }) {
  if (!user_self) {
    throw new Error("renderYield must be passed a user_self");
  }

  const invite_user = get(invite, "syndicator.self");
  if (!invite_user) {
    throw new Error("renderIRR must be passed a invite.syndicator.self");
  }

  let targetYield;
  if (invite_user === user_self) {
    targetYield = deal.metrics.leadYield;
  } else {
    targetYield = deal.metrics.subleadYield;
  }

  return renderPoints(targetYield);
}

export function renderExit({ deal, invite, user_self }) {
  if (!user_self) {
    throw new Error("renderYield must be passed a user_self");
  }

  const invite_user = get(invite, "syndicator.self");
  if (!invite_user) {
    throw new Error("renderExit must be passed a invite.syndicator.self");
  }

  let exit;
  if (invite_user === user_self) {
    exit = deal.metrics.leadExit;
  } else {
    exit = deal.metrics.subleadExit;
  }

  return renderMultiple(exit);
}

export const mergeProps = extra => (stateProps, dispatchProps, ownProps) =>
  Object.assign({}, ownProps, stateProps, dispatchProps, extra);

export const renderPercentage = points => parseInt(points, 10) / 100 + "%";
export const renderPointsNoPercent = percentage => percentage * 100;
export const renderPoints = (percentage, decimals = 1) =>
  (percentage * 100).toFixed(decimals) + "%";
export const renderMultiple = multiple => multiple.toFixed(2) + "x";

export const has_investors_from_invites = (invites, user_self) => {
  return !!find(invites, invite => {
    return (
      invite.syndicator.self === user_self && invite.user.self !== user_self
    );
  });
};

export { default as renderStateList } from "./renderStateList";
export { default as calculator } from "./calculator";
export { default as waitForUser } from "./waitForUser";
export { default as mutateProps } from "./mutateProps";
export {
  useInputChange,
  useMUInputChange,
  useStateChange,
  ValidationError,
  validators
} from "./handleInputChange";
export { graphql, fragments } from "./apollo";
export { isValidUSState, isValidUSZip, isValidEmail } from "./validations";

import { defineAction } from "redux-define";
import { createAction } from "redux-actions";
import browserHistory from "utils/history";
import { put, takeLatest, select, take, call } from "redux-saga/effects";

import { selectEnv } from "reducers/env";
import { getInvite, selectInvite } from "api/invite";

import { goAfterLogin } from "utils/AuthService";

const namespace = "flows/interest/invite";

// Actions
const SUBMIT_INVEST_INVITE = defineAction("SUBMIT_INVEST_INVITE", namespace);

export const submitInvestInvite = createAction(SUBMIT_INVEST_INVITE.ACTION);

// Invest Invite Saga
export function* investInviteSaga({ payload }) {
  const { invite } = payload;
  const after = btoa("/invest/confirm");
  yield call(
    goAfterLogin,
    `/invest${invite.allocation.self}/agreement/after/${after}/confirm/no`
  );
  yield call(browserHistory.push, "/login");
}

export function* sagas() {
  yield takeLatest(SUBMIT_INVEST_INVITE.ACTION, investInviteSaga);
}

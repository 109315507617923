import { fork } from "redux-saga/effects";
import { combineReducers } from "redux";

import { sagas as dealSagas, reducer as dealR } from "./deal";
import { sagas as interestSagas } from "./interest";
import { sagas as investSagas } from "./invest";
import { sagas as routingSagas } from "./routing";
import { sagas as sessionSagas } from "./session";
import { sagas as docsSagas } from "./docs";

import { reducer as interestR } from "./interest/reducer";

import { sagas as flowSagas } from "../../../web/flows";
import { sagas as componentSagas } from "../../../web/components";

export function* sagas() {
  yield fork(dealSagas);
  yield fork(interestSagas);
  yield fork(investSagas);
  yield fork(routingSagas);
  yield fork(sessionSagas);
  yield fork(docsSagas);
  yield fork(flowSagas);
  yield fork(componentSagas);
}

export const reducer = combineReducers({
  interest: interestR,
  deal: dealR
});

import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { graphql } from "utils/apollo";
import waitForUser from "utils/waitForUser";
import gql from "graphql-tag";

import Spinner from "components/Spinner";

import { selectUserSelf, selectUserId } from "flows/session/selectors";

import Investor from "./components/Investor";

import "./style.css";

export const FinalizedInvestors = ({ data: { loading, deal } }) => {
  if (loading) {
    return <Spinner data-test="finalized_investors_spinner" />;
  }
  if (
    deal.allocations &&
    deal.allocations.length &&
    deal.allocations.length > 1
  ) {
    return (
      <div>
        <header styleName="header">
          <h2>Investors</h2>
        </header>
        <section data-test="finalized_investors_list">
          {deal.allocations.map((allocation) => (
            <Investor allocation={allocation} key={allocation.id} deal={deal} />
          ))}
        </section>
      </div>
    );
  }
  return null;
};

FinalizedInvestors.propTypes = {
  deal: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    deal: PropTypes.object,
  }).isRequired,
  userId: PropTypes.string.isRequired,
};

export const mapStore = (store) => ({
  userId: selectUserId(store),
});

const query = gql`
  query DealLeadClosedInvestors($dealId: ID!, $userId: ID!) {
    deal(id: $dealId) {
      id
      self
      metrics {
        allocation
      }
      allocations(user_id: $userId) {
        id
        self
        documents {
          signed
        }
        investment {
          amount
          allocated
          funded
        }
        owner {
          firstName
          lastName
          image
          self
        }
      }
    }
  }
`;

const mapPropsToOptions = ({ deal, userId }) => ({
  variables: { dealId: deal.id, userId },
  fetchPolicy: "network-only",
});

export default compose(
  waitForUser(),
  connect(mapStore),
  graphql(query, {
    options: mapPropsToOptions,
  })
)(FinalizedInvestors);

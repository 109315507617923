import React from "react";
import PropTypes from "prop-types";

import gql from "graphql-tag";

import PictureHeader from "components/PictureHeader";
import WizardSteps from "components/WizardSteps";

export const InterestHeader = ({ deal, step }) => {
  const title = `Invest in ${deal.name}`;

  const steps = [
    {
      text: "Your details",
      test: "interest_header_step_1"
    },
    {
      text: "Accreditation",
      test: "interest_header_step_2"
    },
    {
      text: "Your investment",
      test: "interest_header_step_3"
    }
  ];

  return (
    <>
      <PictureHeader image={deal.image} title={title} />
      <WizardSteps current={step} steps={steps} />
    </>
  );
};

InterestHeader.propTypes = {
  deal: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
  }).isRequired,
  step: PropTypes.number.isRequired
};

InterestHeader.fragments = {
  deal: gql`
    fragment InterestHeaderDeal on Deal {
      name
      image
    }
  `
};

export default InterestHeader;

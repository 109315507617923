import React from "react";
import PropTypes from "prop-types";
import { renderEntityType, renderStateList } from "utils";
import gql from "graphql-tag";

const EntityList = ({ entities, onChange, value }) => {
  return (
    <form className="fs-block highlight-block" data-test="entity_list">
      <select
        onChange={onChange}
        name="investorEntity_self"
        id="investorEntity_self"
        data-test="investorEntity_self"
        value={value}
      >
        {entities.map(({ self, name, type }) => {
          const type_display = renderEntityType(type);
          return (
            <option value={self} key={self}>
              {name} ({type_display})
            </option>
          );
        })}
        <option value="new" key="new">
          New Legal Entity
        </option>
      </select>
    </form>
  );
};

EntityList.propTypes = {
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      self: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

EntityList.fragments = {
  entity: gql`
    fragment EntityListEntity on Entity {
      self
      name
      type
      accreditation {
        type
      }
    }
  `,
};

export default EntityList;

import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { compose } from "redux";
import { connect } from "react-redux";

import { useInputChange } from "utils/handleInputChange";
import { HiddenP, HiddenDiv, PoseGroup } from "utils/posed";
import { waitForUser, calculator as calc_util } from "utils";
import { selectUserSelf } from "flows/session/selectors";

import AnnualReturn from "./components/AnnualReturn";
import TotalReturn from "./components/TotalReturn";
import IRR from "./components/IRR";
import Yield from "./components/Yield";
import Breakdown from "./components/Breakdown";

import "./style.css";

export const Calculator = ({
  deal,
  invite,
  allocation,
  backing,
  asset,
  mode,
  user_self,
  onInvestmentChange
}) => {
  let initialInvestment = 30000;
  if (backing) {
    initialInvestment = parseInt(backing.amount / 100, 10);
  } else if (allocation) {
    initialInvestment = parseInt(allocation.investment.amount / 100, 10);
  }

  const investmentInput = useInputChange({
    initial: initialInvestment
  });

  const raiseInput = useInputChange({
    initial: 0
  });

  const investment = parseInt(investmentInput.value, 10) || 0;
  const raise = parseInt(raiseInput.value, 10) || 0;

  // Update raise with latest investment value when we expand
  const mode_updated = useRef(false);
  useEffect(() => {
    if (!mode_updated.current && mode === "raise") {
      const initalRaise = investmentInput.value * 2;
      raiseInput.setValue(initalRaise);
      mode_updated.current = true;
    }
  }, [mode]);

  useEffect(() => {
    if (onInvestmentChange) {
      onInvestmentChange({ investment });
    }
  }, [investment, onInvestmentChange]);

  const [showBreakdown, setShowBreakdown] = useState(false);

  const isIncentivised =
    deal.metrics.performanceAllocation > 0 || deal.metrics.carry > 0;
  const backing_applies =
    !!backing && invite.syndicator.self !== user_self && isIncentivised;

  let style = `${mode}CalcContainer`;
  if (mode === "invest" && !!backing_applies) {
    style = "backingCalcContainer";
  }

  return (
    <form
      styleName={style}
      data-test="calculator_container"
      className="native_form"
    >
      <div styleName="investmentContainer">
        <label styleName="formlabel" htmlFor="investment">
          Your Investment
        </label>
        <input
          type="number"
          className="input_dollar"
          id="investment"
          data-test="calc_investment"
          autoComplete="off"
          step="5000"
          {...investmentInput.attrs}
        />
        <PoseGroup>
          {mode === "raise" && (
            <HiddenP styleName="sub-note" key="key">
              The amount you’re investing
            </HiddenP>
          )}
        </PoseGroup>
      </div>
      <PoseGroup>
        {mode === "raise" && (
          <HiddenDiv styleName="raiseContainer" key="raiseContainer">
            <label styleName="formlabel" htmlFor="raise">
              Your Raise
            </label>
            <input
              type="number"
              className="input_dollar"
              id="raise"
              data-test="calc_raise"
              autoComplete="off"
              step="5000"
              {...raiseInput.attrs}
            />
            <p styleName="sub-note">
              The amount people you’ve invited will invest
            </p>
          </HiddenDiv>
        )}
        {mode === "invest" && (
          <HiddenDiv styleName="annualContainer" key="annualContainer">
            <label styleName="amount_label">Annual Dividend</label>
            <p styleName="annual">
              <AnnualReturn
                deal={deal}
                invite={invite}
                investment={investment}
                raise={raise}
                user_self={user_self}
                mode={mode}
                backing={backing}
                asset={asset}
                backing_applies={backing_applies}
              />
            </p>
          </HiddenDiv>
        )}
        {mode === "raise" && (
          <HiddenDiv styleName="irrContainer" key="irrContainer">
            <label styleName="amount_label">Projected IRR</label>
            <p styleName="annual">
              <IRR
                deal={deal}
                invite={invite}
                investment={investment}
                raise={raise}
                user_self={user_self}
                mode={mode}
                backing={backing}
                asset={asset}
              />
            </p>
            <p styleName="sub-note" key="key">
              Yield:{" "}
              <Yield
                deal={deal}
                invite={invite}
                investment={investment}
                raise={raise}
                user_self={user_self}
                mode={mode}
                backing={backing}
                asset={asset}
              />
            </p>
          </HiddenDiv>
        )}
      </PoseGroup>

      <div styleName="totalContainer">
        <label styleName="amount_label">Total Return</label>
        <p styleName="total">
          <TotalReturn
            deal={deal}
            invite={invite}
            investment={investment}
            raise={raise}
            user_self={user_self}
            mode={mode}
            backing={backing}
            asset={asset}
            backing_applies={backing_applies}
          />
        </p>
        {mode === "raise" && (
          <p styleName="sub-note" key="key">
            Annual:{" "}
            <AnnualReturn
              deal={deal}
              invite={invite}
              investment={investment}
              raise={raise}
              user_self={user_self}
              mode={mode}
              backing={backing}
              asset={asset}
              backing_applies={backing_applies}
            />
          </p>
        )}
        <PoseGroup>
          {mode === "raise" && !showBreakdown && (
            <HiddenDiv styleName="showBreakdown" key="showBreakdown">
              <a
                onClick={() => setShowBreakdown(true)}
                data-test="calc_breakdown"
              >
                See breakdown
              </a>
            </HiddenDiv>
          )}
          {mode === "invest" && !!backing_applies && (
            <HiddenP styleName="sub-note" key="backing">
              Increased because you pre-committed
            </HiddenP>
          )}
        </PoseGroup>
      </div>
      <PoseGroup>
        {showBreakdown && (
          <HiddenDiv styleName="breakdownContainer" key="breakdown">
            <Breakdown
              deal={deal}
              invite={invite}
              investment={investment}
              raise={raise}
              user_self={user_self}
              backing={backing}
              mode={mode}
              asset={asset}
            />
          </HiddenDiv>
        )}
      </PoseGroup>
    </form>
  );
};

Calculator.propTypes = {
  deal: PropTypes.shape().isRequired,
  invite: PropTypes.shape({
    syndicator: PropTypes.shape({
      self: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  mode: PropTypes.oneOf(["invest", "raise"]).isRequired,
  user_self: PropTypes.string.isRequired,
  allocation: PropTypes.shape({
    investment: PropTypes.shape({
      amount: PropTypes.number.isRequired
    }).isRequired
  }),
  backing: PropTypes.shape({
    amount: PropTypes.number.isRequired
  })
};

Calculator.fragments = {
  deal: gql`
    fragment CalculatorDeal on Deal {
      metrics {
        carry
        carry_hurdle
        performanceAllocation
      }
      ...CalculatorUtilDeal
    }
    ${calc_util.fragments.deal}
  `,
  invite: gql`
    fragment CalculatorInvite on Invite {
      ...CalculatorUtilInvite
    }
    ${calc_util.fragments.invite}
  `,
  backing: gql`
    fragment CalculatorBacking on Backing {
      ...CalculatorUtilBacking
    }
    ${calc_util.fragments.backing}
  `,
  asset: gql`
    fragment CalculatorAsset on Asset {
      ...CalculatorUtilAsset
    }
    ${calc_util.fragments.asset}
  `
};

export const mapStore = store => ({
  user_self: selectUserSelf(store)
});

const exported = compose(
  waitForUser(),
  connect(mapStore)
)(Calculator);

exported.fragments = Calculator.fragments;
export default exported;

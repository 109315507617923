import browserHistory from "utils/history";
import { takeLatest, call, select } from "redux-saga/effects";
import { defineAction } from "redux-define";
import { createAction } from "redux-actions";

import { id_from_self } from "utils/self";
import { selectEnv } from "reducers/env";

const namespace = "flows/routing";

// Actions
const ROUTE_DEAL_MANAGE = defineAction("ROUTE_DEAL_MANAGE", namespace);
export const routeToDealManage = createAction(ROUTE_DEAL_MANAGE.ACTION);

const ROUTE_DEAL_INTEREST = defineAction(
  "ROUTE_DEAL_INTEREST",
  ["HOME", "DEAL", "BACKING"],
  namespace
);
export const routeToDealInterestFromHome = createAction(
  ROUTE_DEAL_INTEREST.HOME
);
export const routeToDealInterestFromDeal = createAction(
  ROUTE_DEAL_INTEREST.DEAL
);
export const routeToInterestBackingFromDeal = createAction(
  ROUTE_DEAL_INTEREST.BACKING
);

const ROUTE_DEAL_INVEST = defineAction(
  "ROUTE_DEAL_INVEST",
  ["HOME", "DEAL"],
  namespace
);
export const routeToDealInvestFromHome = createAction(ROUTE_DEAL_INVEST.HOME);
export const routeToDealInvestFromDeal = createAction(ROUTE_DEAL_INVEST.DEAL);

const ROUTE_DEAL_CLOSING = defineAction("ROUTE_DEAL_CLOSING", namespace);
export const routeToDealClosing = createAction(ROUTE_DEAL_CLOSING.ACTION);

const ROUTE_INVESTMENT = defineAction("ROUTE_DEAL_CLOSING", ["ADD"], namespace);
export const routeToInvestmentAdd = createAction(ROUTE_INVESTMENT.ADD);

// Deal page
export function* routeDealManageSaga({ payload }) {
  const { deal } = payload;
  yield call(browserHistory.push, `${deal.self}`);
}

// Interest flow
export function* routeDealInterestFromHomeSaga({ payload }) {
  const { deal } = payload;
  const after = btoa("/");
  const url = `/interest/investor${deal.self}/after/${after}`;
  yield call(browserHistory.push, url);
}

export function* routeDealInterestFromDealSaga({ payload }) {
  const { deal } = payload;
  const env = yield select(selectEnv);
  const after = btoa(deal.self);
  const url = `/interest/investor${deal.self}/after/${after}`;
  yield call(browserHistory.push, url);
}

export function* routeToInterestBackingFromDealSaga({ payload }) {
  const { deal } = payload;
  const env = yield select(selectEnv);
  const after = btoa(deal.self);
  const url = `/interest/backing${deal.self}/after/${after}`;
  yield call(browserHistory.push, url);
}

// Invest flow
export function* routeDealInvestFromHomeSaga({ payload }) {
  const { allocation } = payload;
  const after = btoa("/");
  yield call(
    browserHistory.push,
    `/invest${allocation.self}/agreement/after/${after}/confirm/yes`
  );
}

export function* routeDealInvestFromDealSaga({ payload }) {
  const { deal, allocation } = payload;
  const after = btoa(deal.self);
  yield call(
    browserHistory.push,
    `/invest${allocation.self}/agreement/after/${after}/confirm/yes`
  );
}

// Wiring page
export function* routeDealClosingSaga({ payload }) {
  const { deal } = payload;
  yield call(browserHistory.push, `/invest/closing${deal.self}`);
}

// Investment add flow

export function* routeInvestmentAddData({ payload }) {
  const { investment } = payload || {};
  if (investment) {
    yield call(browserHistory.push, `/investment/add/${investment.id}`);
  } else {
    yield call(browserHistory.push, `/investment/add`);
  }
}

export function* sagas() {
  yield takeLatest(ROUTE_DEAL_MANAGE.ACTION, routeDealManageSaga);
  yield takeLatest(ROUTE_DEAL_INTEREST.HOME, routeDealInterestFromHomeSaga);
  yield takeLatest(ROUTE_DEAL_INTEREST.DEAL, routeDealInterestFromDealSaga);
  yield takeLatest(
    ROUTE_DEAL_INTEREST.BACKING,
    routeToInterestBackingFromDealSaga
  );
  yield takeLatest(ROUTE_DEAL_INVEST.HOME, routeDealInvestFromHomeSaga);
  yield takeLatest(ROUTE_DEAL_INVEST.DEAL, routeDealInvestFromDealSaga);
  yield takeLatest(ROUTE_DEAL_CLOSING.ACTION, routeDealClosingSaga);
  yield takeLatest(ROUTE_INVESTMENT.ADD, routeInvestmentAddData);
}

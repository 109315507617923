exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/type.css"), "");

// Module
exports.push([module.id, ".web-components-Calculator-components-Breakdown-___style__container___3UG06 {\n  margin: 0.5rem 0;\n}\n\n.web-components-Calculator-components-Breakdown-___style__item___OXfON {\n  margin: 0;\n}\n\n.web-components-Calculator-components-Breakdown-___style__breakdown_return___15Bv6 {\n}\n\n.web-components-Calculator-components-Breakdown-___style__breakdown_backing___38DlG {\n}\n\n.web-components-Calculator-components-Breakdown-___style__breakdown_invite___3X8Y_ {\n}\n", ""]);

// Exports
exports.locals = {
	"container": "web-components-Calculator-components-Breakdown-___style__container___3UG06",
	"item": "web-components-Calculator-components-Breakdown-___style__item___OXfON " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/type.css").locals["light"] + "",
	"breakdown_return": "web-components-Calculator-components-Breakdown-___style__breakdown_return___15Bv6 " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/type.css").locals["dark"] + "",
	"breakdown_backing": "web-components-Calculator-components-Breakdown-___style__breakdown_backing___38DlG " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/type.css").locals["blue"] + "",
	"breakdown_invite": "web-components-Calculator-components-Breakdown-___style__breakdown_invite___3X8Y_ " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/type.css").locals["green"] + ""
};
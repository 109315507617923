import { defineAction } from "redux-define";
import { createAction } from "redux-actions";
import { takeLatest, select, take, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { selectEnv } from "reducers/env";

const namespace = "flows/account/bank";

// Actions
const ONBOARDED_BANK_ACCOUNT = defineAction(
  "ONBOARDED_BANK_ACCOUNT",
  namespace
);
export const onboardedBankAccount = createAction(ONBOARDED_BANK_ACCOUNT.ACTION);

// Invest Agreement Saga
export function* onboardedBankAccountSaga({ payload }) {
  const { source } = payload;

  // Show toast
  const message = `${source.name} added`;
  yield call([toast, toast.success], message, {
    position: toast.POSITION.TOP_CENTER
  });
}

export function* sagas() {
  yield takeLatest(ONBOARDED_BANK_ACCOUNT.ACTION, onboardedBankAccountSaga);
}

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useUserSelf, useIsOps } from "flows/session/selectors";

import { routeToDealInterestFromDeal as invest } from "flows/routing";
import InvestorInvite from "./component";
import config from "config";

export const InvestorInviteContainer = ({ invest, deal, asset, invite }) => {
  const onInvest = useCallback(() => {
    invest({ deal, invite });
  }, [invest, deal, invite]);

  const user_is_ops = useIsOps();
  const user_self = useUserSelf();

  const invite_is_ops = !!invite.user.is_ops;
  const should_hide = !user_is_ops && invite_is_ops;

  if (!should_hide) {
    return (
      <InvestorInvite
        invite={invite}
        user={user_self}
        asset={asset}
        invest={onInvest}
        data-test="investor_invite_child"
      />
    );
  }

  return null;
};

InvestorInviteContainer.propTypes = {
  invite: PropTypes.shape({
    user: PropTypes.shape({
      is_ops: PropTypes.bool,
      self: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
    }).isRequired,
    syndicator: PropTypes.shape({
      self: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  deal: PropTypes.shape({
    self: PropTypes.string.isRequired
  }).isRequired,
  invest: PropTypes.func.isRequired
};

const exported = connect(
  null,
  { invest }
)(InvestorInviteContainer);
exported.fragments = InvestorInvite.fragments;
export default exported;

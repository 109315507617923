import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import { get_amount_from_alloc } from "utils";
import "./style.css";

/* eslint-disable max-len */
export class InvestorInvite extends React.PureComponent {
  renderAllocated() {
    const { invite } = this.props;
    const { user, allocation } = invite;

    const name = `${user.firstName} ${user.lastName}`;

    let text = "Investing";
    if (allocation.investment.amount) {
      const amount = get_amount_from_alloc(allocation);
      text = `Investing ${amount}`;
    }

    return (
      <div styleName="row">
        <div styleName="name item">
          <div
            styleName="pic"
            style={{ backgroundImage: `url(${user.image})` }}
          />
          <span>
            {name}
            <br />
            <span styleName="inline_status allocated">{text}</span>
          </span>
        </div>
        <div
          styleName="allocated item status"
          data-test="investor_invite_status"
        >
          {text}
        </div>
      </div>
    );
  }
  renderAccepted() {
    const { invite } = this.props;
    const { user } = invite;

    const name = `${user.firstName} ${user.lastName}`;
    return (
      <div styleName="row">
        <div styleName="name item">
          <div
            styleName="pic"
            style={{ backgroundImage: `url(${user.image})` }}
          />
          <span data-test="investor_invite_name">{name}</span>
        </div>
      </div>
    );
  }
  renderSelf() {
    const {
      invite: { user }
    } = this.props;
    return (
      <div styleName="row">
        <div styleName="name item">
          <div
            styleName="pic"
            style={{ backgroundImage: `url(${user.image})` }}
          />
          <span data-test="investor_invite_name">You</span>
        </div>
      </div>
    );
  }
  renderSelfAccepted() {
    const {
      invest,
      invite: { user }
    } = this.props;
    return (
      <div styleName="row">
        <div styleName="name item">
          <div
            styleName="pic"
            style={{ backgroundImage: `url(${user.image})` }}
          />
          <span>
            You
            <br />
            <span styleName="inline_status accepted">
              Waiting for you to
              <a
                onClick={invest}
                role="button"
                tabIndex="0"
                data-test="lead_invite_invest_inline"
              >
                invest
              </a>
            </span>
          </span>
        </div>
        <div
          styleName="item status accepted"
          data-test="investor_invite_status"
        >
          Waiting for you to
          <a
            onClick={invest}
            role="button"
            tabIndex="0"
            data-test="lead_invite_invest"
          >
            invest
          </a>
        </div>
      </div>
    );
  }
  renderSelfAllocated() {
    const {
      invest,
      invite: { user, allocation }
    } = this.props;
    const amount = get_amount_from_alloc(allocation);
    return (
      <div styleName="row">
        <div styleName="name item">
          <div
            styleName="pic"
            style={{ backgroundImage: `url(${user.image})` }}
          />
          <span>
            You
            <br />
            <span styleName="inline_status allocated">
              Investing {amount}
              <a
                onClick={invest}
                role="button"
                tabIndex="0"
                data-test="lead_invite_invest_inline"
              >
                Change
              </a>
            </span>
          </span>
        </div>
        <div
          styleName="allocated item status"
          data-test="investor_invite_status"
        >
          Investing {amount}
          <a
            onClick={invest}
            role="button"
            tabIndex="0"
            data-test="lead_invite_invest"
          >
            Change
          </a>
        </div>
      </div>
    );
  }
  render() {
    const { invite, user, asset } = this.props;
    if (invite.user && invite.user.self === user) {
      if (invite.allocation && invite.allocation.id) {
        return this.renderSelfAllocated();
      } else if (invite.user) {
        if (asset.openToCommitments) {
          return this.renderSelfAccepted();
        } else {
          return this.renderSelf();
        }
      }
    } else if (invite.allocation && invite.allocation.id) {
      return this.renderAllocated();
    } else if (invite.user) {
      return this.renderAccepted();
    }
    return null;
  }
}
/* eslint-enable max-len */

InvestorInvite.propTypes = {
  invite: PropTypes.shape({
    user: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired
    }),
    allocation: PropTypes.shape({
      investment: PropTypes.shape({
        amount: PropTypes.number
      }).isRequired
    })
  }).isRequired,
  asset: PropTypes.shape({
    openToCommitments: PropTypes.bool.isRequired
  }).isRequired,
  user: PropTypes.string.isRequired,
  invest: PropTypes.func.isRequired
};

InvestorInvite.fragments = {
  invite: gql`
    fragment InvestorInviteInvite on Invite {
      user {
        id
        self
        is_ops
        firstName
        lastName
        image
      }
      allocation {
        id
        self
        investment {
          amount
          allocated
        }
      }
    }
  `,
  asset: gql`
    fragment InvestorInviteAsset on Asset {
      openToCommitments
    }
  `
};

export default InvestorInvite;

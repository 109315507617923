import brnv from "bank-routing-number-validator";

export const errorColor = "#ff444c";
export const isValidUSZip = zip => /^\d{5}(-\d{4})?$/.test(zip);
export const isValidUSState = state =>
  /^(?:A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])*$/.test(
    state
  );
export const isValidRoutingNumber = number =>
  brnv.ABARoutingNumberIsValid(number);
export const isValidAccountNumber = number =>
  number.length >= 4 && number.length <= 17;
export const isValidEmail = email => /\S+@\S+\.\S+/.test(email);

export function validate(name, validator) {
  const value = this.state[name];
  if (value && !validator(value)) {
    return {
      borderColor: errorColor
    };
  }
  return {};
}

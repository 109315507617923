import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { dealAllocateChange as change } from "flows/deal/allocate";
import InvestorAllocate from "./component";

export const InvestorAllocateContainer = ({ user, allocation, change }) => (
  <InvestorAllocate allocation={allocation} user={user} change={change} />
);

InvestorAllocateContainer.propTypes = {
  allocation: PropTypes.object.isRequired,
  user: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired
};

export default connect(
  null,
  { change }
)(InvestorAllocateContainer);

import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { graphql } from "utils/apollo";
import gql from "graphql-tag";

import Spinner from "components/Spinner";
import Page from "components/Page";
import Profile from "./components/Profile";
import Entities from "./components/Entities";
import TaxDocs from "./components/TaxDocs";

import "./style.css";

export const AccountContainer = ({ data: { loading, userinfo } }) => {
  if (loading) {
    return <Spinner data-test="account_spinner" />;
  }
  return (
    <section className="fullPageContainer has_top_page_border">
      <Profile userinfo={userinfo} data-test="account_profile" />
      <TaxDocs data-test="account_tax" />
      <h2>Investment Entities</h2>
      <p className="section_descriptor">
        You can invest as yourself or with an investment vehicle
      </p>
      <Entities data-test="account_entities" />
    </section>
  );
};

AccountContainer.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    userinfo: PropTypes.object
  }).isRequired
};

const query = gql`
  query ProfilePageUserInfo {
    userinfo {
      ...ProfileUserInfo
    }
  }
  ${Profile.fragments.userinfo}
`;

export default compose(graphql(query))(AccountContainer);

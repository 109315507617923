import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { graphql } from "utils/apollo";
import { useQuery } from "@apollo/react-hooks";

import waitForUser from "utils/waitForUser";
import { useDispatch, useSelector } from "react-redux";

import Spinner from "components/Spinner";

import { selectUserSelf, selectUserId } from "flows/session/selectors";

import { query, dealAllocateLoad } from "flows/deal/allocate";
import Investor from "./components/Investor";
import Save from "./components/Save";

import "./style.css";

export const AllocateInvestors = ({ deal: prop_deal }) => {
  const dispatch = useDispatch();
  const user_self = useSelector(selectUserSelf);
  const userId = useSelector(selectUserId);

  const { loading, data } = useQuery(query, {
    variables: { dealId: prop_deal.id, userId }
  });

  const { deal } = data || {};

  useEffect(() => {
    if (deal) {
      dispatch(dealAllocateLoad({ allocations: deal.allocations }));
    }
  }, [deal]);

  if (loading) {
    return <Spinner data-test="allocate_investors_spinner" />;
  }

  return (
    <div>
      <header styleName="header">
        <h2 styleName="h2">Investors</h2>
      </header>
      <section styleName="table" data-test="allocate_investors_list">
        {deal.allocations &&
          deal.allocations.map(allocation => (
            <Investor
              allocation={allocation}
              key={allocation.id}
              user={user_self}
            />
          ))}
        <Save deal={deal} />
      </section>
    </div>
  );
};

AllocateInvestors.propTypes = {
  deal: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
};

const mapPropsToOptions = ({ deal, userId }) => ({
  variables: { dealId: deal.id, userId }
});

export default compose(waitForUser())(AllocateInvestors);

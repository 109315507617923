import { useSelector } from "react-redux";

// Selectors
export const selectUserSelf = (store) => store.session.user.self;
export const selectUserId = (store) => store.session.user.id;
export const selectAuth0 = (store) => store.session.auth0;
export const selectUserLoaded = (store) => store.session.user.loaded;
export const selectIsOps = (store) => store.session.user.is_ops;

export const useUserId = () => useSelector(selectUserId);
export const useUserSelf = () => useSelector(selectUserSelf);
export const useIsOps = () => useSelector(selectIsOps);

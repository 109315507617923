exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css"), "");

// Module
exports.push([module.id, ".web-flows-invest-agreement-components-LinkAccount-___style__link___ZfIi_ {\n  margin: 0;\n  font-weight: 400;\n}\n\n.web-flows-invest-agreement-components-LinkAccount-___style__sure___3_8fc {\n  margin-top: 0.5rem;\n}\n\n.web-flows-invest-agreement-components-LinkAccount-___style__button_container___QTktT {\n  margin: 2rem 0 0;\n  width: 100%;\n  text-align: center;\n}\n\n.web-flows-invest-agreement-components-LinkAccount-___style__skip___2UWZF {\n  display: block;\n  width: 100%;\n  text-align: center;\n  font-size: 14pt;\n  margin: 1rem 0;\n}\n", ""]);

// Exports
exports.locals = {
	"link": "web-flows-invest-agreement-components-LinkAccount-___style__link___ZfIi_ " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css").locals["blue"] + "",
	"sure": "web-flows-invest-agreement-components-LinkAccount-___style__sure___3_8fc",
	"button_container": "web-flows-invest-agreement-components-LinkAccount-___style__button_container___QTktT",
	"skip": "web-flows-invest-agreement-components-LinkAccount-___style__skip___2UWZF"
};
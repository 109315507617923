import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { selectUserSelf } from "flows/session/selectors";

import { routeToDealInvestFromDeal as invest } from "flows/routing";
import InvestorFinalized from "./component";

export class InvestorFinalizedContainer extends React.PureComponent {
  constructor() {
    super();
    this.invest = this.invest.bind(this);
  }
  invest() {
    const {
      deal,
      allocation: { self }
    } = this.props;
    this.props.invest({
      deal,
      allocation: {
        self
      }
    });
  }
  render() {
    const { user, allocation } = this.props;
    return (
      <InvestorFinalized
        allocation={allocation}
        user={user}
        invest={this.invest}
      />
    );
  }
}

InvestorFinalizedContainer.propTypes = {
  allocation: PropTypes.shape({
    self: PropTypes.string.isRequired
  }).isRequired,
  deal: PropTypes.shape({
    self: PropTypes.string.isRequired
  }).isRequired,
  user: PropTypes.string.isRequired,
  invest: PropTypes.func.isRequired
};

export const mapStore = store => ({
  user: selectUserSelf(store)
});

export default connect(
  mapStore,
  { invest }
)(InvestorFinalizedContainer);

const co = require("co");

const { gql } = require("./agent");

const users = require("./users");
const syndicates = require("./syndicates");
const deals = require("./deals");
const invites = require("./invites");
const allocations = require("./allocations");
const announcements = require("./announcements");
const assets = require("./assets");

module.exports = (options) => ({
  users: users(options),
  syndicates: syndicates(options),
  deals: deals(options),
  invites: invites(options),
  allocations: allocations(options),
  announcements: announcements(options),
  assets: assets(options),
  gql: (query, variables) => gql(query, variables, options),
});

exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".web-flows-interest-investor-components-Investor-___style__taxId___2_wO4 > input {\n  display: inline;\n  -moz-appearance: textfield;\n}\n\n.web-flows-interest-investor-components-Investor-___style__taxId___2_wO4 > input::-webkit-outer-spin-button,\n.web-flows-interest-investor-components-Investor-___style__taxId___2_wO4 > input::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n}\n\n.web-flows-interest-investor-components-Investor-___style__address___1Oyf0 {\n  margin-top: 1rem;\n}\n\n.web-flows-interest-investor-components-Investor-___style__address___1Oyf0 > input {\n  display: block;\n  margin: 0.5em 0;\n  width: 50%;\n}\n\n.web-flows-interest-investor-components-Investor-___style__address___1Oyf0 > input:first-child {\n  margin-top: 0;\n}\n\n@media (min-width: 600px) and (max-width: 959px) {\n  .web-flows-interest-investor-components-Investor-___style__address___1Oyf0 > input {\n    width: 60%;\n  }\n}\n\n@media (max-width: 599px) {\n  .web-flows-interest-investor-components-Investor-___style__address___1Oyf0 > input {\n    width: 100%;\n  }\n}\n\n.web-flows-interest-investor-components-Investor-___style__taxId___2_wO4 {\n  margin-top: 1rem;\n}\n\n.web-flows-interest-investor-components-Investor-___style__taxId___2_wO4 > span {\n  padding: 0 0.5em;\n}\n\n.web-flows-interest-investor-components-Investor-___style__formlabel___2CLBW {\n  display: block;\n  padding: 0.4em 0;\n}\n\n.web-flows-interest-investor-components-Investor-___style__note___356sE {\n  font-size: 0.8em;\n  color: #95989a;\n  padding-top: 1rem;\n}\n", ""]);

// Exports
exports.locals = {
	"taxId": "web-flows-interest-investor-components-Investor-___style__taxId___2_wO4",
	"address": "web-flows-interest-investor-components-Investor-___style__address___1Oyf0",
	"formlabel": "web-flows-interest-investor-components-Investor-___style__formlabel___2CLBW",
	"note": "web-flows-interest-investor-components-Investor-___style__note___356sE"
};
import { useState, useCallback, useRef, useReducer } from "react";
import { identity } from "lodash";
import { defineAction } from "redux-define";
import { createAction, handleActions } from "redux-actions";

import { errorColor, isValidEmail } from "./validations";

export default function handleInputChange(e) {
  const target = e.target;
  const value = target.type === "checkbox" ? target.checked : target.value;
  const name = target.name;

  this.setState({
    [name]: value
  });
}

export function useInputChange(
  { initial, validator, onChange, marshall, unmarshall },
  deps = []
) {
  marshall = marshall || identity;
  unmarshall = unmarshall || identity;

  const [value, setValue] = useState(initial);

  const touched = useRef(false);

  // Handle input change
  const _onChange = useCallback(function(e) {
    const target = e.target;
    const newValue = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setValue(unmarshall(newValue));
    if (onChange) {
      onChange({
        value: unmarshall(newValue),
        name
      });
    }
    touched.current = true;
  }, deps);

  const result = {
    value,
    setValue,
    attrs: {
      value: marshall(value),
      onChange: _onChange
    },
    radioAttrs: {
      onChange: _onChange
    },
    checkedAttrs: {
      checked: !!marshall(value),
      onChange: _onChange
    }
  };

  // Handle validation
  if (validator && touched.current) {
    const invalid = !validator(value);
    result.invalid = invalid;
    if (invalid) {
      result.attrs.style = {
        borderColor: errorColor
      };
    }
  }

  return result;
}

export function useMUInputChange(
  { initial, validator, onChange, marshall, unmarshall },
  deps = []
) {
  marshall = marshall || identity;
  unmarshall = unmarshall || identity;

  if (!deps) {
    throw new Error("You must pass in deps to useMUInputChange");
  }

  const [value, setValue] = useState(initial);

  const touched = useRef(false);

  // Handle input change
  const _onChange = useCallback(function(e) {
    const target = e.target;
    const newValue = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setValue(unmarshall(newValue));
    if (onChange) {
      onChange({
        value: unmarshall(newValue),
        name
      });
    }
    touched.current = true;
  }, deps);

  const result = {
    value,
    setValue,
    attrs: {
      value: marshall(value),
      onChange: _onChange
    }
  };

  return result;
}

const PERSIST = defineAction("PERSIST", "useStateChange");
export const persist = createAction(PERSIST.ACTION);

export const reducer = handleActions(
  {
    [PERSIST.ACTION]: (state, action) => ({
      ...state,
      ...action.payload
    })
  },
  {}
);

export const useStateChange = (initial = {}) => {
  const [state, dispatch] = useReducer(reducer, initial);

  /*
    set as onChange handler for useInputChange or useMUInputChange
  */
  const handleChange = useCallback(
    ({ name, value }) => {
      dispatch(
        persist({
          [name]: value
        })
      );
    },
    [dispatch, persist]
  );

  const setState = useCallback(
    newState => {
      dispatch(persist(newState));
    },
    [dispatch, persist]
  );

  return [state, handleChange, setState];
};

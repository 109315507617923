import { defineAction } from "redux-define";
import { createAction } from "redux-actions";
import { call, put, select } from "redux-saga/effects";
import Covert from "covert";
import config from "config";
import { selectUserSelf } from "flows/session/selectors";
import { EVENTS, selectToken } from "./utils";

const api = config.cove.api;
const CoveApi = Covert({ api });

const namespace = "api/announcements";

// Actions
export const CREATE_ANNOUNCEMENT = defineAction(
  "CREATE_ANNOUNCEMENT",
  EVENTS,
  namespace
);
export const createAnnouncement = createAction(CREATE_ANNOUNCEMENT.ACTION);
export const createAnnouncementRequested = createAction(
  CREATE_ANNOUNCEMENT.REQUESTED
);
export const createAnnouncementSuccess = createAction(
  CREATE_ANNOUNCEMENT.SUCCESS
);
export const createAnnouncementError = createAction(CREATE_ANNOUNCEMENT.ERROR);

// API Calls
export const createAnnouncementApi = (data, token) =>
  CoveApi.announcements.create(data, { token });

// Create Announcement Saga
export function* createAnnouncementSaga({ payload }) {
  const { deal, message, subject } = payload;
  const userSelf = yield select(selectUserSelf);
  const token = yield select(selectToken);

  // Spinner on
  yield put(createAnnouncementRequested());

  try {
    const data = yield call(
      createAnnouncementApi,
      {
        subject,
        message,
        deal: {
          self: deal.self
        },
        syndicator: {
          self: userSelf
        },
        type: "deal_announcement"
      },
      token
    );

    // Spinner off
    yield put(createAnnouncementSuccess());

    return data;
  } catch (e) {
    yield put(createAnnouncementError());
    throw e;
  }
}

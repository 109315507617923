import gql from "graphql-tag";

export const query = gql`
  query AllocationDistributions($dealId: ID!, $userId: ID!, $year: Int!) {
    deal(id: $dealId) {
      id
      self
      allocation(user_id: $userId) {
        id
        self
        createdAt
        distributions(year: $year) {
          distributions {
            id
            self
            status
            description
            distributedDate
            distributedYear
            amount
            projected {
              net
            }
            yield {
              net
              gross
            }
            breakdown {
              initial
              gross
              perf_alloc_paid
              perf_alloc_earned
              perf_alloc_refunded
              platform_paid
              hypothetical_platform_paid_upfront
              net
              paid
              withholding_paid
              withholdings {
                amount
                description
                type
              }
            }
          }
        }
      }
    }
  }
`;

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import Page from "components/Page";
import WillReceive from "components/WillReceive";
import ShareDeal from "components/ShareDeal";
import { get_amount_from_alloc } from "utils";
import InterestCalculator from "./components/InterestCalculator";

import "./style.css";

export const InterestShare = ({
  deal,
  invite,
  allocation,
  asset,
  backing,
  onSubmit,
  onSkip,
}) => {
  const [showNext, setShowNext] = useState(false);

  const on_link = useCallback(() => {
    setShowNext(true);
  }, [setShowNext]);

  return (
    <section className="pageContainer" style={{ marginTop: "1rem" }}>
      <header styleName="confirmationContainer">
        <div styleName="checkmark">
          <img
            src="/images/overlay_check.svg"
            alt=""
            style={{ backgroundImage: `url(${deal.image})` }}
          />
        </div>
        <h2 styleName="confirmed" data-test="share_confirmation">
          You're investing {get_amount_from_alloc(allocation)} in {deal.name}
        </h2>
      </header>
      <Page>
        <section styleName="share_container">
          <div styleName="increase">Invite a friend to this investment</div>
          <p styleName="got_only">Sharing is caring</p>
          <ShareDeal
            deal={deal}
            allocation={allocation}
            invite={invite}
            asset={asset}
            onLink={on_link}
          />
        </section>
      </Page>
      <section styleName="skip_container">
        <a onClick={onSkip} data-test="interest_share_skip">
          Skip inviting for now
        </a>
      </section>
    </section>
  );
};

InterestShare.propTypes = {
  deal: PropTypes.shape({
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  invite: PropTypes.object.isRequired,
  allocation: PropTypes.shape({
    investment: PropTypes.shape({
      amount: PropTypes.number.isRequired,
    }).isRequired,
  }),
  asset: PropTypes.shape({
    location: PropTypes.string,
  }).isRequired,
  backing: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
};

InterestShare.fragments = {
  deal: gql`
    fragment InterestShareDeal on Deal {
      name
      image
      ...InterestCalculatorDeal
      ...WillReceiveDeal
      ...ShareDealDeal
    }
    ${InterestCalculator.fragments.deal}
    ${WillReceive.fragments.deal}
    ${ShareDeal.fragments.deal}
  `,
  invite: gql`
    fragment InterestShareInvite on Invite {
      ...InterestCalculatorInvite
      ...ShareDealInvite
    }
    ${InterestCalculator.fragments.invite}
    ${ShareDeal.fragments.invite}
  `,
  allocation: gql`
    fragment InterestShareAllocation on Allocation {
      investment {
        amount
      }
      ...InterestCalculatorAllocation
      ...ShareDealAllocation
    }
    ${InterestCalculator.fragments.allocation}
    ${ShareDeal.fragments.allocation}
  `,
  asset: gql`
    fragment InterestShareAsset on Asset {
      ...ShareDealAsset
      ...InterestCalculatorAsset
    }
    ${ShareDeal.fragments.asset}
    ${InterestCalculator.fragments.asset}
  `,
  backing: gql`
    fragment InterestShareBacking on Backing {
      ...InterestCalculatorBacking
    }
    ${InterestCalculator.fragments.backing}
  `,
};

export default InterestShare;

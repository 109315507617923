import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { compose } from "redux";
import { connect } from "react-redux";
import { get_amount_from_alloc } from "utils";

import "../../style.css";

const ClosingDealHeaderMetrics = ({ allocation, asset, now }) => {
  let invested, liquidation;
  let bar_width = "0%";

  if (allocation) {
    invested = (
      <div>
        <dt styleName="days" data-test="metrics_invested">
          {get_amount_from_alloc(allocation)}
        </dt>
        <dd styleName="label">invested</dd>
      </div>
    );
  }

  if (asset.term) {
    const liquidationDate = moment(asset.closingDate).add(asset.term, "years");

    const left = moment.duration(liquidationDate.diff(now()));
    const left_days = left.clone().asDays();
    const total_days = asset.term * 365;
    const done = (total_days - left_days) / total_days;
    bar_width = done * 100 + "%";
    liquidation = (
      <div styleName="extra">
        <dt styleName="number" data-test="metrics_liquidation">
          {left.humanize()}
        </dt>
        <dd styleName="label">until liquidation</dd>
      </div>
    );
  }

  return (
    <section>
      <dl styleName="header_metrics_list">
        {invested}
        {liquidation}
      </dl>
      <div styleName="progress_bg">
        <div styleName="progress_bar" style={{ width: bar_width }} />
      </div>
    </section>
  );
};

ClosingDealHeaderMetrics.propTypes = {
  asset: PropTypes.shape({
    term: PropTypes.number,
    closingDate: PropTypes.string.isRequired
  }).isRequired,
  allocation: PropTypes.shape({
    investment: PropTypes.shape({
      allocated: PropTypes.number.isRequired
    }).isRequired
  })
};
export default ClosingDealHeaderMetrics;

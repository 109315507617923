import React, { useState } from "react";
import PropTypes from "prop-types";

import money from "money-math";
import moment from "moment";

import { toAmount, toRoundedAmount, renderPoints } from "utils";
import { SliddenDiv, PoseGroup } from "utils/posed";

import "./style.css";

/* eslint-disable max-len */
export const Distribution = ({
  distribution: {
    amount,
    distributedDate,
    description,
    breakdown,
    projected,
    yield: annual_yield,
    status
  }
}) => {
  const distro = toAmount(amount);
  const date = moment(distributedDate).format("Do MMMM YYYY");

  const [showBreakdown, setShowBreakdown] = useState(false);

  const amount_class = showBreakdown ? "amount_with_breakdown" : "amount";

  let statusText = `Paid on ${date}`;
  if (status === "pending") {
    statusText = "Pending";
  }
  return (
    <div styleName="row" data-test="distribution_item">
      <header styleName="header">
        <div styleName="name item">
          <div styleName="icon">
            <span styleName="icon_text">$</span>
          </div>
          <span>
            <span styleName="description">{description}</span>
            <br />
            <span styleName="date" data-test="distribution_date">
              {statusText}
            </span>
          </span>
        </div>
        <div styleName={amount_class} data-test="distribution_amount">
          {distro}
          <br />
          {breakdown && !showBreakdown && (
            <a
              onClick={() => setShowBreakdown(true)}
              styleName="breakdown_link"
              data-test="distribution_breakdown_link"
            >
              See breakdown
            </a>
          )}
        </div>
      </header>
      <PoseGroup>
        {showBreakdown && (
          <SliddenDiv styleName="breakdown_container" key="breakdown">
            <h4 styleName="breakdown_header">Breakdown</h4>
            <table>
              <tbody>
                {!!breakdown.initial && (
                  <tr>
                    <td>
                      <p styleName="breakdown_item">Asset earnings: </p>
                    </td>
                    <td styleName="amount_column">
                      <p styleName="breakdown_item">
                        {" "}
                        <span styleName="breakdown_amount">
                          {toAmount(breakdown.initial)}
                        </span>
                      </p>
                    </td>
                  </tr>
                )}
                {!breakdown.initial && !!breakdown.gross && (
                  <tr>
                    <td>
                      <p styleName="breakdown_item">Asset earnings: </p>
                    </td>
                    <td styleName="amount_column">
                      <p styleName="breakdown_item">
                        {" "}
                        <span styleName="breakdown_amount">
                          {toAmount(breakdown.gross)}
                        </span>
                      </p>
                    </td>
                  </tr>
                )}
                {!!breakdown.perf_alloc_earned && (
                  <tr>
                    <td>
                      <p styleName="breakdown_item">Syndicate earnings: </p>
                    </td>
                    <td styleName="amount_column">
                      <p styleName="breakdown_item">
                        <span styleName="breakdown_amount">
                          {toAmount(breakdown.perf_alloc_earned)}
                        </span>
                      </p>
                    </td>
                  </tr>
                )}
                {!!breakdown.perf_alloc_paid && (
                  <tr>
                    <td>
                      <p styleName="breakdown_item">Syndicate fee: </p>
                    </td>
                    <td styleName="amount_column">
                      <p styleName="breakdown_item">
                        <span styleName="breakdown_amount">
                          -{toAmount(breakdown.perf_alloc_paid)}
                        </span>
                      </p>
                    </td>
                  </tr>
                )}
                {!!breakdown.perf_alloc_refunded && (
                  <tr>
                    <td>
                      <p styleName="breakdown_item">Pre-committment refund: </p>
                    </td>
                    <td styleName="amount_column">
                      <p styleName="breakdown_item">
                        <span styleName="breakdown_amount">
                          {toAmount(breakdown.perf_alloc_refunded)}
                        </span>
                      </p>
                    </td>
                  </tr>
                )}
                {!!breakdown.platform_paid && (
                  <tr>
                    <td>
                      <p styleName="breakdown_item">Platform fee: </p>
                    </td>
                    <td styleName="amount_column">
                      <p styleName="breakdown_item">
                        <span styleName="breakdown_amount">
                          -{toAmount(breakdown.platform_paid)}
                        </span>
                      </p>
                    </td>
                  </tr>
                )}
                {!!breakdown.net && (
                  <tr>
                    <td>
                      <p styleName="breakdown_item">Net earnings: </p>
                    </td>
                    <td styleName="amount_column">
                      <p styleName="breakdown_item">
                        <span styleName="breakdown_net">
                          {toAmount(breakdown.net)}
                        </span>
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {!!(breakdown.withholdings && breakdown.withholdings.length) && (
              <>
                <h4 styleName="breakdown_header">Withholding</h4>
                <table>
                  <tbody>
                    {breakdown.withholdings.map(withholding => {
                      return (
                        <tr key={`${withholding.type} ${withholding.amount}`}>
                          <td>
                            <p styleName="breakdown_item">
                              {withholding.description}:
                            </p>
                          </td>
                          <td styleName="amount_column">
                            <p styleName="breakdown_item">
                              <span styleName="breakdown_amount">
                                -{toAmount(withholding.amount)}
                              </span>
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                    {!!(breakdown.paid && breakdown.paid !== breakdown.net) && (
                      <tr>
                        <td>
                          <p styleName="breakdown_item">Distributed: </p>
                        </td>
                        <td styleName="amount_column">
                          <p styleName="breakdown_item">
                            <span styleName="breakdown_net">
                              {toAmount(breakdown.paid)}
                            </span>
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </>
            )}
            <h4 styleName="breakdown_header">Metrics</h4>
            <table>
              <tbody>
                {!!(projected && projected.net) && (
                  <tr>
                    <td>
                      <p styleName="breakdown_item">Projected earnings: </p>
                    </td>
                    <td styleName="amount_column">
                      <p styleName="breakdown_item">
                        <span styleName="breakdown_plus">
                          {toRoundedAmount(projected.net)}
                        </span>
                      </p>
                    </td>
                  </tr>
                )}
                {!!(annual_yield && annual_yield.gross) && (
                  <tr>
                    <td>
                      <p styleName="breakdown_item">Asset Yield: </p>
                    </td>
                    <td styleName="amount_column">
                      <p styleName="breakdown_item">
                        <span styleName="breakdown_amount">
                          {renderPoints(annual_yield.gross)}
                        </span>
                      </p>
                    </td>
                  </tr>
                )}
                {!!(annual_yield && annual_yield.net) && (
                  <tr>
                    <td>
                      <p styleName="breakdown_item">Net Yield: </p>
                    </td>
                    <td styleName="amount_column">
                      <p styleName="breakdown_item">
                        <span styleName="breakdown_amount">
                          {renderPoints(annual_yield.net)}
                        </span>
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <a
              onClick={() => setShowBreakdown(false)}
              styleName="breakdown_link"
            >
              Hide breakdown
            </a>
          </SliddenDiv>
        )}
      </PoseGroup>
    </div>
  );
};
/* eslint-enable max-len */

Distribution.propTypes = {
  distribution: PropTypes.shape({
    self: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    distributedDate: PropTypes.string.isRequired,
    breakdown: PropTypes.shape({
      gross: PropTypes.number,
      perf_alloc_paid: PropTypes.number,
      perf_alloc_refunded: PropTypes.number,
      perf_alloc_earned: PropTypes.number,
      platform_paid: PropTypes.number,
      net: PropTypes.number
    })
  }).isRequired
};

export default Distribution;

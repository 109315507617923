import React, { useCallback, useReducer } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { fragments } from "utils";

import AgreementContainer from "./components/Agreement";
import LinkAccountContainer from "./components/LinkAccount";
import WiringInstructions from "components/WiringInstructions";

export const InvestFlow = ({
  onNext,
  step,
  skip_linking,
  deal,
  allocation,
  entity,
  asset,
}) => {
  let show;

  // Agree -> (Link ->) (Wiring ->) Finish
  if (step === 1) {
    show = "agree";
  } else if (step === 2 && skip_linking) {
    show = "wiring";
  } else if (step === 2 && !skip_linking) {
    show = "link";
  } else if (step === 3) {
    show = "wiring";
  }

  return (
    <>
      {show === "agree" && (
        <AgreementContainer
          onSubmit={onNext}
          deal={deal}
          allocation={allocation}
          data-test="invest_agreement"
        />
      )}
      {show === "link" && (
        <LinkAccountContainer
          onSubmit={onNext}
          {...fragments(LinkAccountContainer, { entity, deal })}
          data-test="invest_link"
        />
      )}
      {show === "wiring" && (
        <WiringInstructions
          onFinish={onNext}
          {...fragments(WiringInstructions, { deal, allocation, asset })}
          data-test="invest_wiring"
        />
      )}
    </>
  );
};

InvestFlow.propTypes = {
  onNext: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  skip_linking: PropTypes.bool.isRequired,
  deal: PropTypes.object.isRequired,
  allocation: PropTypes.object.isRequired,
  entity: PropTypes.object.isRequired,
  asset: PropTypes.object.isRequired,
};

InvestFlow.fragments = {
  deal: gql`
    fragment InvestFlowDeal on Deal {
      ...AgreementContainerDeal
      ...WiringInstructionsDeal
      ...LinkAccountContainerDeal
    }
    ${LinkAccountContainer.fragments.deal}
    ${AgreementContainer.fragments.deal}
    ${WiringInstructions.fragments.deal}
  `,
  allocation: gql`
    fragment InvestFlowAllocation on Allocation {
      ...AgreementContainerAllocation
      ...WiringInstructionsAllocation
    }
    ${AgreementContainer.fragments.allocation}
    ${WiringInstructions.fragments.allocation}
  `,
  entity: gql`
    fragment InvestFlowEntity on Entity {
      source {
        id
      }
      ...LinkAccountContainerEntity
    }
    ${LinkAccountContainer.fragments.entity}
  `,
  asset: gql`
    fragment InvestFlowAsset on Asset {
      ...WiringInstructionsAsset
    }
    ${WiringInstructions.fragments.asset}
  `,
};

export default InvestFlow;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";

import { graphql } from "utils/apollo";
import { handleError as error } from "api/utils";
import gql from "graphql-tag";
import { mutateProps } from "utils";

import Spinner from "components/Spinner";

const RetrieveTaxDoc = ({ get_url }) => {
  useEffect(() => {
    get_url()
      .then(result => {
        window.location = result.data.get_tax_documents_url.url;
      })
      .catch(error);
  }, []);

  return <Spinner />;
};

export const mutation = gql`
  mutation Mutation($id: ID!) {
    get_tax_documents_url(id: $id) {
      url
    }
  }
`;

RetrieveTaxDoc.propTypes = {
  get_url: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      docId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export const url_props = ({ mutate, ownProps }) => () =>
  mutate({
    variables: {
      id: ownProps.match.params.docId
    }
  });

const mapMutation = props => ({
  get_url: url_props(props)
});

export default compose(
  graphql(mutation, {
    props: mapMutation
  }),
  mutateProps({ name: "get_url" })
)(RetrieveTaxDoc);

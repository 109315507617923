exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".web-flows-invest-confirmed-___style__agreementContainer___2uOU6 {\n  text-align: center;\n}\n\n.web-flows-invest-confirmed-___style__agreementContainer___2uOU6 > img {\n  border-radius: 50%;\n  width: 50px;\n  height: 50px;\n}\n\n.web-flows-invest-confirmed-___style__agreementContainer___2uOU6 > h2 {\n  font-weight: normal;\n  border-bottom: none;\n  padding-bottom: 1em;\n  padding-top: 1em;\n}\n\n.web-flows-invest-confirmed-___style__messages___2-sHd {\n  border-bottom: 1px solid #e5e3e3;\n  border-top: 1px solid #e5e3e3;\n  padding: 1em 0;\n}\n\n.web-flows-invest-confirmed-___style__transfer___IHggj {\n  font-size: 1.1em;\n  color: #585858;\n  margin-bottom: 0.3em;\n  margin-top: 0;\n  margin-bottom: 0;\n}\n\n.web-flows-invest-confirmed-___style__logout___1_DvC {\n  text-align: center;\n  margin-bottom: 0;\n  color: #95989a;\n}\n", ""]);

// Exports
exports.locals = {
	"agreementContainer": "web-flows-invest-confirmed-___style__agreementContainer___2uOU6",
	"messages": "web-flows-invest-confirmed-___style__messages___2-sHd",
	"transfer": "web-flows-invest-confirmed-___style__transfer___IHggj",
	"logout": "web-flows-invest-confirmed-___style__logout___1_DvC"
};
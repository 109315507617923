import React from "react";
import PropTypes from "prop-types";

import gql from "graphql-tag";

import PictureHeader from "components/PictureHeader";
import WizardSteps from "components/WizardSteps";

export const InvestHeader = ({ deal, step, skip_linking }) => {
  const title = `Invest in ${deal.name}`;

  const steps = [
    {
      text: "Sign contracts",
      test: "invest_header_step_1",
    },
  ];

  if (!skip_linking) {
    steps.push({
      text: "Link bank account",
      test: "invest_header_step_2",
    });
  }

  if (!deal.skipWiring) {
    steps.push({
      text: "Wire funds",
      test: "invest_header_step_3",
    });
  }

  return (
    <>
      <PictureHeader image={deal.image} title={title} />
      <WizardSteps current={step} steps={steps} />
    </>
  );
};

InvestHeader.propTypes = {
  deal: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    skipWiring: PropTypes.bool,
  }).isRequired,
  step: PropTypes.number.isRequired,
  skip_linking: PropTypes.bool.isRequired,
};

InvestHeader.fragments = {
  deal: gql`
    fragment InvestHeaderDeal on Deal {
      name
      image
      skipWiring
    }
  `,
};

export default InvestHeader;

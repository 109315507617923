import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer, Flip } from "react-toastify";
import { Link, NavLink } from "react-router-dom";
import { logout } from "flows/session/reducer";
import config from "config";
import { selectUserId, selectUserLoaded } from "flows/session/selectors";
import { useAuth0 } from "@auth0/auth0-react";

import "./App.css";

export const App = ({ children, isLoggedIn, logout, userLoaded, userId }) => {
  const { logout: auth0Logout } = useAuth0();

  const handleLogout = () => {
    logout();
    auth0Logout({
      returnTo: config.cove.ui,
    });
  };

  return (
    <div styleName="main-container">
      <input
        type="hidden"
        data-test="app_test_mode"
        defaultValue="false"
        id="cove_app_testmode"
      />
      <header styleName="header">
        <Link to="/" data-test="nav_logo">
          <img
            styleName="logo"
            src={config.cove.brand.logo}
            alt={config.cove.brand.title}
          />
        </Link>
        {isLoggedIn && (
          <section data-test="app_nav">
            <a
              href="#main-menu"
              role="button"
              id="main-menu-toggle"
              styleName="menu-toggle"
            >
              <span>
                <img src="/images/burger.svg" alt="Menu" />
              </span>
            </a>
            {userLoaded && (
              <form style={{ display: "none" }}>
                <input
                  type="hidden"
                  data-test="app_user_id"
                  value={userId}
                  readOnly
                />
              </form>
            )}
            <nav
              id="main-menu"
              role="navigation"
              styleName="main-menu"
              auria-expanded="false"
              aria-label="Main menu"
            >
              <a
                href="#main-menu-toggle"
                role="button"
                id="main-menu-close"
                styleName="menu-close"
                aria-expanded="false"
                aria-controls="main-menu"
                aria-label="Close main menu"
              >
                <span>&times; close</span>
              </a>
              <ul>
                <li>
                  <NavLink
                    exact
                    onClick={() => {
                      window.location.hash = "main-menu-toggle";
                    }}
                    to="/"
                    activeStyle={{ color: "#4B4B4B", fontWeight: "bold" }}
                    data-test="nav_investments"
                  >
                    portfolio
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    onClick={() => {
                      window.location.hash = "main-menu-toggle";
                    }}
                    to="/profile"
                    activeStyle={{ color: "#4B4B4B", fontWeight: "bold" }}
                    data-test="nav_profile"
                  >
                    profile
                  </NavLink>
                </li>
                <li>
                  <a onClick={handleLogout} data-test="nav_logout">
                    logout
                  </a>
                </li>
              </ul>
            </nav>
            <a
              href="#main-menu-toggle"
              className="backdrop"
              tabIndex="-1"
              hidden
            />
          </section>
        )}
      </header>
      <div styleName="container">{children}</div>
      <ToastContainer transition={Flip} />
      <footer styleName="footer">
        <Link to="/">
          <img
            src={config.cove.brand.loginLogo}
            alt={config.cove.brand.title}
            styleName="footer_logo"
          />
        </Link>
        <section>
          <p styleName="footer_copy">
            <a
              styleName="footer_terms"
              href={config.cove.brand.toslink}
              target="_blank"
              data-test="footer_tos"
            >
              Terms of Use{" "}
            </a>
            &amp;
            <a
              styleName="footer_terms"
              href={config.cove.brand.privacylink}
              target="_blank"
              data-test="footer_policy"
            >
              {" "}
              Privacy Policy
            </a>
            <br />
            &copy; 2017-{new Date().getFullYear()} {config.cove.brand.platform}
          </p>
          <p styleName="help">
            Need help?{" "}
            <a href="mailto:support@cove.io" data-test="footer_support">
              support@cove.io
            </a>
          </p>
          <div>&nbsp;</div>
        </section>
      </footer>
    </div>
  );
};

App.propTypes = {
  children: PropTypes.element.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  userId: PropTypes.string,
  userLoaded: PropTypes.bool.isRequired,
};

const mapStoreToProps = (store) => ({
  isLoggedIn: !!store.session.user.loaded,
  userId: selectUserId(store),
  userLoaded: selectUserLoaded(store),
});

export default connect(
  mapStoreToProps,
  { logout }
)(App);

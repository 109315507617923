import React from "react";
import PropTypes from "prop-types";

import { useInputChange } from "utils";
import RadioList from "components/RadioList";

const ManagesLanguage = ({ type }) => {
  if (type !== "person" && type !== "Individual") {
    return "This entity manages more than $25m in investments";
  }
  return "I manage more than $25m in investments";
};

const OwnsLanguage = ({ type }) => {
  if (type !== "person" && type !== "Individual") {
    return "This entity has more than $5m in investments";
  }
  return "I have more than $5m in investments";
};

const WhoLanguage = ({ type, name }) => {
  if (type !== "person" && type !== "Individual") {
    return `${name || "Entity"} is a qualified purchase because:`;
  }
  return <>I&rsquo;m a qualified purchaser because:</>;
};

const QualifiedPurchaser = ({ onChange, value, type, name }) => {
  const qualifiedInput = useInputChange({
    initial: value,
    onChange,
  });

  return (
    <>
      <p>
        <WhoLanguage type={type} name={name} />
      </p>
      <RadioList data-test="qualified_form" className="native_form">
        <p>
          <input
            type="radio"
            id="qualified_owns_investments"
            name="qualified"
            value="owns_investments"
            data-test="qualified_option"
            checked={value === "owns_investments"}
            {...qualifiedInput.radioAttrs}
          />
          <label
            htmlFor="qualified_owns_investments"
            data-test="qualified_owns_investments_label"
          >
            <OwnsLanguage type={type} />
          </label>
        </p>
        <p>
          <input
            type="radio"
            id="qualified_manages_investments"
            name="qualified"
            value="manages_investments"
            checked={value === "manages_investments"}
            {...qualifiedInput.radioAttrs}
          />
          <label
            htmlFor="qualified_manages_investments"
            data-test="qualified_manages_investments_label"
          >
            <ManagesLanguage type={type} />
          </label>
        </p>
        {type !== "person" && type !== "Individual" && (
          <p>
            <input
              type="radio"
              id="qualified_owners_qualified"
              name="qualified"
              value="owners_qualified"
              checked={value === "owners_qualified"}
              {...qualifiedInput.radioAttrs}
            />
            <label
              htmlFor="qualified_owners_qualified"
              data-test="qualified_owners_qualified_label"
            >
              All owners of this entity are themselves qualified purchasers
            </label>
          </p>
        )}
      </RadioList>
    </>
  );
};

QualifiedPurchaser.isValid = ({ qualified }) => {
  return !!qualified;
};

QualifiedPurchaser.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default QualifiedPurchaser;

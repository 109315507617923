import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import Spinner from "components/Spinner";
import WelcomeNewUser from "components/WelcomeNewUser";
import ModalPage from "components/ModalPage";

const ACCEPT_INVITE_MUTATION = gql`
  mutation AcceptLeadInvite($secret: ID!, $dealId: ID!, $type: String!) {
    acceptInvite(secret: $secret, dealId: $dealId, type: $type) {
      invite {
        id
        self
        deal {
          id
          self
        }
      }
      is_new_user
    }
  }
`;

const AcceptInvite = ({ secret, dealId, type, onDone, onError }) => {
  const [welcomeOpen, setWelcomeOpen] = useState(false);
  const [acceptMutation, { loading }] = useMutation(ACCEPT_INVITE_MUTATION);

  const close_welcome = useCallback(() => {
    setWelcomeOpen(false);
    onDone();
  }, [dealId, onDone]);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: {
            acceptInvite: { is_new_user }
          }
        } = await acceptMutation({
          variables: {
            dealId,
            secret,
            type
          }
        });

        if (is_new_user) {
          setWelcomeOpen(true);
        } else {
          // Send the user to the deal
          onDone();
        }
      } catch (e) {
        onError(e);
      }
    })();
  }, [dealId, secret, onDone, onError]);

  if (loading) {
    return <Spinner data-test="accept_invite_spinner" />;
  }

  if (welcomeOpen) {
    return (
      <ReactModal
        isOpen={welcomeOpen}
        contentLabel="Welcome"
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
        className="ReactModal"
        ariaHideApp={false}
      >
        <ModalPage>
          <WelcomeNewUser close={close_welcome} />
        </ModalPage>
      </ReactModal>
    );
  }
  return null;
};

AcceptInvite.propTypes = {
  secret: PropTypes.string.isRequired,
  dealId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onDone: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
};

export default AcceptInvite;
export const graphql = {
  ...WelcomeNewUser.graphql,
  ACCEPT_INVITE_MUTATION
};

import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useInputChange, toRoundedAmount, toMoney } from "utils";
import { SliddenP, PoseGroup } from "utils/posed";

import RadioList from "components/RadioList";
import gql from "graphql-tag";

import "./style.css";

const CommitToInvest = ({ onChange, value, deal, setIsValid }) => {
  const { minimumInvestment } = deal;
  const isAmountValid = (amount) => amount >= minimumInvestment;

  useEffect(() => {
    const isValid =
      isAmountValid(value.investment_amount) &&
      value.investment_risk &&
      value.legally_bound;
    setIsValid(isValid);
  }, [value, setIsValid]);

  const amountInput = useInputChange({
    initial: value.investment_amount,
    marshall: (amount) => parseInt(amount / 100, 10) || "",
    unmarshall: (amount) => parseInt(amount * 100, 10),
    validator: isAmountValid,
    onChange,
  });

  const riskInput = useInputChange({
    initial: value.investment_risk,
    onChange,
  });

  const legalInput = useInputChange({
    initial: value.legally_bound,
    onChange,
  });

  const invested_amount =
    amountInput.value &&
    !amountInput.invalid &&
    toRoundedAmount(amountInput.value);

  return (
    <section className="native_form">
      <p>I&rsquo;m investing:</p>
      <input
        className="input_dollar"
        type="number"
        name="investment_amount"
        min="10000"
        step="5000"
        data-test="investment_amount"
        {...amountInput.attrs}
      />
      <PoseGroup>
        {amountInput.invalid && (
          <SliddenP styleName="formerror" key="formerror">
            Minimum: {toMoney(minimumInvestment)}
          </SliddenP>
        )}
      </PoseGroup>
      <RadioList>
        <p>
          <input
            type="checkbox"
            name="investment_risk"
            id="investment_risk"
            data-test="investment_risk"
            {...riskInput.attrs}
          />
          <label htmlFor="investment_risk">
            I understand all the risks of investing, including the risk that I
            could lose all my money
          </label>
        </p>
        <p>
          <input
            type="checkbox"
            name="legally_bound"
            id="legally_bound"
            data-test="legally_bound"
            {...legalInput.attrs}
          />
          <label htmlFor="legally_bound">
            I commit to invest{" "}
            {!!invested_amount && (
              <>
                up to <strong>{invested_amount}</strong>
              </>
            )}{" "}
            in {deal.name} when this opportunity closes
            <br />
            <span styleName="subnote">
              Your actual allocation may be lower than your commitment if this
              opportunity is oversubscribed
            </span>
          </label>
        </p>
      </RadioList>
    </section>
  );
};

CommitToInvest.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    investment_amount: PropTypes.number,
    investment_risk: PropTypes.bool.isRequired,
    legally_bound: PropTypes.bool.isRequired,
  }).isRequired,
  deal: PropTypes.shape({
    name: PropTypes.string.isRequired,
    minimumInvestment: PropTypes.number,
  }).isRequired,
  setIsValid: PropTypes.func.isRequired,
};

CommitToInvest.fragments = {
  deal: gql`
    fragment CommitToInvestDeal on Deal {
      name
      minimumInvestment
    }
  `,
};

export default CommitToInvest;

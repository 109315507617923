import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";

import { selectUserId, selectUserSelf } from "flows/session/selectors";
import { SliddenDiv, PoseGroup } from "utils/posed";
import config from "config";

import "./style.css";

import Commitment from "../Commitment";
import HowToInvest from "../../../HowToInvest";

const AssetFinalizing = ({ asset, deal, allocation }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <section styleName="container">
      {allocation && <Commitment allocation={allocation} />}
      <div>
        <dd styleName="status_label">Status</dd>
        <dt styleName="status_text">Sponsor is allocating between investors</dt>
      </div>
      {config.cove.brand.id === "cove" && (
        <PoseGroup>
          {!isOpen && (
            <SliddenDiv
              key="a"
              className="no_mobile"
              styleName="next_link_container"
            >
              <a
                onClick={() => setOpen(true)}
                data-test="invest_finalizing_next"
              >
                What happens next?
              </a>
            </SliddenDiv>
          )}
          {!!isOpen && (
            <SliddenDiv
              key="c"
              styleName="next_container"
              className="no_mobile"
            >
              <h4>What happens next?</h4>
              <HowToInvest allocation={allocation} deal={deal} />
              <a styleName="next_close" onClick={() => setOpen(false)}>
                Close
              </a>
            </SliddenDiv>
          )}
        </PoseGroup>
      )}
    </section>
  );
};

AssetFinalizing.propTypes = {
  deal: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
  asset: PropTypes.shape({
    openingDate: PropTypes.string.isRequired,
  }).isRequired,
};

export const mapStore = (store) => ({
  userId: selectUserId(store),
  user_self: selectUserSelf(store),
});

export default compose(connect(mapStore))(AssetFinalizing);

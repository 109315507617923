import { defineAction } from "redux-define";
import { createAction } from "redux-actions";
import { takeLatest, call } from "redux-saga/effects";

import { getW9URLSaga } from "api/allocations";

const namespace = "flows/docs/w9";

// Actions
export const LOAD = defineAction("LOAD", namespace);
export const load = createAction(LOAD.ACTION);

// LoggedIn Saga
export function* retrieveW9Saga({ payload }) {
  // Call api
  const { id } = payload;
  // Get pre signed url
  const url = yield call(getW9URLSaga, { payload: { id } });
  // Redirect
  window.location = url;
}

export function* sagas() {
  yield takeLatest(LOAD.ACTION, retrieveW9Saga);
}

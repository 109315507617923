import gql from "graphql-tag";
import { pick } from "lodash";

export const ONBOARD_PLAID = gql`
  mutation OnboardPlaid(
    $plaid: PlaidResponse
    $entity: String
    $owner: String
  ) {
    sources: onboardPlaid(plaid: $plaid, entity: $entity, owner: $owner) {
      id
      self
      name
    }
  }
`;

export const plaidVars = (
  { public_token, institution, account, link_session_id },
  user_self,
  entity_self
) => {
  return {
    variables: {
      plaid: {
        token: public_token,
        metadata: {
          public_token: public_token,
          institution: pick(institution, ["institution_id", "name"]),
          account: pick(account, ["name", "type", "subtype", "mask"]),
          account_id: account.id,
          link_session_id
        }
      },
      owner: user_self,
      entity: entity_self
    }
  };
};

export const ONBOARD_MANUAL = gql`
  mutation manualOp($source: ManualOnboard, $entity: String, $owner: String) {
    sources: onboardManual(source: $source, entity: $entity, owner: $owner) {
      id
      self
      name
    }
  }
`;

export const manualVars = (
  { bankName, bankAccountType, accountName, routingNumber, accountNumber },
  user_self,
  entity_self
) => {
  return {
    variables: {
      source: {
        metadata: {
          institution: {
            name: bankName
          },
          account: {
            name: accountName,
            subtype: bankAccountType,
            routing: routingNumber,
            account: accountNumber,
            mask: accountNumber.slice(-4)
          }
        }
      },
      owner: user_self,
      entity: entity_self
    }
  };
};

import gql from "graphql-tag";

export const query = gql`
  query GetDeal($dealId: ID!, $userId: ID!) {
    deal(id: $dealId) {
      id
      asset_interest(user_id: $userId) {
        id
        self
      }
    }
  }
`;

export const mapPropsToOptions = ({ deal: { id }, userId }) => ({
  variables: { dealId: id, userId }
});

export const mutation = gql`
  mutation M($asset_interest: AssetInterestCreate!) {
    add_asset_interest(asset_interest: $asset_interest) {
      asset_interest {
        id
        self
      }
    }
  }
`;

const notifyProps = ({ mutate, ownProps: { user_self, userId, deal } }) => () =>
  mutate({
    variables: {
      asset_interest: {
        owner: {
          self: user_self
        },
        deal: {
          self: deal.self
        }
      }
    },
    refetchQueries: [
      {
        query,
        variables: { dealId: deal.id, userId }
      }
    ]
  });

export const mapMutation = props => ({
  notify: notifyProps(props)
});

exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../../../styles/type.css"), "");
exports.i(require("-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../../../styles/status.css"), "");

// Module
exports.push([module.id, ".web-flows-deal-lead-components-DealInvest-components-AssetFinalizing-___style__container___2JVgi {\n  display: flex;\n  flex-direction: column;\n}\n\n@media (max-width: 599px) {\n  .web-flows-deal-lead-components-DealInvest-components-AssetFinalizing-___style__container___2JVgi {\n    flex-direction: row;\n    align-items: center;\n    justify-content: flex-start;\n    width: 100%;\n  }\n}\n\n.web-flows-deal-lead-components-DealInvest-components-AssetFinalizing-___style__status_label___2ohhF {\n  margin-bottom: 0.2rem;\n}\n\n.web-flows-deal-lead-components-DealInvest-components-AssetFinalizing-___style__status_text___16GWK {\n}\n\n.web-flows-deal-lead-components-DealInvest-components-AssetFinalizing-___style__next_link_container___3i8Ct {\n  margin-top: 1rem;\n}\n\n.web-flows-deal-lead-components-DealInvest-components-AssetFinalizing-___style__next_container___1Ry_5 {\n  margin-top: 1rem;\n  background-color: #fafafa;\n  padding: 1rem;\n}\n\n.web-flows-deal-lead-components-DealInvest-components-AssetFinalizing-___style__next_container___1Ry_5 h4 {\n  margin-top: 0;\n  margin-bottom: 0.5rem;\n}\n\n.web-flows-deal-lead-components-DealInvest-components-AssetFinalizing-___style__next_close___2lNHL {\n  padding-left: 0.5rem;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "web-flows-deal-lead-components-DealInvest-components-AssetFinalizing-___style__container___2JVgi " + require("-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../../../styles/type.css").locals["dark"] + "",
	"status_label": "web-flows-deal-lead-components-DealInvest-components-AssetFinalizing-___style__status_label___2ohhF " + require("-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../../../styles/type.css").locals["light"] + "",
	"status_text": "web-flows-deal-lead-components-DealInvest-components-AssetFinalizing-___style__status_text___16GWK " + require("-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../../../styles/status.css").locals["waiting"] + "",
	"next_link_container": "web-flows-deal-lead-components-DealInvest-components-AssetFinalizing-___style__next_link_container___3i8Ct",
	"next_container": "web-flows-deal-lead-components-DealInvest-components-AssetFinalizing-___style__next_container___1Ry_5",
	"next_close": "web-flows-deal-lead-components-DealInvest-components-AssetFinalizing-___style__next_close___2lNHL"
};
exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".web-styles-___border__around___3syTX {\n  border: 1px solid #dadade;\n}\n\n.web-styles-___border__bottom___3N4aE {\n  border-bottom: 1px solid #dadade;\n}\n\n.web-styles-___border__bottom_green___230YC {\n  border-bottom: 1px solid var(--sub-highlight-colour);\n}\n\n.web-styles-___border__left___1cmaP {\n  border-left: 1px solid #dadade;\n}\n\n.web-styles-___border__top___1Jn6e {\n  border-top: 1px solid #dadade;\n}\n\n.web-styles-___border__top_important___3mjv9 {\n  border-top: 1px solid #dadade !important;\n}\n", ""]);

// Exports
exports.locals = {
	"around": "web-styles-___border__around___3syTX",
	"bottom": "web-styles-___border__bottom___3N4aE",
	"bottom_green": "web-styles-___border__bottom_green___230YC",
	"left": "web-styles-___border__left___1cmaP",
	"top": "web-styles-___border__top___1Jn6e",
	"top_important": "web-styles-___border__top_important___3mjv9"
};
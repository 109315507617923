import React from "react";
import PropTypes from "prop-types";

import "./style.css";

export const WizardSteps = ({ current, steps }) => {
  const getStyle = this_step => {
    if (this_step === current) {
      return "item current";
    } else if (this_step < current) {
      return "item done";
    } else {
      return "item";
    }
  };

  return (
    <ol styleName="steps">
      {steps.map(({ text, test }, index) => (
        <li styleName={getStyle(index + 1)} data-test={test} key={index}>
          <span styleName="text">{text}</span>
        </li>
      ))}
    </ol>
  );
};

WizardSteps.propTypes = {
  current: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      test: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

export default WizardSteps;

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import { SliddenDiv, PoseGroup } from "utils/posed";
import "./style.css";

import Button from "components/Button";
import Commitment from "../Commitment";
import HowToInvest from "../../../HowToInvest";
import WiringDeadline from "components/WiringDeadline";

const Status = ({ allocation, isSigned, isFunded }) => {
  return (
    <div styleName="status_container">
      <dd styleName="status_label" className="no_mobile">
        Status
      </dd>
      {isFunded && isSigned ? (
        <dt
          styleName="status_waiting"
          data-test="invest_finalized_acquiring_status"
        >
          Sponsor is closing on asset acquisition
        </dt>
      ) : (
        <>
          {!!isSigned && (
            <dt
              styleName="status_done"
              data-test="invest_finalized_sign_status"
            >
              Contracts signed
            </dt>
          )}
          {!isSigned && (
            <dt
              styleName="status_waiting"
              data-test="invest_finalized_sign_status"
            >
              Contracts not signed <span className="no_mobile">yet</span>
            </dt>
          )}
          {!!isFunded && (
            <dt
              styleName="status_done"
              data-test="invest_finalized_wire_status"
            >
              Funding received
            </dt>
          )}
          {!isFunded && (
            <dt
              styleName="status_waiting"
              data-test="invest_finalized_wire_status"
            >
              Funding not received <span className="no_mobile">yet</span>
            </dt>
          )}
        </>
      )}
    </div>
  );
};
Status.propTypes = {
  allocation: PropTypes.shape({
    investment: PropTypes.shape({
      funded: PropTypes.bool.isRequired,
    }).isRequired,
    documents: PropTypes.shape({
      signed: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  isSigned: PropTypes.bool.isRequired,
  isFunded: PropTypes.bool.isRequired,
};

const Next = ({ allocation, deal }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <PoseGroup>
      {!isOpen && (
        <SliddenDiv
          key="a"
          className="no_mobile"
          styleName="next_link_container"
        >
          <a onClick={() => setOpen(true)} data-test="invest_finalized_next">
            What happens next?
          </a>
        </SliddenDiv>
      )}
      {!!isOpen && (
        <SliddenDiv
          key="c"
          styleName="next_container"
          className="no_mobile"
          data-test="invest_finalized_timeline"
        >
          <h4>What happens next?</h4>
          <HowToInvest allocation={allocation} deal={deal} />
          <a
            styleName="next_close"
            onClick={() => setOpen(false)}
            data-test="invest_finalized_close"
          >
            Close
          </a>
        </SliddenDiv>
      )}
    </PoseGroup>
  );
};

const Deadline = ({ asset }) => (
  <div styleName="funding_container" className="no_mobile">
    <dd styleName="funding_label">Funding deadline</dd>
    <dt styleName="funding_text">
      <WiringDeadline asset={asset} />
    </dt>
  </div>
);

const Buttons = ({
  allocation,
  deal,
  onSign,
  onWiring,
  isSigned,
  isFunded,
}) => {
  if (isSigned && isFunded) {
    return null;
  }

  let label, onClick, data_test;

  if (isSigned && deal.skipWiring) {
    return null;
  }

  if (!isFunded) {
    label = "Wiring Instructions";
    onClick = () => onWiring({ deal });
    data_test = "invest_finalized_wire";
  }

  if (!isSigned) {
    label = "Sign Contracts";
    onClick = () => onSign({ deal, allocation });
    data_test = "invest_finalized_sign";
  }

  return (
    <section styleName="button_container">
      <Button onClick={onClick} data-test={data_test}>
        {label}
      </Button>
    </section>
  );
};
Buttons.propTypes = {
  allocation: PropTypes.object,
  deal: PropTypes.object,
  onSign: PropTypes.func.isRequired,
  onWiring: PropTypes.func.isRequired,
  isSigned: PropTypes.bool.isRequired,
  isFunded: PropTypes.bool.isRequired,
};

const AssetFinalized = ({ asset, deal, allocation, sign, wiring }) => {
  const isSigned = allocation && allocation.documents.signed;
  const isFunded = allocation && allocation.investment.funded;

  return (
    <section styleName="container">
      {allocation && (
        <section className={isFunded && isSigned ? "" : "no_mobile"}>
          <Commitment allocation={allocation} />
        </section>
      )}
      {allocation && !allocation.investment.funded && (
        <Deadline asset={asset} />
      )}
      {allocation && (
        <Status
          allocation={allocation}
          isSigned={isSigned}
          isFunded={isFunded}
        />
      )}
      <Next allocation={allocation} deal={deal} />
      {allocation && (
        <Buttons
          allocation={allocation}
          deal={deal}
          onSign={sign}
          onWiring={wiring}
          isSigned={isSigned}
          isFunded={isFunded}
        />
      )}
    </section>
  );
};

AssetFinalized.propTypes = {
  deal: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
  asset: PropTypes.shape({
    openingDate: PropTypes.string.isRequired,
  }).isRequired,
  allocation: PropTypes.shape({
    investment: PropTypes.shape({
      funded: PropTypes.bool.isRequired,
    }).isRequired,
    documents: PropTypes.shape({
      signed: PropTypes.bool.isRequired,
    }).isRequired,
  }),
};

AssetFinalized.fragments = {
  allocation: gql`
    fragment AssetFinalizedAllocation on Allocation {
      investment {
        allocated
        funded
      }
      documents {
        signed
      }
    }
  `,
};

export default AssetFinalized;

exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/box.css"), "");
exports.i(require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/background.css"), "");
exports.i(require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css"), "");

// Module
exports.push([module.id, ".web-flows-deal-updates-components-Update-___style__row___h05_D {\n  padding: 1rem;\n  color: #95989a;\n}\n\n.web-flows-deal-updates-components-Update-___style__item___32umk {\n  flex-grow: 1;\n  flex-basis: 0;\n}\n\n.web-flows-deal-updates-components-Update-___style__name___1656U {\n  display: flex;\n  flex-flow: row nowrap;\n  flex-grow: 2;\n}\n\n.web-flows-deal-updates-components-Update-___style__name___1656U > span {\n  align-self: center;\n  margin: 0 0.5em;\n}\n\n.web-flows-deal-updates-components-Update-___style__icon___2Bs8r {\n  height: 2.5em;\n  width: 2.5em;\n  border-radius: 50%;\n  align-self: center;\n  display: flex;\n  background-repeat: no-repeat;\n  background-position: center center;\n  background-size: 1.3em;\n  background-image: url(\"/images/graph.svg\");\n}\n\n.web-flows-deal-updates-components-Update-___style__icon_text___2uhF- {\n  align-self: center;\n  width: 100%;\n  text-align: center;\n}\n\n.web-flows-deal-updates-components-Update-___style__amount___3xq1l {\n  align-self: center;\n  flex-grow: 1;\n  font-size: 14pt;\n  text-align: right;\n  padding-right: 2em;\n}\n\n.web-flows-deal-updates-components-Update-___style__date___1ctrp {\n  color: #95989a;\n  font-size: 10pt;\n}\n\n.web-flows-deal-updates-components-Update-___style__description___3guv5 {\n  color: #585858;\n  font-size: 12pt;\n}\n\n.web-flows-deal-updates-components-Update-___style__content___2yg66 {\n  padding-left: 3rem;\n}\n\n.web-flows-deal-updates-components-Update-___style__link___10-yf {\n  padding-right: 1rem;\n  display: block;\n}\n\n.web-flows-deal-updates-components-Update-___style__summary___3vdW7 {\n  padding-right: 2rem;\n}\n\n.web-flows-deal-updates-components-Update-___style__summary___3vdW7 ul {\n  padding-left: 1.5rem;\n}\n\n.web-flows-deal-updates-components-Update-___style__summary___3vdW7 ul li {\n  margin: 0.5rem 0;\n}\n", ""]);

// Exports
exports.locals = {
	"row": "web-flows-deal-updates-components-Update-___style__row___h05_D " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/box.css").locals["item"] + "",
	"item": "web-flows-deal-updates-components-Update-___style__item___32umk",
	"name": "web-flows-deal-updates-components-Update-___style__name___1656U",
	"icon": "web-flows-deal-updates-components-Update-___style__icon___2Bs8r " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/background.css").locals["page"] + "",
	"icon_text": "web-flows-deal-updates-components-Update-___style__icon_text___2uhF- " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css").locals["green"] + "",
	"amount": "web-flows-deal-updates-components-Update-___style__amount___3xq1l " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css").locals["green"] + "",
	"date": "web-flows-deal-updates-components-Update-___style__date___1ctrp",
	"description": "web-flows-deal-updates-components-Update-___style__description___3guv5",
	"content": "web-flows-deal-updates-components-Update-___style__content___2yg66",
	"link": "web-flows-deal-updates-components-Update-___style__link___10-yf",
	"summary": "web-flows-deal-updates-components-Update-___style__summary___3vdW7 " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css").locals["dark"] + ""
};
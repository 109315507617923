import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { graphql } from "utils/apollo";
import TestMode from "utils/test_mode";
import { handleError as error } from "api/utils";
import gql from "graphql-tag";

import mutateProps from "utils/mutateProps";
import HelloSign from "hellosign-embedded";
import config from "config";

import {
  sign_mutation,
  sign_props,
  get_sign_url_mutation,
  get_sign_props,
} from "./graphql";

import Spinner from "components/Spinner";
import Agreement from "./component";

export class AgreementContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
  }
  handleSigned() {
    const { sign, onSubmit, error } = this.props;

    return sign()
      .then(onSubmit)
      .catch(error);
  }
  launchHelloSign(signUrl) {
    const client = new HelloSign({
      clientId: config.hellosign.client,
    });

    client.open(signUrl, {
      allowCancel: false,
      skipDomainVerification: config.hellosign.skipDomainVerification,
    });

    client.on("sign", () => {
      setTimeout(() => {
        client.close();
      }, 0);

      this.handleSigned();
    });
  }
  next() {
    const { get_sign_url, error } = this.props;
    if (this.context.get_test_mode()) {
      // Skip hellosign flow
      this.handleSigned();
    } else {
      // Trigger hello sign flow
      return get_sign_url()
        .then((result) => {
          const signUrl = this.props.get_sign_urlResult.data.allocation
            .sign_url;
          this.launchHelloSign(signUrl);
        })
        .catch(error);
    }
  }
  render() {
    const { signLoading, get_sign_urlLoading, allocation, deal } = this.props;

    if (signLoading || get_sign_urlLoading) {
      return <Spinner data-test="agreement_spinner" />;
    }

    return (
      <Agreement
        next={this.next}
        allocation={allocation}
        deal={deal}
        data-test="invest_agreement"
      />
    );
  }
}

AgreementContainer.propTypes = {
  sign: PropTypes.func.isRequired,
  signLoading: PropTypes.bool.isRequired,
  get_sign_url: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  allocation: PropTypes.object.isRequired,
  deal: PropTypes.object.isRequired,
};

AgreementContainer.contextType = TestMode;

AgreementContainer.fragments = {
  deal: gql`
    fragment AgreementContainerDeal on Deal {
      ...AgreementDeal
    }
    ${Agreement.fragments.deal}
  `,
  allocation: gql`
    fragment AgreementContainerAllocation on Allocation {
      ...AgreementAllocation
    }
    ${Agreement.fragments.allocation}
  `,
};

const mapSignMutation = (props) => ({
  sign: sign_props(props),
});

const mapGetSignMutation = (props) => ({
  get_sign_url: get_sign_props(props),
});

const exported = compose(
  connect(
    null,
    { error }
  ),
  graphql(sign_mutation, {
    props: mapSignMutation,
  }),
  mutateProps({ name: "sign" }),
  graphql(get_sign_url_mutation, {
    props: mapGetSignMutation,
  }),
  mutateProps({ name: "get_sign_url" })
)(AgreementContainer);

exported.fragments = AgreementContainer.fragments;
export default exported;

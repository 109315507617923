import React from "react";
import PropTypes from "prop-types";

import { get_amount_from_alloc } from "utils";

import "./style.css";

/* eslint-disable max-len */
export class InvestorFinalized extends React.PureComponent {
  renderFunded() {
    const { allocation } = this.props;
    const { investment } = allocation;

    if (investment.funded) {
      let text = `Funded`;

      if (investment && investment.allocated) {
        const amount = get_amount_from_alloc(allocation);
        text = `Funded ${amount}`;
      }
      return <span styleName="done hasIcon">{text}</span>;
    }
    return <span styleName="waiting hasIcon">Not funded yet</span>;
  }
  renderSigned(options = {}) {
    const {
      invest,
      user,
      allocation: {
        owner,
        documents: { signed }
      }
    } = this.props;

    let test = "lead_investment_sign";
    if (options.inline) {
      test = "lead_investment_sign_inline";
    }

    if (signed) {
      return <span styleName="done hasIcon">Signed closing docs</span>;
    }
    if (user === owner.self) {
      return (
        <span styleName="waiting hasIcon">
          Waiting for you to{" "}
          <a onClick={invest} role="button" tabIndex="0" data-test={test}>
            sign closing docs
          </a>
        </span>
      );
    }
    return <span styleName="waiting hasIcon">Closing docs not signed</span>;
  }
  renderOther() {
    const { allocation } = this.props;
    const { owner } = allocation;
    const name = `${owner.firstName} ${owner.lastName}`;
    return (
      <div styleName="row">
        <div styleName="name item">
          <img src={owner.image} alt="" />
          <span>
            {name}
            <br />
            <span styleName="inline_status">
              {this.renderSigned({ inline: true })}
              <br />
              {this.renderFunded()}
            </span>
          </span>
        </div>
        <div styleName="item status">
          {this.renderSigned()}
          {this.renderFunded()}
        </div>
      </div>
    );
  }
  render() {
    const {
      user,
      allocation: { owner }
    } = this.props;
    if (user === owner.self) {
      return this.renderOther();
    }
    return this.renderOther();
  }
}
/* eslint-enable max-len */

InvestorFinalized.propTypes = {
  allocation: PropTypes.object.isRequired,
  user: PropTypes.string.isRequired,
  invest: PropTypes.func.isRequired
};

export default InvestorFinalized;

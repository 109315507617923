import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadInvestConfirmed as load } from "flows/invest/confirmed/index";

import InvestConfirmed from "./component";

export class InvestConfirmedContainer extends React.Component {
  componentDidMount() {
    this.props.load();
  }
  render() {
    return <InvestConfirmed />;
  }
}

InvestConfirmedContainer.propTypes = {
  load: PropTypes.func.isRequired
};

export default connect(
  null,
  { load }
)(InvestConfirmedContainer);

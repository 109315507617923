exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css"), "");

// Module
exports.push([module.id, "@media (max-width: 599px) {\n  .web-flows-deal-lead-components-DealMetrics-___style__container___38xAV {\n    margin: 0 1rem;\n  }\n}\n\n.web-flows-deal-lead-components-DealMetrics-___style__list___2Zlhr {\n  display: flex;\n  flex-wrap: wrap;\n  margin: 0;\n}\n\n.web-flows-deal-lead-components-DealMetrics-___style__list___2Zlhr > div {\n  margin: 1rem 2rem 1rem 0;\n}\n\n.web-flows-deal-lead-components-DealMetrics-___style__number___3urpt {\n}\n\n.web-flows-deal-lead-components-DealMetrics-___style__label___2WXJK {\n  margin-left: 0;\n}\n\n.web-flows-deal-lead-components-DealMetrics-___style__projections___2Uf0P {\n  margin: 0;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "web-flows-deal-lead-components-DealMetrics-___style__container___38xAV",
	"list": "web-flows-deal-lead-components-DealMetrics-___style__list___2Zlhr",
	"number": "web-flows-deal-lead-components-DealMetrics-___style__number___3urpt " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css").locals["important_value"] + " " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css").locals["blue"] + "",
	"label": "web-flows-deal-lead-components-DealMetrics-___style__label___2WXJK",
	"projections": "web-flows-deal-lead-components-DealMetrics-___style__projections___2Uf0P"
};
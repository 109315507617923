exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!./type.css"), "");

// Module
exports.push([module.id, ".web-styles-___metrics__label___1fmFt {\n  display: block;\n  padding: 0.4em 0;\n}\n\n.web-styles-___metrics__bold___10v0b {\n  font-weight: 600;\n}\n\n.web-styles-___metrics__sub-note___2V4Mc {\n  margin: 0;\n  font-size: 10pt;\n  padding-top: 0.33rem;\n}\n\n.web-styles-___metrics__amount___3dbdN {\n  margin: 0;\n  font-size: 18pt;\n}\n", ""]);

// Exports
exports.locals = {
	"label": "web-styles-___metrics__label___1fmFt " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!./type.css").locals["dark"] + "",
	"bold": "web-styles-___metrics__bold___10v0b",
	"sub-note": "web-styles-___metrics__sub-note___2V4Mc " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!./type.css").locals["light"] + "",
	"amount": "web-styles-___metrics__amount___3dbdN " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!./type.css").locals["important_value"] + ""
};
import { defineAction } from "redux-define";
import { createAction, handleActions } from "redux-actions";
import { get } from "lodash";

import { selectStatus } from "../selectors";

const namespace = "flows/deal/leadmanage";

// Actions
const DEAL_FINALIZING = defineAction(
  "DEAL_FINALIZING",
  ["SHOW", "HIDE"],
  namespace
);
export const showDealFinalizing = createAction(DEAL_FINALIZING.SHOW);
export const hideDealFinalizing = createAction(DEAL_FINALIZING.HIDE);

const DEAL_FINALIZED = defineAction(
  "DEAL_FINALIZED",
  ["SHOW", "HIDE"],
  namespace
);
export const showDealFinalized = createAction(DEAL_FINALIZED.SHOW);
export const hideDealFinalized = createAction(DEAL_FINALIZED.HIDE);

const DEAL_CLOSED = defineAction("DEAL_CLOSED", ["SHOW", "HIDE"], namespace);
export const showDealClosed = createAction(DEAL_CLOSED.SHOW);
export const hideDealClosed = createAction(DEAL_CLOSED.HIDE);

// Selectors
export const selectShowDealFinalizing = store =>
  get(selectStatus(store), "finalizing.show");
export const selectShowDealFinalized = store =>
  get(selectStatus(store), "finalized.show");
export const selectShowDealClosed = store =>
  get(selectStatus(store), "closed.show");

const initialState = {
  finalizing: {
    show: false
  },
  finalized: {
    show: false
  },
  closed: {
    show: false
  }
};

export const reducer = handleActions(
  {
    [DEAL_FINALIZING.SHOW]: state => ({
      ...state,
      finalizing: {
        ...state.finalizing,
        show: true
      }
    }),
    [DEAL_FINALIZING.HIDE]: state => ({
      ...state,
      finalizing: {
        ...state.finalizing,
        show: false
      }
    }),
    [DEAL_FINALIZED.SHOW]: state => ({
      ...state,
      finalized: {
        ...state.finalized,
        show: true
      }
    }),
    [DEAL_FINALIZED.HIDE]: state => ({
      ...state,
      finalized: {
        ...state.finalized,
        show: false
      }
    }),
    [DEAL_CLOSED.SHOW]: state => ({
      ...state,
      closed: {
        ...state.closed,
        show: true
      }
    }),
    [DEAL_CLOSED.HIDE]: state => ({
      ...state,
      closed: {
        ...state.closed,
        show: false
      }
    })
  },
  initialState
);

import React from "react";
import PropTypes from "prop-types";

import Page from "components/Page";

import "../../../flows/interest/invite/style.css";

/* eslint-disable max-len */
const InvestInvite = ({ login, invite }) => (
  <>
    <div styleName="page-bg" />
    <section className="pageContainer">
      <Page>
        <section styleName="inviteContainer">
          <img src="/images/tick.svg" alt="Yup" />
          <h3>{invite.deal.name} has been finalized</h3>
        </section>
        <p styleName="login">
          You have some closing documents to sign, so you need to{" "}
          <a onClick={login} role="button" tabIndex="0" data-test="login">
            login
          </a>
        </p>
      </Page>
    </section>
  </>
);
/* eslint-enable max-len */

InvestInvite.propTypes = {
  login: PropTypes.func.isRequired,
  invite: PropTypes.object.isRequired,
};

export default InvestInvite;

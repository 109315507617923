exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/box.css"), "");

// Module
exports.push([module.id, ".web-flows-account-components-Profile-___style__loadContainer___1jxV9 {\n  text-align: center;\n  padding: 1rem;\n}\n\n.web-flows-account-components-Profile-___style__header___2n9wc {\n  display: flex;\n  align-items: center;\n  padding: 2em 0 0;\n  justify-content: space-between;\n}\n\n.web-flows-account-components-Profile-___style__header___2n9wc > h2 {\n  padding-top: 0;\n}\n\n.web-flows-account-components-Profile-___style__header___2n9wc .web-flows-account-components-Profile-___style__edit_mobile___1biAy {\n  padding-right: 1rem;\n}\n\n@media (max-width: 599px) {\n}\n\n.web-flows-account-components-Profile-___style__profile___3b1oD {\n  margin: 1rem 0;\n  display: flex;\n  padding: 1em 1.5em;\n}\n\n.web-flows-account-components-Profile-___style__detail___35Knr {\n  flex-basis: 33%;\n  color: #95989a;\n  border-left: #e5e5e5 1px solid;\n  padding: 0 0.67em;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n@media (max-width: 599px) {\n  .web-flows-account-components-Profile-___style__profile___3b1oD {\n    flex-direction: column;\n  }\n\n  .web-flows-account-components-Profile-___style__detail___35Knr {\n    flex-basis: auto;\n    border: none;\n    padding: 0.5em 0;\n  }\n}\n\n.web-flows-account-components-Profile-___style__detail___35Knr:first-child {\n  border: none;\n  padding-left: 0;\n  flex-basis: 25%;\n}\n\n.web-flows-account-components-Profile-___style__detail___35Knr > header {\n  padding-bottom: 0.2em;\n}\n\n.web-flows-account-components-Profile-___style__detail___35Knr > p {\n  margin: 0;\n  font-weight: 600;\n}\n\n.web-flows-account-components-Profile-___style__name___1_Jaz {\n}\n", ""]);

// Exports
exports.locals = {
	"loadContainer": "web-flows-account-components-Profile-___style__loadContainer___1jxV9 " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/box.css").locals["box"] + "",
	"header": "web-flows-account-components-Profile-___style__header___2n9wc",
	"edit_mobile": "web-flows-account-components-Profile-___style__edit_mobile___1biAy",
	"profile": "web-flows-account-components-Profile-___style__profile___3b1oD " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/box.css").locals["box"] + "",
	"detail": "web-flows-account-components-Profile-___style__detail___35Knr",
	"name": "web-flows-account-components-Profile-___style__name___1_Jaz " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/box.css").locals["box_title"] + ""
};
exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".web-components-LinkAccount-components-ManualOnboard-___style__form___2xnSI {\n  min-width: 570px;\n}\n\n.web-components-LinkAccount-components-ManualOnboard-___style__label___3JG6V {\n  display: block;\n  padding: 0.4em 0;\n}\n\n.web-components-LinkAccount-components-ManualOnboard-___style__input___3GPvc {\n  display: block;\n  width: 100%;\n  margin-bottom: 1em;\n}\n\n.web-components-LinkAccount-components-ManualOnboard-___style__header___1am7R {\n  display: flex;\n  justify-content: space-between;\n  border-bottom: 1px solid #e5e3e3;\n}\n\n.web-components-LinkAccount-components-ManualOnboard-___style__h2___1wQYz {\n  border-bottom: none !important;\n}\n\n.web-components-LinkAccount-components-ManualOnboard-___style__close___35ipz {\n  padding: 0 0.7em 0.7em 0;\n}\n\n@media (max-width: 599px) {\n  .web-components-LinkAccount-components-ManualOnboard-___style__toplevel___3I22E {\n    padding: 1em 0.5em 0;\n  }\n  .web-components-LinkAccount-components-ManualOnboard-___style__form___2xnSI {\n    min-width: auto;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"form": "web-components-LinkAccount-components-ManualOnboard-___style__form___2xnSI",
	"label": "web-components-LinkAccount-components-ManualOnboard-___style__label___3JG6V",
	"input": "web-components-LinkAccount-components-ManualOnboard-___style__input___3GPvc",
	"header": "web-components-LinkAccount-components-ManualOnboard-___style__header___1am7R",
	"h2": "web-components-LinkAccount-components-ManualOnboard-___style__h2___1wQYz",
	"close": "web-components-LinkAccount-components-ManualOnboard-___style__close___35ipz",
	"toplevel": "web-components-LinkAccount-components-ManualOnboard-___style__toplevel___3I22E"
};
import React from "react";
import PropTypes from "prop-types";
import Markdown from "react-markdown";
import moment from "moment";

import "./style.css";

/* eslint-disable max-len */
const Update = ({ update }) => {
  const date = moment(update.createdAt).format("MMMM Do YYYY");
  const title = update.description.replace(" Quarterly Update", "");
  return (
    <div styleName="row" data-test="update_item">
      <div styleName="name item">
        <div styleName="icon" />
        <span>
          <span styleName="description">{title}</span>
          <br />
          <span styleName="date" data-test="update_date">
            Posted on {date}
          </span>
        </span>
      </div>
      <section styleName="content">
        <section styleName="summary">
          <Markdown source={update.content} />
        </section>
        {update.link && (
          <a href={update.link.url} target="_blank" styleName="link">
            {update.link.text}
          </a>
        )}
      </section>
    </div>
  );
};

/* eslint-enable max-len */

Update.propTypes = {
  update: PropTypes.shape({
    description: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    link: PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  })
};

export default Update;

import React from "react";
import PropTypes from "prop-types";

import EditUserProfile from "components/EditUserProfile";

const WelcomeNewUser = ({ close }) => (
  <EditUserProfile
    header={<h2>Welcome</h2>}
    body={<p>Since you&apos;re new, we need a few details from you:</p>}
    submitText="Continue"
    onSaved={close}
    allowCancel={false}
  />
);

WelcomeNewUser.propTypes = {
  close: PropTypes.func.isRequired
};

export default WelcomeNewUser;
export { graphql } from "components/EditUserProfile";

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { load } from "flows/docs/w92";

export class RetrieveW9 extends React.PureComponent {
  componentDidMount() {
    this.props.load({ id: this.props.match.params.allocationId });
  }
  render() {
    return (
      <div>
        <img src="/images/ring.svg" alt="Loading" />
      </div>
    );
  }
}

RetrieveW9.propTypes = {
  load: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      allocationId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default connect(
  null,
  { load }
)(RetrieveW9);

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import gql from "graphql-tag";
import { pick } from "lodash";

import HowToInvest from "../HowToInvest";
import AssetNotOpen from "./components/AssetNotOpen";
import AssetOpen from "./components/AssetOpen";
import AssetFinalizing from "./components/AssetFinalizing";
import AssetFinalized from "./components/AssetFinalized";
import {
  routeToDealInterestFromDeal as invest,
  routeToInterestBackingFromDeal as confirm,
  routeToDealInvestFromDeal as sign,
  routeToDealClosing as wiring
} from "flows/routing";

import "./style.css";

export class DealInvest extends React.PureComponent {
  renderInvest() {
    const { deal, asset, allocation, backing } = this.props;
    const { openToCommitments } = asset;

    return (
      <section styleName="invest">
        {!openToCommitments && (
          <AssetNotOpen asset={asset} backing={backing} deal={deal} />
        )}
        {!!openToCommitments && (
          <>
            <AssetOpen
              asset={asset}
              allocation={allocation}
              backing={backing}
              deal={deal}
              invite={deal.invite}
            />
          </>
        )}
      </section>
    );
  }
  renderAllocating() {
    const { deal, allocation, backing } = this.props;
    return (
      <section>
        <section styleName="wrapper">{this.renderInvest()}</section>
      </section>
    );
  }
  renderFinalizing() {
    const { deal, allocation, asset } = this.props;
    return (
      <section>
        <section styleName="wrapper">
          <section styleName="invest">
            <AssetFinalizing
              asset={asset}
              deal={deal}
              allocation={allocation}
            />
          </section>
        </section>
      </section>
    );
  }
  renderClosing() {
    const props = pick(this.props, [
      "deal",
      "allocation",
      "asset",
      "sign",
      "wiring"
    ]);

    return (
      <section>
        <section styleName="wrapper">
          <section styleName="invest">
            <AssetFinalized {...props} />
          </section>
        </section>
      </section>
    );
  }
  render() {
    const { deal, invest, allocation } = this.props;
    if (deal.status === "allocating") {
      return this.renderAllocating();
    } else if (deal.status === "finalizing") {
      return this.renderFinalizing();
    } else if (deal.status === "finalized") {
      return this.renderClosing();
    }
    return null;
  }
}

DealInvest.propTypes = {
  deal: PropTypes.shape({
    status: PropTypes.string.isRequired,
    metrics: PropTypes.shape({
      leadExit: PropTypes.number,
      subleadExit: PropTypes.number,
      leadIRR: PropTypes.number,
      subleadIRR: PropTypes.number
    }).isRequired
  }).isRequired,
  allocation: PropTypes.shape({
    investment: PropTypes.shape({
      amount: PropTypes.number.isRequired,
      allocated: PropTypes.number
    }).isRequired
  }),
  backing: PropTypes.shape({
    amount: PropTypes.number.isRequired
  }),
  invite: PropTypes.shape({
    type: PropTypes.string.isRequired
  }).isRequired,
  asset: PropTypes.shape({
    term: PropTypes.number
  }).isRequired,
  invest: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  sign: PropTypes.func.isRequired,
  wiring: PropTypes.func.isRequired,
  user_self: PropTypes.string.isRequired
};

DealInvest.fragments = {
  allocation: gql`
    fragment DealInvestAllocation on Allocation {
      ...AssetFinalizedAllocation
    }
    ${AssetFinalized.fragments.allocation}
  `,
  asset: gql`
    fragment DealInvestAsset on Asset {
      closingDate
      openingDate
      wiringDate
      openToCommitments
    }
  `,
  backing: gql`
    fragment DealInvestBacking on Backing {
      amount
      ...HowToInvestBacking
    }
    ${HowToInvest.fragments.backing}
  `
};

export default connect(
  null,
  { invest, confirm, sign, wiring }
)(DealInvest);

const merge = require("lodash.merge");

const { get, post, gql } = require("./agent");

module.exports = (global_options) => {
  return {
    create: (data, local_options) => {
      const options = merge({}, global_options, local_options);
      return post("/syndicates", data, options);
    },
    get: (id, local_options) => {
      const options = merge({}, global_options, local_options);
      return get(`/syndicates/${id}`, options);
    },
    add_manager: ({ id, manager_id }, local_options) => {
      const options = merge({}, global_options, local_options);
      const query = `
      mutation M {
        add_syndicate_manager(id: "${id}", manager_id: "${manager_id}") {
          added
          syndicate {
            id
            self
          }
        }
      }
      `;
      return gql(query, null, options);
    },
  };
};

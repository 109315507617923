import React from "react";
import PropTypes from "prop-types";

import "./RadioList.css";

const RadioList = props => (
  <form styleName="radioList" {...props}>
    {props.children}
  </form>
);

RadioList.propTypes = {
  children: PropTypes.node.isRequired
};

export default RadioList;

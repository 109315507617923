import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import gql from "graphql-tag";

import { graphql, waitForUser, has_investors_from_invites } from "utils";
import { selectUserSelf, useUserSelf } from "flows/session/selectors";

import Spinner from "components/Spinner";
import InvestorInviteContainer from "./components/InvestorInvite";
import Sub from "./components/Sub";
import { SlideDiv, PoseGroup } from "utils/posed";

import "./style.css";

const AssetNotOpen = ({ deal, asset, invites }) => {
  return (
    <section data-test="invited_list">
      {invites.map((invite) => (
        <InvestorInviteContainer
          invite={invite}
          deal={deal}
          asset={asset}
          key={invite.id}
        />
      ))}
    </section>
  );
};
AssetNotOpen.propTypes = {
  deal: PropTypes.object.isRequired,
  asset: PropTypes.object.isRequired,
  invite: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

const AssetOpen = ({ deal, asset, invites }) => {
  const [expanded, setExpanded] = useState(false);
  const current_user = useUserSelf();

  let shown_invites = invites;

  if (!expanded) {
    shown_invites = invites.filter((invite) => {
      return invite.allocation || invite.user.self === current_user;
    });
  }

  return (
    <>
      <section data-test="invited_list">
        <PoseGroup>
          {shown_invites.map((invite) => (
            <SlideDiv key={invite.id}>
              <InvestorInviteContainer {...{ deal, asset, invite }} />
            </SlideDiv>
          ))}
        </PoseGroup>
      </section>
      <p styleName="more">
        {!expanded && (
          <a onClick={() => setExpanded(true)}>Show all investors</a>
        )}
        {!!expanded && <a onClick={() => setExpanded(false)}>Show less</a>}
      </p>
    </>
  );
};
AssetOpen.propTypes = {
  deal: PropTypes.object.isRequired,
  asset: PropTypes.object.isRequired,
  invite: PropTypes.shape({
    id: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export const InvitedInvestors = ({ announce, user_self, data }) => {
  const { loading, deal } = data;

  if (loading) {
    return <Spinner data-test="invited_spinner" />;
  }

  let { asset, invites } = deal;

  if (invites && invites.length && invites.length > 1) {
    // Sort to put user first, then allocated investors
    invites = invites.sort((a, b) => {
      if (a.user.self === user_self) {
        return -1;
      }
      if (a.allocation) {
        return -1;
      }
      if (b.allocation) {
        return 1;
      }
      return 0;
    });

    return (
      <section>
        <header styleName="header">
          <h2>Investors</h2>
          <Sub {...{ announce, invites }} />
        </header>
        {!!asset.openToCommitments && (
          <AssetOpen {...{ deal, asset, invites }} />
        )}
        {!asset.openToCommitments && (
          <AssetNotOpen {...{ deal, asset, invites }} />
        )}
      </section>
    );
  }

  return null;
};

InvitedInvestors.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    deal: PropTypes.shape({
      invites: PropTypes.array.isRequired,
    }),
  }).isRequired,
  deal: PropTypes.object.isRequired,
  user_self: PropTypes.string.isRequired,
};

const query = gql`
  query DealLeadInvites($dealId: ID!) {
    deal(id: $dealId) {
      id
      self
      asset {
        id
        self
        openToCommitments
        ...InvestorInviteAsset
      }
      invites {
        id
        self
        ...InvitedInvestorsSub
        ...InvestorInviteInvite
      }
    }
  }
  ${Sub.fragments.invite}
  ${InvestorInviteContainer.fragments.invite}
  ${InvestorInviteContainer.fragments.asset}
`;

export const mapStore = (store) => ({
  user_self: selectUserSelf(store),
});

const mapPropsToOptions = ({ deal }) => ({
  variables: { dealId: deal.id },
  fetchPolicy: "network-only",
});

export default compose(
  waitForUser(),
  connect(mapStore),
  graphql(query, {
    options: mapPropsToOptions,
  })
)(InvitedInvestors);

import { handleActions } from "redux-actions";
import { reduce, isNull, isUndefined } from "lodash";

import { DEAL_ALLOCATE_CHANGE, DEAL_ALLOCATE_LOAD } from "flows/deal/allocate";
import { selectAllocate } from "../selectors";

import Big from "big.js";

// Selectors
export const selectDealAllocations = store => selectAllocate(store);

export const initialState = {};

const get_amount = ({ allocated, amount }) =>
  isUndefined(allocated) || isNull(allocated) ? amount : allocated;

export const reducer = handleActions(
  {
    [DEAL_ALLOCATE_CHANGE.PERSIST]: (state, action) => ({
      ...state,
      [action.payload.allocation.id]: Big(action.payload.allocated)
        .times(Big(100))
        .toString()
    }),
    [DEAL_ALLOCATE_LOAD.PERSIST]: (state, action) => {
      const transform = (acc, { id, investment }) => ({
        ...acc,
        [id]: Big(get_amount(investment)).toString()
      });
      return reduce(action.payload.allocations, transform, {});
    }
  },
  initialState
);

import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { graphql } from "utils/apollo";
import waitForUser from "utils/waitForUser";

import { query } from "./graphql";
import { sendAnnouncement as announce } from "flows/deal/announce/index";

import {
  selectUserId,
  selectUserSelf,
  selectIsOps,
} from "flows/session/selectors";

import Spinner from "components/Spinner";
import Page from "components/Page";
import InvitedInvestors from "flows/deal/investors/invited";
import AllocateInvestors from "flows/deal/investors/allocate";
import FinalizedInvestors from "flows/deal/investors/finalized";
import ClosedInvestors from "flows/deal/investors/closed";
import Distributions from "../distributions";
import Updates from "../updates";
import Tracker from "../tracker";

import DealMetrics from "./components/DealMetrics";
import DealInvest from "./components/DealInvest";
import DealCalc from "./components/DealCalc";
import DealShare from "./components/DealShare";
import DecisionDocs from "./components/DecisionDocs";
import Discussion from "./components/Discussion";
import DealHeader from "./components/DealHeader";
import HowToInvest from "./components/HowToInvest";
import SendAnnouncement from "../announce";

import "./style.css";

export class DealLeadContainer extends React.Component {
  constructor() {
    super();
    this.announce = this.announce.bind(this);
  }
  announce() {
    this.props.announce({
      deal: this.props.data.deal,
    });
  }
  renderCommittment() {
    const {
      data: { deal },
      user_self,
    } = this.props;
    return (
      <DealInvest
        deal={deal}
        invite={deal.invite}
        allocation={deal.allocation}
        asset={deal.asset}
        backing={deal.backing}
        data-test="deal_commitment"
        user_self={user_self}
      />
    );
  }
  renderShare() {
    const {
      data: { deal },
      user_self,
    } = this.props;
    if (deal.status === "allocating") {
      return (
        <DealShare
          deal={deal}
          asset={deal.asset}
          invite={deal.invite}
          allocation={deal.allocation}
          backing={deal.backing}
          asset={deal.asset}
          data-test="deal_share"
          user_self={user_self}
        />
      );
    }
  }
  renderCalc() {
    const {
      data: { deal },
      user_self,
    } = this.props;
    if (deal.status === "allocating" && deal.metrics.leadExit) {
      return (
        <DealCalc
          deal={deal}
          asset={deal.asset}
          invite={deal.invite}
          allocation={deal.allocation}
          backing={deal.backing}
          asset={deal.asset}
          data-test="deal_share"
          user_self={user_self}
        />
      );
    }
  }
  renderTracker(mode) {
    const {
      match: { params },
      data: { deal },
    } = this.props;
    return (
      <section styleName={`tracker_container_${mode}`}>
        <Tracker dealId={params.dealId} data-test="deal_tracker" />
      </section>
    );
  }
  renderInvestors() {
    const { isOps } = this.props;
    const { deal } = this.props.data;
    if (deal.status === "allocating") {
      return (
        <InvitedInvestors
          deal={deal}
          data-test="deal_invited"
          announce={this.announce}
        />
      );
    } else if (deal.status === "finalizing") {
      if (isOps) {
        return <AllocateInvestors deal={deal} data-test="deal_allocated" />;
      } else {
        return <ClosedInvestors deal={deal} data-test="deal_closed" />;
      }
    } else if (deal.status === "finalized") {
      return <FinalizedInvestors deal={deal} data-test="deal_finalized" />;
    } else if (deal.status === "closed") {
      return <ClosedInvestors deal={deal} data-test="deal_closed" />;
    }
    return null;
  }
  renderTimeline() {
    const { deal } = this.props.data;
    if (deal.status === "finalizing" || deal.status === "finalized") {
      return (
        <section
          styleName="how_to_invest_mobile_container"
          className="only_mobile"
        >
          <h2>What happens now?</h2>
          <section styleName="how_to_invest_mobile">
            <HowToInvest deal={deal} allocation={deal.allocation} />
          </section>
        </section>
      );
    }
    return null;
  }
  renderDistributions() {
    const {
      match: { params },
      data: { deal },
    } = this.props;
    return (
      <Distributions dealId={params.dealId} data-test="deal_distributions" />
    );
  }
  renderUpdates() {
    const {
      match: { params },
      data: { deal },
    } = this.props;
    return <Updates dealId={params.dealId} data-test="deal_updates" />;
  }
  renderMetrics() {
    const {
      user_self,
      data: { deal },
    } = this.props;
    if (
      deal.status === "allocating" ||
      deal.status === "finalizing" ||
      deal.status === "finalized"
    ) {
      return (
        <DealMetrics
          deal={deal}
          invite={deal.invite}
          asset={deal.asset}
          data-test="deal_metrics"
          user_self={user_self}
        />
      );
    }
  }
  renderDocs() {
    const { deal } = this.props.data;
    return (
      <DecisionDocs
        deal={deal}
        asset={deal.asset}
        allocation={deal.allocation}
        data-test="deal_decision"
      />
    );
  }
  renderDiscussion() {
    const { deal } = this.props.data;
    if (
      deal.status === "allocating" &&
      (deal.asset.discussion_archive || deal.syndicate.whatsapp)
    ) {
      return (
        <Discussion
          asset={deal.asset}
          syndicate={deal.syndicate}
          data-test="deal_discussion"
        />
      );
    }
  }
  renderClosed() {
    return (
      <section styleName="container">
        <section styleName="left_closed">
          {this.renderTracker("desktop")}
          {this.renderInvestors()}
          {this.renderDocs()}
        </section>
        <aside styleName="right_closed">
          {this.renderTracker("mobile")}
          {this.renderDistributions()}
          {this.renderUpdates()}
        </aside>
      </section>
    );
  }
  renderNotClosed() {
    return (
      <section styleName="container">
        <section styleName="left">
          {this.renderCalc()}
          {this.renderMetrics()}
          {this.renderInvestors()}
          {this.renderDiscussion()}
          {this.renderDocs()}
        </section>
        <aside styleName="right">
          {this.renderCommittment()}
          {this.renderUpdates()}
          {this.renderTimeline()}
          {this.renderShare()}
        </aside>
      </section>
    );
  }
  render() {
    const { deal, loading } = this.props.data || {};
    if (loading || !deal) {
      return <Spinner />;
    }

    return (
      <section className="widerPageContainer">
        <DealHeader
          deal={deal}
          asset={deal.asset}
          invite={deal.invite}
          allocation={deal.allocation}
          allocations={deal.allocations}
          data-test="deal_header"
        />
        {deal.status !== "closed" && this.renderNotClosed()}
        {deal.status === "closed" && this.renderClosed()}
        <SendAnnouncement
          deal={deal}
          asset={deal.asset}
          data-test="deal_announcement"
          allocation={deal.allocation}
        />
      </section>
    );
  }
}

DealLeadContainer.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    deal: PropTypes.object,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      dealId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  announce: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  user_self: PropTypes.string.isRequired,
  isOps: PropTypes.bool,
};
DealLeadContainer.defaultProps = {
  isOps: false,
};

export const mapStore = (store, props) => ({
  isOps: selectIsOps(store),
  userId: selectUserId(store),
  user_self: selectUserSelf(store),
});

const mapPropsToOptions = ({
  match: {
    params: { dealId },
  },
  userId,
}) => ({
  variables: { dealId, userId },
  fetchPolicy: "network-only",
});

export default compose(
  waitForUser(),
  connect(
    mapStore,
    { announce }
  ),
  graphql(query, {
    options: mapPropsToOptions,
  })
)(DealLeadContainer);

import { defineAction } from "redux-define";
import { createAction, handleActions } from "redux-actions";

import { GET_USERINFO } from "api/users";

const namespace = "flows/session/login";

// Actions
export const LOGIN = defineAction("LOGIN", namespace);
export const login = createAction(LOGIN.ACTION);

export const LOGOUT = defineAction("LOGOUT", namespace);
export const logout = createAction(LOGOUT.ACTION);

// Reducer
const initialState = {
  user: {
    loaded: false
  },
  auth0: {}
};
export const reducer = handleActions(
  {
    [LOGIN.ACTION]: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        accessToken: action.payload.accessToken
      },
      auth0: action.payload.idTokenPayload
    }),
    [GET_USERINFO.REQUESTED]: state => ({
      ...state,
      user: {
        ...state.user,
        loaded: false
      }
    }),
    [GET_USERINFO.SUCCESS]: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        ...action.payload.data,
        loaded: true
      }
    }),
    [LOGOUT.ACTION]: () => ({
      ...initialState
    })
    // TODO: Handle error
  },
  initialState
);

import { compose } from "redux";
import gql from "graphql-tag";
import { graphql } from "utils/apollo";
import mutateProps from "utils/mutateProps";
import moment from "moment";

const mutation = gql`
  mutation InterestSubmit($allocation: AllocationCreate) {
    allocation: create_allocation(allocation: $allocation) {
      id
      investment {
        allocated
      }
      entity {
        id
        self
        accreditation {
          type
          accreditationDate
        }
        qualifiedPurchaser {
          type
          accreditationDate
        }
      }
    }
  }
`;

const saveProp = ({ mutate }) => ({ allocation }) =>
  mutate({
    variables: {
      allocation
    }
  });

const mapMutation = props => ({
  save: saveProp(props)
});

export const saveMutation = graphql(mutation, {
  props: mapMutation
});

export const saveProps = mutateProps({ name: "save" });

export const setType = state => {
  const { investorType, investorCompany_type } = state;
  if (investorType === "person") {
    return "Individual";
  } else if (investorType === "trust") {
    return "Trust";
  } else {
    return investorCompany_type;
  }
};

export const stateToEntity = (state, deal, asset) => {
  const {
    investorType,
    investorName,
    investorSignatory,
    investorEntity_self
  } = state;
  let entity;

  if (investorEntity_self && investorEntity_self !== "new") {
    // Use existing entity
    entity = {
      self: investorEntity_self
    };
  } else {
    // Create a new entity
    entity = {
      name: investorName,
      street: state.investorAddressStreet,
      city: state.investorAddressCity,
      state: state.investorAddressState,
      zip: state.investorAddressZip
    };

    // Reconstruct SSN or FEIN with dashes
    let taxId;
    if (investorType === "person") {
      taxId = `${state.investorTaxId_S1}-${state.investorTaxId_S2}-${
        state.investorTaxId_S3
      }`;
    } else {
      taxId = `${state.investorTaxId_F1}-${state.investorTaxId_F2}`;
    }

    if (investorType === "person") {
      entity.ssn = taxId;
    } else if (investorType === "trust") {
      entity.signatory = investorSignatory;
      entity.fein = taxId;
    } else if (investorType === "company") {
      entity.signatory = investorSignatory;
      if (state.investorCompany_type === "LLC") {
        entity.taxClassification = state.investorLLC_type;
      }
      entity.fein = taxId;
    }

    entity.type = setType(state);
  }

  if (asset.investorCheck === "qualified_purchaser") {
    entity.qualifiedPurchaser = {
      type: state.qualified,
      accreditationDate: moment().format()
    };
  } else if (asset.investorCheck === "accredited_investor") {
    entity.accreditation = {
      type: state.accredited,
      accreditationDate: moment().format()
    };
  } else {
    throw new Error("asset missing investorCheck attribute");
  }

  return entity;
};

export const savePayload = ({ state, user_self, deal, asset }) => {
  const entity = stateToEntity(state, deal, asset);

  const allocation = {
    owner: {
      self: user_self
    },
    investment: {
      amount: state.investment_amount,
      risk: state.investment_risk
    },
    deal: {
      self: deal.self
    },
    entity
  };

  return {
    allocation
  };
};

/* eslint-disable import/first */

import config from "config";
import { H } from "highlight.run";

if (window.Raven && config.sentry.dsn) {
  window.Raven.config(config.sentry.dsn).install();
}

let enableFS = true;
if (window.localStorage.getItem("cove:fullstory:donottrack") === "yes") {
  enableFS = false;
}
if (config.highlight && enableFS) {
  H.init(config.highlight.orgId, {
    environment: config.highlight.env,
    enableStrictPrivacy: false,
  });
}

import React from "react";
import { render } from "react-dom";
import configureStore from "store";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider as StoreProvider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";

import { login } from "flows/session/reducer";
import { goAfterLogin } from "utils/AuthService";
import { ApolloProvider } from "@apollo/react-hooks";
import apollo from "utils/apollo";

import App from "./containers/App";
import HomeContainer from "./flows/home";
import AccountContainer from "./flows/account";

import LeadInviteContainer from "./flows/lead/invite";
import LeadDealContainer from "./flows/lead/deal";

import InterestInviteContainer from "./flows/interest/invite";
import InterestDealContainer from "./flows/interest/deal";
import InterestInvestorContainer from "./flows/interest/investor";
import InterestBackingContainer from "./flows/interest/backing";
import InterestShareContainer from "./flows/interest/share";

import InvestInviteContainer from "./flows/invest/invite";
import InvestAgreementContainer from "./flows/invest/agreement";
import InvestClosingContainer from "./flows/invest/closing";
import InvestConfirmedContainer from "./flows/invest/confirmed";
import InvestBackingContainer from "./flows/invest/backing";

import DealLeadContainer from "./flows/deal/lead";

import RetrieveW9 from "./flows/docs/w9";
import RetrieveDealUnsigned from "./flows/docs/unsigned";
import RetrieveDealSigned from "./flows/docs/signed";
import RetrieveTaxDoc from "./flows/docs/tax_documents";

// import InvestmentAddContainer from './flows/investment/add';

import Login from "./containers/Login";
import LoggedIn from "./containers/LoggedIn";

import moment from "moment";
import { get } from "lodash";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import { create } from "jss";
import { StylesProvider, jssPreset } from "@material-ui/styles";

import history from "utils/history";
import OnRouteChange from "utils/OnRouteChange";
import TestMode, { get_test_mode } from "utils/test_mode";

import "./global.css";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      '"Helvetica',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      main: "#585858",
    },
    secondary: {
      main: "#2D62B7",
    },
    error: {
      main: "#8b0000",
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        color: "#585858",
        "&$focused": {
          color: "#585858",
        },
        "&$focused $notchedOutline": {
          borderColor: "#95989A",
          borderWidth: "1px",
        },
        "&:hover $notchedOutline": {
          borderColor: "#95989A",
        },
      },
      notchedOutline: {
        borderColor: "#E5E3E3",
      },
    },
    MuiRadio: {
      root: {
        padding: "0.25rem 0.5rem 0.25rem 1rem",
      },
    },
    MuiFormLabel: {
      root: {
        paddingBottom: "0.5rem",
      },
    },
  },
});

if (config.cove.brand.css) {
  Object.keys(config.cove.brand.css).forEach((key) => {
    document.documentElement.style.setProperty(key, config.cove.brand.css[key]);
  });
}

const jss = create({
  ...jssPreset(),
  // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
  insertionPoint: "jss-insertion-point",
});

// Create the store
const store = configureStore();

// validate authentication for private routes
const AuthdRoute = ({ component: Component, authOptional, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        // Already logged in?
        if (!get(store.getState(), "session.user.accessToken")) {
          // Check if we have a valid auth0 token cached
          const expiresAt = moment(
            JSON.parse(localStorage.getItem("expiresAt"))
          );

          const now = moment();
          const valid = now.isBefore(expiresAt);

          if (valid) {
            // Log user in with cached token
            const authResult = JSON.parse(localStorage.getItem("authResult"));
            store.dispatch(login(authResult));
          } else if (authOptional !== true) {
            // Force login
            goAfterLogin(props.location.pathname);
            return <Redirect to="/login" />;
          }
        }
        return <Component {...props} />;
      }}
    />
  );
};

/* eslint-disable max-len */
document.addEventListener("DOMContentLoaded", () => {
  const root = document.getElementById("mount");
  render(
    <TestMode.Provider value={{ get_test_mode }}>
      <StoreProvider store={store}>
        <StylesProvider jss={jss}>
          <ApolloProvider store={store} client={apollo}>
            <ThemeProvider theme={theme}>
              <Auth0Provider
                domain={config.auth0.domain}
                clientId={config.auth0.clientId}
                redirectUri={`${config.cove.ui}/loggedin`}
                audience={config.auth0.audience}
                scope="token id_token"
              >
                <Router history={history}>
                  <OnRouteChange />
                  <App>
                    <Switch>
                      <Route exact path="/login" component={Login} />
                      <Route exact path="/loggedin" component={LoggedIn} />

                      <AuthdRoute
                        exact
                        path="/profile"
                        component={AccountContainer}
                      />

                      <AuthdRoute
                        exact
                        path="/lead/invite/:secret"
                        authOptional={true}
                        component={LeadInviteContainer}
                      />
                      <AuthdRoute
                        exact
                        path="/lead/deals/:dealId/invites/:secret"
                        component={LeadDealContainer}
                      />

                      <AuthdRoute
                        exact
                        path="/interest/invite/:secret"
                        authOptional={true}
                        component={InterestInviteContainer}
                      />
                      <AuthdRoute
                        exact
                        path="/interest/deals/:dealId/invites/:secret"
                        component={InterestDealContainer}
                      />
                      <AuthdRoute
                        exact
                        path="/interest/investor/deals/:dealId/after/:after"
                        component={InterestInvestorContainer}
                      />
                      <AuthdRoute
                        exact
                        path="/interest/backing/deals/:dealId/after/:after"
                        component={InterestBackingContainer}
                      />
                      <AuthdRoute
                        exact
                        path="/interest/share/deals/:dealId"
                        component={InterestShareContainer}
                      />

                      <Route
                        exact
                        path="/invest/invite/:secret"
                        component={InvestInviteContainer}
                      />
                      <AuthdRoute
                        exact
                        path="/invest/allocations/:allocationId/agreement/after/:after/confirm/:confirm"
                        component={InvestAgreementContainer}
                      />
                      <AuthdRoute
                        exact
                        path="/invest/closing/deals/:dealId"
                        component={InvestClosingContainer}
                      />
                      <AuthdRoute
                        exact
                        path="/invest/closing/deals/:dealId/after/:after"
                        component={InvestClosingContainer}
                      />
                      <AuthdRoute
                        exact
                        path="/invest/backing/deals/:dealId"
                        component={InvestBackingContainer}
                      />
                      <Route
                        exact
                        path="/invest/confirm"
                        component={InvestConfirmedContainer}
                      />

                      <AuthdRoute
                        exact
                        path="/deals/:dealId"
                        component={DealLeadContainer}
                      />
                      <AuthdRoute
                        exact
                        path="/deals/:dealId/allocation/:allocationId"
                        component={DealLeadContainer}
                      />

                      <AuthdRoute
                        exact
                        path="/docs/w9/:allocationId"
                        component={RetrieveW9}
                      />
                      <AuthdRoute
                        exact
                        path="/docs/allocation/signed/:allocationId"
                        component={RetrieveDealSigned}
                      />
                      <AuthdRoute
                        exact
                        path="/docs/deal/unsigned/:dealId"
                        component={RetrieveDealUnsigned}
                      />
                      <AuthdRoute
                        exact
                        path="/docs/tax_documents/:docId"
                        component={RetrieveTaxDoc}
                      />

                      {/* <AuthdRoute exact path="investment/add" component={InvestmentAddContainer} />
                        <AuthdRoute exact path="investment/add/:investmentId" component={InvestmentAddContainer} /> */}

                      <AuthdRoute exact path="/" component={HomeContainer} />
                    </Switch>
                  </App>
                </Router>
              </Auth0Provider>
            </ThemeProvider>
          </ApolloProvider>
        </StylesProvider>
      </StoreProvider>
    </TestMode.Provider>,
    root
  );
});

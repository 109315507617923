import gql from "graphql-tag";
import AddBackingContainer from "./components/AddBacking";

export const query = gql`
  query InvestBacking($dealId: ID!, $userId: ID!) {
    deal(id: $dealId) {
      id
      self
      image
      asset {
        id
        ...AddBackingAsset
      }
      invite(user_id: $userId) {
        id
        ...AddBackingInvite
      }
      allocation(user_id: $userId) {
        id
        ...AddBackingAllocation
      }
      ...AddBackingDeal
    }
  }
  ${AddBackingContainer.fragments.asset}
  ${AddBackingContainer.fragments.deal}
  ${AddBackingContainer.fragments.invite}
  ${AddBackingContainer.fragments.allocation}
`;

export const mutation = gql`
  mutation InvestCreateBacking($backing: BackingCreate!) {
    createBacking(backing: $backing) {
      backing {
        id
        self
        amount
      }
    }
  }
`;

export const mutation_props = ({ mutate, ownProps }) => amount =>
  mutate({
    variables: {
      backing: {
        amount,
        deal: {
          self: ownProps.data.deal.self
        },
        owner: {
          self: ownProps.userSelf
        }
      }
    }
  });

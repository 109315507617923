exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".web-styles-___background__red___1Nygt {\n  background-color: rgb(255, 68, 76);\n  transition: background-color 200ms ease;\n}\n\n.web-styles-___background__darkred___1lYst {\n  background-color: #ad2015;\n  transition: background-color 200ms ease;\n}\n\n.web-styles-___background__green___2Z0a1 {\n  background-color: #11ba79;\n  transition: background-color 200ms ease;\n}\n\n.web-styles-___background__green_important___1oAeu {\n  background-color: #11ba79 !important;\n  transition: background-color 200ms ease;\n}\n\n.web-styles-___background__blue___101x1 {\n  background-color: var(--ui-highlight-colour);\n  transition: background-color 200ms ease;\n}\n\n.web-styles-___background__yellow___aGni9 {\n  background-color: #ffc22f;\n  transition: background-color 200ms ease;\n}\n\n.web-styles-___background__dark___3wlD2 {\n  background-color: #585858;\n  transition: background-color 200ms ease;\n}\n\n.web-styles-___background__light___1mPLz {\n  background-color: #95989a;\n  transition: background-color 200ms ease;\n}\n\n.web-styles-___background__page___6Qiv3 {\n  background-color: #fafafa;\n  transition: background-color 200ms ease;\n}\n", ""]);

// Exports
exports.locals = {
	"red": "web-styles-___background__red___1Nygt",
	"darkred": "web-styles-___background__darkred___1lYst",
	"green": "web-styles-___background__green___2Z0a1",
	"green_important": "web-styles-___background__green_important___1oAeu",
	"blue": "web-styles-___background__blue___101x1",
	"yellow": "web-styles-___background__yellow___aGni9",
	"dark": "web-styles-___background__dark___3wlD2",
	"light": "web-styles-___background__light___1mPLz",
	"page": "web-styles-___background__page___6Qiv3"
};
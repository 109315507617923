import React from "react";
import PropTypes from "prop-types";

import Spinner from "components/Spinner";
import Page from "components/Page";

import AddBacking from "./components/AddBacking";

import "./style.css";

const InvestBacking = ({ deal, loading, submit, skip }) => {
  if (loading) {
    return (
      <Page styleName="loadContainer">
        <Spinner data-test="backing_spinner" />
      </Page>
    );
  }

  return (
    <section className="pageContainer" style={{ marginTop: "1rem" }}>
      <header styleName="confirmationContainer">
        <div styleName="checkmark">
          <img
            src="/images/overlay_check.svg"
            alt=""
            style={{ backgroundImage: `url(${deal.image})` }}
          />
        </div>
        <h2 styleName="confirmed">
          You&rsquo;re now officially an investor in {deal.asset.name}
        </h2>
      </header>
      <AddBacking
        deal={deal}
        invite={deal.invite}
        allocation={deal.allocation}
        asset={deal.asset}
        submit={submit}
        skip={skip}
        data-test="backing_add"
      />
    </section>
  );
};

InvestBacking.propTypes = {
  loading: PropTypes.bool.isRequired,
  deal: PropTypes.shape({
    image: PropTypes.string.isRequired,
    asset: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired,
    invite: PropTypes.object.isRequired,
    allocation: PropTypes.object.isRequired
  }),
  submit: PropTypes.func.isRequired,
  skip: PropTypes.func.isRequired
};

export default InvestBacking;

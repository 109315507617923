import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { graphql } from "utils/apollo";
import moment from "moment";

import { selectUserId } from "flows/session/selectors";

import getYearsSince from "utils/getYearsSince";
import handleInputChange from "utils/handleInputChange";

import Spinner from "components/Spinner";
import DistributionsList from "./components/DistributionsList";
import { query } from "./graphql";

import "./style.css";

export class DistributionsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      distribution_year: props.currentYear()
    };

    this.handleInputChange = handleInputChange.bind(this);
  }
  renderYearSelector() {
    const { deal } = this.props.data || {};
    const years = getYearsSince(
      deal.allocation.createdAt,
      this.props.currentYear()
    );

    return (
      <form styleName="year">
        <label styleName="label" htmlFor="distribution_year">
          Year
        </label>
        <select
          onChange={this.handleInputChange}
          name="distribution_year"
          id="distribution_year"
          data-test="distribution_year"
        >
          {years.map(year => (
            <option value={year} key={year}>
              {year}
            </option>
          ))}
        </select>
      </form>
    );
  }
  render() {
    const {
      data: { deal, loading },
      dealId,
      userId
    } = this.props;

    if (loading) {
      return <Spinner data-test="distributions_spinner" />;
    }

    if (deal.allocation) {
      return (
        <div>
          <header styleName="header">
            <h2 styleName="h2">Distributions</h2>
            {this.renderYearSelector()}
          </header>
          <div styleName="descriptor_container">
            <p className="section_descriptor">
              Profits earned from this investment
            </p>
          </div>
          <DistributionsList
            dealId={dealId}
            userId={userId}
            year={this.state.distribution_year}
          />
        </div>
      );
    }

    return null;
  }
}

DistributionsContainer.propTypes = {
  dealId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    deal: PropTypes.shape({
      allocation: PropTypes.shape({
        createdAt: PropTypes.string.isRequired
      })
    })
  }).isRequired,
  currentYear: PropTypes.func.isRequired
};

DistributionsContainer.defaultProps = {
  currentYear: () =>
    moment()
      .year()
      .toString()
};

export const mapStore = store => ({
  userId: selectUserId(store)
});

const mapPropsToOptions = ({ dealId, userId }) => ({
  variables: { dealId, userId, year: moment().year() }
});

export default compose(
  connect(mapStore),
  graphql(query, {
    options: mapPropsToOptions
  })
)(DistributionsContainer);

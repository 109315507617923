import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { compose } from "redux";
import { connect } from "react-redux";
import config from "config";

import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { selectUserSelf } from "flows/session/selectors";

import {
  waitForUser,
  renderPoints,
  renderMultiple,
  get_amount_from_alloc,
} from "utils";

import "./style.css";

const invite_text = ({ deal, invite, allocation, asset }) => {
  const link = `${config.cove.ui}/interest/invite/${
    invite.investor_invite_secret
  }`;

  let amount = "";
  if (allocation) {
    amount = `${get_amount_from_alloc(allocation)} `;
  }

  const content = `${link}`;
  return content;
};

export const ShareDeal = ({
  deal,
  invite,
  allocation,
  asset,
  user_self,
  onLink,
}) => {
  const share_text = invite_text({ deal, invite, allocation, asset });

  const on_copy = useCallback(() => {
    const toastId = "interest_clipboard_copy";
    if (!toast.isActive(toastId)) {
      toast.success("Copied link", {
        position: toast.POSITION.TOP_CENTER,
        toastId,
      });
    }
    if (onLink) {
      onLink();
    }
  });

  const email_link = `mailto:?subject=Sharing an investment with you&body=${share_text}`;
  const whatsapp_link = `https://wa.me/?text=${encodeURI(share_text)}`;

  return (
    <section styleName="link_container" data-test="share_container">
      <input
        type="hidden"
        data-test="share_secret"
        value={invite.investor_invite_secret}
        readOnly
      />
      <CopyToClipboard text={share_text} onCopy={on_copy}>
        <a>
          <div styleName="copy_icon">
            <img src="/images/copy.svg" alt="" />
          </div>
          <div styleName="link_text" data-test="share_copy">
            Copy Link
          </div>
        </a>
      </CopyToClipboard>
      <a href={email_link} target="_blank" onClick={onLink}>
        <div styleName="email_icon">
          <img src="/images/email.svg" alt="" />
        </div>
        <div styleName="link_text">Email</div>
      </a>
      <a href={whatsapp_link} target="_blank" onClick={onLink}>
        <div styleName="whatsapp_icon">
          <img src="/images/whatsapp.svg" alt="" />
        </div>
        <div styleName="link_text">WhatsApp</div>
      </a>
    </section>
  );
};

ShareDeal.propTypes = {
  deal: PropTypes.shape({
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  invite: PropTypes.object.isRequired,
  allocation: PropTypes.shape({
    investment: PropTypes.shape({
      amount: PropTypes.number.isRequired,
    }).isRequired,
  }),
  asset: PropTypes.shape({
    location: PropTypes.string,
    term: PropTypes.number,
  }).isRequired,
  onLink: PropTypes.func,
  user_self: PropTypes.string.isRequired,
};

ShareDeal.fragments = {
  deal: gql`
    fragment ShareDealDeal on Deal {
      metrics {
        subleadExit
        subleadIRR
        subleadYield
      }
    }
  `,
  invite: gql`
    fragment ShareDealInvite on Invite {
      investor_invite_secret
    }
  `,
  allocation: gql`
    fragment ShareDealAllocation on Allocation {
      investment {
        amount
      }
    }
  `,
  asset: gql`
    fragment ShareDealAsset on Asset {
      location
      term
    }
  `,
};

export const mapStore = (store) => ({
  user_self: selectUserSelf(store),
});

const exported = compose(
  waitForUser(),
  connect(mapStore)
)(ShareDeal);

exported.fragments = ShareDeal.fragments;
export default exported;

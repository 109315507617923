import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import gql from "graphql-tag";
import { graphql } from "utils/apollo";
import waitForUser from "utils/waitForUser";

import { routeToInvestmentAdd } from "flows/routing";

import { selectUserId } from "flows/session/selectors";
import Spinner from "components/Spinner";

import Home from "./component";

export const HomeContainer = ({
  data: { loading, userinfo },
  investment_add
}) => {
  if (loading) {
    return <Spinner />;
  }

  return (
    <Home
      opportunities={userinfo.opportunities}
      investments={userinfo.investments}
      investment_add={investment_add}
    />
  );
};

HomeContainer.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    userinfo: PropTypes.object
  }).isRequired,
  investment_add: PropTypes.func.isRequired
};

const query = gql`
  query HomeUserInfo($userId: ID!) {
    userinfo {
      id
      self
      investments {
        id
        self
        ...HomeInvestment
      }
      opportunities {
        id
        self
        deal {
          backing(user_id: $userId) {
            id
            self
            ...HomeOpportunityBacking
          }
        }
        ...HomeOpportunity
      }
    }
  }
  ${Home.fragments.investment}
  ${Home.fragments.opportunity}
  ${Home.fragments.opportunity_backing}
`;

const mapPropsToOptions = () => ({
  fetchPolicy: "network-only"
});

export const mapStore = store => ({
  userId: selectUserId(store)
});

export default compose(
  waitForUser(),
  connect(
    mapStore,
    { investment_add: routeToInvestmentAdd }
  ),
  graphql(query, {
    options: mapPropsToOptions
  })
)(HomeContainer);

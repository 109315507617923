exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../styles/type.css"), "");

// Module
exports.push([module.id, ".web-flows-deal-distributions-___style__header___2cAMf {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding-top: 2em;\n}\n\n.web-flows-deal-distributions-___style__h2___12f-o {\n  padding-top: 0;\n}\n\n@media (max-width: 599px) {\n  .web-flows-deal-distributions-___style__h2___12f-o {\n    padding-left: 1rem;\n  }\n}\n\n.web-flows-deal-distributions-___style__table___2i0-B {\n  display: flex;\n  flex-flow: column nowrap;\n  justify-content: space-between;\n}\n\n.web-flows-deal-distributions-___style__year___1X-wl {\n  margin-bottom: 0;\n}\n\n@media (max-width: 599px) {\n  .web-flows-deal-distributions-___style__year___1X-wl {\n    padding-right: 1rem;\n  }\n}\n\n.web-flows-deal-distributions-___style__label___2PIcX {\n  padding-right: 0.33em;\n}\n\n@media (max-width: 599px) {\n  .web-flows-deal-distributions-___style__descriptor_container___2WKDU {\n    padding-left: 1rem;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"header": "web-flows-deal-distributions-___style__header___2cAMf",
	"h2": "web-flows-deal-distributions-___style__h2___12f-o",
	"table": "web-flows-deal-distributions-___style__table___2i0-B",
	"year": "web-flows-deal-distributions-___style__year___1X-wl",
	"label": "web-flows-deal-distributions-___style__label___2PIcX " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../styles/type.css").locals["light"] + "",
	"descriptor_container": "web-flows-deal-distributions-___style__descriptor_container___2WKDU"
};
import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import { GreyButton } from "components/Button";
import Page from "components/Page";

import { renderPercentage } from "utils";

import "./style.css";

const Discussion = ({ asset, syndicate }) => (
  <section styleName="container">
    <h2>Discussions & Questions</h2>
    <p className="section_descriptor" />
    <dl styleName="list">
      {syndicate.whatsapp && (
        <div>
          <dt>
            <a
              href={syndicate.whatsapp.join_link}
              target="_blank"
              data-test="discussion_whatsapp_link"
            >
              {syndicate.name} WhatsApp Group
            </a>
          </dt>
          <dd styleName="description">
            Chat with your syndicate or ask the sponsor questions
          </dd>
        </div>
      )}
      {asset.discussion_archive && (
        <div>
          <dt>
            <a
              href={asset.discussion_archive.link}
              target="_blank"
              data-test="discussion_archive_link"
            >
              Discussion Archive
            </a>
          </dt>
          <dd styleName="description">
            See what investors in other syndicates have asked about
          </dd>
        </div>
      )}
    </dl>
  </section>
);

Discussion.propTypes = {
  asset: PropTypes.shape({
    sponsor: PropTypes.shape({
      link: PropTypes.string.isRequired
    })
  }).isRequired,
  syndicate: PropTypes.shape({
    whatsapp: PropTypes.shape({
      join_link: PropTypes.string.isRequired
    })
  }).isRequired
};

Discussion.fragments = {
  syndicate: gql`
    fragment DiscussionSyndicate on Syndicate {
      name
      whatsapp {
        join_link
      }
    }
  `,
  asset: gql`
    fragment DiscussionAsset on Asset {
      discussion_archive {
        link
      }
    }
  `
};

export default Discussion;

exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!../styles/box.css"), "");

// Module
exports.push([module.id, ".web-components-___ModalPage__modal_page___VjoRH {\n  box-shadow: 0px 2px 10px 0px #dadade;\n  padding: 2em;\n  background-color: #fff;\n}\n\n.web-components-___ModalPage__modal_page___VjoRH h2 {\n  padding-top: 0;\n}\n\n@media (max-width: 599px) {\n  .web-components-___ModalPage__modal_page___VjoRH {\n    padding: 1em;\n    border: none;\n    border-top: 1px solid #e5e3e3;\n    border-bottom: 1px solid #e5e3e3;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"modal_page": "web-components-___ModalPage__modal_page___VjoRH " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!../styles/box.css").locals["box"] + ""
};
exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".web-styles-___type__red___1jg6W {\n  color: rgb(255, 68, 76);\n  transition: color 200ms ease;\n}\n\n.web-styles-___type__darkred___1TaGC {\n  color: #ad2015;\n  transition: color 200ms ease;\n}\n\n.web-styles-___type__green___JCKbl {\n  color: var(--sub-highlight-colour);\n  transition: color 200ms ease;\n}\n\n.web-styles-___type__green_important___2gk-2 {\n  color: var(--sub-highlight-colour) !important;\n  transition: color 200ms ease;\n}\n\n.web-styles-___type__blue___37G-G {\n  color: var(--text-highlight-colourr);\n  transition: color 200ms ease;\n}\n\n.web-styles-___type__blue_important___3WvlR {\n  color: var(--text-highlight-colourr) !important;\n  transition: color 200ms ease;\n}\n\n.web-styles-___type__dark___3Vr8A {\n  color: var(--dark-colour);\n  transition: color 200ms ease;\n}\n\n.web-styles-___type__mid_dark___1i6Kv {\n  color: #6e6e6e;\n  transition: color 200ms ease;\n}\n\n.web-styles-___type__light___2bmzs {\n  color: #95989a;\n  transition: color 200ms ease;\n}\n\n.web-styles-___type__white___2MnSf {\n  color: #fff;\n  transition: color 200ms ease;\n}\n\n.web-styles-___type__important_value___3uZCH {\n  font-size: var(--type-important-value-font-size);\n  font-weight: var(--type-important-value-font-weight);\n}\n\n.web-styles-___type__link___1UDqZ {\n  color: var(--a-tag-colour);\n  transition: color 200ms ease;\n}\n\n.web-styles-___type__link___1UDqZ:hover {\n  color: var(--a-tag-colour-hover);\n}\n\n.web-styles-___type__link_hover___-P4EU {\n  color: var(--a-tag-colour-hover);\n}\n\n.web-styles-___type__formerror___17nYq {\n  margin: 0.33em 0 0 0;\n}\n", ""]);

// Exports
exports.locals = {
	"red": "web-styles-___type__red___1jg6W",
	"darkred": "web-styles-___type__darkred___1TaGC",
	"green": "web-styles-___type__green___JCKbl",
	"green_important": "web-styles-___type__green_important___2gk-2",
	"blue": "web-styles-___type__blue___37G-G",
	"blue_important": "web-styles-___type__blue_important___3WvlR",
	"dark": "web-styles-___type__dark___3Vr8A",
	"mid_dark": "web-styles-___type__mid_dark___1i6Kv",
	"light": "web-styles-___type__light___2bmzs",
	"white": "web-styles-___type__white___2MnSf",
	"important_value": "web-styles-___type__important_value___3uZCH",
	"link": "web-styles-___type__link___1UDqZ",
	"link_hover": "web-styles-___type__link_hover___-P4EU",
	"formerror": "web-styles-___type__formerror___17nYq web-styles-___type__darkred___1TaGC"
};
exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/type.css"), "");
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/metrics.css"), "");

// Module
exports.push([module.id, ".web-components-CommitToInvest-___style__formerror___1u5ym {\n}\n\n.web-components-CommitToInvest-___style__subnote___3iKAw {\n  margin: 0;\n}\n", ""]);

// Exports
exports.locals = {
	"formerror": "web-components-CommitToInvest-___style__formerror___1u5ym " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/type.css").locals["formerror"] + "",
	"subnote": "web-components-CommitToInvest-___style__subnote___3iKAw " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/metrics.css").locals["sub-note"] + ""
};
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { fragments } from "utils";
import gql from "graphql-tag";

import Page from "components/Page";
import { selectUserSelf } from "flows/session/selectors";

import { BigBlueButton } from "components/Button";
import LinkAccount from "components/LinkAccount";
import { ButtonPulse } from "components/Pulse";

import "./style.css";

const LinkAccountContainer = ({ onSubmit, entity, deal }) => {
  const user_self = useSelector(selectUserSelf);
  const [saving, setSaving] = useState(false);
  const [showLinking, setShowLinking] = useState(false);

  const onLink = () => setShowLinking(true);
  const onLinking = () => setSaving(true);
  const onLinked = () => onSubmit();
  const onCancel = () => setShowLinking(false);

  let message = `receive your distributions`;
  if (deal.skipWiring) {
    message = "send your contribution";
  }
  return (
    <>
      <Page>
        <h2 className="only_mobile">Link Bank Account</h2>
        <h3 styleName="link">Link your bank account to {message}</h3>
        <p styleName="sure">
          Make sure the bank account belongs to <strong>{entity.name}</strong>
        </p>
        <section styleName="button_container">
          <BigBlueButton
            disabled={saving}
            onClick={onLink}
            data-test="link_start"
          >
            {saving ? <ButtonPulse /> : "Login to your bank"}
          </BigBlueButton>
        </section>
        {!!showLinking && (
          <LinkAccount
            {...{ onCancel, onLinking, onLinked, user_self }}
            {...fragments(LinkAccount, { entity })}
          />
        )}
      </Page>
      {!saving && !deal.skipWiring && (
        <a onClick={onSubmit} data-test="link_skip" styleName="skip">
          Skip for now
        </a>
      )}
    </>
  );
};

LinkAccountContainer.propTypes = {
  entity: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  deal: PropTypes.shape({
    skipWiring: PropTypes.bool,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

LinkAccountContainer.fragments = {
  entity: gql`
    fragment LinkAccountContainerEntity on Entity {
      name
      ...LinkAccountEntity
    }
    ${LinkAccount.fragments.entity}
  `,
  deal: gql`
    fragment LinkAccountContainerDeal on Deal {
      skipWiring
    }
  `,
};

export default LinkAccountContainer;

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import ModalPage from "components/ModalPage";
import Button, { ModalXButton } from "components/Button";
import Footer from "components/Footer";
import Spinner from "components/Spinner";

import { useInputChange, toAmount } from "utils";
import { template, get } from "lodash";

import "./style.css";

/* eslint-disable max-len */
const SendAnnouncement = ({
  sending,
  submit,
  cancel,
  asset,
  deal,
  allocation
}) => {
  let initialMessage = ``;
  let initialSubject = `An update on ${deal.name}`;
  if (asset.canned_messages && asset.canned_messages.length) {
    try {
      const sorted = asset.canned_messages.slice().sort(function(a, b) {
        if (a.createdDate < b.createdDate) return -1;
        else if (a.createdDate > b.createdDate) return 1;
        else return 0;
      });
      const canned = sorted[sorted.length - 1];
      const bodyTemplate = template(canned.body);
      const subjectTemplate = template(canned.subject);

      const amount = (allocation && get(allocation, "investment.amount")) || 0;
      const investment_amount = toAmount(amount);

      const params = {
        investment_amount,
        deal
      };
      initialMessage = bodyTemplate(params);
      initialSubject = subjectTemplate(params);
    } catch (e) {
      console.error("Failed to parse canned_message template");
      console.error(e);
    }
  }

  const messageInput = useInputChange({
    initial: initialMessage
  });

  const canSubmit = !!messageInput.value;
  const handlePageSubmit = useCallback(() => {
    if (canSubmit) {
      submit({
        message: messageInput.value,
        subject: initialSubject
      });
    }
  });

  if (sending) {
    return (
      <ModalPage>
        <Spinner data-test="announcement_spinner" />
      </ModalPage>
    );
  } else {
    return (
      <ModalPage>
        <header styleName="header toplevel">
          <h2 styleName="h2">Send Announcement</h2>
          <ModalXButton onClick={cancel} data-test="announcement_cancel" />
        </header>
        <p styleName="toplevel">
          Email your investors regularly to keep them updated on the progress of
          this deal.
        </p>
        <form styleName="toplevel form" className="native_form">
          <textarea
            id="message"
            name="message"
            rows="8"
            style={{ width: "100%" }}
            data-test="announcement_message"
            placeholder="Enter your message here"
            {...messageInput.attrs}
          />
        </form>
        <Footer styleName="toplevel">
          <Button
            onClick={handlePageSubmit}
            disabled={!canSubmit}
            data-test="announcement_submit"
          >
            Send
          </Button>
        </Footer>
      </ModalPage>
    );
  }
};
/* eslint-enable max-len */

SendAnnouncement.propTypes = {
  asset: PropTypes.shape({
    canned_messages: PropTypes.array
  }).isRequired,
  deal: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  sending: PropTypes.bool.isRequired
};

SendAnnouncement.fragments = {
  asset: gql`
    fragment SendAnnouncementAsset on Asset {
      canned_messages {
        subject
        body
        createdDate
      }
    }
  `,
  allocation: gql`
    fragment SendAnnouncementAllocation on Allocation {
      investment {
        amount
      }
    }
  `,
  deal: gql`
    fragment SendAnnouncementDeal on Deal {
      name
    }
  `
};

export default SendAnnouncement;

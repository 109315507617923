const assert = require("assert");

const merge = require("lodash.merge");

const { get, gql } = require("./agent");

module.exports = (global_options) => {
  return {
    get: (id, local_options) => {
      const options = merge({}, global_options, local_options);
      return get(`/allocations/${id}`, options);
    },
    create: ({ allocation }, local_options) => {
      const options = merge({}, global_options, local_options);
      const query = `
        mutation M ($allocation: AllocationCreate) {
          allocation: create_allocation(allocation: $allocation) {
            id
            self
            entity {
              id
              self
            }
          }
        }
      `;
      return gql(query, { allocation }, options);
    },
    w9: (id, local_options) => {
      const options = merge({}, global_options, local_options);
      return get(`/allocations/${id}/w9`, options);
    },
    signed: (id, local_options) => {
      const options = merge({}, global_options, local_options);
      return get(`/allocations/${id}/signed`, options);
    },
    sign: (id, local_options) => {
      const options = merge({}, global_options, local_options);
      const mutation = `
        mutation M {
          allocation: sign_docs(id:  "${id}") {
            id
            documents {
              signed
            }
          }
        }
      `;
      return gql(mutation, null, options);
    },
    create_distribution: ({ distribution }, local_options) => {
      const options = merge({}, global_options, local_options);
      const mutation = `
        mutation M ($distribution: DistributionCreate!) {
          create_distribution(distribution: $distribution) {
            distribution {
              id
              self
              amount
              distributedDate
              distributedYear
              description
              owner {
                self
              }
              deal {
                self
              }
            }
          }
        }
      `;
      return gql(mutation, { distribution }, options);
    },
    recalc_metrics: ({ deal }, local_options) => {
      const options = merge({}, global_options, local_options);
      const mutation = `
      mutation RecalcAllocationMetrics ($deal_id: ID!) {
        recalc_allocation_metrics(deal_id: $deal_id) {
          allocation_count
          allocations {
            id
            projected {
              yield {
                gross
                asset_earned
                net
              }
              irr {
                gross
                asset_earned
                net
                breakdown {
                  year
                  amount
                }
              }
            }
            yield {
              gross
              asset_earned
              net
            }
            irr {
              gross
              asset_earned
              net
              breakdown {
                year
                amount
              }
            }
          }
        }
      }
      `;
      return gql(mutation, { deal_id: deal.id }, options);
    },
  };
};

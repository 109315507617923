exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../styles/box.css"), "");
exports.i(require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../styles/metrics.css"), "");

// Module
exports.push([module.id, ".web-flows-deal-tracker-___style__header___2N1f2 {\n  padding-top: 2em;\n}\n\n@media (max-width: 599px) {\n  .web-flows-deal-tracker-___style__header___2N1f2 {\n    padding-left: 1rem;\n  }\n}\n\n.web-flows-deal-tracker-___style__h2___2Wq4s {\n  padding-top: 0;\n}\n\n.web-flows-deal-tracker-___style__distributions_container___1DMu8 {\n  padding: 1rem;\n  margin-bottom: 0;\n}\n\n.web-flows-deal-tracker-___style__label___TYaKP {\n  margin: 0;\n}\n\n.web-flows-deal-tracker-___style__distributions_label___1_7Vj {\n  padding-top: 0;\n}\n", ""]);

// Exports
exports.locals = {
	"header": "web-flows-deal-tracker-___style__header___2N1f2",
	"h2": "web-flows-deal-tracker-___style__h2___2Wq4s",
	"distributions_container": "web-flows-deal-tracker-___style__distributions_container___1DMu8 " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../styles/box.css").locals["item"] + "",
	"label": "web-flows-deal-tracker-___style__label___TYaKP " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../styles/metrics.css").locals["bold"] + " " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../styles/metrics.css").locals["label"] + "",
	"distributions_label": "web-flows-deal-tracker-___style__distributions_label___1_7Vj web-flows-deal-tracker-___style__label___TYaKP " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../styles/metrics.css").locals["bold"] + " " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../styles/metrics.css").locals["label"] + ""
};
import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import { get_amount_from_alloc, toAmount } from "utils";

import "./style.css";

const commit = <li styleName="current">Commitments</li>;
const commitDescription = (
  <li styleName="description">
    <p styleName="description_text">
      You'll be able to share this opportunity with others once you commit
    </p>
  </li>
);

const precommitted = backing => (
  <li styleName="current">
    Pre-committed to invest{" "}
    <span styleName="current_focus">{toAmount(backing.amount)}</span>{" "}
  </li>
);
const precommittedDescription = (
  <li styleName="description">
    <p styleName="description_text">
      You need to confirm your commitment if you want to invest
    </p>
  </li>
);

const committed = allocation => <li styleName="done">Commitments</li>;
const committedDescription = (
  <li styleName="description">
    <p styleName="description_text">
      You can change your commitment until the deal closes
    </p>
  </li>
);
const finalizingDescription = (
  <li styleName="description">
    <p styleName="description_text">
      Investors have reviewed the opportunity and committed to invest
    </p>
  </li>
);

const invested = allocation => (
  <li styleName="done">Invested {get_amount_from_alloc(allocation)} </li>
);

const allocates = <li styleName="current">Equity allocation</li>;
const allocates_done = <li styleName="done">Equity allocation</li>;
const allocatesDescription = (
  <li styleName="description" data-test="equity_current">
    <p styleName="description_text">
      The sponsor matches commitments from individual and institutional
      investors to the required capital
    </p>
  </li>
);
const allocatedDescription = (
  <li styleName="description" data-test="equity_done">
    <p styleName="description_text">
      The sponsor has allocated each investor a portion of the equity available
      in this deal
    </p>
  </li>
);

const contracts = <li styleName="future">Contracts & funding</li>;
const contracts_current = <li styleName="current">Contracts & funding</li>;
const contracts_done = <li styleName="done">Contracts & funding</li>;
const contractsDescription = (
  <li styleName="description" data-test="contracts_future">
    <p styleName="description_text">
      You'll sign contracts and wire funds to establish your investment
    </p>
  </li>
);
const signingDescription = (
  <li styleName="description" data-test="contracts_current">
    <p styleName="description_text">
      To establish your investment sign the closing docs and wire funds to the
      sponsor
    </p>
  </li>
);
const signedDescription = (
  <li styleName="description" data-test="contracts_done">
    <p styleName="description_text">
      You've signed the contracts required to establish your investment
    </p>
  </li>
);

const asset = <li styleName="future">Asset acquisition</li>;
const asset_current = <li styleName="current">Asset acquisition</li>;
const assetDescription = (
  <li styleName="description" data-test="asset_future">
    <p styleName="description_text">
      Assuming the due diligence process concludes successfully, the sponsor
      acquires the asset using investor funds
    </p>
  </li>
);
const acquiringDescription = (
  <li styleName="description" data-test="asset_current">
    <p styleName="description_text">
      The sponsor is performing due diligence and will acquire the asset using
      investor funds
    </p>
  </li>
);

const income = <li styleName="future">Receive income</li>;
const incomeDescription = (
  <li styleName="description">
    <p styleName="description_text">
      You’ll receive distributions quarterly and tax documents annually until
      the investment is liquidated
    </p>
  </li>
);
const receiving = <li styleName="current">Receiving income</li>;

const considering = () => (
  <ul styleName="list">
    {commit}
    {commitDescription}
    {contracts}
    {contractsDescription}
    {income}
    {incomeDescription}
  </ul>
);

const backed = backing => (
  <ul styleName="list">
    {precommitted(backing)}
    {precommittedDescription}
    {contracts}
    {contractsDescription}
    {income}
    {incomeDescription}
  </ul>
);

const allocated = allocation => (
  <ul styleName="list">
    {committed(allocation)}
    {committedDescription}
    {contracts}
    {contractsDescription}
    {income}
    {incomeDescription}
  </ul>
);

const finalizing = allocation => (
  <ul styleName="list">
    {committed(allocation)}
    {finalizingDescription}
    {allocates}
    {allocatesDescription}
    {contracts}
    {contractsDescription}
    {asset}
    {assetDescription}
    {income}
    {incomeDescription}
  </ul>
);

const finalized = allocation => (
  <ul styleName="list">
    {committed(allocation)}
    {finalizingDescription}
    {allocates_done}
    {allocatedDescription}
    {contracts_current}
    {signingDescription}
    {asset}
    {assetDescription}
    {income}
    {incomeDescription}
  </ul>
);

const acquiring = allocation => (
  <ul styleName="list">
    {committed(allocation)}
    {finalizingDescription}
    {allocates_done}
    {allocatedDescription}
    {contracts_done}
    {signedDescription}
    {asset_current}
    {acquiringDescription}
    {income}
    {incomeDescription}
  </ul>
);

const closed = allocation => (
  <ul styleName="list">
    {invested(allocation)}
    {contracts_done}
    {receiving}
    {incomeDescription}
  </ul>
);

const HowToInvest = ({ deal: { status }, allocation, backing }) => {
  let result = null;

  if (status === "allocating") {
    if (allocation) {
      result = allocated(allocation);
    } else if (backing) {
      result = backed(backing);
    }
    {
      result = considering();
    }
  } else if (status === "finalizing" && allocation) {
    result = finalizing(allocation);
  } else if (status === "finalized" && allocation) {
    if (allocation.investment.funded && allocation.documents.signed) {
      result = acquiring(allocation);
    } else {
      result = finalized(allocation);
    }
  } else if (status === "closed" && allocation) {
    result = closed(allocation);
  }

  if (result) {
    result = <>{result}</>;
  }
  return result;
};

HowToInvest.propTypes = {
  deal: PropTypes.shape({
    status: PropTypes.string.isRequired
  }).isRequired,
  allocation: PropTypes.shape({
    investment: PropTypes.shape({
      amount: PropTypes.number,
      allocated: PropTypes.number,
      funded: PropTypes.bool.isRequired
    }).isRequired,
    documents: PropTypes.shape({
      signed: PropTypes.bool.isRequired
    }).isRequired
  })
};

HowToInvest.fragments = {
  backing: gql`
    fragment HowToInvestBacking on Backing {
      amount
    }
  `
};

export default HowToInvest;

import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import config from "config";

import "./style.css";

const DecisionDocs = ({ deal, asset, allocation }) => {
  let header = config.cove.brand.decisionDocsHeader;

  if (deal.status !== "allocating") {
    header = "Docs & Contracts";
  }

  return (
    <section styleName="container">
      <h2>{header}</h2>
      <dl styleName="list">
        {!!asset.links &&
          asset.links.length &&
          asset.links.map((link) => (
            <div>
              <dt>
                <a
                  href={link.link}
                  target="_blank"
                  data-test="decision_pitch_link"
                >
                  {link.title}
                </a>
              </dt>
              <dd styleName="description">{link.description}</dd>
            </div>
          ))}
        {!!asset.pitch && (
          <div>
            <dt>
              <a
                href={asset.pitch.link}
                target="_blank"
                data-test="decision_pitch_link"
              >
                Pitch Deck
              </a>
            </dt>
            <dd styleName="description">
              Pitch Deck detailing the opportunity
            </dd>
          </div>
        )}
        {!!deal.links &&
          deal.links.length &&
          deal.links.map((link) => (
            <div>
              <dt>
                <a
                  href={link.link}
                  target="_blank"
                  data-test="decision_pitch_link"
                >
                  {link.title}
                </a>
              </dt>
              <dd styleName="description">{link.description}</dd>
            </div>
          ))}
        {!!deal.memo && (
          <div>
            <dt>
              <a
                href={deal.memo.link}
                target="_blank"
                data-test="decision_memo_link"
              >
                Investment Memo
              </a>
            </dt>
            <dd styleName="description">
              Summary of the underwriter’s due diligence and analysis
            </dd>
          </div>
        )}
        {!!deal.model && (
          <div>
            <dt>
              <a
                href={deal.model.link}
                target="_blank"
                data-test="decision_model_link"
              >
                Financial Model
              </a>
            </dt>
            <dd styleName="description">
              Spreadsheet projecting the performance of the asset{" "}
            </dd>
          </div>
        )}
        {!!asset.memo && (
          <div>
            <dt>
              <a
                href={asset.memo.link}
                target="_blank"
                data-test="decision_asset_link"
              >
                Asset Memo
              </a>
            </dt>
            <dd styleName="description">
              Information about the asset shared by the seller
            </dd>
          </div>
        )}
        {!!asset.sponsor && (
          <div>
            <dt>
              <a
                href={asset.sponsor.link}
                target="_blank"
                data-test="decision_sponsor_link"
              >
                Sponsor Overview
              </a>
            </dt>
            <dd styleName="description">
              Sponsor&rsquo;s investment methodology and history
            </dd>
          </div>
        )}
        {deal.status === "finalized" && allocation && (
          <div>
            <dt>
              <a
                href={`/docs/deal/unsigned/${allocation.id}`}
                target="_blank"
                data-test="decision_unsigned_link"
              >
                Investment Contracts (unsigned)
              </a>
            </dt>
            <dd styleName="description">
              You'll receive counter-signed versions once the investment closes
            </dd>
          </div>
        )}
        {deal.status === "closed" && allocation && (
          <div>
            <dt>
              <a
                href={`/docs/allocation/signed/${allocation.id}`}
                target="_blank"
                data-test="decision_signed_link"
              >
                Investment Contracts
              </a>
            </dt>
            <dd styleName="description">
              Contracts establishing this investment
            </dd>
          </div>
        )}
        {(deal.status === "finalized" || deal.status === "closed") &&
          allocation && (
            <div>
              <dt>
                <a
                  href={`/docs/w9/${allocation.id}`}
                  target="_blank"
                  data-test="decision_unsigned_link"
                >
                  IRS Form W-9
                </a>
              </dt>
              <dd styleName="description">
                Identifies you for tax reporting purposes
              </dd>
            </div>
          )}
        {deal.status === "closed" && allocation && (
          <div>
            <dt>
              <a href={`/profile`} data-test="decision_tax_link">
                Tax Documents
              </a>
            </dt>
            <dd styleName="description">Available on your profile</dd>
          </div>
        )}
      </dl>
    </section>
  );
};

DecisionDocs.propTypes = {
  deal: PropTypes.shape({
    status: PropTypes.string.isRequired,
    memo: PropTypes.shape({
      link: PropTypes.string.isRequired,
    }),
    model: PropTypes.shape({
      link: PropTypes.string.isRequired,
    }),
  }).isRequired,
  asset: PropTypes.shape({
    sponsor: PropTypes.shape({
      link: PropTypes.string.isRequired,
    }),
    pitch: PropTypes.shape({
      link: PropTypes.string.isRequired,
    }),
  }).isRequired,
  allocation: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

DecisionDocs.fragments = {
  deal: gql`
    fragment DecisionDocsDeal on Deal {
      status
      memo {
        link
      }
      model {
        link
      }
      links {
        link
        title
        description
      }
    }
  `,
  asset: gql`
    fragment DecisionDocsAsset on Asset {
      sponsor {
        link
      }
      memo {
        link
      }
      pitch {
        link
      }
      links {
        link
        title
        description
      }
    }
  `,
  allocation: gql`
    fragment DecisionDocsAllocation on Allocation {
      id
    }
  `,
};

export default DecisionDocs;

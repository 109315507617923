exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/type.css"), "");
exports.i(require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/border.css"), "");

// Module
exports.push([module.id, ".web-components-WizardSteps-___style__steps___22k73 {\n  display: flex;\n  margin: 1.5rem 0;\n  font-size: 14pt;\n  padding: 0;\n  list-style-position: inside;\n}\n\n@media (max-width: 599px) {\n  .web-components-WizardSteps-___style__steps___22k73 {\n    display: flex;\n    justify-content: center;\n  }\n\n  .web-components-WizardSteps-___style__text___5UzYm {\n    display: none;\n  }\n}\n\n.web-components-WizardSteps-___style__item___3xqPg {\n  padding-bottom: 1rem;\n  padding-left: 0.2rem;\n  flex: 1;\n  margin: 0 0.5rem;\n}\n\n@media (min-width: 600px) {\n  .web-components-WizardSteps-___style__item___3xqPg:first-of-type {\n    margin-left: 0;\n  }\n\n  .web-components-WizardSteps-___style__item___3xqPg:last-of-type {\n    margin-right: 0;\n  }\n}\n\n@media (max-width: 599px) {\n  .web-components-WizardSteps-___style__item___3xqPg {\n    flex-basis: auto;\n    flex-grow: unset;\n    border: none;\n    list-style: none;\n    background-color: #dadade;\n    width: 1rem;\n    height: 1rem;\n    border-radius: 50%;\n    padding: 0;\n    transition: color 200ms ease, border 200ms ease;\n  }\n}\n\n.web-components-WizardSteps-___style__current___1Ol0a {\n}\n\n.web-components-WizardSteps-___style__done___FkTYD {\n}\n\n@media (max-width: 599px) {\n  .web-components-WizardSteps-___style__done___FkTYD {\n    background-color: var(--ui-highlight-colour);\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"steps": "web-components-WizardSteps-___style__steps___22k73 " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/type.css").locals["light"] + "",
	"text": "web-components-WizardSteps-___style__text___5UzYm",
	"item": "web-components-WizardSteps-___style__item___3xqPg " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/border.css").locals["bottom"] + "",
	"current": "web-components-WizardSteps-___style__current___1Ol0a " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/type.css").locals["dark"] + " " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/border.css").locals["bottom_green"] + "",
	"done": "web-components-WizardSteps-___style__done___FkTYD " + require("-!../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../styles/border.css").locals["bottom_green"] + ""
};
import React from "react";
import PropTypes from "prop-types";

import { toAmount, toRoundedAmount } from "utils";

import "./style.css";

const DistributionsTotal = ({ distributions }) => {
  let total_actual = 0;
  let total_projected = 0;

  let total_asset_earned = 0;
  let total_perf_alloc_earned = 0;

  distributions.forEach(distro => {
    total_actual += distro.breakdown.net;
    total_projected += distro.projected.net;
    if (distro.breakdown) {
      total_asset_earned += distro.breakdown.asset_earned;
      total_perf_alloc_earned += distro.breakdown.perf_alloc_earned;
    }
  });

  return (
    <section styleName="container">
      {!!total_perf_alloc_earned && !!total_asset_earned && (
        <>
          <section>
            <p styleName="breakdown" data-test="tracker_asset_earned">
              {toAmount(total_asset_earned)}
            </p>
            <p styleName="subnote">from asset</p>
          </section>
          <section styleName="icon_container">
            <p styleName="icon">&nbsp;+&nbsp;</p>
          </section>
          <section>
            <p styleName="breakdown" data-test="tracker_perf_alloc_earned">
              {toAmount(total_perf_alloc_earned)}
            </p>
            <p styleName="subnote">from inviting</p>
          </section>
          <section styleName="icon_container">
            <p styleName="icon">&nbsp;→&nbsp;</p>
          </section>
        </>
      )}
      <section styleName="total_container">
        <p styleName="total" data-test="tracker_total_actual">
          {toAmount(total_actual)}
        </p>
        <p styleName="subnote">Total earned</p>
      </section>
      <section>
        <p styleName="projected" data-test="tracker_total_projected">
          {toRoundedAmount(total_projected)}
        </p>
        <p styleName="subnote">Total projected</p>
      </section>
    </section>
  );
};

DistributionsTotal.propTypes = {
  distributions: PropTypes.arrayOf(
    PropTypes.shape({
      breakdown: PropTypes.shape({
        net: PropTypes.number.isRequired
      }).isRequired,
      projected: PropTypes.shape({
        net: PropTypes.number.isRequired
      }).isRequired,
      breakdown: PropTypes.shape({
        asset_earned: PropTypes.number.isRequired,
        perf_alloc_earned: PropTypes.number.isRequired
      }).isRequired
    })
  ).isRequired
};

export default DistributionsTotal;

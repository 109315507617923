exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/metrics.css"), "");
exports.i(require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css"), "");

// Module
exports.push([module.id, ".web-flows-deal-tracker-components-totals-___style__container___2mLLD {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.web-flows-deal-tracker-components-totals-___style__container___2mLLD > section {\n  margin-right: 0.5rem;\n  margin-bottom: 1rem;\n}\n\n.web-flows-deal-tracker-components-totals-___style__total_container___RUy2D {\n  margin-right: 1rem !important;\n}\n\n@media (max-width: 800px) {\n  .web-flows-deal-tracker-components-totals-___style__container___2mLLD > section {\n    margin-right: 1rem;\n  }\n\n  .web-flows-deal-tracker-components-totals-___style__icon_container___3DZ_L {\n    display: none;\n  }\n}\n.web-flows-deal-tracker-components-totals-___style__breakdown___241mE,\n.web-flows-deal-tracker-components-totals-___style__icon___cfSj3 {\n  font-weight: 300;\n}\n\n.web-flows-deal-tracker-components-totals-___style__total___1XKcv {\n}\n\n.web-flows-deal-tracker-components-totals-___style__projected___38N8- {\n}\n\n.web-flows-deal-tracker-components-totals-___style__subnote___3-nHQ {\n}\n", ""]);

// Exports
exports.locals = {
	"container": "web-flows-deal-tracker-components-totals-___style__container___2mLLD",
	"total_container": "web-flows-deal-tracker-components-totals-___style__total_container___RUy2D",
	"icon_container": "web-flows-deal-tracker-components-totals-___style__icon_container___3DZ_L",
	"breakdown": "web-flows-deal-tracker-components-totals-___style__breakdown___241mE " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/metrics.css").locals["amount"] + " " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css").locals["light"] + "",
	"icon": "web-flows-deal-tracker-components-totals-___style__icon___cfSj3 " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/metrics.css").locals["amount"] + " " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css").locals["light"] + "",
	"total": "web-flows-deal-tracker-components-totals-___style__total___1XKcv " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/metrics.css").locals["amount"] + " " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css").locals["green"] + "",
	"projected": "web-flows-deal-tracker-components-totals-___style__projected___38N8- " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/metrics.css").locals["amount"] + " " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/type.css").locals["blue"] + "",
	"subnote": "web-flows-deal-tracker-components-totals-___style__subnote___3-nHQ " + require("-!../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../styles/metrics.css").locals["sub-note"] + ""
};
import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { graphql } from "utils/apollo";
import mutateProps from "utils/mutateProps";

import { selectDealAllocations } from "flows/deal/allocate/reducer";
import { mutation, saveProps } from "flows/deal/allocate";

import AllocateSave from "./component";

export const AllocateSaveContainer = ({
  save,
  saveLoading,
  allocations,
  deal
}) => (
  <AllocateSave
    save={save}
    saveLoading={saveLoading}
    allocations={allocations}
    deal={deal}
  />
);

AllocateSaveContainer.propTypes = {
  allocations: PropTypes.object.isRequired,
  saveLoading: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  deal: PropTypes.object.isRequired
};

export const mapStore = store => ({
  allocations: selectDealAllocations(store)
});

const mapMutation = props => ({
  save: saveProps(props)
});

export default compose(
  connect(mapStore),
  graphql(mutation, {
    props: mapMutation
  }),
  mutateProps({ name: "save" })
)(AllocateSaveContainer);

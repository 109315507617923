import { filter as filter_fragment } from "graphql-anywhere";
import config from "config";
import ApolloClient from "apollo-boost";
import { graphql as graphql_ } from "@apollo/react-hoc";

// Add Auth header to GraphQL calls
const request = operation => {
  const authResult = JSON.parse(localStorage.getItem("authResult"));
  operation.setContext({
    headers: {
      authorization: authResult ? `Bearer ${authResult.accessToken}` : ""
    }
  });
};

// Log and record server errors in Sentry
const onError = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      );
      window.Raven &&
        window.Raven.captureException(
          new Error(`[GraphQL error]: Message: ${message}`),
          {
            extra: {
              message,
              locations,
              path
            }
          }
        );
    });

  if (networkError) {
    console.log(`[Network error]: ${JSON.stringify(networkError)}`);
    window.Raven &&
      window.Raven.captureException(
        new Error(`[Network error]: ${JSON.stringify(networkError)}`),
        {
          extra: {
            networkError
          }
        }
      );
  }
};

const apollo = new ApolloClient({
  uri: `${config.cove.api}/graphql`,
  request,
  onError
});

/*
  For some reason running in jest sometimes causes
  querires being imported to be undefined, this detects
  that case and silently fails it.
  Since we only ever unit test the non HOC'd versions of components
  this is ok.
*/
export const graphql = (doc, options) => {
  if (doc === undefined && process.env.NODE_ENV === "test") {
    return c => c;
  } else {
    return graphql_(doc, options);
  }
};

export const fragments = (View, data) => {
  if (View.fragments) {
    const result = {};
    for (const attr in data) {
      if (View.fragments.hasOwnProperty(attr)) {
        const filtered_data = filter_fragment(View.fragments[attr], data[attr]);
        result[attr] = filtered_data;
      }
    }
    return result;
  } else {
    return data;
  }
};

export default apollo;

import { defineAction } from "redux-define";
import { createAction } from "redux-actions";
import browserHistory from "utils/history";
import { put, takeLatest, select, take, call } from "redux-saga/effects";

import { goAfterLogin } from "utils/AuthService";

const namespace = "flows/lead/invite";

// Actions
const LEAD_INVITE = defineAction(
  "LEAD_INVITE",
  ["SUBMIT", "LOAD_ERROR"],
  namespace
);

export const submitLeadInvite = createAction(LEAD_INVITE.SUBMIT);

// Lead Invite Saga
export function* leadInviteSaga({ payload }) {
  const { secret, id } = payload;

  // Take them to the login page, with a post-login redirect
  yield call(goAfterLogin, `/lead/deals/${id}/invites/${secret}`, true);
  yield call(browserHistory.push, "/login");
}

export function* sagas() {
  yield takeLatest(LEAD_INVITE.SUBMIT, leadInviteSaga);
}

import React from "react";
import PropTypes from "prop-types";

import "./Button.css";

const Button = props => (
  <button {...props} styleName="button blue">
    {props.children}
  </button>
);

Button.propTypes = {
  children: PropTypes.node.isRequired
};

export default Button;

export const GreyButton = props => (
  <button {...props} styleName="button grey">
    {props.children}
  </button>
);

GreyButton.propTypes = {
  children: PropTypes.node.isRequired
};

export const BigRedButton = props => (
  <button {...props} styleName="button big red">
    {props.children}
  </button>
);

BigRedButton.propTypes = {
  children: PropTypes.node.isRequired
};

export const BigBlueButton = props => (
  <button {...props} styleName="button big blue">
    {props.children}
  </button>
);

BigBlueButton.propTypes = {
  children: PropTypes.node.isRequired
};

export const WhiteButton = props => (
  <button {...props} styleName="button white">
    {props.children}
  </button>
);

WhiteButton.propTypes = {
  children: PropTypes.node.isRequired
};

export const ModalXButton = props => (
  <button {...props} styleName="button modalx">
    &times; close
  </button>
);

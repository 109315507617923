import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { identity } from "lodash";
import { useStateChange } from "utils";

import Spinner from "components/Spinner";
import { ModalXButton } from "components/Button";

import {
  EDIT_PROFILE_QUERY,
  EDIT_PROFILE_MUTATION,
  mutationProps
} from "./graphql";
import "./EditUserProfile.css";

import EditUserForm from "./components/EditUserForm";

const EditUserProfile = ({
  header,
  body,
  submitText,
  onCancel,
  onSaved,
  allowCancel
}) => {
  // GQL
  const { loading, data } = useQuery(EDIT_PROFILE_QUERY);
  const [editMutation, { loading: saving }] = useMutation(
    EDIT_PROFILE_MUTATION
  );

  // Render loading
  if (loading || saving) {
    return <Spinner data-test="edit_user_spinner" />;
  }

  const onSubmit = ({ firstName, lastName, email, id }) => {
    (async () => {
      const result = await editMutation(
        mutationProps({
          user: { firstName, lastName, email },
          id
        })
      );
      onSaved(result);
    })();
  };

  // Render loaded

  return (
    <>
      <header styleName="header toplevel">
        {header}
        {!!allowCancel && (
          <ModalXButton onClick={onCancel} data-test="edit_user_cancel" />
        )}
      </header>
      <section styleName="content toplevel">
        {body}
        <EditUserForm
          initialValues={data.userinfo}
          {...{ onSubmit, submitText }}
        />
      </section>
    </>
  );
};

EditUserProfile.propTypes = {
  header: PropTypes.element.isRequired,
  body: PropTypes.element,
  submitText: PropTypes.elementType.isRequired,
  onCancel: PropTypes.func,
  onSaved: PropTypes.func,
  allowCancel: PropTypes.bool.isRequired
};

export default EditUserProfile;
export const graphql = {
  EDIT_PROFILE_QUERY,
  EDIT_PROFILE_MUTATION
};

import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import { renderPercentage } from "utils";

const WillReceive = ({ deal }) => {
  const carry = renderPercentage(deal.metrics.carry);
  const performanceAllocation = renderPercentage(
    deal.metrics.performanceAllocation
  );

  const style = { fontWeight: "500" };
  return (
    <>
      You’ll receive <strong style={style}>{performanceAllocation}</strong> of
      the profits from the capital you raise until it earns 10% IRR and{" "}
      <strong style={style}>{carry}</strong> of the profits above that
    </>
  );
};

WillReceive.propTypes = {
  deal: PropTypes.shape({
    metrics: PropTypes.shape({
      carry: PropTypes.number.isRequired,
      performanceAllocation: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};

WillReceive.fragments = {
  deal: gql`
    fragment WillReceiveDeal on Deal {
      metrics {
        carry
        performanceAllocation
      }
    }
  `
};

export default WillReceive;

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { graphql, mutateProps, toAmount } from "utils";
import { handleError as error } from "api/utils";
import moment from "moment";

import { selectUserId, selectUserSelf } from "flows/session/selectors";
import Pulse, { ButtonPulse, PulseGray } from "components/Pulse";
import Button from "components/Button";
import { SliddenDiv, PoseGroup } from "utils/posed";

import { query, mapPropsToOptions, mutation, mapMutation } from "./graphql";
import "./style.css";

const PreCommitment = ({ backing }) => (
  <div styleName="backing_container">
    <dd styleName="backing_label">Pre-commitment</dd>
    <dt styleName="backing_amount" data-test="invest_precommitment">
      {toAmount(backing.amount)}
    </dt>
  </div>
);

const MobilePulse = () => (
  <div styleName="mobile_pulse">
    <PulseGray />
  </div>
);

const NotifyLink = ({ updating, hiddenOnDesktop, onClick }) => (
  <div className={hiddenOnDesktop ? "only_mobile" : "hidden"}>
    {!!updating ? <MobilePulse /> : <a onClick={onClick}>Notify Me</a>}
  </div>
);

NotifyLink.propTypes = {
  updating: PropTypes.bool.isRequired,
  hiddenOnDesktop: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

const NotifyButton = ({ updating, hiddenOnMobile, onClick }) => (
  <div
    styleName="button_container"
    className={hiddenOnMobile ? "no_mobile" : undefined}
  >
    <Button data-test="deal_notify" onClick={onClick} disabled={updating}>
      {updating ? <ButtonPulse /> : "Notify Me"}
    </Button>
  </div>
);

NotifyButton.propTypes = {
  updating: PropTypes.bool.isRequired,
  hiddenOnMobile: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

const TimelineHeader = () => (
  <div className="no_mobile" styleName="timeline_label">
    Timeline
  </div>
);

const WillOpenIn = ({ asset }) => {
  const openingDate = moment(asset.openingDate);
  let open_in = openingDate.fromNow();
  if (openingDate.isBefore(new Date())) {
    open_in = "in 1 day";
  }
  return (
    <div>
      Will open <span styleName="duration">{open_in}</span>
    </div>
  );
};

WillOpenIn.propTypes = {
  asset: PropTypes.shape({
    openingDate: PropTypes.string.isRequired
  }).isRequired
};

const WillBeNotified = () => (
  <div styleName="timeline_notified">
    <img src="/images/tick.svg" styleName="notified_icon" alt="" />
    <span>You'll be notified</span>
  </div>
);

const Timeline = ({ backing, asset_interest, asset, updating, onNotify }) => {
  const styleName = !!backing ? "timeline_left" : "timeline_centered";
  return (
    <div styleName={styleName}>
      <PoseGroup>
        {!!backing && !asset_interest && (
          <SliddenDiv key="h">
            <TimelineHeader />
          </SliddenDiv>
        )}
        <WillOpenIn asset={asset} key="o" />
        {!asset_interest && (
          <SliddenDiv key="l">
            <NotifyLink
              hiddenOnDesktop={!!backing}
              updating={updating}
              onClick={onNotify}
            />
          </SliddenDiv>
        )}
        {!!asset_interest && (
          <SliddenDiv key="d">
            <WillBeNotified />
          </SliddenDiv>
        )}
      </PoseGroup>
    </div>
  );
};

const AssetNotOpen = ({
  asset,
  backing,
  data: { loading, deal },
  notify,
  notifyLoading,
  error
}) => {
  if (loading) {
    return <Pulse />;
  }

  const { asset_interest } = deal;

  const notifyMe = useCallback(() => {
    notify().catch(error);
  }, [notify, error]);

  return (
    <section styleName={!!backing ? "container" : "container no_backing"}>
      <PoseGroup>
        {!!backing && <PreCommitment backing={backing} key="p" />}
        <Timeline
          asset={asset}
          backing={backing}
          asset_interest={asset_interest}
          updating={notifyLoading}
          onNotify={notifyMe}
          key="t"
        />
        {!asset_interest && (
          <SliddenDiv key="b">
            <NotifyButton
              hiddenOnMobile={!!backing}
              updating={notifyLoading}
              onClick={notifyMe}
            />
          </SliddenDiv>
        )}
      </PoseGroup>
    </section>
  );
};

AssetNotOpen.propTypes = {
  deal: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  asset: PropTypes.shape({
    openingDate: PropTypes.string.isRequired
  }).isRequired,
  backing: PropTypes.shape({
    amount: PropTypes.number.isRequired
  }),
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    deal: PropTypes.shape({
      asset_interest: PropTypes.shape({
        id: PropTypes.string
      })
    })
  }),
  notify: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired
};

export const mapStore = store => ({
  userId: selectUserId(store),
  user_self: selectUserSelf(store)
});

export default compose(
  connect(
    mapStore,
    { error }
  ),
  graphql(query, {
    options: mapPropsToOptions
  }),
  graphql(mutation, {
    props: mapMutation
  }),
  mutateProps({ name: "notify" })
)(AssetNotOpen);

import React from "react";
import {
  GoogleLoginButton,
  FacebookLoginButton,
} from "react-social-login-buttons";
import { useAuth0 } from "@auth0/auth0-react";
import config from "config";

import "./Login.css";

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div styleName="fullscreen">
      <div styleName="container">
        <div styleName="login-box">
          <div styleName="logo">
            <img alt="" src={config.cove.brand.loginLogo} />
          </div>
          <div styleName="buttons">
            {config.cove.brand.auth0Connections.includes("facebook") && (
              <FacebookLoginButton
                onClick={() => loginWithRedirect({ connection: "facebook" })}
                iconSize="20px"
                style={{
                  border: "1px solid #dee0e2",
                  boxShadow: "none",
                  fontSize: "14px",
                  borderRadius: "4px",
                  transition: "all 200ms ease",
                  margin: "0px 5px 10px 5px",
                  height: "40px",
                  fontWeight: "500",
                }}
                className="facebook-login"
              />
            )}
            {config.cove.brand.auth0Connections.includes("google-oauth2") && (
              <GoogleLoginButton
                onClick={() =>
                  loginWithRedirect({ connection: "google-oauth2" })
                }
                iconSize="20px"
                style={{
                  border: "1px solid #dee0e2",
                  boxShadow: "none",
                  fontSize: "14px",
                  borderRadius: "4px",
                  transition: "all 200ms ease",
                  margin: "0px 5px 10px 5px",
                  height: "40px",
                  fontWeight: "500",
                }}
                className="google-login"
              />
            )}
          </div>
        </div>
        <p className="login-tos">
          By logging in, you agree to our&nbsp;
          <a href={config.cove.brand.toslink} target="_blank" rel="noreferrer">
            terms of use
          </a>
          &nbsp;and&nbsp;
          <a
            href={config.cove.brand.privacylink}
            target="_blank"
            rel="noreferrer"
          >
            privacy policy
          </a>
        </p>
      </div>
    </div>
  );
};

Login.propTypes = {};

export default Login;

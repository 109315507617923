import { fork } from "redux-saga/effects";
import { combineReducers } from "redux";

import { sagas as dealAllocate } from "./allocate";
import { sagas as dealAnnounce } from "./announce";
import { sagas as dealStatus } from "./status";

import { reducer as allocateR } from "./allocate/reducer";
import { reducer as announceR } from "./announce/reducer";
import { reducer as statusR } from "./status/reducer";

export function* sagas() {
  yield fork(dealAnnounce);
  yield fork(dealAllocate);
  yield fork(dealStatus);
}

export const reducer = combineReducers({
  allocate: allocateR,
  announce: announceR,
  status: statusR
});

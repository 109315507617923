import React, { useCallback, useReducer } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import Page from "components/Page";
import Button from "components/Button";
import Footer from "components/Footer";
import Accreditation from "components/Accreditation";
import QualifiedPurchaser from "components/QualifiedPurchaser";

export const InterestAccreditation = ({
  state: { investorType, investorName, accredited, qualified },
  onSubmit,
  onChange,
  asset: { investorCheck }
}) => {
  const canSubmit =
    (investorCheck === "qualified_purchaser" && !!qualified) ||
    (investorCheck === "accredited_investor" && !!accredited);

  return (
    <Page style={{ paddingTop: "1rem" }}>
      {investorCheck === "accredited_investor" && (
        <Accreditation
          onChange={onChange}
          value={accredited}
          type={investorType}
          name={investorName}
        />
      )}
      {investorCheck === "qualified_purchaser" && (
        <QualifiedPurchaser
          onChange={onChange}
          value={qualified}
          type={investorType}
          name={investorName}
        />
      )}
      <Footer>
        <Button
          onClick={onSubmit}
          disabled={!canSubmit}
          data-test="accreditation_submit"
        >
          Continue
        </Button>
      </Footer>
    </Page>
  );
};

InterestAccreditation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  state: PropTypes.shape({
    investorType: PropTypes.string.isRequired,
    investorName: PropTypes.string.isRequired,
    accredited: PropTypes.strings
  }).isRequired,
  asset: PropTypes.shape({
    investorCheck: PropTypes.string
  }).isRequired
};

InterestAccreditation.fragments = {
  asset: gql`
    fragment InterestAccreditationAsset on Asset {
      investorCheck
    }
  `,
  entity: gql`
    fragment InterestAccreditationEntity on Entity {
      accreditation {
        type
        accreditationDate
      }
      qualifiedPurchaser {
        type
        accreditationDate
      }
    }
  `
};

export default InterestAccreditation;

import React from "react";

import "./style.css";

export const PulseWhite = () => <div styleName="dot-pulse-white" />;
export const PulseGray = () => <div styleName="dot-pulse-gray" />;
export const PulseContainer = ({ children }) => (
  <div styleName="container">{children}</div>
);
const Pulse = () => (
  <PulseContainer>
    <PulseGray />
  </PulseContainer>
);

export const ButtonPulseContainer = ({ children }) => (
  <div styleName="button_container">{children}</div>
);
export const ButtonPulseGray = () => <div styleName="dot-pulse_button" />;
export const ButtonPulse = () => (
  <ButtonPulseContainer>
    <ButtonPulseGray />
  </ButtonPulseContainer>
);

export default Pulse;

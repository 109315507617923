import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { graphql } from "utils/apollo";
import gql from "graphql-tag";

import "./style.css";

const PictureHeader = ({ image, checkmark, title, withBorder }) => {
  return (
    <header styleName="container">
      <div styleName="pic">
        <img src={image} alt="" styleName={withBorder ? "border" : ""} />
      </div>
      <h2 styleName="title" data-test="picture_header">
        {title}
      </h2>
    </header>
  );
};

PictureHeader.propTypes = {
  image: PropTypes.string.isRequired,
  checkmark: PropTypes.bool,
  title: PropTypes.string.isRequired,
  withBorder: PropTypes.bool
};

export default PictureHeader;

exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../styles/type.css"), "");

// Module
exports.push([module.id, ".web-flows-deal-updates-___style__header___8uD4P {\n  padding-top: 2em;\n}\n\n@media (max-width: 599px) {\n  .web-flows-deal-updates-___style__header___8uD4P {\n    padding-left: 1rem;\n  }\n}\n\n.web-flows-deal-updates-___style__h2___2_A6G {\n  padding-top: 0;\n}\n\n.web-flows-deal-updates-___style__table___kw18s {\n  display: flex;\n  flex-flow: column nowrap;\n  justify-content: space-between;\n}\n\n.web-flows-deal-updates-___style__label___3fY_O {\n  padding-right: 0.33em;\n}\n\n.web-flows-deal-updates-___style__show_more___3iTUn {\n  text-align: center;\n  font-size: 14pt;\n}\n", ""]);

// Exports
exports.locals = {
	"header": "web-flows-deal-updates-___style__header___8uD4P",
	"h2": "web-flows-deal-updates-___style__h2___2_A6G",
	"table": "web-flows-deal-updates-___style__table___kw18s",
	"label": "web-flows-deal-updates-___style__label___3fY_O " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../styles/type.css").locals["light"] + "",
	"show_more": "web-flows-deal-updates-___style__show_more___3iTUn"
};
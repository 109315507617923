exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../styles/type.css"), "");

// Module
exports.push([module.id, ".web-flows-invest-backing-___style__loadContainer___1ZFwm {\n  text-align: center;\n}\n\n.web-flows-invest-backing-___style__confirmationContainer___2XyAQ {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@media (max-width: 599px) {\n  .web-flows-invest-backing-___style__confirmationContainer___2XyAQ {\n    padding: 0 1rem;\n    flex-direction: column;\n    text-align: center;\n  }\n  .web-flows-invest-backing-___style__checkmark___cZG9z {\n    margin-bottom: 1rem;\n  }\n}\n\n.web-flows-invest-backing-___style__checkmark___cZG9z {\n  width: 50px;\n  height: 50px;\n}\n\n.web-flows-invest-backing-___style__checkmark___cZG9z img {\n  width: 50px;\n  height: 50px;\n  border-radius: 100%;\n  background-position: center;\n  background-size: cover;\n}\n\n.web-flows-invest-backing-___style__confirmed___1bUmT {\n  font-size: 16pt;\n  padding: 0 1rem;\n}\n", ""]);

// Exports
exports.locals = {
	"loadContainer": "web-flows-invest-backing-___style__loadContainer___1ZFwm",
	"confirmationContainer": "web-flows-invest-backing-___style__confirmationContainer___2XyAQ",
	"checkmark": "web-flows-invest-backing-___style__checkmark___cZG9z",
	"confirmed": "web-flows-invest-backing-___style__confirmed___1bUmT " + require("-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../styles/type.css").locals["dark"] + ""
};
import { defineAction } from "redux-define";
import { createAction, handleActions } from "redux-actions";
import { call, put, takeEvery, select } from "redux-saga/effects";
import apollo from "utils/apollo";
import Covert from "covert";
import config from "config";
import get from "lodash/get";
import gql from "graphql-tag";

import { selectToken } from "./utils";

const api = config.cove.api;
const CoveApi = Covert({ api });

// API Calls
const getDealUnsignedApi = (id, token) => CoveApi.deals.unsigned(id, { token });

// Get Deal Unsgined agreement URL Saga
export function* getDealUnsignedURLSaga({ payload }) {
  const { id } = payload;
  const token = yield select(selectToken);
  try {
    const { url } = yield call(getDealUnsignedApi, id, token);
    return url;
  } catch (error) {
    throw error;
  }
}

export const dealNameQuery = gql`
  query GetDealName($dealId: ID!) {
    deal(id: $dealId) {
      id
      name
      self
    }
  }
`;
export function* queryDealName(dealId) {
  const result = yield call([apollo, apollo.query], {
    query: dealNameQuery,
    variables: {
      dealId
    }
  });

  return result.data.deal.name;
}

import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";

import { toAmount, calculator, renderPoints } from "utils";
import { selectUserSelf } from "flows/session/selectors";

import "./style.css";

const CouldEarnMore = ({
  deal,
  invite,
  backing,
  asset,
  user_self,
  investment,
  raise
}) => {
  const {
    metrics: { syndicateExit }
  } = deal;

  let localRaise = raise;

  if (raise <= 0) {
    localRaise = investment * 2;
  }

  const { inviteReturnDelta, inviteIRR } = calculator({
    deal,
    invite,
    backing,
    asset,
    user_self,
    investment,
    raise: localRaise
  });

  const amount = Math.round(inviteReturnDelta) * 100;

  return (
    <p styleName="could_earn_text">
      You could earn{" "}
      <span styleName="could_earn_amount" data-test="could_earn_amount">
        {toAmount(amount)}
      </span>{" "}
      ({renderPoints(inviteIRR)} IRR) more by inviting two people to invest with
      you
    </p>
  );
};

CouldEarnMore.propTypes = {
  deal: PropTypes.shape({
    metrics: PropTypes.shape({
      syndicateExit: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  invite: PropTypes.object.isRequired,
  backing: PropTypes.object,
  asset: PropTypes.object.isRequired,
  investment: PropTypes.number.isRequired,
  user_self: PropTypes.string.isRequired,
  raise: PropTypes.number.isRequired
};

export const mapStore = store => ({
  user_self: selectUserSelf(store)
});

export default compose(connect(mapStore))(CouldEarnMore);

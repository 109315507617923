import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

import config from "config";
import { get_amount_from_alloc, toAmount } from "utils";

import "./style.css";

export default class Opportunity extends React.PureComponent {
  constructor() {
    super();
    this.manage = this.manage.bind(this);
    this.interest = this.interest.bind(this);
    this.invest = this.invest.bind(this);
    this.closing = this.closing.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  manage(e) {
    if (e) {
      e.stopPropagation();
    }
    const { deal, manage } = this.props;
    return manage({ deal });
  }
  interest(e) {
    if (e) {
      e.stopPropagation();
    }
    const { invite, deal, interest } = this.props;
    interest({ deal, invite });
  }
  invest(e) {
    if (e) {
      e.stopPropagation();
    }
    const { invite, invest } = this.props;
    return invest({ allocation: invite.allocation });
  }
  closing(e) {
    if (e) {
      e.stopPropagation();
    }
    const { invite, closing } = this.props;
    return closing({ deal: invite.deal });
  }
  renderNeedsSign(options) {
    let test = "home_invest";
    if (options && options.inline) {
      test = "home_invest_inline";
    }
    return (
      <p styleName="detail_body waiting" onClick={this.invest} data-test={test}>
        <a>Sign investment agreement</a>
      </p>
    );
  }
  renderSigned(options) {
    let test = "opportunity_signed";
    if (options && options.inline) {
      test = "opportunity_signed_inline";
    }
    return (
      <p styleName="detail_body done" data-test={test}>
        Contracts signed
      </p>
    );
  }
  renderNeedsFunding(options) {
    const { invite, deal } = this.props;
    const { allocation } = invite;
    const amount = get_amount_from_alloc(allocation);
    let test = "home_closing";
    if (options && options.inline) {
      test = "home_closing_inline";
    }
    if (deal.skipWiring) {
      return null;
    }
    return (
      <p
        styleName="detail_body waiting"
        onClick={this.closing}
        data-test={test}
      >
        <a>Wire contribution</a> ({amount})
      </p>
    );
  }
  renderFunded(options) {
    const { invite } = this.props;
    const { allocation } = invite;
    const amount = get_amount_from_alloc(allocation);
    let test = "opportunity_received";
    if (options && options.inline) {
      test = "opportunity_received_inline";
    }
    return (
      <p styleName="detail_body done" data-test={test}>
        Contribution received ({amount})
      </p>
    );
  }
  renderCommitment(options) {
    const { invite } = this.props;
    const { allocation } = invite;
    if (allocation) {
      const amount = get_amount_from_alloc(allocation);
      let test = "home_interest";
      if (options && options.inline) {
        test = "home_interest_inline";
      }
      return (
        <p styleName="detail_body done" onClick={this.manage} data-test={test}>
          Your commitment:{" "}
          <span styleName="green" data-test="opportunity_commitment">
            {amount}
          </span>
        </p>
      );
    }
  }
  renderSponsorFinalizing() {
    return (
      <p
        styleName="detail_body waiting"
        data-test="opportunity_sponsor_finalizing"
      >
        Sponsor is allocating between investors
      </p>
    );
  }
  renderSponsorFinalized() {
    return (
      <p
        styleName="detail_body waiting"
        data-test="opportunity_sponsor_finalized"
      >
        Sponsor is closing on asset acquisition
      </p>
    );
  }
  getFinalizedStatus(options) {
    const { invite } = this.props;
    const { allocation } = invite;
    const { signed } = allocation.documents;
    const { funded } = allocation.investment;

    if (signed && funded) {
      return {
        main: this.renderSponsorFinalized(options),
        sub: this.renderSigned(options),
      };
    } else if (!signed && funded) {
      return {
        main: this.renderNeedsSign(options),
        sub: this.renderFunded(options),
      };
    } else if (signed && !funded) {
      return {
        main: this.renderNeedsFunding(options),
        sub: this.renderSigned(options),
      };
    } else if (!signed && !funded) {
      return {
        main: this.renderNeedsSign(options),
        sub: this.renderNeedsFunding(options),
      };
    }
  }
  getMainStatus(options) {
    const { invite, deal, backing } = this.props;

    let result;

    if (invite.allocation) {
      const { allocation } = invite;
      const amount = get_amount_from_alloc(allocation);

      if (deal.status === "allocating") {
        // Change committment
        result = (
          <p
            styleName="detail_body"
            onClick={this.manage}
            data-test="home_interest"
          >
            Investing{" "}
            <span styleName="green" data-test="opportunity_commitment">
              {amount}
            </span>
          </p>
        );
      } else if (deal.status === "finalizing") {
        // See committment
        result = this.renderSponsorFinalizing(options);
      } else if (deal.status === "finalized") {
        const finalized = this.getFinalizedStatus(options);
        result = finalized.main;
      }
    } else if (backing) {
      result = (
        <p styleName="detail_body">
          Pre-committed to invest{" "}
          <span styleName="green" data-test="opportunity_precommitment">
            {toAmount(backing.amount)}
          </span>
        </p>
      );
    }

    return result;
  }
  getStatus(options) {
    const { invite, deal, backing } = this.props;
    let result;
    let sub;

    if (invite.type === "lead") {
      let person_status = "investors";
      let person_count = deal.allocationCount || 0;
      if (deal.status === "allocating") {
        person_status = "people invited";
        person_count = deal.inviteCount || 0;
      }

      // Link to manage deal
      sub = (
        <p
          styleName="detail_body"
          onClick={this.manage}
          data-test="home_manage"
        >
          <span styleName="blue">{person_count}</span> {person_status}
        </p>
      );

      // If they have their own investment in this deal, handle it
      if (invite.allocation || backing) {
        result = this.getMainStatus(options);

        if (deal.status === "finalizing") {
          // See committment
          sub = this.renderCommitment(options);
        }
      } else {
        result = sub;
        sub = null;
      }
    } else if (invite.type === "investor") {
      result = this.getMainStatus(options);

      if (deal.status === "allocating") {
        sub = (
          <p styleName="detail_body">
            <span styleName="blue">{deal.asset.inviteCount}</span> people
            considering
          </p>
        );
      } else if (deal.status === "finalizing") {
        // See committment
        sub = this.renderCommitment(options);
      } else if (deal.status === "finalized") {
        // See status of closing
        const finalized = this.getFinalizedStatus(options);
        sub = finalized.sub;
      }
    }
    return {
      result,
      sub,
    };
  }
  renderStatus() {
    const { result, sub } = this.getStatus();
    return (
      <section styleName="status">
        {result}
        {sub}
      </section>
    );
  }
  handleClick(e) {
    return this.manage(e);
  }
  renderInlineStatus() {
    return this.getStatus({ inline: true }).result;
  }
  render() {
    const { deal, invite } = this.props;

    // If the deal is in the process af closing and the user hasn't invested, nix this invite
    if (deal.status === "finalizing" || deal.status === "finalized") {
      if (invite.type === "investor" && !invite.allocation) {
        return null;
      }
    }

    return (
      <div
        styleName="opportunity_clickable"
        onClick={this.handleClick}
        data-test="opportunity_item"
      >
        <section styleName="header">
          <img src={deal.image} alt="" styleName="pic" />
          <header styleName="titleContainer">
            <div styleName="title">{deal.name}</div>
            <div styleName="inline_status">{this.renderInlineStatus()}</div>
          </header>
        </section>
        {!config.cove.brand.hideHomeSponsors && (
          <section styleName="detail multiple_details extra">
            <section styleName="kinda_extra">
              <header styleName="detail_header">Sponsor</header>
              <p styleName="detail_body">{deal.sponsor.name}</p>
            </section>
            <section>
              <header styleName="detail_header">Syndicate</header>
              <p styleName="detail_body">{deal.syndicate.name}</p>
            </section>
          </section>
        )}

        {this.renderStatus()}
      </div>
    );
  }
}

Opportunity.propTypes = {
  invite: PropTypes.shape({
    type: PropTypes.string.isRequired,
    self: PropTypes.string.isRequired,
    allocation: PropTypes.shape({
      self: PropTypes.string.isRequired,
      documents: PropTypes.shape({
        signed: PropTypes.bool.isRequired,
      }),
    }),
  }).isRequired,
  deal: PropTypes.shape({
    self: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  backing: PropTypes.shape({
    amount: PropTypes.number.isRequired,
  }),
  manage: PropTypes.func.isRequired,
  interest: PropTypes.func.isRequired,
  invest: PropTypes.func.isRequired,
  closing: PropTypes.func.isRequired,
};

Opportunity.fragments = {
  invite: gql`
    fragment OpportunityInvite on Invite {
      type
      deal {
        id
        self
        name
        status
        image
        inviteCount
        allocationCount
        skipWiring
        sponsor {
          name
        }
        syndicate {
          id
          self
          name
        }
        asset {
          id
          self
          name
          inviteCount
          allocationCount
        }
      }
      allocation {
        id
        self
        investment {
          allocated
          funded
          amount
        }
        documents {
          signed
        }
      }
    }
  `,
  backing: gql`
    fragment OpportunityBacking on Backing {
      amount
    }
  `,
};

import { fork } from "redux-saga/effects";

import { sagas as w9 } from "./w92";
import { sagas as unsigned } from "./unsigned";
import { sagas as signed } from "./signed";

export function* sagas() {
  yield fork(w9);
  yield fork(unsigned);
  yield fork(signed);
}

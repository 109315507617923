import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { shortAmount, renderPoints } from "utils";

import "../../style.css";

const AllocatingDealHeaderMetrics = ({
  asset: { raised, allocated, inviteCount }
}) => {
  let done = 0;
  let percentage = 0;
  if (raised > 0 && allocated > 0) {
    done = raised / allocated;
    percentage = raised / allocated;
  }
  if (done > 1) {
    done = 1;
  }
  const bar_width = done * 100 + "%";

  return (
    <section data-test="header_metrics">
      <dl styleName="header_metrics_list">
        {!!raised && (
          <div>
            <dt styleName="days">{shortAmount(raised)}</dt>
            <dd styleName="label">raised</dd>
          </div>
        )}
        {!!percentage && (
          <div>
            <dt styleName="number" data-test="header_allocated">
              {renderPoints(percentage, 0)}
            </dt>
            <dd styleName="label">of allocation</dd>
          </div>
        )}
        <div styleName="extra">
          <dt styleName="number">{inviteCount} investors</dt>
          <dd styleName="label">considering</dd>
        </div>
      </dl>
      <div styleName="progress_bg">
        <div styleName="progress_bar" style={{ width: bar_width }} />
      </div>
    </section>
  );
};

AllocatingDealHeaderMetrics.fragments = {
  asset: gql`
    fragment AllocatingDealHeaderMetricsAsset on Asset {
      allocated
      raised
      inviteCount
    }
  `
};

AllocatingDealHeaderMetrics.propTypes = {
  asset: PropTypes.shape({
    allocated: PropTypes.number.isRequired,
    raised: PropTypes.number.isRequired,
    inviteCount: PropTypes.number.isRequired
  }).isRequired
};

export default AllocatingDealHeaderMetrics;

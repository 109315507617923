import React from "react";
import PropTypes from "prop-types";

import ShareDeal from "components/ShareDeal";

import config from "config";

import "./style.css";

export class DealShare extends React.Component {
  renderLink() {
    const { deal, asset, allocation, invite } = this.props;
    return (
      <section styleName="share_container">
        <ShareDeal
          deal={deal}
          allocation={allocation}
          invite={invite}
          asset={asset}
          data-test="deal_share_share"
        />
      </section>
    );
  }
  renderSeparateShare() {
    const { allocation } = this.props;
    if (allocation) {
      return (
        <section>
          <header styleName="header">
            <h4>Invite a friend</h4>
          </header>
          {this.renderLink()}
        </section>
      );
    }
  }
  render() {
    if (config.cove.brand.hideDealShare) {
      return null;
    }
    return <section>{this.renderSeparateShare()}</section>;
  }
}

DealShare.propTypes = {
  deal: PropTypes.object.isRequired,
  asset: PropTypes.object.isRequired,
  invite: PropTypes.shape({
    type: PropTypes.string.isRequired,
    syndicator: PropTypes.shape({
      self: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  allocation: PropTypes.shape({
    investment: PropTypes.shape({
      amount: PropTypes.number.isRequired,
    }).isRequired,
  }),
  user_self: PropTypes.string.isRequired,
};

export default DealShare;

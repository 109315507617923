import gql from "graphql-tag";

import DealCalc from "./components/DealCalc";
import DecisionDocs from "./components/DecisionDocs";
import Discussion from "./components/Discussion";
import DealInvest from "./components/DealInvest";
import DealHeader from "./components/DealHeader";
import SendAnnouncement from "../announce";

// Query
export const query = gql`
  query DealLead($dealId: ID!, $userId: ID!) {
    deal(id: $dealId) {
      id
      name
      self
      image
      status
      description
      createdAt
      skipWiring
      isManager(user_id: $userId)
      sponsor {
        image
        name
      }
      invite(user_id: $userId) {
        id
        self
        type
        investor_invite_secret
        syndicator {
          id
          self
          firstName
          lastName
          image
        }
        ...DealCalcInvite
      }
      allocation(user_id: $userId) {
        id
        self
        investment {
          amount
          allocated
        }
        ...DealInvestAllocation
        ...SendAnnouncementAllocation
        ...DecisionDocsAllocation
      }
      metrics {
        allocation
        flatFees
        leadIRR
        subleadIRR
      }
      asset {
        id
        self
        term
        location
        allocationCount
        ...DealInvestAsset
        ...DecisionDocsAsset
        ...DealCalcAsset
        ...DiscussionAsset
        ...SendAnnouncementAsset
        ...DealHeaderAsset
      }
      backing(user_id: $userId) {
        id
        self
        ...DealInvestBacking
        ...DealCalcBacking
      }
      syndicate {
        id
        self
        ...DiscussionSyndicate
      }
      ...DealCalcDeal
      ...DecisionDocsDeal
      ...SendAnnouncementDeal
    }
  }
  ${DealCalc.fragments.deal}
  ${DealCalc.fragments.invite}
  ${DealCalc.fragments.backing}
  ${DealCalc.fragments.asset}
  ${DecisionDocs.fragments.deal}
  ${DecisionDocs.fragments.asset}
  ${DecisionDocs.fragments.allocation}
  ${Discussion.fragments.asset}
  ${Discussion.fragments.syndicate}
  ${DealInvest.fragments.asset}
  ${DealInvest.fragments.backing}
  ${DealInvest.fragments.allocation}
  ${SendAnnouncement.fragments.asset}
  ${SendAnnouncement.fragments.allocation}
  ${SendAnnouncement.fragments.deal}
  ${DealHeader.fragments.asset}
`;

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { graphql } from "utils/apollo";
import mutateProps from "utils/mutateProps";

import waitForUser from "utils/waitForUser";
import { handleError as error } from "api/utils";
import { selectUserId, selectUserSelf } from "flows/session/selectors";

import { query, mutation, mutation_props } from "./graphql";
import { nextInvestFlow as done } from "../redux";
import InvestBacking from "./component";
import AddBackingContainer from "./components/AddBacking";

export const InvestBackingContainer = ({
  create,
  createLoading,
  data: { deal, loading },
  done,
  error
}) => {
  const submit = useCallback(
    amount => {
      create(amount)
        .then(done)
        .catch(error);
    },
    [create, done, error]
  );

  return (
    <InvestBacking
      deal={deal}
      loading={loading || createLoading}
      submit={submit}
      skip={done}
      data-test="backing_child"
    />
  );
};

InvestBackingContainer.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    deal: PropTypes.object
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      dealId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  userId: PropTypes.string.isRequired,
  userSelf: PropTypes.string.isRequired
};

const mapPropsToOptions = ({
  match: {
    params: { dealId }
  },
  userId
}) => ({
  variables: { dealId, userId }
});

const mapMutation = props => ({
  create: mutation_props(props)
});

export const mapStore = store => ({
  userId: selectUserId(store),
  userSelf: selectUserSelf(store)
});

export default compose(
  waitForUser(),
  connect(
    mapStore,
    { done, error }
  ),
  graphql(query, {
    options: mapPropsToOptions
  }),
  graphql(mutation, {
    props: mapMutation
  }),
  mutateProps({ name: "create" })
)(InvestBackingContainer);

import React from "react";
import PropTypes from "prop-types";

import Page from "components/Page";
// import RedirectToDeal from "components/RedirectToDeal";

import "./style.css";

/* eslint-disable max-len */
const InterestInvite = ({ login, invite }) => (
  <>
    <div styleName="page-bg" />
    <section className="pageContainer">
      <Page>
        <section styleName="inviteContainer">
          <img src={invite.user.image} alt="" />
          <h3>
            {invite.user.firstName} {invite.user.lastName} invited you to invest
            in {invite.deal.name}
          </h3>
        </section>
        {/* <RedirectToDeal deal={invite.deal}> */}
        <p styleName="login">
          To see this deal, you need to{" "}
          <a onClick={login} role="button" tabIndex="0" data-test="login">
            login
          </a>
        </p>
        {/* </RedirectToDeal> */}
      </Page>
    </section>
  </>
);
/* eslint-enable max-len */

InterestInvite.propTypes = {
  login: PropTypes.func.isRequired,
  invite: PropTypes.shape({
    user: PropTypes.shape({
      image: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }).isRequired,
    deal: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default InterestInvite;

import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import gql from "graphql-tag";
import { fragments, graphql } from "utils";
import { useDispatch } from "react-redux";

import { startInvestFlow as start, nextInvestFlow as next } from "../redux";

import Spinner from "components/Spinner";

import InvestFlow from "./component";
import InvestHeader from "./components/InvestHeader";

import { routeToDealManage } from "flows/routing";

export const InvestFlowContainer = ({
  data: { loading, allocation },
  match: {
    params: { after, confirm },
  },
}) => {
  // Setup
  const { deal, entity } = allocation || {};
  const { asset } = deal || {};
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);

  const initialised = useRef(false);
  useEffect(() => {
    if (!initialised.current && allocation) {
      if (allocation.documents.signed) {
        // Already signed so send them to deal page
        dispatch(routeToDealManage({ deal }));
      } else {
        dispatch(
          start({
            after,
            deal,
            confirmationToast: confirm,
          })
        );
      }
      initialised.current = true;
    }
  }, [allocation]);

  // Loading
  if (loading) {
    return <Spinner data-test="invest_spinner" />;
  }

  // Loaded
  let steps = 1;
  const skip_linking = !!entity.source;
  if (!skip_linking) {
    steps += 1;
  }
  if (!deal.skipWiring) {
    steps += 1;
  }

  const onNext = () => {
    if (step < steps) {
      setStep(step + 1);
    } else {
      dispatch(next());
    }
  };

  return (
    <section className="pageContainer">
      <InvestHeader {...{ deal, skip_linking, step }} />
      <InvestFlow
        {...{ onNext, skip_linking, step }}
        {...fragments(InvestFlow, { deal, entity, allocation, asset })}
      />
    </section>
  );
};

InvestFlowContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      allocationId: PropTypes.string.isRequired,
      after: PropTypes.string.isRequired,
      confirm: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    allocation: PropTypes.shape({
      deal: PropTypes.shape({
        asset: PropTypes.object.isRequired,
      }).isRequired,
      entity: PropTypes.object.isRequired,
    }),
  }).isRequired,
};

export const query = gql`
  query InvestFlow($allocationId: ID!) {
    allocation(id: $allocationId) {
      id
      self
      documents {
        signed
      }
      ...InvestFlowAllocation
      deal {
        id
        skipWiring
        self
        ...InvestFlowDeal
        ...InvestHeaderDeal
        asset {
          id
          self
          ...InvestFlowAsset
        }
      }
      entity {
        id
        self
        ...InvestFlowEntity
      }
    }
  }
  ${InvestFlow.fragments.deal}
  ${InvestFlow.fragments.allocation}
  ${InvestFlow.fragments.entity}
  ${InvestFlow.fragments.asset}
  ${InvestHeader.fragments.deal}
`;

const mapPropsToOptions = ({
  match: {
    params: { allocationId },
  },
}) => ({
  variables: { allocationId },
});

export default compose(
  graphql(query, {
    options: mapPropsToOptions,
  })
)(InvestFlowContainer);

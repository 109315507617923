import React from "react";
import PropTypes from "prop-types";
import { every } from "lodash";

import ModalPage from "components/ModalPage";
import Button, { ModalXButton } from "components/Button";
import Footer from "components/Footer";
import Spinner from "components/Spinner";

import handleInputChange from "utils/handleInputChange";
import {
  validate,
  isValidRoutingNumber,
  isValidAccountNumber
} from "utils/validations";
import "./style.css";

/* eslint-disable max-len */
export class ManualOnboard extends React.Component {
  constructor() {
    super();

    this.state = {
      bankName: "",
      bankAccountType: "checking",
      accountName: "",
      routingNumber: "",
      accountNumber: ""
    };

    // handlers
    this.handleInputChange = handleInputChange.bind(this);
    this.validate = validate.bind(this);
    this.handlePageSubmit = this.handlePageSubmit.bind(this);
  }
  handlePageSubmit() {
    // dispatch submit
    if (this.canSubmit()) {
      this.props.submit(this.state);
    }
  }
  canSubmit() {
    const {
      bankName,
      bankAccountType,
      accountName,
      routingNumber,
      accountNumber
    } = this.state;

    const allEntered =
      !!bankName &&
      !!bankAccountType &&
      !!accountName &&
      !!routingNumber &&
      !!accountNumber;
    return (
      allEntered &&
      isValidRoutingNumber(routingNumber) &&
      isValidAccountNumber(accountNumber)
    );
  }
  render() {
    return (
      <ModalPage>
        <header styleName="header toplevel">
          <h2 styleName="h2">Link Manually</h2>
          <ModalXButton
            onClick={() => {
              this.props.cancel();
            }}
            data-test="manual_cancel"
          />
        </header>
        <p>
          You can add your bank account manually or{" "}
          <a
            onClick={() => {
              this.props.cancel();
            }}
          >
            cancel
          </a>
        </p>
        <form styleName="form toplevel" className="native_form">
          <label htmlFor="bankName" styleName="label">
            Bank Name
          </label>
          <input
            type="text"
            id="bankName"
            name="bankName"
            onChange={this.handleInputChange}
            data-test="manual_bankName"
            styleName="input"
            placeholder="e.g. Chase"
          />
          <label htmlFor="bankAccountType" styleName="label">
            Account Type:
          </label>
          <select
            onChange={this.handleInputChange}
            name="bankAccountType"
            id="bankAccountType"
            data-test="manual_bankAccountType"
            styleName="input"
          >
            <option value="checking">Checking</option>
            <option value="savings">Savings</option>
          </select>
          <label htmlFor="accountName" styleName="label">
            Account Name
          </label>
          <input
            type="text"
            id="accountName"
            name="accountName"
            onChange={this.handleInputChange}
            data-test="manual_accountName"
            styleName="input"
            placeholder="e.g. Real Estate"
          />
          <label htmlFor="routingNumber" styleName="label">
            Routing Number
          </label>
          <input
            type="number"
            id="routingNumber"
            name="routingNumber"
            onChange={this.handleInputChange}
            data-test="manual_routingNumber"
            styleName="input"
            style={this.validate("routingNumber", isValidRoutingNumber)}
          />
          <label htmlFor="accountNumber" styleName="label">
            Account Number
          </label>
          <input
            type="number"
            id="accountNumber"
            name="accountNumber"
            onChange={this.handleInputChange}
            data-test="manual_accountNumber"
            styleName="input"
            style={this.validate("accountNumber", isValidAccountNumber)}
          />
        </form>
        <Footer styleName="toplevel">
          <Button
            onClick={this.handlePageSubmit}
            disabled={!this.canSubmit()}
            data-test="manual_submit"
          >
            Save
          </Button>
        </Footer>
      </ModalPage>
    );
  }
}
/* eslint-enable max-len */

ManualOnboard.propTypes = {
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired
};

export default ManualOnboard;

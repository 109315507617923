exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../../../styles/type.css"), "");

// Module
exports.push([module.id, ".web-flows-deal-lead-components-DealInvest-components-Commitment-___style__committment_container___1jWXM {\n}\n\n.web-flows-deal-lead-components-DealInvest-components-Commitment-___style__committment_label___3HHtQ {\n  margin-bottom: 0.2rem;\n}\n\n.web-flows-deal-lead-components-DealInvest-components-Commitment-___style__committment_amount___30eSp {\n  font-weight: 600;\n}\n\n@media (min-width: 600px) {\n  .web-flows-deal-lead-components-DealInvest-components-Commitment-___style__committment_container___1jWXM {\n    margin-bottom: 1rem;\n  }\n}\n\n@media (max-width: 599px) {\n  .web-flows-deal-lead-components-DealInvest-components-Commitment-___style__committment_container___1jWXM {\n    margin-right: 1rem;\n  }\n}\n", ""]);

// Exports
exports.locals = {
	"committment_container": "web-flows-deal-lead-components-DealInvest-components-Commitment-___style__committment_container___1jWXM",
	"committment_label": "web-flows-deal-lead-components-DealInvest-components-Commitment-___style__committment_label___3HHtQ " + require("-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../../../styles/type.css").locals["light"] + "",
	"committment_amount": "web-flows-deal-lead-components-DealInvest-components-Commitment-___style__committment_amount___30eSp " + require("-!../../../../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../../../../styles/type.css").locals["green"] + ""
};
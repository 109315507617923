import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/react-hooks";

import { query } from "./graphql";

import { fragments } from "utils";

import Spinner from "components/Spinner";
import LinkAccount from "components/LinkAccount";
import Entity from "./components/Entity";

import "./style.css";

const EntitiesContainer = () => {
  const { loading, data, refetch } = useQuery(query);

  const [saving, setSaving] = useState(false);
  const [showLinking, setShowLinking] = useState(false);
  const [entity, setEntity] = useState(null);

  const onLink = ({ entity }) => {
    setEntity(entity);
    setShowLinking(true);
  };
  const onLinking = () => setSaving(true);
  const onLinked = () => {
    refetch().then(onCancel);
  };
  const onCancel = () => {
    setEntity(null);
    setShowLinking(false);
    setSaving(false);
  };

  if (loading || saving) {
    return <Spinner data-test="entities_spinner" />;
  }

  const { userinfo } = data;

  return (
    <section>
      <Entity userinfo={userinfo} link={onLink} data-test="entities_child" />
      {!!showLinking && (
        <LinkAccount
          {...{ onLinking, onLinked, onCancel }}
          user_self={userinfo.self}
          {...fragments(LinkAccount, { entity })}
        />
      )}
    </section>
  );
};

EntitiesContainer.propTypes = {};

export default EntitiesContainer;

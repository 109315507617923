exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!../styles/border.css"), "");

// Module
exports.push([module.id, ".web-styles-___flow__container___1admu {\n  list-style: none;\n  padding-left: 0.5em;\n}\n\n.web-styles-___flow__header___15CWh {\n  padding: 0.3em 0 0.3em 1.3rem;\n  color: #585858;\n  background-repeat: no-repeat;\n  background-position: left center;\n  background-size: 1em;\n  font-weight: 400;\n}\n\n.web-styles-___flow__arrow___1ZdND {\n  background-image: url(\"/images/bullet-arrow.svg\");\n}\n\n.web-styles-___flow__tick___1_e4h {\n  background-image: url(\"/images/bullet-tick.svg\");\n}\n\n.web-styles-___flow__bullet___Xf6oU {\n  background-image: url(\"/images/bullet-grey.svg\");\n}\n\n.web-styles-___flow__description___hmqJm {\n  border-color: #cbc8c8;\n  padding: 0 0 0.66em 0.66em;\n  margin-left: 0.5rem;\n  color: #95989a;\n}\n\n.web-styles-___flow__description_text___1PSDX {\n  margin: 0;\n  position: relative;\n  top: -0.1em;\n}\n\n.web-styles-___flow__description___hmqJm:last-child {\n  border: none;\n  margin-left: 0.66rem;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "web-styles-___flow__container___1admu",
	"header": "web-styles-___flow__header___15CWh",
	"arrow": "web-styles-___flow__arrow___1ZdND web-styles-___flow__header___15CWh",
	"tick": "web-styles-___flow__tick___1_e4h web-styles-___flow__header___15CWh",
	"bullet": "web-styles-___flow__bullet___Xf6oU web-styles-___flow__header___15CWh",
	"description": "web-styles-___flow__description___hmqJm " + require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!../styles/border.css").locals["left"] + "",
	"description_text": "web-styles-___flow__description_text___1PSDX"
};
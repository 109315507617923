import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";

const InvitedInvestorsSub = ({ invites }) => {
  return (
    <p className="section_descriptor">
      Your syndicate has {invites.length} investors
    </p>
  );
};

InvitedInvestorsSub.propTypes = {
  invites: PropTypes.array.isRequired
};

InvitedInvestorsSub.fragments = {
  invite: gql`
    fragment InvitedInvestorsSub on Invite {
      syndicator {
        id
        self
      }
    }
  `
};

export default InvitedInvestorsSub;

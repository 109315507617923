exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/box.css"), "");

// Module
exports.push([module.id, ".web-flows-account-components-Entities-___style__bank___3onhW {\n  margin: 1rem 0;\n  display: flex;\n  padding: 1em 1.5em;\n}\n\n.web-flows-account-components-Entities-___style__block___2boUg {\n  flex-basis: 33%;\n  color: #95989a;\n  border-left: #e5e5e5 1px solid;\n  padding: 0 0.67em;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n@media (max-width: 599px) {\n  .web-flows-account-components-Entities-___style__bank___3onhW {\n    flex-direction: column;\n  }\n\n  .web-flows-account-components-Entities-___style__block___2boUg {\n    flex-basis: auto;\n    border: none;\n    padding: 0.5em 0;\n  }\n}\n\n.web-flows-account-components-Entities-___style__block___2boUg:first-child {\n  border: none;\n  padding-left: 0;\n  flex-basis: 25%;\n}\n\n.web-flows-account-components-Entities-___style__name___33LTb {\n}\n\n.web-flows-account-components-Entities-___style__type___2_GWb {\n}\n.web-flows-account-components-Entities-___style__block___2boUg > p {\n  margin: 0;\n  font-weight: 600;\n}\n\n.web-flows-account-components-Entities-___style__block___2boUg > p > a {\n  font-weight: normal;\n}\n", ""]);

// Exports
exports.locals = {
	"bank": "web-flows-account-components-Entities-___style__bank___3onhW " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/box.css").locals["box"] + "",
	"block": "web-flows-account-components-Entities-___style__block___2boUg",
	"name": "web-flows-account-components-Entities-___style__name___33LTb " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/box.css").locals["box_title"] + "",
	"type": "web-flows-account-components-Entities-___style__type___2_GWb " + require("-!../../../../../node_modules/css-loader/dist/cjs.js??ref--5-1!../../../../styles/box.css").locals["box_subtitle"] + ""
};